import { VirsEditionCheckDataTableHeaderType } from './tableTypes';

export const columnNames: VirsEditionCheckDataTableHeaderType[] = [
  {
    id: 'checkDate',
    numeric: false,
    label: 'Data'
  },
  {
    id: 'docNr',
    numeric: false,
    label: 'Nr.'
  },
  {
    id: 'outletName',
    numeric: false,
    label: 'Susijusios VIP'
  },
  {
    id: 'periodName',
    numeric: false,
    label: 'Tiražo laikotarpis'
  },
  {
    id: 'conclusion',
    numeric: false,
    label: 'Tiražo patikrinimo išvada'
  },
  {
    id: 'checkedByInstitutionName',
    numeric: false,
    label: 'Tiražą patikrino'
  }
];
