export enum ImageMimeTypes {
  Png = 'image/png',
  Jpeg = 'image/jpeg'
}

export function downloadCanvas(
  canvas: HTMLCanvasElement,
  imageType: ImageMimeTypes,
  filename: string
) {
  const a = document.createElement('a');
  a.download = filename;
  a.href = canvas.toDataURL(imageType);
  a.click();
}
