import { ShareholderVirsAnnualFinancialReportsData } from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { Order } from '../../../../../../utils/tableTypes';
import {
  VirsAnnualFinancialReportsColumnHeaderField,
  VirsAnnualFinancialReportsDataTableState,
  VirsAnnualFinancialReportsTableCustomFilter
} from './tableTypes';

export function sortByColumn(
  actionSortBy: VirsAnnualFinancialReportsColumnHeaderField,
  stateSortBy: VirsAnnualFinancialReportsColumnHeaderField,
  stateOrder: Order
) {
  if (actionSortBy === stateSortBy) {
    return stateOrder === 'desc' ? 'asc' : 'desc';
  }
  return 'asc';
}

export function updateCustomFilter(
  filter: VirsAnnualFinancialReportsTableCustomFilter,
  filterBy: VirsAnnualFinancialReportsColumnHeaderField,
  value: string | null
): VirsAnnualFinancialReportsTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function filterAndSortAnnualFinancialReportsDataVirs(
  tableState: VirsAnnualFinancialReportsDataTableState,
  data: ShareholderVirsAnnualFinancialReportsData[]
): ShareholderVirsAnnualFinancialReportsData[] {
  const columnsFilters = tableState.customFilter;

  const filters: Filter<ShareholderVirsAnnualFinancialReportsData>[] = [
    getStringMappedColumnFilter(columnsFilters.documentHeading, ({ documentHeading }) => [
      documentHeading
    ]),
    getStringMappedColumnFilter(
      columnsFilters.financialPeriodEndDate,
      ({ financialPeriodEndDate }) => [financialPeriodEndDate]
    )
  ];

  const filtered = filterData(data, filters);

  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);

  return sorted;
}
