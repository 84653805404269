import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ChartShareholder } from '../../../../store/virsTree/virsTreeDataTypes';
import { ApplicationState } from '../../../../store';
import { PersonInfo } from './PersonInfo';
import { PersonChart } from './PersonChart';
import {
  filterShareholdersByDate,
  findShareholderSiblings,
  getParentObj,
  mayHaveSharePercentages,
  mayHaveVotePercentages,
  setParentNameInPersonCard,
  setProperParentId
} from '../../../../pages/VirsTreePage/treeState/treeFunctions';
import {
  sharesChartData,
  votesChartData
} from '../../../../pages/VirsTreePage/treeState/pieChartFunctions';

interface Props {
  isGroup: boolean;
  shareholderData: ChartShareholder;
  shareType: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    personWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '100%',
      alignItems: 'flex-start',
      '& .VictoryContainer svg g': {
        transform: 'translate(15%, 20px)'
      }
    }
  })
);

export const PersonInfoSection: React.FC<Props> = ({ isGroup, shareholderData, shareType }) => {
  const classes = useStyles();

  const {
    chartData: {
      virsData,
      timeline: { activeDate }
    }
  } = useSelector((state: ApplicationState) => state);

  const { shareholderLevel, shareholderFromGroup, parentId, personAsParentId } = shareholderData;

  const { t } = useTranslation();
  const shareholdersGroupText: string = t('Shareholders group');
  const unprovidedText: string = t('Unprovided');
  const votesOnRulesText: string = t('Votes on rules');
  const authorizedPersonText: string = t('Shareholders authorized person');

  if (!virsData) {
    return <></>;
  }

  const nestedParentObj = getParentObj(
    virsData.shareholders,
    setProperParentId(shareholderLevel, parentId, shareholderFromGroup, personAsParentId)
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parent: any = shareholderLevel === 1 ? virsData : nestedParentObj[0];
  const shareholderSiblings = findShareholderSiblings(
    parent,
    shareholderLevel,
    shareholderFromGroup
  );
  const validShareholderSiblings = filterShareholdersByDate(shareholderSiblings, activeDate);

  const sharesData = sharesChartData(
    validShareholderSiblings,
    shareholdersGroupText,
    unprovidedText,
    authorizedPersonText
  );
  const votesData = votesChartData(
    validShareholderSiblings,
    shareholdersGroupText,
    unprovidedText,
    authorizedPersonText,
    votesOnRulesText
  );

  const hasSharePercentages = mayHaveSharePercentages(
    shareholderLevel,
    parent,
    shareholderFromGroup
  );

  const hasVotePercentages = mayHaveVotePercentages(shareholderLevel, parent, shareholderFromGroup);

  return (
    <div className={`${classes.personWrap} person-wrapper`}>
      <PersonInfo shareTypeName={shareType} isGroup={isGroup} shareholderData={shareholderData} />

      {hasSharePercentages && sharesData ? (
        <PersonChart
          title={shareType}
          parentName={setParentNameInPersonCard(parent, shareholderLevel, shareholderFromGroup)}
          width={300}
          height={250}
          shareholderId={shareholderData.shareholderId}
          chartData={sharesData}
          ariaLabel={t('Contribution share chart')}
        />
      ) : null}

      {hasVotePercentages && votesData ? (
        <PersonChart
          title={t('Votes part')}
          parentName={setParentNameInPersonCard(parent, shareholderLevel, shareholderFromGroup)}
          width={300}
          height={250}
          shareholderId={shareholderData.shareholderId}
          chartData={votesData}
          ariaLabel={t('Votes diagram')}
        />
      ) : null}
    </div>
  );
};
