import React from 'react';
import { useTranslation } from 'react-i18next';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import {
  useVirsEditionCheckTableDispatch,
  useVirsEditionCheckTableState
} from './EditionCheckSection';
import { columnNames } from './tableState/tableColumns';
import {
  VirsEditionCheckDataTableField,
  VirsEditionCheckDataTableHeaderType
} from './tableState/tableTypes';

const EditionCheckCustomizationBar: React.FC = () => {
  const { state } = useVirsEditionCheckTableState();
  const { dispatch } = useVirsEditionCheckTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: VirsEditionCheckDataTableField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: VirsEditionCheckDataTableField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  function getVirsEditionCheckColumnsWithTranslation(): VirsEditionCheckDataTableHeaderType[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`VIRS edition check ${col.id}`)
    }));
  }

  const translatedColumns = getVirsEditionCheckColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default EditionCheckCustomizationBar;
