import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { getDocument } from '../virsisApp/virsisApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  fetchVirsInfoDataError,
  fetchVirsInfoDataSuccess,
  fetchVirsInfoData,
  fetchVirsEnterprseData,
  fetchVirsEnterpriseDataSuccess,
  fetchVirsEnterpriseDataError,
  fetchVirsProfessionalMisconductData,
  fetchVirsProfessionalMisconductDataSuccess,
  fetchVirsProfessionalMisconductDataError,
  fetchVirsEthicalNonComplianceData,
  fetchVirsEthicalNonComplianceDataSuccess,
  fetchVirsEthicalNonComplianceDataError,
  fetchVirsFundsReceivedData,
  fetchVirsFundsReceivedDataSuccess,
  fetchVirsFundsReceivedDataError,
  fetchVirsEditionCheckData,
  fetchVirsEditionCheckDataSuccess,
  fetchVirsEditionCheckDataError,
  fetchVirsLicensesData,
  fetchVirsLicensesDataSuccess,
  fetchVirsLicensesDataError,
  fetchVirsPoliticalAdFinancialSupportData,
  fetchVirsPoliticalAdFinancialSupportDataSuccess,
  fetchVirsPoliticalAdFinancialSupportDataError,
  fetchVirsLegalEntityFinancialSupportData,
  fetchVirsLegalEntityFinancialSupportDataSuccess,
  fetchVirsLegalEntityFinancialSupportDataError,
  fetchVirsIndividualPersonFinancialSupportData,
  fetchVirsIndividualPersonFinancialSupportDataSuccess,
  fetchVirsIndividualPersonFinancialSupportDataError,
  fetchVirsAnnualFinancialReportsData,
  fetchVirsAnnualFinancialReportsDataSuccess,
  fetchVirsAnnualFinancialReportsDataError,
  fetchVirsPaymentData,
  fetchVirsPaymentDataSuccess,
  fetchVirsPaymentDataError,
  fetchVirsOutletsData,
  fetchVirsOutletsDataSuccess,
  fetchVirsOutletsDataError,
  getPaymentDocumentInNewTabRequest,
  getEditionCheckDocumentInNewTabRequest
} from './virsInfoActions';
import {
  EDITION_CHECK_DOCUMENT_PDF_REQUEST,
  PAYMENT_DOCUMENT_PDF_REQUEST,
  VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_REQUEST,
  VIRS_EDITION_CHECK_DATA_REQUEST,
  VIRS_ENTERPRISE_DATA_REQUEST,
  VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
  VIRS_FUNDS_RECEIVED_DATA_REQUEST,
  VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_REQUEST,
  VIRS_INFO_DATA_REQUEST,
  VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_REQUEST,
  VIRS_LICENSES_DATA_REQUEST,
  VIRS_OUTLETS_DATA_REQUEST,
  VIRS_PAYMENT_DATA_REQUEST,
  VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_REQUEST,
  VIRS_PROFESSIONAL_MISCONDUCT_DATA_REQUEST
} from './virsInfoActionTypes';
import {
  getVirsAnnualFinancialReportsData,
  getVirsEditionCheckData,
  getVirsEnterprisesData,
  getVirsEthicalNonComplianceData,
  getVirsFundsReceivedData,
  getVirsIndividualPersonfinancialSupportData,
  getVirsInfoData,
  getVirsLegalEntityFinancialSupportData,
  getVirsLicencesData,
  getVirsOutletsData,
  getVirsPaymentData,
  getVirsPoliticalAdRevenueData,
  getVirsProfessionalMisconductData
} from './virsInfoApi';
import { openDocumentInNewTab } from '../../utils/documentFunctions';
import { ShareholderVirsPoliticalAdFinancialSupportData } from './virsInfoDataTypes';
import { language } from '../virsisApp/virsisSelectors';

function* handleVirsInfoFetchSaga(action: ActionType<typeof fetchVirsInfoData>) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsInfoData, virsId, lang);
    yield put(fetchVirsInfoDataSuccess(data.virsInfo));
  } catch (err) {
    yield put(fetchVirsInfoDataError(err.response));
  }
}

function* handleVirsEnterpriseFetchSaga(action: ActionType<typeof fetchVirsEnterprseData>) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsEnterprisesData, virsId, lang);
    yield put(fetchVirsEnterpriseDataSuccess(data.enterprises));
  } catch (err) {
    yield put(fetchVirsEnterpriseDataError(err.response));
  }
}

function* handleVirsProfessionalMisconductFetchSaga(
  action: ActionType<typeof fetchVirsProfessionalMisconductData>
) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsProfessionalMisconductData, virsId, lang);
    yield put(fetchVirsProfessionalMisconductDataSuccess(data.professionalMisconducts));
  } catch (err) {
    yield put(fetchVirsProfessionalMisconductDataError(err.response));
  }
}

function* handleVirsEthicalNonComplianceFetchSaga(
  action: ActionType<typeof fetchVirsEthicalNonComplianceData>
) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsEthicalNonComplianceData, virsId, lang);
    yield put(fetchVirsEthicalNonComplianceDataSuccess(data.ethicalNonCompliances));
  } catch (err) {
    yield put(fetchVirsEthicalNonComplianceDataError(err.response));
  }
}

function* handleVirsFundsReceivedFetchSaga(action: ActionType<typeof fetchVirsFundsReceivedData>) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsFundsReceivedData, virsId, lang);
    yield put(fetchVirsFundsReceivedDataSuccess(data.fundsReceived));
  } catch (err) {
    yield put(fetchVirsFundsReceivedDataError(err.response));
  }
}

function* handleVirsEditionCheckFetchSaga(action: ActionType<typeof fetchVirsEditionCheckData>) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsEditionCheckData, virsId, lang);
    yield put(fetchVirsEditionCheckDataSuccess(data.editionChecks));
  } catch (err) {
    yield put(fetchVirsEditionCheckDataError(err.response));
  }
}

function* handleEditionCheckDocumentRequestSaga(
  action: ActionType<typeof getEditionCheckDocumentInNewTabRequest>
) {
  try {
    const { editionCheckDocumentId, documentNumber } = action.payload;
    const { data } = yield call(getDocument, editionCheckDocumentId);
    const file = new Blob([data], { type: 'application/pdf' });
    openDocumentInNewTab(file, `${documentNumber}.pdf`);
  } catch (err) {
    yield put(sendMessage('error', 'Dokumentas nepasiekiamas'));
  }
}

function* handleVirsPaymentFetchSaga(action: ActionType<typeof fetchVirsPaymentData>) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsPaymentData, virsId, lang);
    yield put(fetchVirsPaymentDataSuccess(data.annualPayments));
  } catch (err) {
    yield put(fetchVirsPaymentDataError(err.response));
  }
}

function* handlePaymentDocumentRequestSaga(
  action: ActionType<typeof getPaymentDocumentInNewTabRequest>
) {
  try {
    const { paymentDocumentId, documentNumber } = action.payload;
    const { data } = yield call(getDocument, paymentDocumentId);
    const file = new Blob([data], { type: 'application/pdf' });
    openDocumentInNewTab(file, `${documentNumber}.pdf`);
  } catch (err) {
    yield put(sendMessage('error', 'Dokumentas nepasiekiamas'));
  }
}

function* handleVirsLicensesFetchSaga(action: ActionType<typeof fetchVirsLicensesData>) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsLicencesData, virsId, lang);
    yield put(fetchVirsLicensesDataSuccess(data.licenses));
  } catch (err) {
    yield put(fetchVirsLicensesDataError(err.response));
  }
}

function* handleVirsPoliticalAdFinancialSupportFetchSaga(
  action: ActionType<typeof fetchVirsPoliticalAdFinancialSupportData>
) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const {
      data: { politicalAdRevenues }
    }: {
      data: {
        politicalAdRevenues: (Omit<
          ShareholderVirsPoliticalAdFinancialSupportData,
          'financialSupportAmount'
        > & { financialSupportAmount: number })[];
      };
    } = yield call(getVirsPoliticalAdRevenueData, virsId, lang);

    yield put(
      fetchVirsPoliticalAdFinancialSupportDataSuccess(
        politicalAdRevenues.map(({ financialSupportAmount, ...value }) => ({
          ...value,
          financialSupportAmount: `${financialSupportAmount}`
        }))
      )
    );
  } catch (err) {
    yield put(fetchVirsPoliticalAdFinancialSupportDataError(err.response));
  }
}

function* handleLegalEntityFinancialSupportFetchSaga(
  action: ActionType<typeof fetchVirsLegalEntityFinancialSupportData>
) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsLegalEntityFinancialSupportData, virsId, lang);
    yield put(
      fetchVirsLegalEntityFinancialSupportDataSuccess(data.legalPersonFinancialSupportReceived)
    );
  } catch (err) {
    yield put(fetchVirsLegalEntityFinancialSupportDataError(err.response));
  }
}

function* handleVirsIndividualPersonFinancialSupportFetchSaga(
  action: ActionType<typeof fetchVirsIndividualPersonFinancialSupportData>
) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsIndividualPersonfinancialSupportData, virsId, lang);
    yield put(
      fetchVirsIndividualPersonFinancialSupportDataSuccess(
        data.naturalPersonFinancialSupportReceived
      )
    );
  } catch (err) {
    yield put(fetchVirsIndividualPersonFinancialSupportDataError(err.response));
  }
}

function* handleVirsAnnualFinancialReportsFetchSaga(
  action: ActionType<typeof fetchVirsAnnualFinancialReportsData>
) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsAnnualFinancialReportsData, virsId, lang);
    yield put(fetchVirsAnnualFinancialReportsDataSuccess(data.annualFinancialReports));
  } catch (err) {
    yield put(fetchVirsAnnualFinancialReportsDataError(err.response));
  }
}

function* handleVirsOutletsFetchSaga(action: ActionType<typeof fetchVirsOutletsData>) {
  try {
    const lang = yield select(language);
    const { virsId } = action.payload;
    const { data } = yield call(getVirsOutletsData, virsId, lang);
    yield put(fetchVirsOutletsDataSuccess(data.virsOutlets));
  } catch (err) {
    yield put(fetchVirsOutletsDataError(err.response));
  }
}

function* virsInfoSaga() {
  yield takeEvery(VIRS_INFO_DATA_REQUEST, handleVirsInfoFetchSaga);
  yield takeEvery(VIRS_ENTERPRISE_DATA_REQUEST, handleVirsEnterpriseFetchSaga);
  yield takeEvery(
    VIRS_PROFESSIONAL_MISCONDUCT_DATA_REQUEST,
    handleVirsProfessionalMisconductFetchSaga
  );
  yield takeEvery(
    VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
    handleVirsEthicalNonComplianceFetchSaga
  );
  yield takeEvery(VIRS_FUNDS_RECEIVED_DATA_REQUEST, handleVirsFundsReceivedFetchSaga);
  yield takeEvery(VIRS_EDITION_CHECK_DATA_REQUEST, handleVirsEditionCheckFetchSaga);
  yield takeEvery(EDITION_CHECK_DOCUMENT_PDF_REQUEST, handleEditionCheckDocumentRequestSaga);
  yield takeEvery(VIRS_PAYMENT_DATA_REQUEST, handleVirsPaymentFetchSaga);
  yield takeEvery(PAYMENT_DOCUMENT_PDF_REQUEST, handlePaymentDocumentRequestSaga);
  yield takeEvery(VIRS_LICENSES_DATA_REQUEST, handleVirsLicensesFetchSaga);
  yield takeEvery(
    VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_REQUEST,
    handleVirsPoliticalAdFinancialSupportFetchSaga
  );
  yield takeEvery(
    VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_REQUEST,
    handleLegalEntityFinancialSupportFetchSaga
  );
  yield takeEvery(
    VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_REQUEST,
    handleVirsIndividualPersonFinancialSupportFetchSaga
  );
  yield takeEvery(
    VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_REQUEST,
    handleVirsAnnualFinancialReportsFetchSaga
  );
  yield takeEvery(VIRS_OUTLETS_DATA_REQUEST, handleVirsOutletsFetchSaga);
}

export default virsInfoSaga;
