import { getWithQuery } from '../../utils/axiosApi';
import {
  LegalPersonSearchQuery,
  NaturalPersonSearchQuery,
  OutletSearchQuery
} from './virsSearchTypes';
import { Language } from '../../utils/tableTypes';

export const getFindVirsByPerson = (
  personSearchQuery: NaturalPersonSearchQuery,
  lang: Language
) => {
  return getWithQuery('/virs-lookup/by-natural-person', {
    params: {
      ...personSearchQuery,
      lang
    }
  });
};

export const getFindVirsByLegal = (legalSearchQuery: LegalPersonSearchQuery, lang: Language) => {
  return getWithQuery('/virs-lookup/by-legal-person', {
    params: { ...legalSearchQuery, lang }
  });
};

export const getFindVirsByOutlet = (query: OutletSearchQuery, lang: Language) => {
  return getWithQuery('/virs-lookup/by-outlet', {
    params: {
      ...query,
      lang
    }
  });
};

export const getOutletTypes = (lang: Language) =>
  getWithQuery('/classifiers/outlet-types', { params: { lang } });
