import React from 'react';

interface Props {
  color: string;
}

export const ShareholdersPieNotFilled: React.FC<Props> = ({ color }) => {
  return (
    <svg width="17.13" height="16.969">
      <g fill={color}>
        <path
          data-name="Path 848"
          d="M7.318 7.882H.56a.56.56 0 01-.56-.56A7.332 7.332 0 017.323 0a.56.56 0 01.56.56v6.763a.566.566 0 01-.565.559zM1.141 6.763h5.617V1.141a6.22 6.22 0 00-5.617 5.622z"
        />
        <path
          data-name="Path 849"
          d="M9.807 16.968a7.332 7.332 0 01-7.323-7.323.56.56 0 01.56-.56h6.2v-6.2a.56.56 0 01.559-.56 7.321 7.321 0 110 14.641zm-6.181-6.759a6.2 6.2 0 106.736-6.736V9.65a.56.56 0 01-.56.559z"
        />
      </g>
    </svg>
  );
};
