import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderVirsLicensesData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsLicensesTableState } from './LicensesSection';

interface Props {
  record: ShareholderVirsLicensesData;
}

export const LicensesRow: React.FC<Props> = ({ record }) => {
  const { state: tableState } = useVirsLicensesTableState();
  return (
    <TableRow key={record.licenseId}>
      {tableState.columnsDisplayStatus.licenseIssueDate && (
        <TableCell>{record.licenseIssueDate}</TableCell>
      )}
      {tableState.columnsDisplayStatus.licenseNumber && (
        <TableCell>{record.licenseNumber} </TableCell>
      )}
      {tableState.columnsDisplayStatus.licenseTypeName && (
        <TableCell>{record.licenseTypeName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.enterpriseTypeName && (
        <TableCell>{record.enterpriseTypeName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.licenseStatus && (
        <TableCell>{record.licenseStatus}</TableCell>
      )}
      {tableState.columnsDisplayStatus.validFrom && <TableCell>{record.validFrom}</TableCell>}
      {tableState.columnsDisplayStatus.validTo && <TableCell>{record.validTo}</TableCell>}
      {tableState.columnsDisplayStatus.issuingInstitutionName && (
        <TableCell>{record.issuingInstitutionName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.licenseNotes && (
        <TableCell dangerouslySetInnerHTML={{ __html: record.licenseNotes }} />
      )}
    </TableRow>
  );
};
