import React from 'react';
import { TableHead, TableRow } from '@material-ui/core/';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ColumnHeader } from '../../../../../utils/tableTypes';
import { OutletEditionColumnHeaderField, OutletEditionTableState } from './tableState/tableTypes';
import { useOutletEditionTableDispatch, useOutletEditionTableState } from './EditionSection';
import { getSortLabelPropsFactory } from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { ApplicationState } from '../../../../../store';

interface Props {
  columns: ColumnHeader<OutletEditionColumnHeaderField>[];
}

const EditionTableHead: React.FC<Props> = () => {
  const editionsPeriodic = useSelector(
    (state: ApplicationState) => state?.outletInfoData?.outletInfo?.editionsPeriodic
  );
  const { state: tableState } = useOutletEditionTableState();
  const { dispatch } = useOutletEditionTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: OutletEditionColumnHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    OutletEditionTableState,
    typeof handleSetSortByColumn,
    OutletEditionColumnHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.periodName && (
          <TableSortingHeaderCell
            label={editionsPeriodic ? t('Edition duration') : t('Edition nonPeriodicName')}
            columnName="periodName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.circulation && (
          <TableSortingHeaderCell
            label={t('Edition circulation')}
            columnName="circulation"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default EditionTableHead;
