import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import { CustomButton } from '../Buttons/CustomButton';

import { IconButtonWrapper } from '../Buttons/IconButtonWrapper';
import { DownloadButtonsContainer } from '../ButtonsContainers/DownloadButtonsContainer';

export const MiddlePanel = () => (
  <>
    <div style={{ marginBottom: 10, width: 'fit-content' }}>
      <IconButtonWrapper icon={<SaveIcon />}>
        <DownloadButtonsContainer />
      </IconButtonWrapper>
    </div>

    <div style={{ width: 'fit-content' }}>
      <IconButtonWrapper icon={<SearchIcon />}>
        <CustomButton />
      </IconButtonWrapper>
    </div>
  </>
);
