import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Moment } from 'moment';
import MomentUtils from '@date-io/moment';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, Button, Hidden, IconButton, makeStyles, Typography } from '@material-ui/core';
import { ArrowRightAlt, Today } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { changeActiveDate } from './state/actions';
import { selector } from './state/selector';
import { getAdjacentEvents, shouldDisableDate } from './dateUtil';
import theme from '../../../style/virsisTheme';
import { language as languageSelector } from '../../../store/virsisApp/virsisSelectors';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { VirsTreeCustomTooltip } from '../VirsTreeCustomTooltip';
import { ActivityPeriod } from '../../../store/virsisApp/virsisReducer';

const useStyles = makeStyles({
  dateNavigation: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: '#fff',
    width: '100%',
    display: 'flex',
    boxShadow: '0px 0px 4px 6px rgba(0,0,0,0.1)',
    alignItems: 'center',
    '&>*': {
      flex: 1,
      borderRadius: 0,
      minWidth: '200px',
      '&:first-child': {
        borderRight: 'solid 1px #c3e2fb'
      },
      '&:last-child': {
        borderLeft: 'solid 1px #c3e2fb'
      }
    }
  },
  sideButton: {
    minWidth: 'auto',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.primary.main}`
    },
    '&:active': {
      backgroundColor: '#F5F8FF',
      border: '1px solid #006FB2'
    }
  },
  sideButtonLabel: {
    display: 'flex',
    placeContent: 'center'
  },
  sideButtonIcon: {
    fill: '#006fb1',
    height: 20,
    width: 20
  },
  sideButtonText: {
    fontWeight: 600
  },
  sideButtonDisabled: {
    '& *': {
      color: 'rgba(0, 0, 0, 0.54)',
      fill: 'rgba(0, 0, 0, 0.54)'
    }
  },
  dateSection: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dateText: {
    fontWeight: 'bold',
    fontSize: '1em',
    textAlign: 'center'
  }
});

function getLongFormatDate(date: Moment, locale: string): string {
  return date.locale(locale).format('ll');
}

interface Props {
  minDate?: string;
  maxDate: string;
  activityPeriods: ActivityPeriod[];
}

export const TimelineNavigation = ({ minDate, maxDate, activityPeriods }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const activeDate = useSelector(selector.activeDate);
  const timelineEvents = useSelector(selector.timelineEvents);
  const [showPicker, setShowPicker] = useState(false);
  const { t, i18n } = useTranslation();

  const { following, previous } = getAdjacentEvents(activeDate, timelineEvents);

  function handleChange(value: Moment) {
    return dispatch(changeActiveDate(value));
  }

  const tooltipWrapper = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    let text = '';

    if (shouldDisableDate(activityPeriods, day)) text = t('VIRS was not active during this period');

    return (
      <VirsTreeCustomTooltip
        className={'MuiButtonBase-root MuiIconButton-root MuiPickersDay-day'}
        children={<div>{dayComponent}</div>}
        text={text}
      />
    );
  };

  return (
    <>
      <Box className={classes.dateNavigation}>
        <Button
          className={classes.sideButton}
          classes={{
            label: classes.sideButtonLabel,
            disabled: classes.sideButtonDisabled
          }}
          style={{ borderTopLeftRadius: '5px' }}
          disabled={!previous}
          onClick={() => previous && handleChange(previous.date)}
        >
          <ArrowRightAlt
            className={classes.sideButtonIcon}
            style={{ margin: 'auto 20 auto 0', transform: 'rotate(180deg)' }}
          />

          <Hidden smDown>
            <Typography className={classes.sideButtonText} color="primary">
              {t('Previous change')}
            </Typography>
          </Hidden>
        </Button>

        <div className={classes.dateSection}>
          <Typography className={classes.dateText}>
            {getLongFormatDate(activeDate, i18n.language)}
          </Typography>
          <IconButton
            size="small"
            color="primary"
            style={{ marginLeft: '10px' }}
            onClick={() => setShowPicker(!showPicker)}
          >
            <Today />
          </IconButton>
        </div>

        <Button
          disabled={!following}
          className={classes.sideButton}
          classes={{
            label: classes.sideButtonLabel,
            disabled: classes.sideButtonDisabled
          }}
          style={{ borderTopRightRadius: '5px' }}
          onClick={() => following && handleChange(following.date)}
        >
          <Hidden smDown>
            <Typography className={classes.sideButtonText} color="primary">
              {t('Next change')}{' '}
            </Typography>
          </Hidden>

          <ArrowRightAlt className={classes.sideButtonIcon} style={{ margin: 'auto 0 auto 20' }} />
        </Button>
      </Box>

      <MuiPickersUtilsProvider locale={language} utils={MomentUtils}>
        <KeyboardDatePicker
          renderDay={(
            day: MaterialUiPickersDate,
            selectedDate: MaterialUiPickersDate,
            dayInCurrentMonth: boolean,
            dayComponent: JSX.Element
          ) => {
            return tooltipWrapper(day, selectedDate, dayInCurrentMonth, dayComponent);
          }}
          format="YYYY-MM-DD"
          value={activeDate}
          minDate={minDate}
          maxDate={maxDate}
          okLabel={t('Select')}
          cancelLabel={t('Cancel')}
          clearLabel={t('Clear')}
          shouldDisableDate={(date) => shouldDisableDate(activityPeriods, date)}
          onChange={(value) => value && handleChange(value)}
          style={{ display: 'none' }}
          open={showPicker}
          onClose={() => setShowPicker(false)}
          minDateMessage={t('Date should not be before minimal date')}
          maxDateMessage={t('Date should not be after maximal date')}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
