import { Paper, Table, TableBody, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../store/virsisApp/virsisDataTypes';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { TableLoader } from '../../../../../components/TableLoader/TableLoad';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import ProfessionalMisconductCustomFilter from './ProfessionalMisconductCustomFilter';
import ProfessionalMisconductCustomizationBar from './ProfessionalMisconductCustomizationBar';
import { ProfessionalMisconductRow } from './ProfessionalMisconductRow';
import {
  useVirsProfessionalMisconductTableDispatch,
  useVirsProfessionalMisconductTableState
} from './ProfessionalMisconductSection';
import { ProfessionalMisconductTableHead } from './ProfessionalMisconductTableHead';
import { filterAndSortProfessionalMisconductTableData } from './tableState/tableFunctions';

interface Props {
  chosenDate: string;
  onClickOutlet: (outledId: number, outletName: string) => void;
}

export const VirsProfessionalMisconductTable: React.FC<Props> = ({ chosenDate, onClickOutlet }) => {
  const { state: tableState } = useVirsProfessionalMisconductTableState();
  const { dispatch, dataFilter } = useVirsProfessionalMisconductTableDispatch();

  const {
    VirsProfessionalMisconductData,
    loadingVirsProfessionalMisconductData,
    virsProfessionalMisconductError
  } = useSelector((state: ApplicationState) => state.virsInfoData);

  const allData = (VirsProfessionalMisconductData || []).filter(dataFilter);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const data = filterAndSortProfessionalMisconductTableData(tableState, allData, chosenDate);
  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <>
        {virsProfessionalMisconductError ? (
          <Typography variant="caption">{virsProfessionalMisconductError}</Typography>
        ) : (
          <div className="data-table">
            <ProfessionalMisconductCustomizationBar data={allData} />
            <Table>
              <ProfessionalMisconductTableHead />
              {tableState.customFilterOn && <ProfessionalMisconductCustomFilter />}
              <TableBody>
                {loadingVirsProfessionalMisconductData ? (
                  <TableLoader columns={8} />
                ) : (
                  <>
                    {dataPage.map((record) => (
                      <ProfessionalMisconductRow
                        key={record.professionalMisconductId}
                        record={record}
                        onClickOutlet={onClickOutlet}
                      />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              recordsCount={data.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={handleSetPage}
              setRowsPerPage={handleSetRowsPerPage}
              disabled={data.length === 0}
            />
          </div>
        )}
      </>
    </Paper>
  );
};
