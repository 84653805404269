import { Paper, Table, TableBody, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../store/virsisApp/virsisDataTypes';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import { LegalEntityFinancialSupportCustomFilter } from './LegalEntityFinancialSupportCustomFilter';
import LegalEntityFinancialSupportCustomizationBar from './LegalEntityFinancialSupportCustomizationBar';
import { LegalEntityFinancialSupportHead } from './LegalEntityFinancialSupportHead';
import {
  useVirsLegalEntityFinancialSupportTableDispatch,
  useVirsLegalEntityFinancialSupportTableState
} from './LegalEntityFinancialSupportSection';
import { LegalEntityFinancialSupportRow } from './LegalEntityFinancialSupportRow';
import { filterAndSortLegalEntityFinancialSupportDataVirs } from './tableState/tableFunctions';
import { TableLoader } from '../../../../../components/TableLoader/TableLoad';

interface Props {
  chosenDate: string;
}

export const VirsLegalEntityFinancialSupportTable: React.FC<Props> = ({ chosenDate }) => {
  const { state: tableState } = useVirsLegalEntityFinancialSupportTableState();
  const { dispatch } = useVirsLegalEntityFinancialSupportTableDispatch();

  const {
    VirsLegalEntityFinancialSupportData,
    loadingVirsLegalEntityFinancialSupportData,
    virsLegalEntityFinancialSupportError
  } = useSelector((state: ApplicationState) => state.virsInfoData);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'SET_PAGE', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'SET_ROWS_PER_PAGE',
      rowsPerPage
    });
  };

  const data = filterAndSortLegalEntityFinancialSupportDataVirs(
    tableState,
    VirsLegalEntityFinancialSupportData || [],
    chosenDate
  );

  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <>
        {virsLegalEntityFinancialSupportError ? (
          <Typography variant="caption">{virsLegalEntityFinancialSupportError}</Typography>
        ) : (
          <div className="data-table">
            <LegalEntityFinancialSupportCustomizationBar
              data={VirsLegalEntityFinancialSupportData || []}
            />
            <Table>
              <LegalEntityFinancialSupportHead />
              {tableState.customFilterOn && <LegalEntityFinancialSupportCustomFilter />}
              <TableBody>
                {loadingVirsLegalEntityFinancialSupportData ? (
                  <TableLoader columns={6} />
                ) : (
                  <>
                    {dataPage.map((record) => (
                      <LegalEntityFinancialSupportRow
                        key={record.financialSupportReceivedId}
                        record={record}
                      />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              recordsCount={data.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={handleSetPage}
              setRowsPerPage={handleSetRowsPerPage}
              disabled={data.length === 0}
            />
          </div>
        )}
      </>
    </Paper>
  );
};
