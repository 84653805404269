import { ColumnHeader, Order } from '../../../../../../utils/tableTypes';

export type VirsEditionCheckDataTableHeaderType = ColumnHeader<VirsEditionCheckDataTableField>;

export type VirsEditionCheckDataTableField =
  | 'checkDate'
  | 'docNr'
  | 'outletName'
  | 'periodName'
  | 'conclusion'
  | 'checkedByInstitutionName';

export interface VirsEditionCheckDataTableState {
  customFilter: VirsEditionCheckDataTableCustomFilter;
  customFilterOn: boolean;
  order: Order;
  sortBy: VirsEditionCheckDataTableField;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: VirsEditionCheckDataTableColumnsDisplayStatus;
}

export interface VirsEditionCheckDataTableCustomFilter {
  checkDate: string[];
  docNr: string[];
  outletName: string[];
  periodName: string[];
  conclusion: string[];
  checkedByInstitutionName: string[];
}

export interface VirsEditionCheckDocsCustomFilter {
  docNr: string[];
}

export interface VirsEditionCheckVipsCustomFilter {
  outletName: string[];
  editionCheckConclusionsCustomFilter: VirsEditionCheckConclusionsCustomFilter;
}

export interface VirsEditionCheckConclusionsCustomFilter {
  periodName: string[];
  conclusion: string[];
}

export type VirsEditionCheckDataTableColumnsDisplayStatus = {
  // eslint-disable-next-line
  [key in VirsEditionCheckDataTableField]: boolean;
};

export type VirsEditionCheckDataTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: VirsEditionCheckDataTableField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: VirsEditionCheckDataTableField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: VirsEditionCheckDataTableField };

export const initialVirsEditionCheckDataTableState: VirsEditionCheckDataTableState = {
  order: 'desc',
  sortBy: 'checkDate',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: {
    checkDate: true,
    docNr: true,
    outletName: true,
    periodName: true,
    conclusion: true,
    checkedByInstitutionName: true
  },
  customFilterOn: false,
  customFilter: {
    checkDate: [],
    docNr: [],
    outletName: [],
    periodName: [],
    conclusion: [],
    checkedByInstitutionName: []
  }
};
