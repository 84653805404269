import { makeStyles } from '@material-ui/core';
import React from 'react';
import { BottomPanel } from './PanelBottom/BottomPanel';
import { MiddlePanel } from './PanelMiddle/MiddlePanel';
import { TopPanel } from './PanelTop/TopPanel';
import { PaperWrapper } from './Wrappers/PaperWrapper';

const useStyles = makeStyles({
  container: {
    paddingTop: 'auto',
    paddingBottom: 'auto',
    paddingRight: '20px',
    paddingLeft: '20px'
  }
});

export const SideNavigation = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <PaperWrapper>
        <TopPanel />
      </PaperWrapper>

      <PaperWrapper>
        <MiddlePanel />
      </PaperWrapper>

      <PaperWrapper>
        <BottomPanel />
      </PaperWrapper>
    </div>
  );
};
