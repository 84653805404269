import React from 'react';
import { TableHead, TableRow } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { getSortLabelPropsFactory } from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  VirsIndividualPersonFinancialSupportColumnHeaderField,
  VirsIndividualPersonFinancialSupportDataTableState
} from './tableState/tableTypes';
import {
  useVirsIndividualPersonFinancialSupportTableDispatch,
  useVirsIndividualPersonFinancialSupportTableState
} from './IndividualPersonFinancialSupportSection';

export const IndividualPersonFinancialSupportHead: React.FC = () => {
  const { state: tableState } = useVirsIndividualPersonFinancialSupportTableState();
  const { dispatch: tableDispatch } = useVirsIndividualPersonFinancialSupportTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsIndividualPersonFinancialSupportColumnHeaderField) => {
    tableDispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsIndividualPersonFinancialSupportDataTableState,
    typeof handleSetSortByColumn,
    VirsIndividualPersonFinancialSupportColumnHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.supportProviderFullName && (
          <TableSortingHeaderCell
            label={t('VIRS individual financial support supportProviderFullName')}
            columnName="supportProviderFullName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.financialSupportAmount && (
          <TableSortingHeaderCell
            label={t('VIRS individual financial support financialSupportAmount')}
            columnName="financialSupportAmount"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.supportReceivedYear && (
          <TableSortingHeaderCell
            label={t('VIRS individual financial support supportReceivedYear')}
            columnName="supportReceivedYear"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
