import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { CircleIcon } from '../../../components/Icons/CircleIcon';
import { RectIcon } from '../../../components/Icons/RectIcon';

interface Props {
  relativeOffset?: number;
  outletChange?: boolean;
  shareholderChange?: boolean;
  shareholderEnd?: boolean;
  cursor?: boolean;
  temp?: boolean;
  dateAvailable?: boolean;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  eventCount?: number;
  helperText?: string;
}

const useStyles = makeStyles({
  indicatorWrapper: {
    position: 'absolute',
    bottom: 0,
    width: '16px',
    height: '16px',
    zIndex: 1
  },
  indicatorTransform: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    transform: 'translate(-50%, 50%)',
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  indicatorTextTransform: {
    position: 'absolute',
    fontSize: '0.9em',
    transform: 'translate(-50%, -50%)',
    color: '#9d9d9d',
    fontWeight: 600,
    zIndex: 1,
    userSelect: 'none'
  },
  indicatorHelperTransform: {
    position: 'absolute',
    fontSize: '0.9em',
    whiteSpace: 'nowrap',
    transform: 'translate(-50%, 150%)',
    color: '#9d9d9d',
    fontWeight: 600,
    zIndex: 1,
    userSelect: 'none'
  }
});

export const TimelineIndicator: React.FC<Props> = ({
  relativeOffset,
  outletChange,
  shareholderChange,
  shareholderEnd,
  temp,
  dateAvailable,
  cursor,
  onClick,
  eventCount,
  helperText
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const offset = relativeOffset || 0;

  return (
    <Box
      className={classes.indicatorWrapper}
      style={{
        left: `${offset * 100}%`,
        pointerEvents: temp ? 'none' : undefined
      }}
      onClick={onClick}
    >
      {cursor && !shareholderEnd && (
        <CircleIcon
          className={classes.indicatorTransform}
          color={theme.palette.primary.main}
          size="16px"
        />
      )}

      {cursor && shareholderEnd && (
        <RectIcon
          className={classes.indicatorTransform}
          color={theme.palette.primary.main}
          size="20px"
        />
      )}

      {temp && dateAvailable && (
        <CircleIcon
          className={classes.indicatorTransform}
          color="rgba(0, 111, 178, 0.3)"
          size="16px"
        />
      )}

      {temp && !dateAvailable && (
        <CircleIcon
          className={classes.indicatorTransform}
          color="rgba(111, 111, 111, 0.3)"
          size="16px"
        />
      )}

      {shareholderChange && !shareholderEnd && !cursor && (
        <CircleIcon
          className={classes.indicatorTransform}
          color={theme.palette.primary.main}
          innerSize={0.8}
          fillInner={cursor ? theme.palette.primary.main : 'white'}
          size="16px"
        />
      )}

      {shareholderEnd && !cursor && (
        <RectIcon
          className={classes.indicatorTransform}
          color={theme.palette.primary.main}
          fillInner={cursor ? theme.palette.primary.main : 'white'}
          size="20px"
        />
      )}

      {outletChange && (
        <CircleIcon
          className={classes.indicatorTransform}
          color="#b8581b"
          innerSize={0.5}
          size="10px"
          fillInner="white"
        />
      )}

      {eventCount && (
        <Typography className={classes.indicatorTextTransform}>+{eventCount}</Typography>
      )}

      {helperText && (
        <Typography className={classes.indicatorHelperTransform}>{helperText}</Typography>
      )}
    </Box>
  );
};
