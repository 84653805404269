import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  useVirsTreeDispatch,
  useVirsTreeState
} from '../../pages/VirsTreePage/VirsTreePageContext';
import { ApplicationState } from '../../store';

import { AdPrintingSection } from './VirsTreePreviewCards/OutletCard/AdPrintingSection/AdPrintingSection';
import { CategorySection } from './VirsTreePreviewCards/OutletCard/CategorySection/CategorySection';
import { EditionSection } from './VirsTreePreviewCards/OutletCard/EditionSection/EditionSection';
import { OutletInfoSection } from './VirsTreePreviewCards/OutletCard/InfoSection/OutletInfoSection';
import { ResponsiblePersonsSection } from './VirsTreePreviewCards/OutletCard/ResponsiblePersonsSection/ResponsiblePersonsSectionSection';
import {
  NavigationBar,
  ShareholderPreviewCardSubNavTab
} from './VirsTreePreviewCards/ShareholderPreviewCardNavigation/NavigationBar';
import { fetchShareholderOutletInfoData } from '../../store/virsTreeOutletInfo/outletInfoActions';
import { GenericDataTableContainer } from '../../components/GenericDataTable/GenericDataTableContainer';
import { fetchGenericTableDataRequest } from '../../store/genericDataTable/genericDataTableAction';
import { VirsTreePreviewCards } from './VirsTreePreviewCards/VirsTreePreviewCards';
import {
  getActiveAndSortedTablesByType,
  mapIntoActiveTabs,
  insertDefaultOutletInfoTab,
  openTabByKeyname,
  getAllTabsByThisOutletType
} from '../../pages/VirsTreePage/treeState/treeFunctions';

const OutletPreviewContainer: React.FC = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<ShareholderPreviewCardSubNavTab[] | null>(null);

  const openTab = tabs ? tabs.find((tab) => tab.open) : null;

  const {
    treeState: { virsId, preopenOutletCardTab, outletId, outletName }
  } = useVirsTreeState();
  const { treeDispatch } = useVirsTreeDispatch();

  const { graphicalRepresentationToolData } = useSelector(
    (state: ApplicationState) => state.graphicalRepresentationToolData
  );

  const { loadingOutletInfo, outletInfo, outletInfoError } = useSelector(
    (state: ApplicationState) => state.outletInfoData
  );

  const {
    chartData: {
      timeline: { activeDate }
    },
    virsis: { language }
  } = useSelector((state: ApplicationState) => state);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    const activeTables = getActiveAndSortedTablesByType(
      graphicalRepresentationToolData || [],
      'VIP'
    );

    const activeTabsFromTables = mapIntoActiveTabs(activeTables, language);

    const activeTabsWithDefaultTab = insertDefaultOutletInfoTab(
      activeTabsFromTables,
      t('Outlet default tab name')
    );

    const withPreopenedTab = openTabByKeyname(
      activeTabsWithDefaultTab,
      preopenOutletCardTab || 'outletInfo'
    );

    setTabs(outletInfo ? getAllTabsByThisOutletType(outletInfo, withPreopenedTab) : null);
    // eslint-disable-next-line
  }, [outletInfo]);

  useEffect(() => {
    reduxDispatch(fetchShareholderOutletInfoData(virsId, outletId));
  }, [outletId, virsId, reduxDispatch]);

  useEffect(() => {
    if (openTab && openTab.url && openTab.url !== '/') {
      reduxDispatch(fetchGenericTableDataRequest(`${openTab.url}/${virsId}/${outletId}`));
    }
    // eslint-disable-next-line
  }, [reduxDispatch, openTab]);

  const handleCloseOutletCard = () => {
    treeDispatch({
      type: 'CLOSE_OUTLET_PREVIEW_CARD'
    });
  };

  function handleTabClick(tabKey: string) {
    if (!tabs) return;
    setTabs(
      tabs.map((tab) => (tab.key === tabKey ? { ...tab, open: true } : { ...tab, open: false }))
    );
  }

  const chosenDate = activeDate.format('L');

  const tabsToDisplay = () => {
    if (!tabs || !openTab || !openTab.url) return null;
    switch (openTab.key) {
      case 'outletInfo':
        return <OutletInfoSection />;
      case 'categories':
        return <CategorySection chosenDate={chosenDate} />;
      case 'responsiblePersons':
        return <ResponsiblePersonsSection chosenDate={chosenDate} />;
      case 'editions':
        return <EditionSection chosenDate={chosenDate} />;
      case 'advertisementPrintings':
        return <AdPrintingSection chosenDate={chosenDate} />;
      default:
        return <GenericDataTableContainer chosenDate={chosenDate} url={openTab.url} />;
    }
  };

  return (
    <VirsTreePreviewCards
      isOpen
      onClose={handleCloseOutletCard}
      title={outletName || outletInfo?.outletName || ''}
      subTitle={t('Media (VIP)')}
      reduceHeightBy="120px"
    >
      {loadingOutletInfo ? (
        <div
          style={{
            display: 'flex',
            height: '100%'
          }}
        >
          <CircularProgress style={{ height: 60, width: 60, margin: 'auto' }} />
        </div>
      ) : (
        <>
          {outletInfo && tabs ? (
            <>
              <NavigationBar tabs={tabs} onTabClick={handleTabClick} />
              {tabsToDisplay()}
            </>
          ) : (
            <>
              {outletInfoError ? (
                <>
                  <Typography variant="caption">{outletInfoError}</Typography>
                </>
              ) : (
                <Typography style={{ marginTop: 10 }} variant="body2">{`${t(
                  'The data are being revised'
                )}.`}</Typography>
              )}
            </>
          )}
        </>
      )}
    </VirsTreePreviewCards>
  );
};

export default OutletPreviewContainer;
