import { InsitutionDataTypes } from '../../../containers/VirsTreeCards/OutletInstitutionDataContainer';
import {
  ChartShareholder,
  ChartShareholderPerson,
  ChartVirsData
} from '../../../store/virsTree/virsTreeDataTypes';

export interface VirsTreeState {
  shareholderVotesChartData?: PieChartData[];
  shareholderSharesChartData?: PieChartData[];
  shareType: string;
  virsCardOpen: boolean;
  virsName: string;
  virsId: number;
  outletCardOpen: boolean;
  preopenOutletCardTab: string | undefined;
  outletName: string;
  outletId: number;
  sideChartCardOpen: boolean;
  shareholderCardOpen: boolean;
  shareholderName: string;
  shareholderData?: ChartShareholder;
  votesOnRules: boolean;
  allShareholders: StateShareholder[];
  showingShareholders: StateShareholder[];
  preextendShareholderById: number | undefined;
  preopenVirsCardTab: string | undefined;
  visibleInstitutionDataType: InsitutionDataTypes | undefined;
  displaySharePieChart: boolean;
  displayVotesPieChart: boolean;
}

export const initialVirsTreeState: VirsTreeState = {
  shareholderVotesChartData: undefined,
  shareholderSharesChartData: undefined,
  shareType: '',
  votesOnRules: false,
  virsCardOpen: false,
  virsName: '',
  virsId: 0,
  outletCardOpen: false,
  preopenOutletCardTab: undefined,
  outletName: '',
  outletId: 0,
  sideChartCardOpen: true,
  shareholderCardOpen: false,
  shareholderName: '',
  shareholderData: undefined,
  allShareholders: [],
  showingShareholders: [],
  preextendShareholderById: undefined,
  preopenVirsCardTab: undefined,
  visibleInstitutionDataType: undefined,
  displaySharePieChart: false,
  displayVotesPieChart: false
};

export interface PieChartData {
  key: number;
  fill: string;
  shareholderName: string;
  percentage: number;
}

export interface StateShareholder {
  shareholderId: number;
  parentId: number;
  shareholderLevel: number;
  shareholderGroupId?: number;
  showingGroupExtended?: boolean;
  shareholderPersons: ChartShareholderPerson[];
}

export interface KeyNumberValueBoolean {
  [key: number]: boolean;
}

export interface ShareholderIdAndLevel {
  shareholderId: number;
  shareholderLevel: number;
}

export type VirsTreeAction =
  | {
      type: 'INIT_VIRS_TREE_STATE';
      virsId: number;
      preshowOutletById: number | undefined;
      preshowShareholderById: number | undefined;
      preopenVirsCardTab: string | undefined;
    }
  | {
      type: 'UPDATE_CHART_DATA';
      shareholderVotesChartData?: PieChartData[];
      shareholderSharesChartData?: PieChartData[];
      shareType: string;
      votesOnRules: boolean;
      mayHaveSharePercentage: boolean;
      mayHaveVotePercentage: boolean;
    }
  | {
      type: 'OPEN_VIRS_PREVIEW_CARD';
      virsName: string;
      virsId: number;
      preopenVirsCardTab?: string;
    }
  | { type: 'CLOSE_VIRS_PREVIEW_CARD' }
  | {
      type: 'OPEN_OUTLET_PREVIEW_CARD';
      virsId: number;
      outletName: string;
      outletId: number;
    }
  | { type: 'CLOSE_OUTLET_PREVIEW_CARD' }
  | {
      type: 'OPEN_OUTLET_INSTITUTION_DATA_CARD';
      dataType: InsitutionDataTypes;
    }
  | { type: 'CLOSE_OUTLET_INSTITUTION_DATA_CARD' }
  | { type: 'CLOSE_CHART_CARD' }
  | { type: 'OPEN_CHART_CARD' }
  | {
      type: 'OPEN_SHAREHOLDER_PREVIEW_CARD';
      shareholderName: string;
      shareholderData: ChartShareholder;
    }
  | { type: 'CLOSE_SHAREHOLDER_PREVIEW_CARD' }
  | { type: 'EXPAND_TREE' }
  | { type: 'COLLAPSE_TREE' }
  | { type: 'SHOW_UP_TO_LEVEL'; level: number }
  | {
      type: 'HIDE_GROUP_LIST';
      group: StateShareholder;
    }
  | {
      type: 'SHOW_GROUP_LIST';
      groupId: number;
    }
  | { type: 'TOGGLE_SHAREHOLDER_CHILDREN'; shareholder: ShareholderIdAndLevel }
  | { type: 'TOGGLE_VIRS_SHAREHOLDERS' }
  | {
      type: 'SHOW_GROUP_PERSONS_CHILDREN';
      shareholders: ShareholderIdAndLevel[];
    }
  | { type: 'HIDE_SHAREHOLDER'; shareholder: StateShareholder }
  | {
      type: 'DATA_RECEIVED';
      data: ChartVirsData;
    };
