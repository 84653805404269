import { VirsLicensesColumnHeader } from './tableTypes';

export const columnNames: VirsLicensesColumnHeader[] = [
  {
    id: 'licenseIssueDate',
    numeric: false,
    label: 'Licencijos data'
  },
  {
    id: 'licenseNumber',
    numeric: false,
    label: 'Licencijos nr.'
  },
  {
    id: 'licenseTypeName',
    numeric: false,
    label: 'Licencijos tipas'
  },
  {
    id: 'enterpriseTypeName',
    numeric: false,
    label: 'Licencijuojamos veiklos pavadinimas'
  },
  {
    id: 'licenseStatus',
    numeric: false,
    label: 'Licencijos būsena'
  },
  {
    id: 'validFrom',
    numeric: false,
    label: 'Galioja nuo'
  },
  {
    id: 'validTo',
    numeric: false,
    label: 'Galioja iki'
  },
  {
    id: 'issuingInstitutionName',
    numeric: false,
    label: 'Licenciją išdavė'
  },
  {
    id: 'licenseNotes',
    numeric: false,
    label: 'Pastaba / komentaras'
  }
];
