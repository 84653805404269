import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderVirsIndividualPersonFinancialSupportData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsIndividualPersonFinancialSupportTableState } from './IndividualPersonFinancialSupportSection';
import { replaceDecimalPoint } from '../../../../../utils/tableDataFunctions';

interface Props {
  record: ShareholderVirsIndividualPersonFinancialSupportData;
}

export const IndividualPersonFinancialSupportRow: React.FC<Props> = ({ record }) => {
  const { state: tableState } = useVirsIndividualPersonFinancialSupportTableState();

  return (
    <TableRow key={record.financialSupportReceivedId}>
      {tableState.columnsDisplayStatus.supportProviderFullName && (
        <TableCell>{record.supportProviderFullName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.financialSupportAmount && (
        <TableCell>{` ${replaceDecimalPoint(`${record.financialSupportAmount}`)} ${
          record.currencyCode ? record.currencyCode : 'EUR'
        }`}</TableCell>
      )}
      {tableState.columnsDisplayStatus.supportReceivedYear && (
        <TableCell>{record.supportReceivedYear}</TableCell>
      )}
    </TableRow>
  );
};
