import { Reducer } from 'redux';
import {
  FETCH_SHAREHOLDER_OUTLET_INFO_DATA_ERROR,
  FETCH_SHAREHOLDER_OUTLET_INFO_DATA_REQUEST,
  FETCH_SHAREHOLDER_OUTLET_INFO_DATA_SUCCESS
} from './outletInfoActionTypes';
import { ShareholderOutletInfoData } from './outletInfoDataTypes';

export interface OutletInfoDataState {
  loadingOutletInfo: boolean;
  outletInfoError?: Error;
  outletInfo?: ShareholderOutletInfoData | null;
}

export const initialState: OutletInfoDataState = {
  loadingOutletInfo: false,
  outletInfoError: undefined,
  outletInfo: undefined
};

export const outletInfoReducer: Reducer<OutletInfoDataState> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHAREHOLDER_OUTLET_INFO_DATA_REQUEST: {
      return { ...state, loadingOutletInfo: true };
    }
    case FETCH_SHAREHOLDER_OUTLET_INFO_DATA_SUCCESS: {
      return {
        ...state,
        loadingOutletInfo: false,
        outletInfo: action.payload
      };
    }
    case FETCH_SHAREHOLDER_OUTLET_INFO_DATA_ERROR: {
      return { ...state, loadingOutletInfo: false };
    }
    default: {
      return state;
    }
  }
};
