import {
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../../../../utils/tableDataFunctions';
import {
  initialOutletDataTableState,
  VirsOutletDataTableAction,
  VirsOutletDataTableState
} from './tableTypes';

export const virsOutletsDataTableReducer = (
  state: VirsOutletDataTableState,
  action: VirsOutletDataTableAction
): VirsOutletDataTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialOutletDataTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateCustomFilterGeneric(state.customFilter, action.filterBy, action.value),
        page: 0
      };
    case 'SET_DISPLAY_FILTER':
      return {
        ...state,
        displayFilter: action.value
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    default:
      return state;
  }
};
