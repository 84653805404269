import moment from 'moment';
import { ShareholderVirsPoliticalAdFinancialSupportData } from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { replaceDecimalPoint, stableSortWrapped } from '../../../../../../utils/tableDataFunctions';
import { Order, SortOrder } from '../../../../../../utils/tableTypes';
import {
  VirsPoliticalAdFinancialSupportColumnHeaderField,
  VirsPoliticalAdFinancialSupportDataTableState,
  VirsPoliticalAdFinancialSupportTableCustomFilter
} from './tableTypes';

export function sortByColumn(
  actionSortBy: VirsPoliticalAdFinancialSupportColumnHeaderField,
  stateSortBy: VirsPoliticalAdFinancialSupportColumnHeaderField,
  stateOrder: Order
) {
  if (actionSortBy === stateSortBy) {
    return stateOrder === 'desc' ? 'asc' : 'desc';
  }
  return 'asc';
}

export function updateCustomFilter(
  filter: VirsPoliticalAdFinancialSupportTableCustomFilter,
  filterBy: VirsPoliticalAdFinancialSupportColumnHeaderField,
  value: string | null
): VirsPoliticalAdFinancialSupportTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export const getSortedVirsPoliticalAdFinancialSupportData = (
  data: ShareholderVirsPoliticalAdFinancialSupportData[],
  order: SortOrder,
  sortBy: string
): ShareholderVirsPoliticalAdFinancialSupportData[] => {
  return stableSortWrapped(data, order, sortBy);
};

export function displayDataFilter(
  data: ShareholderVirsPoliticalAdFinancialSupportData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderVirsPoliticalAdFinancialSupportData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(
          item.advertisingFrom,
          item.advertisingTo || undefined,
          undefined,
          '[]'
        );
      });
    case 'selectedDate':
      return data.filter((item) => {
        return moment(chosenDate).isBetween(
          item.advertisingFrom,
          item.advertisingTo || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function getCustomFilteredData(
  data: ShareholderVirsPoliticalAdFinancialSupportData[],
  tableState: VirsPoliticalAdFinancialSupportDataTableState,
  chosenDate: string
): ShareholderVirsPoliticalAdFinancialSupportData[] {
  function createCustomFilter(field: VirsPoliticalAdFinancialSupportColumnHeaderField) {
    return (record: ShareholderVirsPoliticalAdFinancialSupportData): boolean => {
      const [valueToFilterBy] = tableState.customFilter[field];
      if (valueToFilterBy) {
        const recordField = record[field];
        if (recordField) {
          return replaceDecimalPoint(recordField.toString())
            .toLowerCase()
            .includes(replaceDecimalPoint(valueToFilterBy.toString()).toLowerCase());
        }
        return false;
      }
      return true;
    };
  }
  const allTableFilters = [
    createCustomFilter('distributorName'),
    createCustomFilter('distributorCode'),
    createCustomFilter('beneficiaryName'),
    createCustomFilter('campaignName'),
    createCustomFilter('advertisingFrom'),
    createCustomFilter('advertisingTo'),
    createCustomFilter('financialSupportAmount')
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);

  const filteredData = displayFiltered.filter((record) => {
    return allTableFilters.every((filterUnit) => {
      return filterUnit(record);
    });
  });

  const sorted = getSortedVirsPoliticalAdFinancialSupportData(
    filteredData,
    tableState.order,
    tableState.sortBy
  );

  return sorted;
}
