import { Order } from '../../../../../../utils/tableTypes';

export interface VirsAnnualFinancialReportsDataTableState {
  sortBy: VirsAnnualFinancialReportsColumnHeaderField;
  order: Order;
  page: number;
  recordsPassed: number;
  pagesCount: number;
  rowsPerPage: number;
  columnsDisplayStatus: VirsAnnualFinancialReportsColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: VirsAnnualFinancialReportsTableCustomFilter;
}

export type VirsAnnualFinancialReportsColumnHeaderField =
  | 'documentHeading'
  | 'financialPeriodEndDate';

export type VirsAnnualFinancialReportsColumnsDisplayStatus = {
  documentHeading: boolean;
  financialPeriodEndDate: boolean;
};
export type VirsAnnualFinancialReportsTableCustomFilter = {
  documentHeading: string[];
  financialPeriodEndDate: string[];
};

export type VirsAnnualFinancialReportsTableAction =
  | {
      type: 'SET_SORT_BY_COLUMN';
      sortBy: VirsAnnualFinancialReportsColumnHeaderField;
    }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: number }
  | { type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' }
  | {
      type: 'SET_CUSTOM_FILTER';
      filterBy: VirsAnnualFinancialReportsColumnHeaderField;
      value: string | null;
    }
  | {
      type: 'TOGGLE_DISPLAY_COLUMN';
      column: VirsAnnualFinancialReportsColumnHeaderField;
    };

export const virsInitialAnnualFinancialReportsTableState: VirsAnnualFinancialReportsDataTableState =
  {
    order: 'desc',
    sortBy: 'financialPeriodEndDate',
    page: 0,
    recordsPassed: 0,
    pagesCount: 0,
    rowsPerPage: 5,
    columnsDisplayStatus: {
      documentHeading: true,
      financialPeriodEndDate: true
    },
    customFilterOn: false,
    customFilter: {
      documentHeading: [],
      financialPeriodEndDate: []
    }
  };
