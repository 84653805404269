import { Paper, Table, TableBody, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../store/virsisApp/virsisDataTypes';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import { PoliticalAdFinancialSupportCustomFilter } from './PoliticalAdFinancialSupportCustomFilter';
import PoliticalAdFinancialSupportCustomizationBar from './PoliticalAdFinancialSupportCustomizationBar';
import {
  useVirsPoliticalAdFinancialSupportTableDispatch,
  useVirsPoliticalAdFinancialSupportTableState
} from './PoliticalAdFinancialSupportSection';
import { getCustomFilteredData } from './tableState/tableStateFunctions';
import { PoliticalAdFinancialSupportTableHead } from './PoliticalAdFinancialSupportHead';
import { PoliticalAdFinancialSupportRow } from './PoliticalAdFinancialSupportRow';
import { TableLoader } from '../../../../../components/TableLoader/TableLoad';

interface Props {
  chosenDate: string;
}

export const VirsPoliticalAdFinancialSupportTable: React.FC<Props> = ({ chosenDate }) => {
  const { state: tableState } = useVirsPoliticalAdFinancialSupportTableState();
  const { dispatch } = useVirsPoliticalAdFinancialSupportTableDispatch();

  const {
    VirsPoliticalAdFinancialSupportData,
    virsPoliticalAdFinancialSupportError,
    loadingVirsPoliticalAdFinancialSupportData
  } = useSelector((state: ApplicationState) => state.virsInfoData);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'SET_PAGE', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'SET_ROWS_PER_PAGE',
      rowsPerPage
    });
  };

  const data = getCustomFilteredData(
    VirsPoliticalAdFinancialSupportData || [],
    tableState,
    chosenDate
  );

  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <>
        {virsPoliticalAdFinancialSupportError ? (
          <Typography variant="caption">{virsPoliticalAdFinancialSupportError}</Typography>
        ) : (
          <div className="data-table">
            <PoliticalAdFinancialSupportCustomizationBar
              data={VirsPoliticalAdFinancialSupportData || []}
            />
            <Table>
              <PoliticalAdFinancialSupportTableHead />
              {tableState.customFilterOn && <PoliticalAdFinancialSupportCustomFilter />}
              <TableBody>
                {loadingVirsPoliticalAdFinancialSupportData ? (
                  <TableLoader columns={7} />
                ) : (
                  <>
                    {dataPage.map((record) => (
                      <PoliticalAdFinancialSupportRow key={record.vprId} record={record} />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              recordsCount={data.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={handleSetPage}
              setRowsPerPage={handleSetRowsPerPage}
              disabled={data.length === 0}
            />
          </div>
        )}
      </>
    </Paper>
  );
};
