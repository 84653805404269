import React from 'react';
import { useTranslation } from 'react-i18next';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import {
  useVirsEthicalNonComplianceTableDispatch,
  useVirsEthicalNonComplianceTableState
} from './EthicalNonComplianceSection';
import {
  VirsEthicalNonComplianceColumnHeader,
  VirsEthicalNonComplianceHeaderField
} from './tableState/tableTypes';
import { columnNames } from './tableState/tableColumns';

export const EthicalNonComplianceTableCustomizationBar: React.FC = () => {
  const { state } = useVirsEthicalNonComplianceTableState();
  const { dispatch } = useVirsEthicalNonComplianceTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: VirsEthicalNonComplianceHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: VirsEthicalNonComplianceHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  function getVirsEthicalNonComplianceColumnsWithTranslation(): VirsEthicalNonComplianceColumnHeader[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`VIRS ethical non compliance ${col.id}`)
    }));
  }

  const translatedColumns = getVirsEthicalNonComplianceColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <>
            <TableCustomTools
              tableColumns={translatedColumns}
              showFilter={state.customFilterOn}
              onFilterTabToggle={toggleDisplayCustomFilter}
              sortOrder={state.order}
              sortBy={state.sortBy}
              onSortByColumnClick={handleSetSortByColumn}
              columnsDisplayStatus={state.columnsDisplayStatus}
              toggleDisplayColumn={toggleDisplayColumn}
            />
          </>
        }
      />
    </div>
  );
};
