import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { OutletCategoryHeaderField, OutletCategoryColumnHeader } from './tableState/tableTypes';
import { useOutletCategoryTableDispatch, useOutletCategoryTableState } from './CategorySection';
import { ApplicationState } from '../../../../../store';
import { ShareholderOutletCategoryData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';

interface Props {
  columnNames: OutletCategoryColumnHeader[];
}

const CategoryCustomFilter: React.FC<Props> = ({ columnNames }) => {
  const { state: tableState } = useOutletCategoryTableState();
  const { dispatch } = useOutletCategoryTableDispatch();

  const handleSetCustomFilter = (column: OutletCategoryHeaderField, value: string | null) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  const allData =
    useSelector((state: ApplicationState) => state.outletInfoData.outletInfo?.categories) || [];

  function getUniqueOptions(
    name: OutletCategoryHeaderField,
    data: ShareholderOutletCategoryData[]
  ) {
    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => item[name].toString())
        )
      );
    }
    return [];
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
export default CategoryCustomFilter;
