import { VirsOutletDataTableColumn } from './tableTypes';

export const columnNames: VirsOutletDataTableColumn[] = [
  {
    id: 'outletName',
    numeric: false,
    label: 'VIP Pavadinimas'
  },
  {
    id: 'outletTypeName',
    numeric: false,
    label: 'Rūšis'
  },
  {
    id: 'outletGroupName',
    numeric: false,
    label: 'Grupė'
  },
  {
    id: 'establishedDate',
    numeric: false,
    label: 'Steigimo data'
  },
  {
    id: 'controlledFrom',
    numeric: false,
    label: 'Nuo'
  },
  {
    id: 'controlledTo',
    numeric: false,
    label: 'Iki'
  },
  {
    id: 'internationalNumber',
    numeric: false,
    label: 'Tarptautinis Nr.'
  }
];
