import { VirsPoliticalAdFinancialSupportColumnHeader } from './tableTypes';

export const columnNames: VirsPoliticalAdFinancialSupportColumnHeader[] = [
  {
    id: 'distributorName',
    numeric: false,
    label: 'Skleidejo Pavadinimas'
  },
  {
    id: 'distributorCode',
    numeric: false,
    label: 'Kodas'
  },
  {
    id: 'beneficiaryName',
    numeric: false,
    label: 'Asmuo / grupė, kurių interesais skleista reklama'
  },
  {
    id: 'campaignName',
    numeric: false,
    label: 'Kampanijos pavadinimas'
  },
  {
    id: 'advertisingFrom',
    numeric: false,
    label: 'Nuo'
  },
  {
    id: 'advertisingTo',
    numeric: false,
    label: 'Iki'
  },
  {
    id: 'financialSupportAmount',
    numeric: false,
    label: 'Suma (EUR)'
  }
];
