import React from 'react';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Form, Formik } from 'formik';
import { FormGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { pickBy, identity } from 'lodash';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormikFormTextField } from '../../components/FormikFields/FormikFormTextField';
import { useDialogFormStyles } from '../../components/FormikFields/FieldStyles';
import { NaturalPersonSearchQuery } from '../../store/virsSearch/virsSearchTypes';
import {
  FormikFormDateField,
  getDateString
} from '../../components/FormikFields/FormikFormDateField';
import { language as languageSelector } from '../../store/virsisApp/virsisSelectors';
import { getDatePlaceholder } from '../../components/FormikFields/utilities';
import { currentDate } from '../../utils/dateUtils';
import { todaysDateString } from '../../utils/tableDataFunctions';

interface Props {
  setQuerySize: (size: number) => void;
  searchVirs: (virsQuery: NaturalPersonSearchQuery) => void;
  isSearching: boolean;
}
export interface Values {
  personSurname: string | null;
  personName: string | null;
  personBirthdate: string | null;
}

export const PersonSearchForm: React.FC<Props> = ({ setQuerySize, searchVirs, isSearching }) => {
  const language = useSelector(languageSelector);
  const classes = useDialogFormStyles();
  const { t } = useTranslation();

  const initValues: Partial<Values> = {
    personSurname: '',
    personName: '',
    personBirthdate: null
  };

  const matchParams = /^[a-zA-ZąčęėįšųūĄČĘĖĮŠŲŪžŽ(),.'\-/\s]+$/u;

  const validationSchema = Yup.object().shape({
    personSurname: Yup.string()
      .trim()
      .matches(matchParams, t('Validation illegal characters'))
      .required(t('Validation required field message')),
    personName: Yup.string()
      .trim()
      .matches(matchParams, t('Validation illegal characters'))
      .required(t('Validation required field message')),
    personBirthdate: Yup.string()
      .nullable()
      .test('T1', t('Date format'), (value) => value === null || moment(value).isValid())
      .test(
        'T2',
        t('Date same or before'),
        (value) => value === null || moment(value).isSameOrBefore(moment(todaysDateString()))
      )
  });

  const clearForm = (setFieldValue: (id: string, value: string | null | undefined) => void) => {
    setFieldValue('personSurname', '');
    setFieldValue('personName', '');
    setFieldValue('personBirthdate', null);
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const searchQuery: NaturalPersonSearchQuery = {
          personLastName: values.personSurname ? values.personSurname : null,
          personFirstName: values.personName ? values.personName : null,
          personBirthdate: getDateString(values.personBirthdate)
        };
        setQuerySize(Object.keys(pickBy(searchQuery, identity)).length);
        searchVirs(searchQuery);
        setSubmitting(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => (
        <MuiPickersUtilsProvider locale={language} utils={MomentUtils}>
          <Form>
            <FormGroup
              classes={{
                root: classes.formGroup
              }}
            >
              <FormikFormTextField
                label={`${t('Name')}*`}
                formikKey="personName"
                errorText
                values={values.personName}
                error={errors.personName}
                setFieldValue={setFieldValue}
                fullWidth={true}
              />
              <FormikFormTextField
                label={`${t('Surname')}*`}
                formikKey="personSurname"
                errorText
                values={values.personSurname}
                error={errors.personSurname}
                setFieldValue={setFieldValue}
                fullWidth={true}
              />
              <FormikFormDateField
                variant="medium"
                values={values.personBirthdate}
                label={t('Birth date')}
                isRequired={false}
                formikKey="personBirthdate"
                date={values.personBirthdate}
                setFieldValue={setFieldValue}
                errors={errors.personBirthdate}
                fullWidth={true}
                maxDate={currentDate}
                placeholder={getDatePlaceholder(language)}
              />
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isValidating || isSearching}
                  onClick={submitForm}
                  className={classes.button}
                  startIcon={<SearchIcon className={classes.smallIcon} />}
                >
                  <Typography color="inherit">{t('Search')}</Typography>
                </Button>
                <Button
                  variant="outlined"
                  disabled={isValidating || isSearching}
                  onClick={() => clearForm(setFieldValue)}
                  className={[classes.button, classes.greyButton].join(' ')}
                  style={{ marginRight: '4px' }}
                >
                  <ClearIcon className={classes.smallIcon} />
                  <Typography color="inherit">{t('Clear')}</Typography>
                </Button>
              </Box>
            </FormGroup>
            {isSearching && <LinearProgress style={{ margin: '1rem 0' }} />}
          </Form>
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  );
};
