import { makeStyles, Paper } from '@material-ui/core';
import React, { ReactNode } from 'react';

export interface PaperWrapperProps {
  children: ReactNode;
}
const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F8FF',
    padding: '10px 20px',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '0px'
  }
}));

export const PaperWrapper: React.FC<PaperWrapperProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      {children}
    </Paper>
  );
};
