import React from 'react';
import { TableHead, TableRow } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { getSortLabelPropsFactory } from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  useVirsLegalEntityFinancialSupportTableDispatch,
  useVirsLegalEntityFinancialSupportTableState
} from './LegalEntityFinancialSupportSection';
import {
  VirsLegalEntityFinancialSupportColumnHeaderField,
  VirsLegalEntityFinancialSupportDataTableState
} from './tableState/tableTypes';

export const LegalEntityFinancialSupportHead: React.FC = () => {
  const { state: tableState } = useVirsLegalEntityFinancialSupportTableState();
  const { dispatch: tableDispatch } = useVirsLegalEntityFinancialSupportTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsLegalEntityFinancialSupportColumnHeaderField) => {
    tableDispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsLegalEntityFinancialSupportDataTableState,
    typeof handleSetSortByColumn,
    VirsLegalEntityFinancialSupportColumnHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.supportProviderName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS legal financial support supportProviderName')}
            columnName="supportProviderName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.supportProviderLegalCode && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS legal financial support supportProviderLegalCode')}
            columnName="supportProviderLegalCode"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.financialSupportAmount && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS legal financial support financialSupportAmount')}
            columnName="financialSupportAmount"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.supportReceivedYear && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS legal financial support supportReceivedYear')}
            columnName="supportReceivedYear"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
