import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { useOutletCategoryTableDispatch } from '../CategorySection/CategorySection';
import { useOutletAdPrintingTableState } from './AdPrintingSection';
import {
  OutletAdPrintingColumnHeaderField,
  OutletAdPrintingTableState
} from './tableState/tableTypes';

export const AdPrintingTableHead: React.FC = () => {
  const { state: tableState } = useOutletAdPrintingTableState();
  const { dispatch } = useOutletCategoryTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: OutletAdPrintingColumnHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    OutletAdPrintingTableState,
    typeof handleSetSortByColumn,
    OutletAdPrintingColumnHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">{t('Printing')}</Typography>
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('From capitalized')}
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('To capitalized')}
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
