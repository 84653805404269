import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  useOutletAdPrintingTableDispatch,
  useOutletAdPrintingTableState
} from './AdPrintingSection';
import { ApplicationState } from '../../../../../store';
import { OutletAdPrintingColumnHeaderField } from './tableState/tableTypes';
import { ShareholderOutletAdPrintingData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { columnNames } from './tableState/TableColumns';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';

const AdPrintingCustomFilter: React.FC = () => {
  const { state: tableState } = useOutletAdPrintingTableState();
  const { dispatch } = useOutletAdPrintingTableDispatch();

  const allData =
    useSelector(
      (state: ApplicationState) => state.outletInfoData.outletInfo?.advertisementPrintings
    ) || [];

  const handleSetCustomFilter = (
    column: OutletAdPrintingColumnHeaderField,
    value: string | null
  ) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  function getUniqueOptions(
    name: OutletAdPrintingColumnHeaderField,
    data: ShareholderOutletAdPrintingData[]
  ) {
    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => item[name].toString())
        )
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
export default AdPrintingCustomFilter;
