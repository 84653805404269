import { filterOptions } from '../../../store/virsisApp/virsisDataTypes';
import { Order } from '../../../utils/tableTypes';

export interface GenericDataTableState {
  sortBy: string;
  order: Order;
  page: number;
  recordsPassed: number;
  pagesCount: number;
  rowsPerPage: number;
  columnsDisplayStatus: GenericColumnsDisplayStatus;
  customFilterOn: boolean;
  displayFilter: filterOptions;
  customFilter: GenericTableCustomFilter;
  data?: GenericData;
}

export interface GenericColumnHeader {
  id: string;
  numeric: boolean;
  label: string;
}

export type GenericColumnsDisplayStatus = {
  [key: string]: boolean;
};
export type GenericTableCustomFilter = {
  [key: string]: string[];
};

export type GenericTableAction =
  | { type: 'INITIALIZE'; state: GenericDataTableState }
  | { type: 'RESET_STATE' }
  | { type: 'SET_SORT_BY_COLUMN'; sortBy: string }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: number }
  | { type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' }
  | {
      type: 'SET_DISPLAY_FILTER';
      value: filterOptions;
    }
  | {
      type: 'SET_CUSTOM_FILTER';
      filterBy: string;
      value: string | null;
    }
  | {
      type: 'TOGGLE_DISPLAY_COLUMN';
      column: string;
    };

export interface GenericData {
  columnNames: GenericColumnNames;
  groupedColumns: GenericGroupedColumns[];
  rows: GenericRow[];
  sortableDate: string[];
  sortableString: string[];
  sortableNumber: string[];
  sortableStringArray: string[];
  filteredDates: string[];
}

export interface GenericGroupedColumns {
  name: string;
  group: string[];
}

export type GenericRow = {
  [key: string]: string[];
};

export interface NestedRow {
  id: string;
  value: string;
}

export interface GenericColumnNames {
  [key: string]: string;
}

export const initialGenericTableState: GenericDataTableState = {
  order: 'asc',
  sortBy: '',
  displayFilter: 'allItems',
  page: 0,
  recordsPassed: 0,
  pagesCount: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {},
  customFilterOn: false,
  customFilter: {}
};
