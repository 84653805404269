import { Box, createStyles, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import virsisTheme from '../../../style/virsisTheme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { Info } from '@material-ui/icons';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: '4px 0',
      display: 'flex',
      alignItems: 'center',
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    labelBox: {
      display: 'flex',
      flex: '0 0 170px',
      justifyContent: 'flex-end',
      marginRight: '0.5rem',
      alignItems: 'center',
      [breakpoints.down('xs')]: {
        paddingBottom: '8px',
        flex: '1'
      }
    },
    contentBox: {
      display: 'flex',
      flex: '1',
      maxWidth: '610px',
      [breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  })
);

interface Props {
  label?: string;
  infoTooltip?: string;
  rowSpacing?: string;
  style?: {
    container?: React.CSSProperties;
    labelBox?: React.CSSProperties;
    contentBox?: React.CSSProperties;
  };
  disabled?: boolean;
}

export type FieldGroupProps = PropsWithChildren<Props>;

export const FieldGroup: React.FC<FieldGroupProps> = ({
  label,
  disabled,
  infoTooltip,
  children,
  style
}) => {
  const color = disabled ? virsisTheme.palette.text.disabled : undefined;
  const classes = useStyles();

  return (
    <Box className={classes.container} style={style?.container}>
      <Box className={classes.labelBox} style={style?.labelBox}>
        {label && (
          <Typography style={{ color, fontSize: '0.9rem', fontWeight: 'bold' }}>{label}</Typography>
        )}
        {infoTooltip && (
          <Tooltip title={infoTooltip} placement="top" arrow>
            <Info style={{ fontSize: '20px', marginLeft: '5px' }} color="disabled" />
          </Tooltip>
        )}
      </Box>
      <Box className={classes.contentBox} style={{ ...style?.contentBox }}>
        {children}
      </Box>
    </Box>
  );
};
