import React from 'react';

interface Props {
  color: string;
  size: string;
  aspect?: number;
  innerOffset?: number;
  fillInner?: string;
}

export const RectIcon: React.FC<React.SVGProps<SVGSVGElement> & Props> = ({
  color,
  size,
  aspect,
  innerOffset,
  fillInner,
  ...props
}) => {
  const ran = Math.random();
  return (
    <svg {...props} viewBox={`0 0 1 ${aspect || 3}`} width={size} height={size}>
      <rect id={`outer_${ran}`} x="0" y="0" width="1" height="3" fill={color} />
      {fillInner && aspect && innerOffset && (
        <rect
          id={`inner_${ran}`}
          x={`${innerOffset}`}
          y={`${innerOffset}`}
          width={`${1 - innerOffset * 2}`}
          height={`${aspect - innerOffset * 2}`}
          fill={fillInner}
        />
      )}
    </svg>
  );
};

RectIcon.defaultProps = {
  aspect: 3,
  innerOffset: 0.25
};
