import { State } from './reducer';

export const selector = {
  visiblePeriod: (state: State) => state.visiblePeriod,
  containerWidth: (state: State) => state.containerWidth,
  groupingDistance: (state: State) => state.groupingDistance,
  activeDate: (state: State) => state.activeDate,
  cursorDate: (state: State) => state.cursorDate,
  periodName: (state: State) => state.periodName,
  timelineEvents: (state: State) => state.timelineEvents,
  periods: (state: State) => state.periods,
  isLoading: (state: State) => state.isLoading,
  error: (state: State) => state.error
};
