import React from 'react';
import { Typography, Divider, Grid, Menu, MenuItem, TableSortLabel } from '@material-ui/core/';
import ArrowIcon from '@material-ui/icons/ExpandLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { useTranslation } from 'react-i18next';
import { TableColumn } from './TableCustomTools';
import { SortOrder } from '../../utils/tableTypes';
import SortByButton from '../TableButtons/SortByButton';

interface Props {
  columnNames: TableColumn[];
  order: SortOrder;
  sortBy: string;
  onSortByColumnClick: (column: string) => void;
  disabled?: boolean;
}

const SortByTool: React.FC<Props> = ({
  columnNames,
  order,
  sortBy,
  onSortByColumnClick,
  disabled
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleSortByButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  return (
    <>
      <SortByButton onToggle={toggleSortByButton} anchorEl={anchorEl} disabled={disabled} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={toggleSortByButton}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Grid container justify="space-between" alignItems="center" style={{ padding: 15 }}>
          <Grid item>
            <Typography style={{ marginRight: 15 }}>
              <i>{`${t('Sort data by')}:`}</i>
            </Typography>
          </Grid>
        </Grid>
        <Divider light />
        {columnNames.map((column) => {
          if (column.id !== 'representationNotes') {
            return (
              <MenuItem key={column.id}>
                <TableSortLabel
                  active={sortBy === column.id}
                  direction={sortBy === column.id ? order : 'asc'}
                  onClick={() => onSortByColumnClick(column.id)}
                  IconComponent={sortBy === column.id ? ArrowIcon : UnfoldMoreIcon}
                >
                  <Typography>{column.label}</Typography>
                </TableSortLabel>
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
    </>
  );
};
export default SortByTool;
