import {
  CircularProgress,
  Collapse,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import virsisTheme from '../../../../../style/virsisTheme';

const useStyles = makeStyles(() =>
  createStyles({
    infoSection: {
      display: 'grid',
      gridTemplateColumns: '0.9fr 1.1fr',
      gridGap: '30px'
    },
    infoColumn: {
      width: '100%'
    },
    rowWrap: {
      display: 'flex',
      flexFlow: 'row'
    },
    interactivePropertyName: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0 30px 0 0',
      width: 200
    },
    propertyName: {
      margin: '0 30px 0 0',
      width: 200,
      textAlign: 'end',
      fontSize: '0.95rem',
      fontWeight: 600
    },
    propertyName2: {
      margin: '0 30px 0 0',
      width: 300,
      textAlign: 'end',
      fontSize: '0.95rem',
      fontWeight: 600
    },
    propertyName3: {
      fonWeight: 'normal',
      textAlign: 'end',
      fontSize: '0.95rem',
      fontWeight: 600
    },
    dropdownIcon: {
      height: 18,
      width: 18,
      fill: '#006FB2'
    },
    listItem: {
      padding: 0
    },
    listItemTypograhpy: {
      fontWeight: 600
    },
    propertyValue: {
      fontSize: '0.95rem',
      fontWeight: 'normal',
      flexShrink: 10000
    },
    propertyValueWrap: {
      display: 'flex',
      flexFlow: 'row'
    },
    dateFrom: {
      fontSize: '1rem',
      fontWeight: 'normal',
      marginRight: 25
    },
    dateTo: {
      marginLeft: 12,
      fontSize: '1rem',
      fontWeight: 'normal'
    },
    midDivider: {
      margin: '14px 0',
      height: '0.9px'
    },
    invisibleDivider: {
      margin: '24px 0',
      opacity: 0
    },
    button: {
      margin: '0 0 0 15px',
      color: virsisTheme.palette.primary.main,
      padding: 0,
      height: 'auto',
      lineHeight: '1.167',
      fontSize: '0.95rem',
      fontWeight: 600
    }
  })
);

export const VirsInfoSection: React.FC = () => {
  const classes = useStyles();
  const { virsInfoData, loadingVirsInfoData, VirsInfoError } = useSelector(
    (state: ApplicationState) => state.virsInfoData
  );
  const [showList, setShowList] = useState(
    new Array<boolean>(Object.entries(virsInfoData?.boards || {}).length)
  );
  const { t } = useTranslation();

  const membersPlural = t('VIRS info boardMembers plural comment');

  const handleShowListClick = (index: number, moreThanOneMember: boolean) => {
    if (moreThanOneMember) {
      const tempShowList = [...showList];
      tempShowList[index] = !tempShowList[index];
      setShowList(tempShowList);
    }
  };

  return (
    <>
      {loadingVirsInfoData ? (
        <div
          style={{
            display: 'flex',
            height: '100%'
          }}
        >
          <CircularProgress style={{ height: 60, width: 60, margin: 'auto' }} />
        </div>
      ) : (
        <div className={classes.infoSection}>
          {VirsInfoError ? (
            <Typography variant="caption">{VirsInfoError}</Typography>
          ) : (
            <>
              <div className={classes.infoColumn}>
                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName}>
                    {t('VIRS info registrationCode')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.registrationCode || '-'}
                  </Typography>
                </div>
                <Divider className={classes.midDivider} />

                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName}>
                    {t('VIRS info address')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.address || '-'}
                  </Typography>
                </div>
                <Divider className={classes.midDivider} />

                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName}>
                    {t('VIRS info phoneNumber')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.phoneNumber || '-'}
                  </Typography>
                </div>
                <Divider className={classes.midDivider} />

                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName}>
                    {t('VIRS info email')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.email || '-'}
                  </Typography>
                </div>
                <Divider className={classes.midDivider} />

                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName}>
                    {t('VIRS info managerFullName')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.managerFullName || '-'}
                  </Typography>
                </div>

                {Object.entries(virsInfoData?.boards || {}).map((board, index) => {
                  const moreThanOneMember = board[1].length > 1;
                  return (
                    <div key={index}>
                      <Divider className={classes.midDivider} />
                      <div className={classes.rowWrap}>
                        <div
                          className={
                            moreThanOneMember
                              ? classes.interactivePropertyName
                              : classes.propertyName
                          }
                          onClick={() => handleShowListClick(index, moreThanOneMember)}
                        >
                          <Typography variant="h3" className={classes.propertyName3}>
                            {' '}
                            {board[0]}{' '}
                          </Typography>
                          {moreThanOneMember ? (
                            !showList[index] ? (
                              <ArrowDropDownIcon className={classes.dropdownIcon} />
                            ) : (
                              <ArrowDropUpIcon className={classes.dropdownIcon} />
                            )
                          ) : null}
                        </div>
                        <Typography variant="h3" className={classes.propertyValue}>
                          {moreThanOneMember ? `${board[1].length} ${membersPlural}` : board[1][0]}
                        </Typography>
                      </div>
                      {!moreThanOneMember ? null : (
                        <Collapse in={showList[index]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding style={{ paddingTop: 15 }}>
                            {board[1].map((member, index) => (
                              <ListItem key={index + member} className={classes.listItem}>
                                <ListItemText
                                  classes={{
                                    primary: classes.listItemTypograhpy
                                  }}
                                  inset
                                  primary={member}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className={classes.infoColumn}>
                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName2}>
                    {t('VIRS info legalForm')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.legalForm}
                  </Typography>
                </div>
                <Divider className={classes.midDivider} />

                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName2}>
                    {t('VIRS info legalStatus')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.legalStatus}
                  </Typography>
                </div>
                <Divider className={classes.midDivider} />

                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName2}>
                    {t('VIRS info registrationDate')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.registrationDate}
                  </Typography>
                </div>
                <Divider className={classes.midDivider} />

                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName2}>
                    {t('VIRS info deregistrationDate')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.deregistrationDate || '-'}
                  </Typography>
                </div>

                <Divider className={classes.invisibleDivider} />

                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName2}>
                    {t('VIRS info virsRegistrationStatus')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.virsRegistrationStatus}
                  </Typography>
                </div>
                <Divider className={classes.midDivider} />
                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName2}>
                    {t('VIRS info virsRegistrationDate')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.virsRegistrationDate}
                  </Typography>
                </div>
                <Divider className={classes.midDivider} />
                <div className={classes.rowWrap}>
                  <Typography variant="h3" className={classes.propertyName2}>
                    {t('VIRS info latestInformationProvidedDate')}
                  </Typography>
                  <Typography variant="h3" className={classes.propertyValue}>
                    {virsInfoData?.latestInformationProvidedDate}
                  </Typography>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
