import { action } from 'typesafe-actions';
import {
  GET_GRAPHICAL_REPRESENTATION_DATA_ERROR,
  GET_GRAPHICAL_REPRESENTATION_DATA_REQUEST,
  GET_GRAPHICAL_REPRESENTATION_DATA_SUCCESS
} from './graphicalRepresentationToolType';
import { GenericData } from '../../components/GenericDataTable/State/types';

export const fetchGraphicalRepresentationToolDataRequest = (versionId: string | null) =>
  action(GET_GRAPHICAL_REPRESENTATION_DATA_REQUEST, versionId);

export const fetchGraphicalRepresentationToolDataError = (error: Error) =>
  action(GET_GRAPHICAL_REPRESENTATION_DATA_ERROR, error);

export const fetchGraphicalRepresentationToolDataSuccess = (data: GenericData) =>
  action(GET_GRAPHICAL_REPRESENTATION_DATA_SUCCESS, data);
