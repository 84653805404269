import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { History } from 'history';
import { VirsisState, virsisReducer } from './virsisApp/virsisReducer';
import virsisSaga from './virsisApp/virsisSaga';
import { virsInfoReducer, VirsInfoDataState } from './virsTreeVirsInfo/virsInfoReducer';
import virsInfoSaga from './virsTreeVirsInfo/virsInfoSaga';
import { outletInfoReducer, OutletInfoDataState } from './virsTreeOutletInfo/outletInfoReducer';
import outletInfoSaga from './virsTreeOutletInfo/outletInfoSaga';
import { virsTreeReducer, ShareholdersChartState } from './virsTree/virsTreeReducer';
import virsTreeSaga from './virsTree/virsTreeSaga';
import {
  errorOrSuccessMessageReducer,
  ErrorOrSuccessMessageState
} from './errorOrSuccessMessage/errorOrSuccessMessageReducer';
import { virsSearchDataReducer, VirsSearchState } from './virsSearch/virsSearchReducer';
import virsSearchSaga from './virsSearch/virsSearchSaga';
import genericDataTableSaga from './genericDataTable/genericDataTableSaga';

import {
  graphicalRepresentationDataReducer,
  GraphicalRepresentationDataState
} from './graphicalRepresentationTool/graphicalRepresentationToolReducers';
import graphicalRepresentationDataSaga from './graphicalRepresentationTool/graphicalRepresentationToolSaga';
import {
  GenericDataState,
  genericDataTableReducer
} from './genericDataTable/genericDataTableReducers';
import { reportSaga } from './reports/reportsSaga';
import { reportReducer } from './reports/store';
import { OptionedFormState } from '../components/FormikFields/GenericFormikWrappers/types';
import { FormOptions, ReportInput } from './reports/types';

export interface ApplicationState {
  virsis: VirsisState;
  virsSearchData: VirsSearchState;
  outletInfoData: OutletInfoDataState;
  virsInfoData: VirsInfoDataState;
  chartData: ShareholdersChartState;
  errorOrSuccessMessage: ErrorOrSuccessMessageState;
  genericData: GenericDataState;
  graphicalRepresentationToolData: GraphicalRepresentationDataState;
  report: OptionedFormState<ReportInput, FormOptions>;
}

export const createRootReducer = (history: History) =>
  combineReducers({
    virsis: virsisReducer,
    virsSearchData: virsSearchDataReducer,
    virsInfoData: virsInfoReducer,
    outletInfoData: outletInfoReducer,
    chartData: virsTreeReducer,
    errorOrSuccessMessage: errorOrSuccessMessageReducer,
    genericData: genericDataTableReducer,
    graphicalRepresentationToolData: graphicalRepresentationDataReducer,
    report: reportReducer
  });

export function* rootSaga() {
  yield all([
    fork(virsisSaga),
    fork(virsSearchSaga),
    fork(virsInfoSaga),
    fork(outletInfoSaga),
    fork(virsTreeSaga),
    fork(genericDataTableSaga),
    fork(graphicalRepresentationDataSaga),
    fork(reportSaga)
  ]);
}
