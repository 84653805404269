import React from 'react';
import { makeStyles, TableCell, TableRow } from '@material-ui/core/';
import {
  ProfessionalMisconductOutlet,
  ShareholderVirsProfessionalMisconductData
} from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsProfessionalMisconductTableState } from './ProfessionalMisconductSection';
import OutletNameButton from '../../../../../components/TableButtons/OutletNameButton';

const useStyles = makeStyles({
  dataColumnWidth: {
    width: '140px',
    boxSizing: 'border-box'
  },
  validDataColumnWidth: {
    width: '105px',
    boxSizing: 'border-box'
  },
  actionColumnWidth: {
    width: '155px',
    boxSizing: 'border-box'
  },
  removePadding: {
    padding: '5px',
    position: 'relative',
    left: '-5px'
  }
});

interface Props {
  record: ShareholderVirsProfessionalMisconductData;
  onClickOutlet: (outledId: number, outletName: string) => void;
}

export const ProfessionalMisconductRow: React.FC<Props> = ({ record, onClickOutlet }) => {
  const classes = useStyles();

  const {
    state: { columnsDisplayStatus }
  } = useVirsProfessionalMisconductTableState();

  const { decisionDate, documentNumber, reportingInstitutionName, outlets, validFrom, validTo } =
    record;

  const summingReducer = (total: number, currentValue: number) => total + currentValue;

  const totalRowSpan = () =>
    outlets
      .flatMap((outlet) => outlet.determinedMisconducts)
      .map((misconduct) => misconduct.sanctions.length)
      .reduce(summingReducer);

  const outletRowSpan = ({
    determinedMisconducts: detectedProfessionalMisconducts
  }: ProfessionalMisconductOutlet) =>
    detectedProfessionalMisconducts
      .map((misconduct) => misconduct.sanctions.length)
      .reduce(summingReducer);

  let rowIndex = 0;
  const rows: JSX.Element[] = [];

  outlets.forEach((outlet) => {
    outlet.determinedMisconducts.forEach((misconduct, misconductIndex) => {
      misconduct.sanctions.forEach((sanction, sanctionIndex) => {
        rows.push(
          <TableRow key={rowIndex}>
            {rowIndex === 0 && (
              <>
                {columnsDisplayStatus.decisionDate && (
                  <TableCell
                    align="left"
                    rowSpan={totalRowSpan()}
                    className={classes.dataColumnWidth}
                  >
                    {decisionDate}
                  </TableCell>
                )}
                {columnsDisplayStatus.documentNumber && (
                  <TableCell
                    align="left"
                    rowSpan={totalRowSpan()}
                    className={classes.validDataColumnWidth}
                  >
                    {documentNumber}
                  </TableCell>
                )}
              </>
            )}

            {misconductIndex === 0 && sanctionIndex === 0 && columnsDisplayStatus.outletName && (
              <TableCell align="left" rowSpan={outletRowSpan(outlet)}>
                <OutletNameButton
                  onClicked={() => {
                    onClickOutlet(outlet.outletId, outlet.outletName);
                  }}
                  text={outlet.outletName}
                />
              </TableCell>
            )}

            {sanctionIndex === 0 && columnsDisplayStatus.professionalMisconduct && (
              <TableCell align="left" rowSpan={misconduct.sanctions.length}>
                {misconduct.misconductTypeName}
              </TableCell>
            )}

            {columnsDisplayStatus.sanction && (
              <TableCell align="left">{sanction.sanctionTypeName}</TableCell>
            )}

            {rowIndex === 0 && (
              <>
                {columnsDisplayStatus.validFrom && (
                  <TableCell
                    align="left"
                    rowSpan={totalRowSpan()}
                    className={classes.validDataColumnWidth}
                  >
                    {validFrom}
                  </TableCell>
                )}
                {columnsDisplayStatus.validTo && (
                  <TableCell
                    align="left"
                    rowSpan={totalRowSpan()}
                    className={classes.validDataColumnWidth}
                  >
                    {validTo}
                  </TableCell>
                )}
                {columnsDisplayStatus.misconductReportingInstitutionName && (
                  <TableCell align="left" rowSpan={totalRowSpan()}>
                    {reportingInstitutionName}
                  </TableCell>
                )}
              </>
            )}
          </TableRow>
        );
        // eslint-disable-next-line no-plusplus
        rowIndex++;
      });
    });
  });

  return <>{record && rows}</>;
};
