import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import { ShareholderVirsPaymentData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsPaymentTableState } from './PaymentSection';
import OutletNameButton from '../../../../../components/TableButtons/OutletNameButton';
import BtnOpenNewDoc from '../../../../../components/TableButtons/BtnOpenNewDoc';
import { getPaymentDocumentInNewTabRequest } from '../../../../../store/virsTreeVirsInfo/virsInfoActions';
import { replaceDecimalPoint } from '../../../../../utils/tableDataFunctions';

interface Props {
  record: ShareholderVirsPaymentData;
  onClickOutlet: (outletId: number, outletName: string) => void;
}

export const PaymentRow: React.FC<Props> = ({ record, onClickOutlet }) => {
  const { state: tableState } = useVirsPaymentTableState();
  const reduxDispatch = useDispatch();

  function openDocumentInNewTab(paymentDocumentId: number, documentNumber: string) {
    reduxDispatch(getPaymentDocumentInNewTabRequest(paymentDocumentId, documentNumber));
  }

  return (
    <TableRow key={record.paymentId}>
      {tableState.columnsDisplayStatus.decisionDate && <TableCell>{record.decisionDate}</TableCell>}

      {tableState.columnsDisplayStatus.paymentDocuments && (
        <TableCell>
          {record.paymentDocuments.map((document) => (
            <div key={document.documentId}>
              <BtnOpenNewDoc
                onClicked={() => openDocumentInNewTab(document.documentId, document.documentNumber)}
                text={document.documentNumber}
              />
            </div>
          ))}
        </TableCell>
      )}

      {tableState.columnsDisplayStatus.paymentOutlets && (
        <TableCell>
          {record.paymentOutlets.map((outlet) => (
            <OutletNameButton
              key={outlet.outletId}
              onClicked={() => {
                onClickOutlet(outlet.outletId, outlet.outletName);
              }}
              text={outlet.outletName}
            />
          ))}
        </TableCell>
      )}

      {tableState.columnsDisplayStatus.paymentAmount && (
        <TableCell>{replaceDecimalPoint(`${record.paymentAmount}`)}</TableCell>
      )}
      {tableState.columnsDisplayStatus.validFrom && <TableCell>{record.validFrom}</TableCell>}
      {tableState.columnsDisplayStatus.validTo && <TableCell>{record.validTo}</TableCell>}
      {tableState.columnsDisplayStatus.reportingInstitutionName && (
        <TableCell>{record.reportingInstitutionName}</TableCell>
      )}
    </TableRow>
  );
};
