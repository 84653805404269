import {
  ChartShareholder,
  ChartShareholderPerson,
  LegalPerson,
  NaturalPerson
} from '../../../../store/virsTree/virsTreeDataTypes';
import { legalPersonColor, naturalPersonColor, virsBoxColor } from './constants';

export const boxColor = (personType: string, relatedVirsId?: number) => {
  if (relatedVirsId) {
    return virsBoxColor;
  }

  if (personType === NaturalPerson.LFA || personType === NaturalPerson.UFA) {
    return naturalPersonColor;
  }

  return legalPersonColor;
};

export const isLegalPerson = (type: string) => type === LegalPerson.LJA || type === LegalPerson.UJA;

export const isNaturalPerson = (type: string) =>
  type === NaturalPerson.LFA || type === NaturalPerson.UFA;

export const subtitle = (
  type: string,
  personsGroup: boolean,
  naturalPersonText: string,
  legalPersonText: string
) => {
  if (
    (personsGroup && (type === NaturalPerson.LFA || type === NaturalPerson.UFA)) ||
    type === NaturalPerson.LFA ||
    type === NaturalPerson.UFA
  ) {
    return naturalPersonText;
  }
  return legalPersonText;
};

export const personBoxColor = (personType: string) => {
  if (personType === NaturalPerson.LFA || personType === NaturalPerson.UFA) {
    return naturalPersonColor;
  }
  return legalPersonColor;
};

export const displayPersonName = (
  personsGroup: boolean,
  authorisedPerson: ChartShareholderPerson,
  persons: ChartShareholderPerson[]
) => {
  if (personsGroup) {
    return authorisedPerson.personName;
  }
  return persons[0].personName;
};

export const setBubblePosition = (index: number) => {
  if (index === 0) {
    return { top: 157.5, right: 2 };
  }
  return { top: 157.5 + index * 105, right: 2 };
};

export function childrenNumber(children: ChartShareholder[]) {
  return children.length === 0 ? undefined : children.length;
}
