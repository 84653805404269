import React, { useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchTab, SearchTabPanel } from '../components/SearchTabs/SearchTabs';
import LegalSearchPage from '../containers/SearchByLegalPerson/LegalSearchPage';
import OutletSearch from '../containers/SearchByOutlet/OutletSearchPage';
import PersonSearch from '../containers/SearchByNaturalPerson/PersonSearchPage';
import { VirsisBreadCrumbs } from '../components/BreadCrumbs/VirsisBreadCrumbs';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

const VirsisSearchPage: React.FC = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>('person');
  const display = {
    person: activeTab === 'person',
    legal: activeTab === 'legal',
    outlet: activeTab === 'outlet'
  };

  const language = useSelector((state: ApplicationState) => state.virsis.language);

  return (
    <Container maxWidth="lg" className="search-container">
      <Box style={{ padding: '2em 0' }}>
        <VirsisBreadCrumbs
          links={[
            {
              enabled: false,
              label: t('VIRS search breadcrumb'),
              path: language === 'lt' ? '/paieska' : '/virs-search'
            }
          ]}
        />
        <Typography variant="h2">{t('VIRS search breadcrumb')}</Typography>
      </Box>
      <Container disableGutters style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
        <SearchTab
          active={activeTab === 'person'}
          label={t('Natural person')}
          value="person"
          setActiveTab={setActiveTab}
        />
        <SearchTab
          active={activeTab === 'legal'}
          label={t('Legal entity')}
          value="legal"
          setActiveTab={setActiveTab}
        />
        <SearchTab
          active={activeTab === 'outlet'}
          label={t('Media (VIP)')}
          value="outlet"
          setActiveTab={setActiveTab}
        />
      </Container>
      <SearchTabPanel>
        {display.person && <PersonSearch />}
        {display.legal && <LegalSearchPage />}
        {display.outlet && <OutletSearch />}
      </SearchTabPanel>
    </Container>
  );
};

export default VirsisSearchPage;
