import moment from 'moment';
import {
  GenericColumnsDisplayStatus,
  GenericData,
  GenericDataTableState,
  GenericRow,
  GenericTableCustomFilter
} from './types';
import { Order } from '../../../utils/tableTypes';
import { filterOptions } from '../../../store/virsisApp/virsisDataTypes';
import { replaceDecimalPoint, stableSortWrapped } from '../../../utils/tableDataFunctions';
import { TableColumn } from '../../TableCustomTools/TableCustomTools';

export function sortByColumn(actionSortBy: string, stateSortBy: string, stateOrder: Order) {
  if (actionSortBy === stateSortBy) {
    return stateOrder === 'desc' ? 'asc' : 'desc';
  }
  return 'asc';
}

export function updateCustomFilter(
  filter: GenericTableCustomFilter,
  filterBy: string,
  value: string | null
): GenericTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function getCustomFilter(data?: GenericData): GenericTableCustomFilter {
  if (!data) {
    return {};
  }
  return Object.keys(data.columnNames).reduce(
    (map, key) => ({
      ...map,
      [key]: []
    }),
    {}
  );
}

export function getDisplayStatus(data?: GenericData): GenericColumnsDisplayStatus {
  if (!data) {
    return {};
  }
  return Object.keys(data.columnNames).reduce(
    (map, key) => ({
      ...map,
      [key]: true
    }),
    {}
  );
}

export const getFirstKey = (data?: GenericData): string => {
  if (!data) {
    return '';
  }
  return Object.keys(data.columnNames)[0];
};

export const getColumns = (data?: GenericData): TableColumn[] => {
  if (!data) {
    return [];
  }
  return Object.entries(data.columnNames).map((entry) => {
    const [key, value] = entry;
    return {
      id: key,
      numeric: false,
      label: value
    };
  });
};

export const getRowSpan = (id: string, record: GenericRow): number => {
  const allSizes = Object.entries(record).map((entry) => {
    return entry[1].length;
  });
  if (record[id].length !== 1) {
    return 1;
  }
  return Math.max(...allSizes);
};

export const getMaxRowSpan = (row: GenericRow): number => {
  const allSizes = Object.entries(row).map((entry) => {
    return entry[1].length;
  });
  return Math.max(...allSizes);
};

export function displayDataFilter(
  data: GenericRow[],
  displayFilter: filterOptions,
  tableState: GenericData,
  chosenDate: string
): GenericRow[] {
  const {
    filteredDates: [fromDate, toDate]
  } = tableState;
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(item[fromDate], item[toDate] || undefined, undefined, '[]');
      });
    case 'selectedDate':
      return data.filter((item) => {
        return moment(chosenDate).isBetween(
          item[fromDate],
          item[toDate] || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function filterAndSortGenericData(
  tableState: GenericDataTableState,
  data: GenericData | undefined,
  chosenDate: string
): GenericRow[] {
  if (!data || !tableState.data) {
    return [];
  }

  function createCustomFilter(field: string) {
    return (record: GenericTableCustomFilter): boolean => {
      const [valueToFilterBy] = tableState.customFilter[field];
      if (valueToFilterBy) {
        const recordField = record[field];
        if (recordField) {
          return replaceDecimalPoint(recordField.toString())
            .toLowerCase()
            .includes(replaceDecimalPoint(valueToFilterBy.toString()).toLowerCase());
        }
        return false;
      }
      return true;
    };
  }

  const filters = Object.keys(data.columnNames).flatMap((key) => createCustomFilter(key));

  const displayFiltered = displayDataFilter(
    data.rows,
    tableState.displayFilter,
    tableState.data,
    chosenDate
  );

  const filteredData =
    displayFiltered.filter((record) => {
      return filters.every((filterUnit) => {
        return filterUnit(record);
      });
    }) || [];

  return stableSortWrapped(filteredData, tableState.order, tableState.sortBy);
}
