import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import theme from '../../../../style/virsisTheme';

const useStyles = makeStyles(() => ({
  sideBox: {
    minWidth: '22px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'stretch'
  },
  button: {
    margin: '0px',
    padding: '2px',
    minWidth: '22px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '0.75rem',
    height: 'inherit',
    display: 'flex',
    alignItems: 'flex-end'
  }
}));

interface Props {
  childrenNumber?: number;
  handleSideBoxClick?: () => void;
  customSideboxStyle?: React.CSSProperties;
  isDisabled?: boolean;
}

export const SideBox: React.FC<Props> = ({
  childrenNumber,
  handleSideBoxClick,
  customSideboxStyle,
  isDisabled
}) => {
  const classes = useStyles();
  return (
    <div className={classes.sideBox} style={customSideboxStyle}>
      <Button disabled={isDisabled} onClick={handleSideBoxClick} className={classes.button}>
        {childrenNumber}
      </Button>
    </div>
  );
};
