import { VirsLegalEntityFinancialSupportColumnHeader } from './tableTypes';

export const columnNames: VirsLegalEntityFinancialSupportColumnHeader[] = [
  {
    id: 'supportProviderName',
    numeric: false,
    label: 'Paramos teikėjo pavadinimas'
  },
  {
    id: 'supportProviderLegalCode',
    numeric: false,
    label: 'Kodas'
  },
  {
    id: 'financialSupportAmount',
    numeric: false,
    label: 'Paramos suma'
  },
  {
    id: 'supportReceivedYear',
    numeric: false,
    label: 'Metai'
  }
];
