import {
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../../../../utils/tableDataFunctions';
import {
  OutletCategoryTableAction,
  OutletCategoryTableState,
  OutletCategoryHeaderField
} from './tableTypes';

export const OutletCategoryTableReducer = (
  state: OutletCategoryTableState,
  action: OutletCategoryTableAction
): OutletCategoryTableState => {
  switch (action.type) {
    case 'TABLE_INITIALIZED':
      return {
        ...state
      };
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: {
          categoryTypeName: [],
          validFrom: [],
          validTo: []
        }
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateCustomFilterGeneric<OutletCategoryHeaderField>(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'SET_DISPLAY_FILTER':
      return {
        ...state,
        displayFilter: action.value
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    default:
      return state;
  }
};
