import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { VirsComponentInformationIcons } from './TreeChartBoxes/VirsComponentInformationIcons';
import { TreeSingleBox } from './TreeChartBoxes/TreeSingleBox';
import { VirsBoxTitle } from './TreeChartBoxes/VirsBoxTitle';

import {
  useVirsTreeDispatch,
  useVirsTreeState
} from '../../../pages/VirsTreePage/VirsTreePageContext';
import { outletBoxColor, virsBoxColor } from './treeChartFunctions/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const VirsComponent = ({ data: { chartData } }: any) => {
  const { treeDispatch } = useVirsTreeDispatch();
  const { treeState } = useVirsTreeState();

  const {
    shareholders,
    outlets,
    hasPoliticalAdRevenues,
    hasFinancialSupportReceived,
    hasFundsReceivedFromTransactions,
    hasEthicalNonCompliances,
    hasProfessionalMisconducts,
    shareholderErrors
  } = chartData;

  const outletsNumber = outlets.length;

  const handleOpenDiagramCard = () => {
    treeDispatch({ type: 'OPEN_CHART_CARD' });
  };

  const handleOpenVirsCard = () => {
    treeDispatch({
      type: 'OPEN_VIRS_PREVIEW_CARD',
      virsId: chartData.virsId,
      virsName: chartData.virsLegalName
    });
  };

  const handleOpenVirsCardByTab = (preopenVirsCardTab: string) => () => {
    treeDispatch({
      type: 'OPEN_VIRS_PREVIEW_CARD',
      virsId: chartData.virsId,
      virsName: chartData.virsLegalName,
      preopenVirsCardTab
    });
  };

  const toggleVirsShareholders = () => treeDispatch({ type: 'TOGGLE_VIRS_SHAREHOLDERS' });

  const areDiagrams = treeState.displaySharePieChart || treeState.displayVotesPieChart;

  const hasErrors = !!shareholderErrors && shareholderErrors.length > 0;

  const minHeight = 95;
  const maxHeight = 110;

  return (
    <>
      <div>
        {outlets.length > 0 && (
          <Handle type="target" position={Position.Left} style={{ background: outletBoxColor }} />
        )}
        <TreeSingleBox
          boxTitle={<VirsBoxTitle title={chartData.virsLegalName} subtitle="VIRS" />}
          leftChildrenNumber={outletsNumber}
          rightChildrenNumber={shareholders.length}
          color={virsBoxColor}
          showLeftSideBox
          showRightSideBox
          minHeight={hasErrors ? minHeight + 15 : minHeight}
          maxHeight={hasErrors ? maxHeight + 15 : maxHeight}
          virsClickableIcons={
            <VirsComponentInformationIcons
              onAdRevenuesClick={
                hasPoliticalAdRevenues && handleOpenVirsCardByTab('politicalAdFunds')
              }
              onFinancialSupportReceivedClick={
                hasFinancialSupportReceived && handleOpenVirsCardByTab('legalPersonSupport')
              }
              onFundsReceivedFromTransactionsClick={
                hasFundsReceivedFromTransactions && handleOpenVirsCardByTab('receivedFunds')
              }
              onEthicalNonCompliancesClick={
                hasEthicalNonCompliances && handleOpenVirsCardByTab('ethicalNonCompliance')
              }
              onProfessionalMisconductsClick={
                hasProfessionalMisconducts && handleOpenVirsCardByTab('professionalMisconducts')
              }
              hasErrors={hasErrors}
              areDiagrams={areDiagrams}
              onClickDiagram={handleOpenDiagramCard}
              sideChartCardOpen={treeState.sideChartCardOpen}
            />
          }
          handleRightSideBoxClick={toggleVirsShareholders}
          hasErrors={hasErrors}
          errorText={shareholderErrors}
          onClick={handleOpenVirsCard}
        />
        {shareholders.length > 0 && <Handle type="source" position={Position.Right} />}
      </div>
    </>
  );
};
