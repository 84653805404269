import { action } from 'typesafe-actions';
import {
  GET_MENU_DATA_ERROR,
  GET_MENU_DATA_REQUEST,
  GET_MENU_DATA_SUCCESS,
  SET_LANGUAGE,
  SET_VIRS_NAME,
  FETCH_PDF_DOWNLOAD
} from './virsisActionTypes';
import { MenuItem } from './virsisDataTypes';
import { ActivityPeriod } from './virsisReducer';

export const getMenuDataRequest = () => action(GET_MENU_DATA_REQUEST);
export const getMenuDataSuccess = (menu: MenuItem[], lang: string) =>
  action(GET_MENU_DATA_SUCCESS, { menu, lang });
export const getMenuDataError = () => action(GET_MENU_DATA_ERROR);

export const setInitialVirsData = (
  activityPeriods?: ActivityPeriod[],
  virsName?: string,
  virsId?: number,
  virsStartDate?: string | null,
  virsEndDate?: string | null
) =>
  action(SET_VIRS_NAME, {
    activityPeriods,
    virsName,
    virsId,
    virsStartDate,
    virsEndDate
  });

export const setLanguage = (lang: string) => action(SET_LANGUAGE, lang);

export const fetchPdfData = (
  documentId: number,
  pathPrefix?: string,
  onData?: (file: Blob) => void
) => action(FETCH_PDF_DOWNLOAD, { documentId, pathPrefix, onData });
