import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VirsisBreadCrumbs } from '../../components/BreadCrumbs/VirsisBreadCrumbs';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

export interface Props {
  virsName: string;
  virsId: number;
}

export const VirsTreeHeader: React.FC<Props> = ({ virsName, virsId }) => {
  const { t } = useTranslation();

  const language = useSelector((state: ApplicationState) => state.virsis.language);

  return (
    <Box style={{ paddingBottom: '1em', marginLeft: 20 }}>
      <VirsisBreadCrumbs
        links={[
          {
            enabled: true,
            label: t('VIRS search breadcrumb'),
            path: language === 'lt' ? '/paieska' : '/virs-search'
          },
          { enabled: false, label: virsName, path: virsId.toString() }
        ]}
      />

      <Typography variant="h2">{virsName}</Typography>
    </Box>
  );
};
