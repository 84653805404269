import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { ApplicationState } from '../../../../../store';
import { columnNames } from './tableState/tableColumns';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import {
  useVirsLegalEntityFinancialSupportTableDispatch,
  useVirsLegalEntityFinancialSupportTableState
} from './LegalEntityFinancialSupportSection';
import { VirsLegalEntityFinancialSupportColumnHeaderField } from './tableState/tableTypes';
import { ShareholderVirsLegalEntityFinancialSupportData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { replaceDecimalPoint } from '../../../../../utils/tableDataFunctions';

export const LegalEntityFinancialSupportCustomFilter: React.FC = () => {
  const { state: tableState } = useVirsLegalEntityFinancialSupportTableState();
  const { dispatch: tableDispatch } = useVirsLegalEntityFinancialSupportTableDispatch();

  const handleSetCustomFilter = (
    column: VirsLegalEntityFinancialSupportColumnHeaderField,
    value: string | null
  ) => {
    tableDispatch({
      type: 'SET_CUSTOM_FILTER',
      filterBy: column,
      value
    });
  };
  const allData =
    useSelector(
      (state: ApplicationState) => state.virsInfoData.VirsLegalEntityFinancialSupportData
    ) || [];

  function getUniqueOptions(
    name: VirsLegalEntityFinancialSupportColumnHeaderField,
    data: ShareholderVirsLegalEntityFinancialSupportData[]
  ) {
    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => replaceDecimalPoint(item[name].toString()))
        )
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
