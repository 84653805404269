export interface MenuItem {
  field_cms_link: Value[];
  field_id: Value[];
  field_url: Value[];
  field_language: Value[];
  field_hide: Value[];
  name: Value[];
  tid: Value[];
  parent: TargetId[];
}

export interface TargetId {
  target_id: number;
}

export interface MenuData {
  fieldId: string;
  fieldUrl: string;
  fieldCmsLink: string;
  fieldLanguage: string;
  fieldHide: string | boolean;
  name: string;
  tid: string;
  parentId: number | null;
  subMenu: MenuData[];
}

export interface Value {
  value: any;
}

export type RowsPerPageOptions = number[];

export const ROWS_PER_PAGE_OPTIONS: RowsPerPageOptions = [5, 10, 20, 30];

export type filterOptions = 'allItems' | 'todayItems' | 'selectedDate';

export const DISPLAY_FILTER_HEADERS: DisplayFilterField[] = [
  { value: 'allItems', label: 'Visi įrašai' },
  { value: 'todayItems', label: 'Aktualūs duomenys' },
  { value: 'selectedDate', label: 'Pagal pasirinktą datą' }
];

export interface DisplayFilterField {
  value: filterOptions;
  label: string;
}
