import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Menu, MenuItem } from '@material-ui/core';
import { getPeriodRelativeValue } from './dateUtil';
import { TimelineIndicator } from './TimelineIndicator';
import { TimelineEventGroup } from './state/types';
import { selector } from './state/selector';
import { changeActiveDate } from './state/actions';
import { TimelineEvent } from '../../../store/virsTree/virsTreeDataTypes';

export interface Props {
  eventGroup: TimelineEventGroup;
}

export const TimelineIndicatorGroup: React.FC<Props> = ({ eventGroup }) => {
  const dispatch = useDispatch();
  const activeDate = useSelector(selector.activeDate);
  const period = useSelector(selector.visiblePeriod);

  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>();

  if (!period) {
    return <></>;
  }

  const eventCount = eventGroup.events.length;
  const hasCursor = eventGroup.events.some(({ date }) => date.valueOf() === activeDate.valueOf());
  const hasOutletChange = eventGroup.events.some(({ outletsChanged }) => outletsChanged);

  const hasShareholderChange = eventGroup.events.some(
    ({ shareholdersChanged }) => shareholdersChanged
  );

  const hasShareholdersEnded = eventGroup.events.some(({ shareholdersEnded }) => shareholdersEnded);

  const handleIndicatorClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (eventCount > 1) {
      setMenuAnchor(event.currentTarget);
    } else {
      dispatch(changeActiveDate(eventGroup.events[0].date));
    }
  };

  const handleMenuItemClick = (event: TimelineEvent) => {
    dispatch(changeActiveDate(event.date));
    setMenuAnchor(undefined);
  };

  return (
    <>
      <TimelineIndicator
        key={eventGroup.date.valueOf()}
        outletChange={hasOutletChange}
        shareholderChange={hasShareholderChange}
        shareholderEnd={hasShareholdersEnded}
        cursor={hasCursor}
        relativeOffset={getPeriodRelativeValue(eventGroup.date, period)}
        onClick={handleIndicatorClick}
        eventCount={eventCount > 1 ? eventCount : undefined}
      />

      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(undefined)}
        style={{ transform: 'translate(0, 2.5em)' }}
      >
        {eventGroup.events
          .sort((a, b) => a.date.diff(b.date))
          .map((event) => (
            <MenuItem key={event.date.valueOf()} onClick={() => handleMenuItemClick(event)}>
              <span style={{ position: 'relative', marginRight: '10px' }}>
                <TimelineIndicator
                  outletChange={event.outletsChanged}
                  shareholderChange={event.shareholdersChanged}
                  shareholderEnd={event.shareholdersEnded}
                  cursor={event.date.valueOf() === activeDate.valueOf()}
                />
              </span>

              {moment(event.date).format('YYYY-MM-DD')}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
