import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { FormikFieldStyles } from '../FieldStyles';
import { FormikFormDateField, getDateString } from '../FormikFormDateField';
import { useDialogFormStyles } from './DialogFormStyles';
import { maxDate as maxDateDefault, minDate as minDateDefault } from './validationSchemas';

interface Props<TData> extends FormikFieldStyles {
  formikProps: FormikProps<TData>;
  label: string;
  // eslint-disable-next-line
  field: keyof TData;
  isRequired?: boolean;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  variant?: 'small' | 'medium';
  placeholder?: string;
}

export const GenericDateField = <TData,>({
  isRequired,
  field,
  label,
  formikProps,
  disabled,
  maxDate,
  minDate,
  style,
  variant,
  placeholder
}: Props<TData>) => {
  const classes = useDialogFormStyles();

  useEffect(() => {
    if (formikProps.errors[field]) {
      formikProps.setFieldError(`${field}`, undefined);
    }
  }, [formikProps.values[field], disabled]);

  return (
    <FormikFormDateField
      styledClasses={classes}
      values={formikProps.values[field]}
      label={label}
      formikKey={`${field}`}
      date={formikProps.values[field]}
      errors={formikProps.errors[field]}
      setFieldValue={formikProps.setFieldValue}
      minDate={minDate || getDateString(minDateDefault) || undefined}
      maxDate={maxDate || getDateString(maxDateDefault) || undefined}
      isRequired={!!isRequired}
      disabled={disabled}
      style={style}
      variant={variant}
      placeholder={placeholder}
    />
  );
};

GenericDateField.defaultProps = {
  isRequired: false,
  disabled: false
};
