import { put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  addDays,
  getInitialStartDate,
  getPeriodCount,
  getPeriodEnd,
  getPeriodStart,
  getTimelinePeriods
} from '../dateUtil';
import {
  changeActiveDate,
  changeContainerWidth,
  changeVisiblePeriod,
  setActiveDate,
  setContainerWidth,
  setGroupingDistance,
  setPeriods,
  setVisiblePeriod
} from './actions';
import { selector } from './selector';

function* changeActiveDateTask(action: ActionType<typeof changeActiveDate>) {
  const periodsState = yield select(selector.periods);
  const periodName = yield select(selector.periodName);

  if (!periodsState) {
    return;
  }

  const startDate = getInitialStartDate(action.payload.activeDate, periodsState.length, periodName);

  const periods = getTimelinePeriods(periodName, startDate, periodsState.length);
  const { start } = periods[0];
  const { end } = periods[periods.length - 1];

  yield put(setPeriods(periods));
  yield put(setActiveDate(action.payload.activeDate));
  yield put(setVisiblePeriod({ start, end }));
}

function* changeContainerWidthTask(action: ActionType<typeof changeContainerWidth>) {
  const periodName = yield select(selector.periodName);
  const activeDate = yield select(selector.activeDate);

  const {
    payload: { containerWidth }
  } = action;
  if (!containerWidth) {
    return;
  }

  const groupingDistance = 20 / containerWidth;
  const periodsCount = getPeriodCount(periodName, containerWidth);
  const start = getInitialStartDate(activeDate, periodsCount, periodName);

  const periods = getTimelinePeriods(periodName, start, periodsCount);

  const visiblePeriod = {
    start: periods[0].start,
    end: periods[periods.length - 1].end
  };

  yield put(setGroupingDistance(groupingDistance));
  yield put(setPeriods(periods));
  yield put(setVisiblePeriod(visiblePeriod));
  yield put(setContainerWidth(containerWidth));
}

function* changeVisiblePeriodTask(action: ActionType<typeof changeVisiblePeriod>) {
  const periodsState = yield select(selector.periods);
  const visiblePeriodState = yield select(selector.visiblePeriod);
  const periodName = yield select(selector.periodName);

  if (!visiblePeriodState || !periodsState) {
    return;
  }
  let { start, end } = visiblePeriodState;

  for (let i = 0; i < Math.abs(action.payload.ammount); i += 1) {
    if (action.payload.ammount >= 0) {
      start = getPeriodEnd(start, periodName);
      end = getPeriodEnd(end, periodName);
    } else {
      start = getPeriodStart(addDays(start, -1), periodName);
      end = getPeriodStart(addDays(end, -1), periodName);
    }
  }

  const periods = getTimelinePeriods(periodName, start, periodsState.length);

  yield put(setVisiblePeriod({ start, end }));
  yield put(setPeriods(periods));
}

export function* saga() {
  yield takeLatest(changeActiveDate, changeActiveDateTask);
  yield takeLatest(changeContainerWidth, changeContainerWidthTask);
  yield takeLatest(changeVisiblePeriod, changeVisiblePeriodTask);
}
