import { VirsEthicalNonComplianceColumnHeader } from './tableTypes';

export const columnNames: VirsEthicalNonComplianceColumnHeader[] = [
  {
    id: 'decisionDate',
    numeric: false,
    label: 'Sprendimo data'
  },
  {
    id: 'documentNumber',
    numeric: false,
    label: 'Sprendimo Nr.'
  },
  {
    id: 'outletList',
    numeric: false,
    label: 'VIP'
  },
  {
    id: 'ethicalNonComplianceStatus',
    numeric: false,
    label: 'Būsena'
  },
  {
    id: 'validFrom',
    numeric: false,
    label: 'Galioja nuo'
  },
  {
    id: 'validTo',
    numeric: false,
    label: 'Galioja iki'
  },
  {
    id: 'reportingInstitutionName',
    numeric: false,
    label: 'Pripažino'
  }
];
