import React from 'react';

interface Props {
  color: string;
  size: string;
  innerSize?: number;
  fillInner?: string;
}

export const CircleIcon: React.FC<React.SVGProps<SVGSVGElement> & Props> = ({
  innerSize,
  size,
  color,
  fillInner,
  ...props
}) => {
  const ran = Math.random();
  return (
    <svg {...props} viewBox="0 0 1 1" width={size} height={size}>
      <defs>
        <circle id={`outer_${ran}`} cx="0.5" cy="0.5" r="0.5" />

        <circle id={`inner_${ran}`} cx="0.5" cy="0.5" r={(innerSize && innerSize * 0.5) || 0} />
      </defs>

      <mask id={`mask_${ran}`}>
        <circle cx="0.5" cy="0.5" r="1" fill="white" />

        <use href={`#inner_${ran}`} />
      </mask>

      {fillInner && <use href={`#inner_${ran}`} fill={fillInner} />}

      <use href={`#outer_${ran}`} fill={color} mask={`url(#mask_${ran})`} />
    </svg>
  );
};
