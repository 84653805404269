import moment from 'moment';
import { ShareholderVirsIndividualPersonFinancialSupportData } from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  replaceDecimalPoint,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { Order } from '../../../../../../utils/tableTypes';
import {
  VirsIndividualPersonFinancialSupportColumnHeaderField,
  VirsIndividualPersonFinancialSupportDataTableState,
  VirsIndividualPersonFinancialSupportTableCustomFilter
} from './tableTypes';

export function sortByColumn(
  actionSortBy: VirsIndividualPersonFinancialSupportColumnHeaderField,
  stateSortBy: VirsIndividualPersonFinancialSupportColumnHeaderField,
  stateOrder: Order
) {
  if (actionSortBy === stateSortBy) {
    return stateOrder === 'desc' ? 'asc' : 'desc';
  }
  return 'asc';
}

export function updateCustomFilter(
  filter: VirsIndividualPersonFinancialSupportTableCustomFilter,
  filterBy: VirsIndividualPersonFinancialSupportColumnHeaderField,
  value: string | null
): VirsIndividualPersonFinancialSupportTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function displayDataFilter(
  data: ShareholderVirsIndividualPersonFinancialSupportData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderVirsIndividualPersonFinancialSupportData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(
          `${item.supportReceivedYear}-01-01`,
          `${item.supportReceivedYear}-12-31` || undefined,
          undefined,
          '[]'
        );
      });
    case 'selectedDate':
      return data.filter((item) => {
        return moment(chosenDate).isBetween(
          `${item.supportReceivedYear}-01-01`,
          `${item.supportReceivedYear}-12-31` || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function filterAndSortIndividualPersonFinancialSupportDataVirs(
  tableState: VirsIndividualPersonFinancialSupportDataTableState,
  data: ShareholderVirsIndividualPersonFinancialSupportData[],
  chosenDate: string
): ShareholderVirsIndividualPersonFinancialSupportData[] {
  const columnsFilters = tableState.customFilter;

  const filters: Filter<ShareholderVirsIndividualPersonFinancialSupportData>[] = [
    getStringMappedColumnFilter(
      columnsFilters.supportProviderFullName,
      ({ supportProviderFullName }) => [supportProviderFullName]
    ),
    getStringMappedColumnFilter(
      columnsFilters.financialSupportAmount.map((item) => item.toString()),
      ({ financialSupportAmount }) => [replaceDecimalPoint(financialSupportAmount.toString())]
    ),
    getStringMappedColumnFilter(columnsFilters.supportReceivedYear, ({ supportReceivedYear }) => [
      supportReceivedYear
    ])
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);

  const filtered = filterData(displayFiltered, filters);

  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);

  return sorted;
}
