import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderVirsFundsReceivedData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../store/virsisApp/virsisDataTypes';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import DisplayFilterTool from '../../../../../components/TableDisplayFilter/DisplayFilterTool';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import { columnNames } from './tableState/columnNames';
import {
  useVirsFundsReceivedTableDispatch,
  useVirsFundsReceivedTableState
} from './FundsReceivedSection';
import {
  VirsFundsReceivedDataTableHeaderType,
  VirsFundsReceivedDataVirsTableField
} from './tableState/tableTypes';

interface Props {
  data: ShareholderVirsFundsReceivedData[];
}

const FundsReceivedCustomizationBar: React.FC<Props> = ({ data }) => {
  const { state } = useVirsFundsReceivedTableState();
  const { dispatch } = useVirsFundsReceivedTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: VirsFundsReceivedDataVirsTableField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: VirsFundsReceivedDataVirsTableField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const handleSetDisplayFilter = (value: filterOptions) => {
    dispatch({ type: 'SET_DISPLAY_FILTER', value });
  };

  function getVirsFundsReceivedColumnsWithTranslation(): VirsFundsReceivedDataTableHeaderType[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`VIRS funds received ${col.id}`)
    }));
  }

  const translatedColumns = getVirsFundsReceivedColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <DisplayFilterTool
            disabled={data.length === 0}
            displayFilter={state.displayFilter}
            setDisplayFilter={handleSetDisplayFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default FundsReceivedCustomizationBar;
