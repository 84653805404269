import { Reducer } from 'redux';
import {
  CLEAR_SEARCH_VALUES,
  GET_OUTLET_TYPES_SUCCESS,
  RESET_SEARCH_STATE,
  SEARCH_BY_LEGAL_ERROR,
  SEARCH_BY_LEGAL_LOADING,
  SEARCH_BY_LEGAL_SUCCESS,
  SEARCH_BY_OUTLET_ERROR,
  SEARCH_BY_OUTLET_LOADING,
  SEARCH_BY_OUTLET_SUCCESS,
  SEARCH_BY_PERSON_ERROR,
  SEARCH_BY_PERSON_LOADING,
  SEARCH_BY_PERSON_SUCCESS,
  SEARCH_REQUEST
} from './virsSearchActionTypes';
import {
  LegalPersonSearchData,
  NaturalPersonSearchData,
  OutletSearchData,
  OutletType
} from './virsSearchTypes';

export interface VirsSearchState {
  searchValue?: string;
  loadingPersonSearchResults: boolean;
  personSearchResults?: NaturalPersonSearchData[] | null;
  personSearchResultsError?: Error;
  loadingLegalSearchResults: boolean;
  legalSearchResults?: LegalPersonSearchData[] | null;
  legalSearchResultsError?: Error;
  loadingVirsSearchByOutletResults: boolean;
  outletTypes?: OutletType[];
  virsSearchByOutletResults?: OutletSearchData[] | null;
  virsSearchByOutletError?: Error;
}

export const initialState: VirsSearchState = {
  searchValue: undefined,
  loadingPersonSearchResults: false,
  personSearchResults: undefined,
  personSearchResultsError: undefined,
  loadingLegalSearchResults: false,
  legalSearchResults: undefined,
  legalSearchResultsError: undefined,
  outletTypes: undefined,
  loadingVirsSearchByOutletResults: false,
  virsSearchByOutletResults: undefined,
  virsSearchByOutletError: undefined
};

export const virsSearchDataReducer: Reducer<VirsSearchState> = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        searchValue: action.payload
      };

    case CLEAR_SEARCH_VALUES:
      return {
        ...state,
        searchValue: undefined
      };

    case GET_OUTLET_TYPES_SUCCESS:
      return {
        ...state,
        outletTypes: action.payload.outletTypes
      };

    case SEARCH_BY_OUTLET_SUCCESS:
      return {
        ...state,
        virsSearchByOutletResults: action.payload.virsResultsByOutlet,
        loadingVirsSearchByOutletResults: false
      };

    case SEARCH_BY_OUTLET_LOADING:
      return {
        ...state,
        loadingVirsSearchByOutletResults: true
      };
    case SEARCH_BY_OUTLET_ERROR:
      return {
        ...state,
        virsSearchByOutletError: {
          message: action?.payload,
          name: 'virsSearchByOutletError'
        },
        loadingPersonSearchResults: false
      };

    case SEARCH_BY_PERSON_LOADING:
      return {
        ...state,
        loadingPersonSearchResults: action.payload
      };

    case SEARCH_BY_PERSON_SUCCESS:
      return {
        ...state,
        personSearchResults: action.payload.virsResultsByNaturalPerson,
        loadingPersonSearchResults: false
      };

    case SEARCH_BY_PERSON_ERROR:
      return {
        ...state,
        personSearchResultsError: {
          message: action?.payload,
          name: 'personSearchResultsError'
        },
        loadingPersonSearchResults: false
      };

    case SEARCH_BY_LEGAL_LOADING:
      return {
        ...state,
        loadingLegalSearchResults: true
      };

    case SEARCH_BY_LEGAL_SUCCESS:
      return {
        ...state,
        legalSearchResults: action.payload.virsResultsByLegalPerson,
        loadingLegalSearchResults: false
      };

    case SEARCH_BY_LEGAL_ERROR:
      return {
        ...state,
        legalSearchResultsError: {
          message: action?.payload,
          name: 'legalSearchResultsError'
        },
        loadingLegalSearchResults: false
      };

    case RESET_SEARCH_STATE:
      return { ...initialState, legalSearchResults: state.legalSearchResults, outletTypes: state.outletTypes };

    default: {
      return state;
    }
  }
};
