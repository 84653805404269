import { ColumnHeader } from '../../../../../../utils/tableTypes';
import { VirsAnnualFinancialReportsColumnHeaderField } from './tableTypes';

export const columnNames: ColumnHeader<VirsAnnualFinancialReportsColumnHeaderField>[] = [
  {
    id: 'documentHeading',
    numeric: false,
    label: 'Dokumento antraštė'
  },
  {
    id: 'financialPeriodEndDate',
    numeric: false,
    label: 'Finansinio laikotarpio pabaigos data'
  }
];
