import moment from 'moment';
import { ShareholderVirsLicensesData } from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { stableSortWrapped } from '../../../../../../utils/tableDataFunctions';
import { Order, SortOrder } from '../../../../../../utils/tableTypes';
import {
  VirsLicensesColumnHeaderField,
  VirsLicensesDataTableState,
  VirsLicensesTableCustomFilter
} from './tableTypes';

export function sortByColumn(
  actionSortBy: VirsLicensesColumnHeaderField,
  stateSortBy: VirsLicensesColumnHeaderField,
  stateOrder: Order
) {
  if (actionSortBy === stateSortBy) {
    return stateOrder === 'desc' ? 'asc' : 'desc';
  }
  return 'asc';
}

export const getSortedLicenseData = (
  data: ShareholderVirsLicensesData[],
  order: SortOrder,
  sortBy: string
): ShareholderVirsLicensesData[] => {
  return stableSortWrapped(data, order, sortBy);
};

export function updateCustomFilter(
  filter: VirsLicensesTableCustomFilter,
  filterBy: VirsLicensesColumnHeaderField,
  value: string | null
): VirsLicensesTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function displayDataFilter(
  data: ShareholderVirsLicensesData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderVirsLicensesData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(item.validFrom, item.validTo || undefined, undefined, '[]');
      });
    case 'selectedDate':
      return data.filter((item) => {
        return moment(chosenDate).isBetween(
          item.validFrom,
          item.validTo || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function getCustomFilteredData(
  data: ShareholderVirsLicensesData[],
  tableState: VirsLicensesDataTableState,
  chosenDate: string
): ShareholderVirsLicensesData[] {
  function createCustomFilter(field: VirsLicensesColumnHeaderField) {
    return (record: ShareholderVirsLicensesData): boolean => {
      const [valueToFilterBy] = tableState.customFilter[field];
      if (valueToFilterBy) {
        const recordField = record[field];
        if (recordField) {
          return recordField
            .toString()
            .toLowerCase()
            .includes(valueToFilterBy.toString().toLowerCase());
        }
        return false;
      }
      return true;
    };
  }
  const allTableFilters = [
    createCustomFilter('licenseNumber'),
    createCustomFilter('licenseIssueDate'),
    createCustomFilter('licenseTypeName'),
    createCustomFilter('enterpriseTypeName'),
    createCustomFilter('validFrom'),
    createCustomFilter('validTo'),
    createCustomFilter('issuingInstitutionName')
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);

  const filteredData = displayFiltered.filter((record) => {
    return allTableFilters.every((filterUnit) => {
      return filterUnit(record);
    });
  });

  const sorted = getSortedLicenseData(filteredData, tableState.order, tableState.sortBy);

  return sorted;
}
