import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useGenericTableDispatch, useGenericTableState } from './GenericDataTableContext';
import { GenericRow } from './State/types';
import { getColumns } from './State/utilities';
import { TableCustomFilterAutocomplete } from '../TableCustomFilterCell/TableCustomFilterAutocomplete';

export const GenericDataTableCustomFilter: React.FC = () => {
  const { state: tableState } = useGenericTableState();
  const { dispatch: tableDispatch } = useGenericTableDispatch();

  const handleSetCustomFilter = (column: string, value: string | null) => {
    tableDispatch({
      type: 'SET_CUSTOM_FILTER',
      filterBy: column,
      value
    });
  };

  function getUniqueOptions(name: string, data: GenericRow[]) {
    if (data) {
      return Array.from(
        new Set(
          data.filter((item) => item[name] && item[name] !== null).flatMap((item) => item[name])
        )
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {getColumns(tableState.data)
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, tableState?.data?.rows || [])}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
