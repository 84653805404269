import React, { ReactNode } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import 'moment/locale/lt';

export interface IconButtonProps {
  icon: ReactNode;
  children: ReactNode;
}

const useStyles = makeStyles({
  container: {
    borderRadius: 5,
    borderColor: 'transparent',
    backgroundColor: 'white',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
    color: '#637082',
    display: 'flex',
    alignItems: 'center',
    height: '40px'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '5px 10px',
    borderRight: '2px solid #c3e2fb'
  }
});

export const IconButtonWrapper: React.FC<IconButtonProps> = ({ children, icon }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      <div className={classes.icon}>{icon}</div>
      {children}
    </Paper>
  );
};
