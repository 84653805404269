import { FormikProps } from 'formik';
import React from 'react';
import { FormikFieldStyles } from '../FieldStyles';
import { FormikFormSelectField } from '../FormikFormSelectField';
import { useDialogFormStyles } from './DialogFormStyles';

// eslint-disable-next-line
type ArrayType<T> = T extends (infer X)[] ? X : never;

interface Props<TData, TField, TValue> extends FormikFieldStyles {
  formikProps: FormikProps<TData>;
  label?: string;
  field: TField;
  isRequired?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  onChange?: (value: TValue[]) => void;
  options: { value: TValue; label: string }[];
  fullWidth?: boolean;
  withoutLabel?: boolean;
}

export const GenericMultipleSelectField = <
  TData,
  TField extends keyof TData,
  TValue extends ArrayType<TData[TField]>
>({
  isRequired,
  clearable,
  disabled,
  field,
  label,
  formikProps,
  options,
  onChange,
  style,
  fullWidth,
  withoutLabel
}: Props<TData, TField, TValue>) => {
  const classes = useDialogFormStyles();
  return (
    <FormikFormSelectField
      styledClasses={classes}
      getOptionLabel={(option: TValue) =>
        options.find(({ value }) => value === option)?.label || ''
      }
      isClearable={!!clearable}
      isRequired={!!isRequired}
      label={label}
      disabled={disabled}
      multiple
      formikKey={`${field}`}
      value={formikProps.values[field]}
      options={options.map((option) => option.value)}
      errors={formikProps.errors[field]}
      setFieldValue={formikProps.setFieldValue}
      defaultValue={[]}
      onChange={onChange}
      style={{ ...style }}
      fullWidth={fullWidth}
      withoutLabel={withoutLabel}
    />
  );
};

GenericMultipleSelectField.defaultProps = {
  isRequired: false,
  disabled: false,
  clearable: true,
  onChange: undefined,
  fullWidth: true
};
