import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  useVirsFundsReceivedTableDispatch,
  useVirsFundsReceivedTableState
} from './FundsReceivedSection';
import { VirsFundsReceivedDataVirsTableField } from './tableState/tableTypes';
import { ApplicationState } from '../../../../../store';
import { ShareholderVirsFundsReceivedData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { columnNames } from './tableState/columnNames';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import { replaceDecimalPoint } from '../../../../../utils/tableDataFunctions';

export const FundsReceivedCustomFilter: React.FC = () => {
  const { dispatch: tableDispatch, dataFilter } = useVirsFundsReceivedTableDispatch();
  const { state: tableState } = useVirsFundsReceivedTableState();

  const handleSetCustomFilter = (
    column: VirsFundsReceivedDataVirsTableField,
    value: string | null
  ) => {
    tableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };
  const allData = (
    useSelector((state: ApplicationState) => state.virsInfoData.VirsFundsReceivedData) || []
  ).filter(dataFilter);

  function getUniqueOptions(
    name: VirsFundsReceivedDataVirsTableField,
    data: ShareholderVirsFundsReceivedData[]
  ) {
    if (name === 'outletName') {
      return Array.from(
        new Set(data.flatMap((item) => item.fundsReceivedOutlets.map((vip) => vip.outletName)))
      );
    }

    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => replaceDecimalPoint(item[name].toString()))
        )
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
