import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  useVirsEditionCheckTableDispatch,
  useVirsEditionCheckTableState
} from './EditionCheckSection';
import { VirsEditionCheckDataTableField } from './tableState/tableTypes';
import { ApplicationState } from '../../../../../store';
import { ShareholderVirsEditionCheckData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import { columnNames } from './tableState/tableColumns';

export const EditionChecCustomFilter: React.FC = () => {
  const { dispatch: tableDispatch } = useVirsEditionCheckTableDispatch();
  const { state: tableState } = useVirsEditionCheckTableState();

  const handleSetCustomFilter = (column: VirsEditionCheckDataTableField, value: string | null) => {
    tableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };
  const allData =
    useSelector((state: ApplicationState) => state.virsInfoData.VirsEditionCheckData) || [];

  function getUniqueOptions(
    name: VirsEditionCheckDataTableField,
    data: ShareholderVirsEditionCheckData[]
  ) {
    if (name === 'docNr') {
      const array: string[] = [];
      data.forEach((item) =>
        item.editionCheckDocuments.forEach((doc) => array.push(doc.documentNumber))
      );
      return Array.from(new Set(array));
    }

    if (name === 'outletName') {
      const array: string[] = [];
      data.forEach((item) =>
        item.editionCheckOutlets.forEach((outlet) => array.push(outlet.outletName))
      );
      return Array.from(new Set(array));
    }

    if (name === 'periodName') {
      const array: string[] = [];
      data.forEach((item) =>
        item.editionCheckOutlets.forEach((outlet) =>
          outlet.editionCheckConclusions.forEach((conclusion) => array.push(conclusion.periodName))
        )
      );
      return Array.from(new Set(array));
    }
    if (name === 'conclusion') {
      const array: string[] = [];
      data.forEach((item) =>
        item.editionCheckOutlets.forEach((outlet) =>
          outlet.editionCheckConclusions.forEach((conclusion) =>
            array.push(conclusion.conclusionTypeName)
          )
        )
      );
      return Array.from(new Set(array));
    }

    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => item[name])
        )
      );
    }

    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
