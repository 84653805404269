import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import {
  initialVirsEditionCheckDataTableState,
  VirsEditionCheckDataTableAction,
  VirsEditionCheckDataTableState
} from './tableState/tableTypes';
import { virsEditionCheckDataTableReducer } from './tableState/tableReducer';
import { VirsEditionCheckTable } from './EditionCheckTable';
import { ShareholderVirsEditionCheckData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';

interface Props {
  onClickOutlet: (outletId: number, outletName: string) => void;
  dataFilter?: (data: ShareholderVirsEditionCheckData) => boolean;
}

const VirsEditionCheckTableStateContext = createContext<{
  state: VirsEditionCheckDataTableState;
}>({
  state: initialVirsEditionCheckDataTableState
});

const VirsEditionCheckTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsEditionCheckDataTableAction>;
  dataFilter: (data: ShareholderVirsEditionCheckData) => boolean;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
  dataFilter: () => true
});

export const EditionCheckSection: React.FC<Props> = ({ onClickOutlet, dataFilter }) => {
  const [state, dispatch] = useReducer(
    virsEditionCheckDataTableReducer,
    initialVirsEditionCheckDataTableState
  );

  return (
    <VirsEditionCheckTableStateContext.Provider value={{ state }}>
      <VirsEditionCheckTableDispatchContext.Provider
        value={{ dispatch, dataFilter: dataFilter || (() => true) }}
      >
        <VirsEditionCheckTable onClickOutlet={onClickOutlet} />
      </VirsEditionCheckTableDispatchContext.Provider>
    </VirsEditionCheckTableStateContext.Provider>
  );
};

export function useVirsEditionCheckTableState() {
  return useContext(VirsEditionCheckTableStateContext);
}
export function useVirsEditionCheckTableDispatch() {
  return useContext(VirsEditionCheckTableDispatchContext);
}
