import {
  OutletResponsiblePersonsTableState,
  OutletResponsiblePersonsSectionTableAction
} from './tableTypes';
import {
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../../../../utils/tableDataFunctions';

export const outletResponsiblePersonsSectionTableReducer = (
  state: OutletResponsiblePersonsTableState,
  action: OutletResponsiblePersonsSectionTableAction
): OutletResponsiblePersonsTableState => {
  switch (action.type) {
    case 'TABLE_INITIALIZED':
      return {
        ...state
      };
    case 'SET_SORT_BY_COLUMN':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: action.page
      };
    case 'SET_ROWS_PER_PAGE':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'TOGGLE_DISPLAY_CUSTOM_FILTER':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: {
          responsiblePersonFullName: [],
          validFrom: [],
          validTo: [],
          notes: []
        }
      };
    case 'SET_CUSTOM_FILTER':
      return {
        ...state,
        customFilter: updateCustomFilterGeneric(state.customFilter, action.filterBy, action.value),
        page: 0
      };
    case 'SET_DISPLAY_FILTER':
      return {
        ...state,
        displayFilter: action.value
      };
    case 'TOGGLE_DISPLAY_COLUMN':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    default:
      return state;
  }
};
