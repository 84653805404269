import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { ColumnHeader, Order } from '../../../../../../utils/tableTypes';

export type VirsProfessionalMisconductTableField =
  | 'decisionDate'
  | 'documentNumber'
  | 'outletName'
  | 'professionalMisconduct'
  | 'sanction'
  | 'validFrom'
  | 'validTo'
  | 'misconductReportingInstitutionName';

export type VirsProfessionalMisconductColumnsDisplayStatus = {
  decisionDate: boolean;
  documentNumber: boolean;
  outletName: boolean;
  professionalMisconduct: boolean;
  sanction: boolean;
  validFrom: boolean;
  validTo: boolean;
  misconductReportingInstitutionName: boolean;
};

export type VirsProfessionalMisconductCustomFilter = {
  decisionDate: string[];
  documentNumber: string[];
  outletName: string[];
  professionalMisconduct: string[];
  sanction: string[];
  validFrom: string[];
  validTo: string[];
  misconductReportingInstitutionName: string[];
};

export type VirsProfessionalMisconductColumnHeader =
  ColumnHeader<VirsProfessionalMisconductTableField>;

export interface VirsProfessionalMisconductTableState {
  sortBy: VirsProfessionalMisconductTableField;
  order: Order;
  page: number;
  displayFilter: filterOptions;
  rowsPerPage: number;
  columnsDisplayStatus: VirsProfessionalMisconductColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: VirsProfessionalMisconductCustomFilter;
}

export type VirsProfessionalMisconductTableAction =
  | { type: 'TABLE_INITIALIZED' }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: VirsProfessionalMisconductTableField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: VirsProfessionalMisconductTableField }
  | {
      type: 'COLUMN_DISPLAY_TOGGLED';
      column: VirsProfessionalMisconductTableField;
    }
  | { type: 'PAGE_SET'; page: number }
  | {
      type: 'SET_DISPLAY_FILTER';
      value: filterOptions;
    }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number };

export const initialVirsProfessionalMisconductTableState: VirsProfessionalMisconductTableState = {
  order: 'desc',
  sortBy: 'decisionDate',
  displayFilter: 'allItems',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: {
    decisionDate: true,
    documentNumber: true,
    outletName: true,
    sanction: true,
    professionalMisconduct: true,
    validFrom: true,
    validTo: true,
    misconductReportingInstitutionName: true
  },
  customFilterOn: false,
  customFilter: {
    decisionDate: [],
    documentNumber: [],
    outletName: [],
    professionalMisconduct: [],
    sanction: [],
    validFrom: [],
    validTo: [],
    misconductReportingInstitutionName: []
  }
};
