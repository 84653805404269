import React from 'react';
import { Box, Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  label: string;
  active: boolean;
  value: string;
  setActiveTab: (value: string) => void;
}

const useStyle = makeStyles({
  activeTab: {
    cursor: 'pointer',
    backgroundColor: '#F5F8FF',
    border: '1px solid rgb(185 226 247)',
    padding: '15px 20px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    boxShadow: '-1px -1px 2px 0px rgba(0, 0, 0, 0.12)',
    color: '#006FB2'
  },
  tab: {
    cursor: 'pointer',
    padding: '15px 20px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    boxShadow: '-1px -2px 3px 0px rgba(0, 0, 0, 0.12)',
    color: '#637082'
  },
  container: {
    borderTopLeftRadius: '0',
    padding: '20px 20px',
    minHeight: '250px',
    marginBottom: '20px'
  },
  label: {
    fontSize: '0.95rem',
    fontWeight: 700
  }
});

export const SearchTab: React.FC<Props> = ({ label, active, setActiveTab, value }) => {
  const classes = useStyle();
  return (
    <Box style={{ display: 'flex' }}>
      <Paper
        onClick={() => setActiveTab(value)}
        className={active ? classes.tab : classes.activeTab}
        elevation={2}
      >
        <Typography
          className={classes.label}
          style={{ userSelect: 'none' }}
          variant="subtitle2"
          color="inherit"
        >
          {label}
        </Typography>
      </Paper>
    </Box>
  );
};

export const SearchTabPanel: React.FC = ({ children }) => {
  const classes = useStyle();
  if (!children) {
    return null;
  }
  return (
    <Box style={{ padding: 0 }}>
      <Paper elevation={2} className={classes.container}>
        <Container disableGutters>{children}</Container>
      </Paper>
    </Box>
  );
};
