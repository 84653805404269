import { OutletAdPrintingColumnHeader } from './tableTypes';

export const columnNames: OutletAdPrintingColumnHeader[] = [
  {
    id: 'validFrom',
    numeric: false,
    label: 'Nuo'
  },
  {
    id: 'validTo',
    numeric: false,
    label: 'Iki'
  }
];
