import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

const MessageDialog: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { message, variant, newMessage } = useSelector(
    (state: ApplicationState) => state.errorOrSuccessMessage
  );
  useEffect(() => {
    if (message && variant) {
      const key = enqueueSnackbar(message, {
        onClick: () => {
          closeSnackbar(key);
        },
        variant,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  }, [closeSnackbar, enqueueSnackbar, message, variant, newMessage]);

  return <></>;
};

export default MessageDialog;
