import moment from 'moment';
import {
  OutletShortData,
  ShareholderVirsFundsReceivedData
} from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter, replaceDecimalPoint,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import {
  VirsFundsReceivedDataTableCustomFilter,
  VirsFundsReceivedDataTableState,
  VirsFundsReceivedDataVirsTableField
} from './tableTypes';

export function updateFundsReceivedDataCustomFilter(
  filter: VirsFundsReceivedDataTableCustomFilter,
  filterBy: VirsFundsReceivedDataVirsTableField,
  value: string | null
): VirsFundsReceivedDataTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function displayDataFilter(
  data: ShareholderVirsFundsReceivedData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderVirsFundsReceivedData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(
          `${item.fundsReceivedYear}-01-01`,
          `${item.fundsReceivedYear}-12-31` || undefined,
          undefined,
          '[]'
        );
      });
    case 'selectedDate':
      return data.filter((item) => {
        return moment(chosenDate).isBetween(
          `${item.fundsReceivedYear}-01-01`,
          `${item.fundsReceivedYear}-12-31` || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function sortTableData(
  data: ShareholderVirsFundsReceivedData[],
  tableState: VirsFundsReceivedDataTableState
) {
  let sortedData = [...data];
  if (
    tableState.sortBy === 'fundsReceivedSum' ||
    tableState.sortBy === 'fundsReceivedYear' ||
    tableState.sortBy === 'fundsSourceName' ||
    tableState.sortBy === 'fundsSourceLegalCode' ||
    tableState.sortBy === 'transactionType'
  ) {
    sortedData = stableSortWrapped(data, tableState.order, tableState.sortBy);
  }

  if (tableState.sortBy === 'outletName') {
    sortedData = sortedData.map((entry) => ({
      ...entry,
      fundsReceivedOutlets: stableSortWrapped(
        entry.fundsReceivedOutlets,
        tableState.order,
        tableState.sortBy
      )
    }));
  }

  return sortedData;
}

export function filterAndSortFundsReceiveTableData(
  data: ShareholderVirsFundsReceivedData[],
  tableState: VirsFundsReceivedDataTableState,
  chosenDate: string
): ShareholderVirsFundsReceivedData[] {
  const filters: Filter<ShareholderVirsFundsReceivedData>[] = [
    getStringMappedColumnFilter(
      tableState.customFilter.fundsReceivedSum,
      ({ fundsReceivedSum }) => [replaceDecimalPoint(fundsReceivedSum.toString())]
    ),
    getStringMappedColumnFilter(
      tableState.customFilter.fundsSourceLegalCode,
      ({ fundsSourceLegalCode }) => [fundsSourceLegalCode]
    ),
    getStringMappedColumnFilter(tableState.customFilter.fundsSourceName, ({ fundsSourceName }) => [
      fundsSourceName
    ]),
    getStringMappedColumnFilter(
      tableState.customFilter.fundsReceivedYear,
      ({ fundsReceivedYear }) => [fundsReceivedYear.toString()]
    ),
    getStringMappedColumnFilter(tableState.customFilter.transactionType, ({ transactionType }) => [
      transactionType
    ]),
    getStringMappedColumnFilter(tableState.customFilter.outletName, ({ fundsReceivedOutlets }) =>
      fundsReceivedOutlets.map(({ outletName }) => outletName)
    )
  ];

  const nestedOutletFilters: Filter<OutletShortData>[] = [
    getStringMappedColumnFilter(tableState.customFilter.outletName, ({ outletName }) => [
      outletName
    ])
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);

  const filtered = filterData(displayFiltered, filters).map((entry) => ({
    ...entry,
    fundsReceivedOutlets: filterData(entry.fundsReceivedOutlets, nestedOutletFilters).map(
      (outlet) => ({
        ...outlet
      })
    )
  }));

  return sortTableData(filtered, tableState);
}
