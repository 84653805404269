import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { Field, useFormikContext } from 'formik';
import { IconButton, Typography, Box, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { FormikFieldStyles, useDialogFormStyles } from './FieldStyles';
import moment, { Moment } from 'moment';

export function getDateString(date: Moment | Date | string | null | undefined): string | null {
  return date ? moment(date).format('YYYY-MM-DD') : null;
}

interface Props extends FormikFieldStyles {
  styledClasses?: any;
  values?: any;
  label?: string;
  isRequired: boolean;
  disabled?: boolean;
  formikKey: string;
  date: any;
  setFieldValue: any;
  errors: any;
  maxDate?: string;
  minDate?: string;
  variant?: 'small' | 'medium';
  fullWidth?: boolean;
  placeholder?: string;
}

export const FormikDateField: React.FC<Props> = ({
  date,
  isRequired,
  disabled,
  setFieldValue,
  formikKey,
  errors,
  maxDate,
  minDate,
  style,
  styledClasses,
  fullWidth,
  placeholder
}) => {
  const { t } = useTranslation();
  const { submitForm } = useFormikContext();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitForm();
    }
  };

  return (
    <KeyboardDatePicker
      minDate={minDate}
      maxDate={maxDate}
      required={isRequired}
      id={formikKey}
      placeholder={placeholder}
      inputVariant="outlined"
      format="YYYY-MM-DD"
      okLabel={t('Select')}
      cancelLabel={t('Cancel')}
      clearable
      clearLabel={t('Clear')}
      error={!!errors}
      helperText={errors}
      disabled={disabled}
      FormHelperTextProps={{
        style: { ...style?.helper, padding: '4px' },
        className: styledClasses.dateField
      }}
      value={date}
      onChange={(value) => setFieldValue(formikKey, value)}
      KeyboardButtonProps={{
        'aria-label': 'change date',
        color: disabled ? 'secondary' : 'primary'
      }}
      minDateMessage={t('Date should not be before minimal date')}
      maxDateMessage={t('Date should not be after maximal date')}
      fullWidth={fullWidth}
      onKeyDown={handleKeyPress}
      TextFieldComponent={TextField}
    />
  );
};

export const FormikFormDateField: React.FC<Props> = ({
  styledClasses,
  date,
  values,
  label,
  setFieldValue,
  formikKey,
  errors,
  style,
  disabled,
  variant,
  ...props
}) => {
  const defaultClasses = useDialogFormStyles();
  const classes = styledClasses || defaultClasses;

  const containerClass = (): string | undefined => {
    switch (variant) {
      case 'medium':
        return classes.inputContainer;
      case 'small':
        return classes.shortDateFieldContainer;
      default:
        return classes.shortDateFieldContainer;
    }
  };

  return (
    <Box className={containerClass()} style={style?.container}>
      <Typography variant="h5" className={classes.inputLabel}>
        {label}
      </Typography>

      <Field
        styledClasses={classes}
        date={date}
        errors={errors}
        formikKey={formikKey}
        component={FormikDateField}
        setFieldValue={setFieldValue}
        {...{ ...props, disabled }}
      />

      {values && (
        <IconButton
          className={classes.clearButton}
          disabled={disabled}
          onClick={() => {
            setFieldValue(formikKey, null);
          }}
        >
          <ClearIcon className={classes.clearIcon} />
        </IconButton>
      )}
    </Box>
  );
};
