import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { VirsTreeState, initialVirsTreeState, VirsTreeAction } from './treeState/treeTypes';
import { virsTreeReducer } from './treeState/treeReducer';
import { VirsTreePage } from './VirsTreePage';

const VirsTreeStateContext = createContext<{
  treeState: VirsTreeState;
}>({
  treeState: initialVirsTreeState
});

const VirsTreeDispatchContext = createContext<{
  treeDispatch: Dispatch<VirsTreeAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  treeDispatch: () => {}
});

export const VirsPageWithContext: React.FC = () => {
  const [treeState, treeDispatch] = useReducer(virsTreeReducer, initialVirsTreeState);

  return (
    <VirsTreeStateContext.Provider value={{ treeState }}>
      <VirsTreeDispatchContext.Provider value={{ treeDispatch }}>
        <VirsTreePage />
      </VirsTreeDispatchContext.Provider>
    </VirsTreeStateContext.Provider>
  );
};

export function useVirsTreeState() {
  return useContext(VirsTreeStateContext);
}
export function useVirsTreeDispatch() {
  return useContext(VirsTreeDispatchContext);
}
