import { CircularProgress } from '@material-ui/core';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialValues, ReportInput } from '../../store/reports/types';

import { FieldGroup } from '../../components/FormikFields/GenericFormikWrappers/FieldGroup';
import { GenericDateField } from '../../components/FormikFields/GenericFormikWrappers/GenericDateField';
import { GenericRadioGroup } from '../../components/FormikFields/GenericFormikWrappers/GenericRadioGroup';
import { GenericSelectField } from '../../components/FormikFields/GenericFormikWrappers/GenericSelectField';
import {
  loadOutletOptions,
  loadOutletTypeOptions,
  loadReportOptions,
  setReportOptions
} from '../../store/reports/store';
import { GenericPopperSelect } from '../../components/FormikFields/GenericFormikWrappers/GenericPopperSelect';
import { ApplicationState } from '../../store';
import { LegalSearchPopper } from './LegalSearchPopper';
import { GenericMultipleSelectField } from '../../components/FormikFields/GenericFormikWrappers/GenericMultipleSelectField';
import { OutletSearchPopper } from './OutletSearchPopper';
import { getDatePlaceholder } from '../../components/FormikFields/utilities';
import { language as languageSelector } from '../../store/virsisApp/virsisSelectors';
import { getDateString } from '../../components/FormikFields/FormikFormDateField';
import { currentDate, parseDate } from '../../utils/dateUtils';
import { InstitutionSearchPopper } from './InstitutionSearchPopper';

export const FormContent: React.FC<FormikProps<ReportInput>> = (formikProps) => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const { options, isLoading } = useSelector((state: ApplicationState) => state.report);
  const { t } = useTranslation();
  const datePlaceholder = getDatePlaceholder(language);

  const {
    reportOptions,
    sortOptions,
    fileOptions,
    reportSettings,
    dataBlockOptions,
    enterpriseTypeOptions,
    outletTypeOptions,
    outletOptions,
    institutionOptions,
    prefilledValues
  } = options;

  useEffect(() => {
    dispatch(
      setReportOptions({
        ...options,
        outletOptions: []
      })
    );
    formikProps.setValues({
      ...formikProps.values,
      outletIds: []
    });
  }, [formikProps.values.virsSelectEnabled]);

  useEffect(() => {
    if (formikProps.values.virsSelectEnabled) {
      formikProps.setValues({
        ...formikProps.values,
        outletIds: formikProps.values.outletIds.filter((id) =>
          outletOptions.some(({ value }) => value === id)
        )
      });
    }
  }, [outletOptions]);

  useEffect(() => {
    formikProps.setValues({
      ...initialValues,
      report: formikProps.values.report || reportOptions[0]?.value || initialValues.report,
      dataBlock: dataBlockOptions[0] ? 'null' : initialValues.dataBlock,
      fileFormat: fileOptions[0]?.value || initialValues.fileFormat
    });
  }, [reportOptions, dataBlockOptions, sortOptions, fileOptions, reportSettings]);

  useEffect(() => {
    if (prefilledValues) {
      formikProps.setValues({
        ...formikProps.values,
        report: 'A6',
        todayReport: false,
        fromDate: parseDate('2023-01-01'),
        virsSelectEnabled: false,
        institutionIds: prefilledValues?.institutionIds,
        classifierDataBlockIds: dataBlockOptions
          ?.filter(
            (option) =>
              option.label === 'Viešinimo paslaugų sandoris' ||
              option.label === 'Viešinimo paslaugų sandoris (iš ES lėšų)'
          )
          .map((option) => option.value),
        institutionSelectEnabled: true,
        fileFormat: fileOptions[0]?.value || initialValues.fileFormat
      });
    }
  }, [prefilledValues]);

  return (
    <>
      <FieldGroup label={t('Data type')}>
        <GenericSelectField
          field="report"
          options={reportOptions}
          formikProps={formikProps}
          onChange={(value) => dispatch(loadReportOptions(value || undefined))}
          clearable={false}
          withoutLabel={true}
        />

        {isLoading && <CircularProgress style={{ flex: '0 0 40px' }} />}
      </FieldGroup>

      <FieldGroup
        label={t('Duration')}
        disabled={!reportSettings.timeFilter}
        style={{
          container: {
            alignItems: 'flex-start'
          },
          labelBox: {
            marginTop: '9px'
          },
          contentBox: {
            maxWidth: '586px',
            marginLeft: '4px',
            flexWrap: 'wrap'
          }
        }}
      >
        <GenericRadioGroup
          formikProps={formikProps}
          field="todayReport"
          clearable={false}
          style={{
            container: { flexGrow: 0, flexShrink: 1, marginRight: '-20px' },
            radioGroup: { flexDirection: 'column' }
          }}
          options={[
            { value: true, label: '' },
            { value: false, label: '' }
          ]}
          onChange={(value) =>
            value &&
            formikProps.setValues({
              ...formikProps.values,
              fromDate: null,
              toDate: null
            })
          }
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '10px'
          }}
        >
          <span
            style={{ margin: '4px' }}
            className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
          >
            {t('Duration of today')}
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <GenericDateField
              label={t('Duration from')}
              field="fromDate"
              formikProps={formikProps}
              disabled={!reportSettings.timeFilter || formikProps.values.todayReport}
              maxDate={currentDate}
              placeholder={datePlaceholder}
              style={{
                container: { maxWidth: '250px' }
              }}
            />

            <GenericDateField
              label={t('Duration to')}
              field="toDate"
              formikProps={formikProps}
              disabled={!reportSettings.timeFilter || formikProps.values.todayReport}
              minDate={getDateString(formikProps.values.fromDate) || undefined}
              maxDate={currentDate}
              placeholder={datePlaceholder}
              style={{
                container: { maxWidth: '250px' }
              }}
            />
          </div>
        </div>
      </FieldGroup>

      <FieldGroup
        label={t('VIRS type')}
        disabled={!reportSettings.virsEnterpriseFilter}
        infoTooltip={t('Producer and/or disseminator of public information (VIRS) activity type')}
      >
        <GenericMultipleSelectField
          field="virsEnterpriseTypeIds"
          options={enterpriseTypeOptions}
          formikProps={formikProps}
          disabled={!reportSettings.virsEnterpriseFilter}
          onChange={(value) => dispatch(loadOutletTypeOptions(value))}
          withoutLabel={true}
        />
      </FieldGroup>

      <FieldGroup
        label={t('VIRS only')}
        disabled={!reportSettings.virsFilter}
        infoTooltip={t('Producer and/or disseminator of public information (VIRS)')}
      >
        <GenericRadioGroup
          field="virsSelectEnabled"
          style={{
            container: { width: '120px' },
            radioGroup: { width: '200px' }
          }}
          options={[
            { value: false, label: t('All') },
            { value: true, label: t('Selected VIRS') }
          ]}
          formikProps={formikProps}
          clearable={false}
          disabled={!reportSettings.virsFilter}
          onChange={(value) => {
            if (!value) {
              formikProps.setValues({
                ...formikProps.values,
                virsIds: initialValues.virsIds,
                outletIds: initialValues.outletIds,
                institutionIds: initialValues.institutionIds
              });
            }
          }}
        />
      </FieldGroup>

      <FieldGroup disabled={!formikProps.values.virsSelectEnabled}>
        <GenericPopperSelect
          field="virsIds"
          formikProps={formikProps}
          disabled={!formikProps.values.virsSelectEnabled}
          dialogContent={LegalSearchPopper}
          onChange={(value) => dispatch(loadOutletOptions(value))}
        />
      </FieldGroup>

      <FieldGroup
        label={t('Institution')}
        disabled={!reportSettings.institutionFilter}
        infoTooltip={t('institutionFilterInfoMessage')}
      >
        <GenericRadioGroup
          field="institutionSelectEnabled"
          style={{
            container: { width: '120px' },
            radioGroup: { width: '250px' }
          }}
          options={[
            { value: false, label: t('allInstitutions') },
            { value: true, label: t('selectedInstitution') }
          ]}
          formikProps={formikProps}
          clearable={false}
          disabled={!reportSettings.institutionFilter}
          onChange={(value) => {
            if (!value) {
              formikProps.setValues({
                ...formikProps.values,
                virsIds: initialValues.virsIds,
                outletIds: initialValues.outletIds,
                institutionIds: initialValues.institutionIds
              });
            }
          }}
        />
      </FieldGroup>

      <FieldGroup disabled={!formikProps.values.institutionSelectEnabled}>
        <GenericPopperSelect
          field="institutionIds"
          formikProps={formikProps}
          disabled={!formikProps.values.institutionSelectEnabled}
          dialogContent={InstitutionSearchPopper}
          onChange={(value) => dispatch(loadOutletOptions(value))}
          initialOptions={institutionOptions?.filter((option) =>
            prefilledValues?.institutionIds?.includes(option.value)
          )}
        />
      </FieldGroup>

      <FieldGroup
        label={t('VIP type')}
        disabled={!reportSettings.outletTypeFilter}
        infoTooltip={t('Media (VIP) type')}
      >
        <GenericMultipleSelectField
          field="outletTypeIds"
          options={outletTypeOptions}
          formikProps={formikProps}
          disabled={!reportSettings.outletTypeFilter}
          withoutLabel={true}
        />
      </FieldGroup>

      <FieldGroup
        label={t('VIP name')}
        disabled={!reportSettings.outletNameFilter}
        infoTooltip={t('Media (VIP) name')}
      >
        {formikProps.values.virsSelectEnabled && (
          <GenericMultipleSelectField
            field="outletIds"
            options={outletOptions}
            formikProps={formikProps}
            disabled={!reportSettings.outletNameFilter}
            withoutLabel={true}
          />
        )}
        {!formikProps.values.virsSelectEnabled && (
          <GenericPopperSelect
            field="outletIds"
            formikProps={formikProps}
            disabled={!reportSettings.outletNameFilter}
            dialogContent={OutletSearchPopper}
          />
        )}
      </FieldGroup>

      <FieldGroup label={t('Data block')} disabled={dataBlockOptions.length <= 0}>
        {reportSettings.classifierBlocks ? (
          <GenericMultipleSelectField
            field="classifierDataBlockIds"
            options={dataBlockOptions}
            disabled={dataBlockOptions.length <= 0}
            formikProps={formikProps}
            clearable={false}
            withoutLabel={true}
          />
        ) : (
          <GenericSelectField
            field="dataBlock"
            options={[{ value: 'null', label: t('All') }, ...dataBlockOptions]}
            disabled={dataBlockOptions.length <= 0}
            formikProps={formikProps}
            clearable={false}
            withoutLabel={true}
          />
        )}
      </FieldGroup>

      <FieldGroup label={t('Sorting')} disabled={!reportSettings.sorting}>
        <GenericSelectField
          field="sortByColumn"
          options={sortOptions}
          formikProps={formikProps}
          disabled={!reportSettings.sorting}
          withoutLabel={true}
        />
      </FieldGroup>

      <FieldGroup label={t('Data format')}>
        <GenericRadioGroup
          style={{
            container: { width: '120px' },
            radioGroup: { width: '210px' }
          }}
          field="fileFormat"
          options={fileOptions.map((option) => ({
            ...option,
            label: `.${option.label.toLowerCase()}`
          }))}
          clearable={false}
          formikProps={formikProps}
        />
      </FieldGroup>
    </>
  );
};
