import { ColumnHeader } from '../../../../../../utils/tableTypes';
import { VirsIndividualPersonFinancialSupportColumnHeaderField } from './tableTypes';

export const columnNames: ColumnHeader<VirsIndividualPersonFinancialSupportColumnHeaderField>[] = [
  {
    id: 'supportProviderFullName',
    numeric: false,
    label: 'Paramos teikėjo vardas, pavardė'
  },
  {
    id: 'financialSupportAmount',
    numeric: false,
    label: 'Paramos suma'
  },
  {
    id: 'supportReceivedYear',
    numeric: false,
    label: 'Metai'
  }
];
