import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { BoxTitle } from './TreeChartBoxes/BoxTitle';
import { PercentageInfo } from './TreeChartBoxes/PercentageInfo';
import { TreeSingleBox } from './TreeChartBoxes/TreeSingleBox';
import {
  removeConcatenatedLevelFromParentIdNumber,
  virsIdOfshareholderOrAuthorizedShareholder
} from '../../../pages/VirsTreePage/treeState/treeFunctions';
import {
  useVirsTreeDispatch,
  useVirsTreeState
} from '../../../pages/VirsTreePage/VirsTreePageContext';
import {
  boxColor,
  childrenNumber,
  isLegalPerson,
  isNaturalPerson
} from './treeChartFunctions/uiFunctions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ShareholderComponent = ({ data }: any) => {
  const { treeDispatch } = useVirsTreeDispatch();
  const {
    treeState: { shareType }
  } = useVirsTreeState();

  const { shareholder, activeDate } = data;
  const {
    shareholderPersons,
    sharePercentage,
    votePercentage,
    votesOnRules,
    shareholderLevel,
    shareholderId,
    parentId,
    shareholderEndDate,
    sharePercentageLessThanPermitted,
    votePercentageLessThanPermitted
  } = shareholder;
  const firstPerson = shareholderPersons[0];
  const { t } = useTranslation();

  const subtitle = () => {
    if (isNaturalPerson(shareholderPersons[0].personType)) {
      return t('Natural person');
    }
    return t('Legal entity');
  };

  const handleOpenShareholderCard = () => {
    treeDispatch({
      type: 'OPEN_SHAREHOLDER_PREVIEW_CARD',
      shareholderName: firstPerson.personName,
      shareholderData: shareholder
    });
  };

  const toggleChildrenShareholders = () => {
    treeDispatch({
      type: 'TOGGLE_SHAREHOLDER_CHILDREN',
      shareholder: {
        shareholderId,
        shareholderLevel
      }
    });
  };

  const closeShareholder = () => {
    treeDispatch({
      type: 'HIDE_SHAREHOLDER',
      shareholder: {
        shareholderId,
        parentId: removeConcatenatedLevelFromParentIdNumber(parentId, shareholderLevel),
        shareholderLevel,
        shareholderPersons
      }
    });
  };
  const relatedVirsId: number | undefined =
    virsIdOfshareholderOrAuthorizedShareholder(shareholderPersons);
  const hasErrors = !!firstPerson.shareholderErrors && firstPerson.shareholderErrors.length > 0;

  return (
    <div>
      <Handle type="target" position={Position.Left} />
      <TreeSingleBox
        boxTitle={<BoxTitle title={firstPerson.personName} subtitle={subtitle()} />}
        showLeftSideBox
        showRightSideBox={isLegalPerson(firstPerson.personType)}
        rightChildrenNumber={childrenNumber(firstPerson.shareholders)}
        minHeight={95}
        maxHeight={95}
        color={boxColor(firstPerson.personType, relatedVirsId)}
        additionalInfo={
          <PercentageInfo
            shares={sharePercentage}
            votes={votePercentage}
            shareType={shareType}
            votesOnRule={votesOnRules}
            sharePercentageLessThanPermitted={sharePercentageLessThanPermitted}
            votePercentageLessThanPermitted={votePercentageLessThanPermitted}
          />
        }
        hasErrors={hasErrors}
        errorText={firstPerson.shareholderErrors}
        onClick={handleOpenShareholderCard}
        handleRightSideBoxClick={toggleChildrenShareholders}
        handleLeftSideBoxClick={closeShareholder}
        leftSideButtonDisabled={shareholderLevel === 1}
        foregroundGrid={shareholderEndDate === activeDate}
      />
      {firstPerson.shareholderLinks.length > 0 && (
        <Handle type="source" position={Position.Right} />
      )}
    </div>
  );
};
