import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

type PaginationIteratePageProps = {
  currentPage: number;
  previousPage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  nextPage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  pagesCount: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1)
    }
  })
);

export const PaginationIteratePage: React.FC<PaginationIteratePageProps> = ({
  currentPage,
  previousPage,
  nextPage,
  pagesCount
}) => {
  const classes = useStyles();

  return (
    <div className="pagination-item">
      <IconButton
        onClick={previousPage}
        aria-label="previous"
        className={classes.margin}
        size="small"
        disabled={currentPage === 0}
      >
        <NavigateBeforeIcon fontSize="inherit" />
      </IconButton>
      <Typography variant="h6" style={{ color: '#637082', fontWeight: 600 }}>
        {currentPage + 1}
      </Typography>
      <IconButton
        onClick={nextPage}
        aria-label="next"
        className={classes.margin}
        size="small"
        disabled={currentPage === pagesCount - 1 || pagesCount === 0}
      >
        <NavigateNextIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};
