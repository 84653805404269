import { EnterpriseDataTablColumnHeader } from './tableTypes';

export const columnNames: EnterpriseDataTablColumnHeader[] = [
  {
    id: 'enterpriseTypeName',
    numeric: false,
    label: 'Veiklos rūšis'
  },
  {
    id: 'validFrom',
    numeric: false,
    label: 'Nuo'
  },
  {
    id: 'validTo',
    numeric: false,
    label: 'Iki'
  }
];
