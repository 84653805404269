import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { History } from 'history';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';

const useStyles = makeStyles(() => ({
  text: {
    color: '#637082',
    padding: '1px 15px'
  },
  button: {
    padding: 0,
    borderColor: 'transparent',
    backgroundColor: 'white',
    divShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
    color: '#637082'
  },
  themeButton: {
    '&:hover, &:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  margin: {
    margin: '15px 0px'
  }
}));

export const CustomButton: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history: History = useHistory();
  const { language } = useSelector((state: ApplicationState) => state.virsis);

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => history.push(language === 'en' ? '/virs-search' : '/paieska')}
        className={`${classes.button} ${classes.themeButton} ${classes.margin}`}
      >
        <Typography variant="h5" className={classes.text}>
          {t('Search for VIRS button')}
        </Typography>
      </Button>
    </>
  );
};
