import React from 'react';
import { Container, Typography } from '@material-ui/core';

const NotFoundPage: React.FC = () => {
  return (
    <Container maxWidth="lg" className="not-found-container">
      <Typography variant="h1" style={{ padding: '20px 0' }}>
        Puslapis nerastas
      </Typography>
      <Typography variant="h2" style={{ padding: '20px 0' }}>
        Atsiprašome, bet puslapis, kurio ieškojote nerastas
      </Typography>
    </Container>
  );
};

export default NotFoundPage;
