import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { VictoryContainer, VictoryPie, VictoryTooltip } from 'victory';
import {
  splitLabelToLines,
  splitTooltipLabelToLines
} from '../../../../pages/VirsTreePage/treeState/treeFunctions';
import { PieChartData } from '../../../../pages/VirsTreePage/treeState/treeTypes';

const useStyles = makeStyles(() => ({
  customChart: {
    color: 'red'
  },
  pieChartWrapper: {
    position: 'relative'
  }
}));

interface Props {
  title: string;
  parentName: string;
  chartData: PieChartData[];
  shareholderId?: number;
  height?: number;
  width?: number;
  wrapStyles?: React.CSSProperties;
  ariaLabel: string;
}

export const PersonChart: React.FC<Props> = ({
  title,
  parentName,
  chartData,
  shareholderId,
  height,
  wrapStyles,
  width,
  ariaLabel
}) => {
  const classes = useStyles();
  const ratio = height ? height / 320 : 1;

  return (
    <div style={wrapStyles}>
      <Typography variant="h3" align="center" style={{ fontSize: '0.95rem' }}>
        {title}
      </Typography>
      <Typography align="center" style={{ fontSize: '0.95rem', color: '#637082' }}>
        {parentName}
      </Typography>

      <div className={classes.pieChartWrapper}>
        {/* diagrama rodanti pavadinimą priklausomai nuo procentų, jei 25 ir daugiau - pilnas pavadinimas, jei < 25 tik procentai */}
        <VictoryPie
          x="shareholderName"
          y="percentage"
          padding={{ top: 60, left: 80, right: 100, bottom: 10 }}
          height={height || 320}
          width={width}
          data={chartData}
          padAngle={2}
          radius={({ datum }) => (datum.key === shareholderId ? 120 * ratio : 110 * ratio)}
          innerRadius={75 * ratio}
          labels={({ datum }) => splitLabelToLines(datum.shareholderName)}
          containerComponent={
            <VictoryContainer responsive={false} width={410} height={360} title={ariaLabel} />
          }
          labelRadius={({ datum }) => (datum.key === shareholderId ? 140 * ratio : 130 * ratio)}
          style={{
            labels: { fontSize: 11 },
            data: {
              fill: ({ datum }) => datum.fill,
              stroke: ({ datum }) => datum.stroke
            }
          }}
          labelPlacement={chartData.length > 2 ? 'parallel' : 'vertical'}
        />
        {/* diagrama rodanti dalyvio pavadinimą užvedius ant diagramos dalies - diagrama su tooltipu */}
        <VictoryPie
          x="shareholderName"
          y="percentage"
          padding={{ top: 60, left: 80, right: 100, bottom: 10 }}
          height={height || 320}
          width={width}
          data={chartData}
          padAngle={2}
          radius={({ datum }) => (datum.key === shareholderId ? 120 * ratio : 110 * ratio)}
          innerRadius={75 * ratio}
          labelComponent={
            <VictoryTooltip
              constrainToVisibleArea
              active={false}
              flyoutPadding={{ top: 10, bottom: 5, left: 20, right: 20 }}
              flyoutStyle={{
                strokeWidth: 0.7,
                fill: 'white',
                stroke: '#006FB2'
              }}
              style={{ fontFamily: 'Open Sans' }}
            />
          }
          labels={({ datum }) => splitTooltipLabelToLines(datum.shareholderName)}
          containerComponent={<VictoryContainer responsive={false} width={410} height={360} />}
          style={{
            labels: { fontSize: 14 },
            data: {
              fill: 'transparent',
              stroke: 'transparent'
            },
            parent: { position: 'absolute', top: 0 }
          }}
        />
      </div>
    </div>
  );
};
