import React from 'react';
import { Paper, Table, TableBody } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { useOutletEditionTableDispatch, useOutletEditionTableState } from './EditionSection';
import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../utils/tableTypes';
import { columnNames } from './tableState/TableColumns';
import { filterAndSortTableData } from './tableState/tableStateFunctions';
import EditionCustomizationBar from './EditionTableCustomizationBar';
import { OutletEditionRow } from './EdtionRow';
import EditionDataTableHead from './EditionTableHead';
import EditionCustomFilter from './EditionCustomFilter';

interface Props {
  chosenDate: string;
}

export const OutletEditionTable: React.FC<Props> = ({ chosenDate }) => {
  const { state: tableState } = useOutletEditionTableState();
  const { dispatch } = useOutletEditionTableDispatch();

  const { outletInfo } = useSelector((state: ApplicationState) => state.outletInfoData);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const validData =
    outletInfo?.editions.map((edition) => ({
      ...edition,
      periodName: edition.periodName !== null ? edition.periodName : edition.editionDate
    })) || [];

  const data = filterAndSortTableData(validData, tableState, chosenDate);

  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <div className="data-table">
        <EditionCustomizationBar
          data={outletInfo?.editions || []}
          editionsPeriodic={outletInfo?.editionsPeriodic}
        />
        <Table>
          <EditionDataTableHead columns={columnNames()} />
          {tableState.customFilterOn && <EditionCustomFilter columnNames={columnNames()} />}
          <TableBody>
            {dataPage.map((record) => (
              <OutletEditionRow key={record.editionId} record={record} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          recordsCount={data.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={data.length === 0}
        />
      </div>
    </Paper>
  );
};
