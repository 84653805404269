import React from 'react';
import { ClickAwayListener, TextField, Chip, Popper, Box } from '@material-ui/core';
import { FormikFieldStyles } from './FieldStyles';
import { useDialogFormStyles } from './GenericFormikWrappers/DialogFormStyles';

interface SelectOption<TOption> {
  value: TOption;
  label: string;
  onDelete: () => void;
}

interface FormikChipInputProps<TValue> extends FormikFieldStyles {
  dialogContent: React.ReactNode;
  selected: SelectOption<TValue>[];
  dialogTitle?: string;
  disabled?: boolean;
  setAnchorEl: (a: HTMLElement | null) => void;
  anchorEl: HTMLElement | null;
}

function FormikChipInput<TValue>({
  dialogContent: DialogContents,
  dialogTitle,
  selected,
  disabled,
  setAnchorEl,
  anchorEl
}: FormikChipInputProps<TValue>) {
  const classes = useDialogFormStyles();

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ width: '100%' }}>
        <TextField
          disabled={disabled}
          className={classes.textField}
          fullWidth
          variant="outlined"
          onClick={handleClick}
          aria-multiline={true}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <Box display="flex" flexWrap="wrap" alignItems="center" padding={0} margin={0}>
                {selected.map((value) => (
                  <Chip
                    key={`${value.value}`}
                    label={value.label}
                    onDelete={value.onDelete}
                    style={{ margin: 4, maxWidth: '520px' }}
                    size={'small'}
                  />
                ))}
              </Box>
            )
          }}
        />
        <Popper title={dialogTitle} open={!!anchorEl && !disabled} anchorEl={anchorEl}>
          {DialogContents}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

export default FormikChipInput;
