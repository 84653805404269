import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { OutletEditionColumnHeaderField } from './tableState/tableTypes';
import { ColumnHeader } from '../../../../../utils/tableTypes';
import { useOutletEditionTableDispatch, useOutletEditionTableState } from './EditionSection';
import { ApplicationState } from '../../../../../store';
import { ShareholderOutletEditionData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';

interface Props {
  columnNames: ColumnHeader<OutletEditionColumnHeaderField>[];
}

const EditionCustomFilter: React.FC<Props> = ({ columnNames }) => {
  const { state: tableState } = useOutletEditionTableState();
  const { dispatch } = useOutletEditionTableDispatch();

  const handleSetCustomFilter = (column: OutletEditionColumnHeaderField, value: string | null) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  const allData =
    useSelector((state: ApplicationState) => state.outletInfoData.outletInfo?.editions) || [];

  const validData = allData.map((edition) => ({
    ...edition,
    periodName: edition.periodName !== null ? edition.periodName : edition.editionDate
  }));

  function getUniqueOptions(
    name: OutletEditionColumnHeaderField,
    data: ShareholderOutletEditionData[]
  ) {
    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => item[name].toString())
        )
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, validData)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
export default EditionCustomFilter;
