import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { ColumnHeader, Order } from '../../../../../../utils/tableTypes';

export type OutletAdPrintingColumnHeaderField = 'validFrom' | 'validTo';

export type OutletAdPrintingColumnsDisplayStatus = {
  validFrom: boolean;
  validTo: boolean;
};

export type OutletAdPrintingTableCustomFilter = {
  validFrom: string[];
  validTo: string[];
};

export type OutletAdPrintingColumnHeader = ColumnHeader<OutletAdPrintingColumnHeaderField>;

export interface OutletAdPrintingTableState {
  sortBy: OutletAdPrintingColumnHeaderField;
  order: Order;
  displayFilter: filterOptions;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: OutletAdPrintingColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: OutletAdPrintingTableCustomFilter;
}

export const initialAdPrintingTableState: OutletAdPrintingTableState = {
  order: 'desc',
  sortBy: 'validFrom',
  displayFilter: 'allItems',
  page: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    validFrom: true,
    validTo: true
  },
  customFilterOn: false,
  customFilter: {
    validFrom: [],
    validTo: []
  }
};

export type OutletAdPrintingTableAction =
  | {
      type: 'TABLE_INITIALIZED';
    }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: OutletAdPrintingColumnHeaderField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: OutletAdPrintingColumnHeaderField }
  | {
      type: 'COLUMN_DISPLAY_TOGGLED';
      column: OutletAdPrintingColumnHeaderField;
    }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'SET_DISPLAY_FILTER'; value: filterOptions };
