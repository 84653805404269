import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Moment } from 'moment';

import { makeStyles, Typography } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { useTranslation } from 'react-i18next';
import { CustomSelect } from './CustomSelect';
import { IconButtonWrapper } from '../Buttons/IconButtonWrapper';
import { ApplicationState } from '../../../../store';
import { fetchVirsDataOfDate } from '../../../../store/virsTree/virsTreeActions';
import { currentDate } from '../../../../utils/dateUtils';
import { language as languageSelector } from '../../../../store/virsisApp/virsisSelectors';
import { getDateAvailablePredicate, shouldDisableDate } from '../../Timeline/dateUtil';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { VirsTreeCustomTooltip } from '../../VirsTreeCustomTooltip';

type Error = 'FORMAT' | 'DATE' | 'MIN' | 'MAX';

const useStyles = makeStyles(() => ({
  calendar: {
    maxWidth: 180,
    marginLeft: '20px',
    height: '40px',
    '& > div': {
      height: 40,
      padding: 0,
      '& > input': { fontSize: '0.875rem', color: '#626f81' },
      '& > div': {
        margin: 0,
        paddingRight: 12,
        '& > button': { padding: '0', '& svg': { fill: '#006FB2' } }
      }
    }
  },
  biggerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  selectionName: {
    width: 120,
    fontWeight: 'bold',
    color: '#626f81'
  },
  paper: {
    backgroundColor: '#F5F8FF',
    paddingLeft: 20
  }
}));

export const TopPanel: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const {
    chartData: {
      timeline: { activeDate }
    },
    virsis: { virsStartDate, virsEndDate, activityPeriods }
  } = useSelector((state: ApplicationState) => state);
  const { t } = useTranslation();

  const isDateAvailable = getDateAvailablePredicate(activityPeriods || []);
  const value = isDateAvailable(activeDate) ? activeDate : null;

  const [error, setError] = useState<Error | null>(null);

  function handleChange(value: Moment) {
    const showError = shouldDisableDate(activityPeriods || [], value);
    if (showError) {
      setError('DATE');
    }
    if (!value.isValid()) {
      setError('FORMAT');
    }
    if (value.isValid() && isDateAvailable(value) && !showError) {
      setError(null);
      dispatch(fetchVirsDataOfDate(value.format('L')));
    }
  }

  const getErrorMessage = (errorType: Error | null) => {
    switch (errorType) {
      case 'DATE':
        return t('VIRS was not active during this period');
      case 'FORMAT':
        return t('Date format');
      default:
        return null;
    }
  };

  const tooltipWrapper = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    let text = '';

    if (shouldDisableDate(activityPeriods || [], day)) {
      text = t('VIRS was not active during this period');
    }

    return (
      <VirsTreeCustomTooltip
        className={'MuiButtonBase-root MuiIconButton-root MuiPickersDay-day'}
        children={<div>{dayComponent}</div>}
        text={text}
      />
    );
  };

  return (
    <>
      <div className={classes.biggerContainer} style={{ marginBottom: 10 }}>
        <Typography className={classes.selectionName} style={{ marginRight: 20 }}>
          {t('Display by')}
        </Typography>

        <IconButtonWrapper icon={<TuneIcon />}>
          <CustomSelect />
        </IconButtonWrapper>
      </div>

      <div className={classes.biggerContainer} style={{ paddingBottom: error ? '32px' : '0px' }}>
        <Typography className={classes.selectionName}>{t('Choose date')}</Typography>

        <MuiPickersUtilsProvider locale={language} utils={MomentUtils}>
          <KeyboardDatePicker
            renderDay={(
              day: MaterialUiPickersDate,
              selectedDate: MaterialUiPickersDate,
              dayInCurrentMonth: boolean,
              dayComponent: JSX.Element
            ) => {
              return tooltipWrapper(day, selectedDate, dayInCurrentMonth, dayComponent);
            }}
            className={classes.calendar}
            minDate={virsStartDate}
            maxDate={virsEndDate || currentDate}
            inputVariant="outlined"
            okLabel={t('Select')}
            cancelLabel={t('Cancel')}
            value={value}
            placeholder="mmmm-mm-dd"
            onChange={(date) => date && handleChange(date)}
            format="YYYY-MM-DD"
            shouldDisableDate={(date) => shouldDisableDate(activityPeriods || [], date)}
            error={error !== null}
            helperText={getErrorMessage(error)}
            invalidDateMessage="mmmm-mm-dd"
            minDateMessage={t('Date should not be before minimal date')}
            maxDateMessage={t('Date should not be after maximal date')}
          />
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
};
