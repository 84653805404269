import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { ShareholderVirsEthicalNonComplianceData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { VirsEthicalNonComplianceTable } from './EthicalNonComplianceTable';
import { virsEthicalNonComplianceTableReducer } from './tableState/tableReducer';
import {
  initialVirsEthicalNonComplianceTableState,
  VirsEthicalNonComplianceTableAction,
  VirsEthicalNonComplianceTableState
} from './tableState/tableTypes';

interface Props {
  onClickOutlet: (outletId: number, outletName: string) => void;
  dataFilter?: (entry: ShareholderVirsEthicalNonComplianceData) => boolean;
}

const VirsEthicalNonComplianceTableStateContext = createContext<{
  state: VirsEthicalNonComplianceTableState;
}>({
  state: initialVirsEthicalNonComplianceTableState
});

const VirsEthicalNonComplianceTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsEthicalNonComplianceTableAction>;
  dataFilter: (entry: ShareholderVirsEthicalNonComplianceData) => boolean;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
  dataFilter: () => true
});

export const EthicalNonComplianceSection: React.FC<Props> = ({ onClickOutlet, dataFilter }) => {
  const [state, dispatch] = useReducer(
    virsEthicalNonComplianceTableReducer,
    initialVirsEthicalNonComplianceTableState
  );

  return (
    <VirsEthicalNonComplianceTableStateContext.Provider value={{ state }}>
      <VirsEthicalNonComplianceTableDispatchContext.Provider
        value={{ dispatch, dataFilter: dataFilter || (() => true) }}
      >
        <VirsEthicalNonComplianceTable onClickOutlet={onClickOutlet} />
      </VirsEthicalNonComplianceTableDispatchContext.Provider>
    </VirsEthicalNonComplianceTableStateContext.Provider>
  );
};

export function useVirsEthicalNonComplianceTableState() {
  return useContext(VirsEthicalNonComplianceTableStateContext);
}
export function useVirsEthicalNonComplianceTableDispatch() {
  return useContext(VirsEthicalNonComplianceTableDispatchContext);
}
