import { api, get, post } from '../../utils/axiosApi';
import { Language } from '../../utils/tableTypes';
import { FormOption, ReportInput, ReportSettings } from './types';
import { getDateString } from '../../components/FormikFields/FormikFormDateField';

export async function getReportTypeOptions(lang: Language): Promise<FormOption<string>[]> {
  const { data }: { data: { id: string; name: string }[] } = await get(
    `/reports/types?lang=${lang}`
  );
  return data.map(({ id, name }) => ({ label: name, value: id }));
}

export async function getDataBlockOptions(
  report: string,
  lang: Language
): Promise<FormOption<string>[]> {
  const { data }: { data: { id: string; name: string }[] } = await get(
    `/reports/data-blocks/${report}?lang=${lang}`
  );
  return data.map(({ id, name }) => ({ label: name, value: id }));
}

export async function getSortOptions(
  report: string,
  lang: Language
): Promise<FormOption<string>[]> {
  const {
    data
  }: {
    data: {
      name: string;
      logicalName: string;
    }[];
  } = await get(`/reports/report-columns/${report}?lang=${lang}`);

  return data.map(({ name, logicalName }) => ({
    value: logicalName,
    label: name
  }));
}

export async function getFileOptions(report: string): Promise<FormOption<string>[]> {
  const { data }: { data: { id: string; name: string }[] } = await get(
    `/reports/report-formats/${report}`
  );
  return data.map(({ id, name }) => ({ label: name, value: id }));
}

export async function getReportSettings(report: string): Promise<ReportSettings> {
  return (await get(`/reports/report-settings/${report}`)).data;
}

export async function getEnterpriseTypeOptions(lang: Language): Promise<FormOption<number>[]> {
  const {
    data: { enterpriseTypes }
  }: {
    data: {
      enterpriseTypes: {
        enterpriseTypeId: number;
        enterpriseTypeName: string;
      }[];
    };
  } = await get(`/classifiers/enterprise-types?lang=${lang}`);

  return enterpriseTypes.map(({ enterpriseTypeId, enterpriseTypeName }) => ({
    value: enterpriseTypeId,
    label: enterpriseTypeName
  }));
}

export async function getOutletTypes(lang: Language): Promise<FormOption<number>[]> {
  const url = `/classifiers/outlet-types?lang=${lang}`;

  const {
    data: { outletTypes }
  }: {
    data: {
      outletTypes: {
        outletTypeId: number;
        outletTypeName: string;
      }[];
    };
  } = await get(url);

  return outletTypes.map(({ outletTypeId, outletTypeName }) => ({
    value: outletTypeId,
    label: outletTypeName
  }));
}

export async function getOutlets(virsIds: number[], lang: Language): Promise<FormOption<number>[]> {
  const {
    data
  }: {
    data: {
      outletId: number;
      outletName: string;
      outletTypeId: number;
      outletTypeName: string;
    }[];
  } = await post(`/outlets?lang=${lang}`, virsIds);
  return data.map(({ outletId, outletName }) => ({
    label: outletName,
    value: outletId
  }));
}

export async function getReport(input: ReportInput, lang: Language): Promise<Blob> {
  return (
    await api.post(
      `/reports/${input.report}?lang=${lang}`,
      {
        ...input,
        toDate: getDateString(input.toDate) || null,
        fromDate: getDateString(input.fromDate) || null
      },
      { responseType: 'blob' }
    )
  ).data;
}
