import moment from 'moment';
import { ShareholderVirsEnterpriseData } from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { VirsEnterpriseTableState } from './tableTypes';

export function displayDataFilter(
  data: ShareholderVirsEnterpriseData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderVirsEnterpriseData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(item.validFrom, item.validTo || undefined, undefined, '[]');
      });
    case 'selectedDate':
      return data.filter((item) => {
        return moment(chosenDate).isBetween(
          item.validFrom,
          item.validTo || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function filterAndSortEnterpriseTableData(
  tableState: VirsEnterpriseTableState,
  data: ShareholderVirsEnterpriseData[],
  chosenDate: string
): ShareholderVirsEnterpriseData[] {
  const columnsFilters = tableState.customFilter;

  const filters: Filter<ShareholderVirsEnterpriseData>[] = [
    getStringMappedColumnFilter(columnsFilters.enterpriseTypeName, ({ enterpriseTypeName }) => [
      enterpriseTypeName
    ]),
    getStringMappedColumnFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringMappedColumnFilter(columnsFilters.validTo, ({ validTo }) => [validTo])
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);

  const filtered = filterData(displayFiltered, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}
