import { makeStyles, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';

export interface LegendItemProps {
  symbol: ReactNode;
  text: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center'
  },
  symbol: {
    flexBasis: 25,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  text: {
    color: '#626f81',
    fontWeight: 600,
    padding: 4,
    marginLeft: 10
  }
});

export const LegendItem: React.FC<LegendItemProps> = ({ symbol, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.symbol}>{symbol}</div>
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};
