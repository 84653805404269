import { action } from 'typesafe-actions';

import { GenericData } from '../../components/GenericDataTable/State/types';
import {
  GET_GENERIC_DATA_ERROR,
  GET_GENERIC_DATA_REQUEST,
  GET_GENERIC_DATA_SUCCESS,
  INITIALIZE
} from './genericDataTableType';

export const fetchGenericTableDataRequest = (url: string) => action(GET_GENERIC_DATA_REQUEST, url);

export const fetchGenericTableDataError = (error: Error) => action(GET_GENERIC_DATA_ERROR, error);

export const fetchGenericTableDataSuccess = (data: GenericData) =>
  action(GET_GENERIC_DATA_SUCCESS, data);

export const initializeGenericTableData = () => action(INITIALIZE);
