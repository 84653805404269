import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderOutletCategoryData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { useOutletCategoryTableState } from './CategorySection';

interface Props {
  record: ShareholderOutletCategoryData;
}

export const OutletCategoryRow: React.FC<Props> = ({ record }) => {
  const {
    state: { columnsDisplayStatus }
  } = useOutletCategoryTableState();

  return (
    <TableRow key={record.outletCategoryId}>
      {columnsDisplayStatus.categoryTypeName && <TableCell>{record.categoryTypeName}</TableCell>}
      {columnsDisplayStatus.validFrom && <TableCell>{record.validFrom} </TableCell>}
      {columnsDisplayStatus.validTo && <TableCell>{record.validTo} </TableCell>}
    </TableRow>
  );
};
