import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { SortOrder } from '../../../../../../utils/tableTypes';

export interface VirsOutletDataTableState {
  extendedEnterprises: number[];
  extendedOutlets: number[];
  customFilter: VirsOutletDataTableCustomFilter;
  customFilterOn: boolean;
  order: SortOrder;
  sortBy: VirsOutletDataTableField;
  displayFilter: filterOptions;
  page: number;
  rowsPerPage: number;
  pagesCount: number;
  recordsCountAfterFilters: number;
  columnsDisplayStatus: OutletDataTableColumnsDisplayStatus;
}

export type VirsOutletDataTableField =
  | 'outletName'
  | 'outletTypeName'
  | 'outletGroupName'
  | 'establishedDate'
  | 'controlledFrom'
  | 'controlledTo'
  | 'internationalNumber';

export interface VirsOutletDataTableColumn {
  id: VirsOutletDataTableField;
  numeric: boolean;
  label: string;
}

export interface VirsOutletDataTableCustomFilter {
  outletName: string[];
  outletTypeName: string[];
  outletGroupName: string[];
  establishedDate: string[];
  controlledFrom: string[];
  controlledTo: string[];
  internationalNumber: string[];
}

export type OutletDataTableColumnsDisplayStatus = {
  // eslint-disable-next-line
  [key in VirsOutletDataTableField]: boolean;
};

export type VirsOutletDataTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: VirsOutletDataTableField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: VirsOutletDataTableField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: VirsOutletDataTableField }
  | { type: 'SET_DISPLAY_FILTER'; value: filterOptions };

export const initialOutletDataTableState: VirsOutletDataTableState = {
  extendedEnterprises: [],
  extendedOutlets: [],
  customFilter: {
    outletName: [],
    outletTypeName: [],
    outletGroupName: [],
    establishedDate: [],
    controlledFrom: [],
    controlledTo: [],
    internationalNumber: []
  },
  displayFilter: 'allItems',
  customFilterOn: false,
  order: 'desc',
  sortBy: 'controlledFrom',
  page: 0,
  rowsPerPage: 5,
  pagesCount: 0,
  recordsCountAfterFilters: 0,
  columnsDisplayStatus: {
    outletName: true,
    outletTypeName: true,
    outletGroupName: true,
    establishedDate: true,
    controlledFrom: true,
    controlledTo: true,
    internationalNumber: true
  }
};
