import { ColumnHeader, Order } from '../../../../../../utils/tableTypes';

export type VirsEthicalNonComplianceHeaderField =
  | 'decisionDate'
  | 'documentNumber'
  | 'outletList'
  | 'ethicalNonComplianceStatus'
  | 'validFrom'
  | 'validTo'
  | 'reportingInstitutionName';

export type VirsEthicalNonComplianceColumnsDisplayStatus = {
  reportingInstitutionName: boolean;
  decisionDate: boolean;
  documentNumber: boolean;
  outletList: boolean;
  ethicalNonComplianceStatus: boolean;
  validFrom: boolean;
  validTo: boolean;
};

export type VirsEthicalNonComplianceCustomFilter = {
  virsName: string[];
  decisionDate: string[];
  outletList: string[];
  documentNumber: string[];
  ethicalNonComplianceStatus: string[];
  validFrom: string[];
  validTo: string[];
  reportingInstitutionName: string[];
};

export type VirsEthicalNonComplianceColumnHeader =
  ColumnHeader<VirsEthicalNonComplianceHeaderField>;

export interface VirsEthicalNonComplianceTableState {
  sortBy: VirsEthicalNonComplianceHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: VirsEthicalNonComplianceColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: VirsEthicalNonComplianceCustomFilter;
}

export type VirsEthicalNonComplianceTableAction =
  | {
      type: 'TABLE_INITIALIZED';
    }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: VirsEthicalNonComplianceHeaderField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: VirsEthicalNonComplianceHeaderField }
  | {
      type: 'COLUMN_DISPLAY_TOGGLED';
      column: VirsEthicalNonComplianceHeaderField;
    }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number };

export const initialVirsEthicalNonComplianceTableState: VirsEthicalNonComplianceTableState = {
  order: 'desc',
  sortBy: 'decisionDate',
  page: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    decisionDate: true,
    documentNumber: true,
    outletList: true,
    ethicalNonComplianceStatus: true,
    validFrom: true,
    validTo: true,
    reportingInstitutionName: true
  },
  customFilterOn: false,
  customFilter: {
    virsName: [],
    decisionDate: [],
    documentNumber: [],
    outletList: [],
    ethicalNonComplianceStatus: [],
    validFrom: [],
    validTo: [],
    reportingInstitutionName: []
  }
};
