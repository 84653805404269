import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { getSortLabelPropsFactory } from '../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../TableSortingHeaderCell/TableSortingHeaderCell';
import { useGenericTableDispatch, useGenericTableState } from './GenericDataTableContext';
import {
  GenericColumnNames,
  GenericData,
  GenericDataTableState,
  GenericGroupedColumns
} from './State/types';

interface Props {
  genericData: GenericData;
  tableState: GenericDataTableState;
}

export const GenericTableHead: React.FC<Props> = ({ genericData }) => {
  const { dispatch: tableDispatch } = useGenericTableDispatch();
  const { state: tableState } = useGenericTableState();

  const handleSetSortByColumn = (column: string) => {
    tableDispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    GenericDataTableState,
    typeof handleSetSortByColumn,
    string
  >(handleSetSortByColumn, tableState);

  const { columnNames, groupedColumns } = genericData;

  const isGroupedColumn = (key: string): boolean => {
    return groupedColumns.map((columnGroup) => columnGroup.group.includes(key)).includes(true);
  };

  const getTopRow = (): GenericColumnNames => {
    const genericColumnNames: GenericColumnNames = {};

    Object.entries(columnNames).forEach((column) => {
      if (isGroupedColumn(column[0])) {
        const groupKey =
          groupedColumns.find((group) => group.group.includes(column[0]))?.name || 'key';
        genericColumnNames[groupKey] = groupKey;
      } else {
        const [key, value] = column;
        genericColumnNames[key] = value;
      }
    });
    return genericColumnNames;
  };

  const getBottomRow = (): GenericColumnNames => {
    const genericColumnNames: GenericColumnNames = {};

    Object.entries(columnNames)
      .filter((column) => isGroupedColumn(column[0]))
      .forEach((column) => {
        const [key, value] = column;
        genericColumnNames[key] = value;
      });

    return genericColumnNames;
  };

  const getHeaderRowSpan = (rowNr: number): number => {
    if (rowNr === 1) return 2;
    return 1;
  };

  const getGroupColSpan = (groupColumns: GenericGroupedColumns): number => {
    return Object.entries(tableState.columnsDisplayStatus).filter((status) => {
      const [key, value] = status;
      return groupColumns.group.includes(key) && value;
    }).length;
  };

  const renderRow = (columns: GenericColumnNames, rowNr: number) => {
    return (
      <TableRow>
        {groupedColumns &&
          Object.entries(columns).map((entry) => {
            const [key, value] = entry;
            if (
              !tableState.columnsDisplayStatus[key] &&
              !groupedColumns.map((group) => group.name === key).includes(true)
            ) {
              return null;
            }
            if (groupedColumns.map((group) => group.name === key).includes(true) && rowNr === 1) {
              const group = groupedColumns.filter((groupedColumn) =>
                groupedColumn.name.includes(key)
              );
              const [selectedGroup] = group;
              if (getGroupColSpan(selectedGroup) === 0) {
                return null;
              }

              return (
                <TableCell
                  key={selectedGroup.name}
                  rowSpan={1}
                  colSpan={getGroupColSpan(selectedGroup)}
                >
                  {selectedGroup.name}
                </TableCell>
              );
            }

            return (
              <TableSortingHeaderCell
                key={key + value}
                rowSpan={getHeaderRowSpan(rowNr)}
                label={value}
                columnName={key}
                sortLabelPropsFactory={sortLabelPropsFactory}
                tableSettings={tableState}
              />
            );
          })}
      </TableRow>
    );
  };

  return (
    <TableHead>
      {renderRow(getTopRow(), 1)}
      {renderRow(getBottomRow(), 2)}
    </TableHead>
  );
};
