import {
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../../../../utils/tableDataFunctions';
import {
  OutletEditionColumnHeaderField,
  OutletEditionTableAction,
  OutletEditionTableState
} from './tableTypes';

export const outletEditionTableReducer = (
  state: OutletEditionTableState,
  action: OutletEditionTableAction
): OutletEditionTableState => {
  switch (action.type) {
    case 'TABLE_INITIALIZED':
      return {
        ...state,
        periodic: action.periodic
      };
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: {
          periodName: [],
          circulation: []
        }
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateCustomFilterGeneric<OutletEditionColumnHeaderField>(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'SET_DISPLAY_FILTER':
      return {
        ...state,
        displayFilter: action.value
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    default:
      return state;
  }
};
