import { call, put, takeLatest, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { ActionType } from 'typesafe-actions';
import {
  loadReportOptions,
  generateReportFile,
  setLoading,
  setError,
  setReportOptions,
  loadOutletTypeOptions,
  loadOutletOptions
} from './store';
import { FormOption, FormOptions, initialReportSettings, ReportSettings } from './types';
import {
  getDataBlockOptions,
  getEnterpriseTypeOptions,
  getFileOptions,
  getOutlets,
  getOutletTypes,
  getReport,
  getReportSettings,
  getReportTypeOptions,
  getSortOptions
} from './api';
import { ApplicationState } from '../index';
import { searchByLegalDataSuccess, searchByOutletSuccess } from '../virsSearch/virsSearchActions';
import {
  SEARCH_BY_LEGAL_SUCCESS,
  SEARCH_BY_OUTLET_SUCCESS
} from '../virsSearch/virsSearchActionTypes';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import { language } from '../virsisApp/virsisSelectors';
import { Language } from '../../utils/tableTypes';

function* loadReportOptionsTask(action: ActionType<typeof loadReportOptions>) {
  yield put(setLoading(true));

  const lang: Language = yield select(language);

  const options: FormOptions = yield select((state: ApplicationState) => state.report.options);

  yield put(setReportOptions({ ...options, reportSettings: initialReportSettings }));

  try {
    const reportOptions: FormOption<string>[] = yield call(getReportTypeOptions, lang);

    const selectedReport = action.payload.report || reportOptions[0].value;

    const dataBlockOptions: FormOption<string>[] = yield call(
      getDataBlockOptions,
      selectedReport,
      lang
    );

    const sortOptions: FormOption<string>[] = yield call(getSortOptions, selectedReport, lang);

    const fileOptions: FormOption<string>[] = yield call(getFileOptions, selectedReport);

    const reportSettings: ReportSettings = yield call(getReportSettings, selectedReport);

    const enterpriseTypeOptions: FormOption<number>[] = yield call(getEnterpriseTypeOptions, lang);

    const outletTypeOptions: FormOption<number>[] = yield call(getOutletTypes, lang);

    yield put(
      setReportOptions({
        reportOptions,
        dataBlockOptions,
        sortOptions,
        fileOptions,
        enterpriseTypeOptions,
        outletTypeOptions,
        reportSettings,
        virsOptions: options.virsOptions,
        outletOptions: [],
        institutionOptions: options.institutionOptions,
        prefilledValues: action.payload.prefilledValues
      })
    );

    yield put(setError(false));
  } catch (error) {
    yield put(setError(true));
  }

  yield put(setLoading(false));
}

function* generateReportFileTask({ payload: { input } }: ActionType<typeof generateReportFile>) {
  yield put(setLoading(true));
  const lang: Language = yield select(language);

  try {
    const data: Blob = yield call(
      getReport,
      {
        ...input,
        dataBlock: input.dataBlock !== 'null' ? input.dataBlock : null
      },
      lang
    );

    saveAs(data, `document.${input.fileFormat?.toLocaleLowerCase()}`);

    yield put(setError(false));
  } catch (error) {
    const { message }: { message: string } = JSON.parse(yield error.response.data.text());
    yield put(sendMessage('error', message));
  }

  yield put(setLoading(false));
}

function* loadOutletTypeOptionsTask() {
  const options: FormOptions = yield select((state: ApplicationState) => state.report.options);

  const lang: Language = yield select(language);

  let outletTypeOptions: FormOption<number>[] = [];

  yield put(
    setReportOptions({
      ...options,
      outletTypeOptions
    })
  );

  try {
    outletTypeOptions = yield call(getOutletTypes, lang);
  } finally {
    yield put(
      setReportOptions({
        ...options,
        outletTypeOptions
      })
    );
  }
}

function* searchVirsResultsTask({
  payload: { virsResultsByLegalPerson: results }
}: ActionType<typeof searchByLegalDataSuccess>) {
  const options: FormOptions = yield select((state: ApplicationState) => state.report.options);

  const virsOptions: FormOption<number>[] =
    results
      ?.map(({ virsResults }) =>
        virsResults
          .filter(({ virsRole }) => virsRole === 'VIRS')
          .map(({ virsId, virsLegalName }) => ({
            value: virsId,
            label: virsLegalName
          }))
      )
      .reduce((prev, value) => (value ? [...prev, ...value] : prev), [])
      .filter((option, i, arr) => !arr.slice(0, i).some(({ value }) => value === option.value)) ||
    [];

  const institutionOptions: FormOption<number>[] =
    results
      ?.map((result) => ({
        value: result.personId,
        label: result.legalName
      }))
      .filter((option, i, arr) => !arr.slice(0, i).some(({ value }) => value === option.value)) ||
    [];

  yield put(
    setReportOptions({
      ...options,
      virsOptions,
      institutionOptions
    })
  );
}

function* searchOutletsResultsTask({
  payload: { virsResultsByOutlet: results }
}: ActionType<typeof searchByOutletSuccess>) {
  const options: FormOptions = yield select((state: ApplicationState) => state.report.options);

  const outletOptions: FormOption<number>[] =
    results
      ?.map(({ outletId, outletName }) => ({
        value: outletId,
        label: outletName
      }))
      .filter((option, i, arr) => !arr.slice(0, i).some(({ value }) => value === option.value)) ||
    [];

  yield put(
    setReportOptions({
      ...options,
      outletOptions
    })
  );
}

function* loadOutletOptionsTask(action: ActionType<typeof loadOutletOptions>) {
  const options: FormOptions = yield select((state: ApplicationState) => state.report.options);
  const lang: Language = yield select(language);

  const outletOptions: FormOption<number>[] = yield call(getOutlets, action.payload.virsIds, lang);
  yield put(
    setReportOptions({
      ...options,
      outletOptions
    })
  );
}

export function* reportSaga() {
  yield takeLatest(loadReportOptions, loadReportOptionsTask);
  yield takeLatest(generateReportFile, generateReportFileTask);
  yield takeLatest(loadOutletTypeOptions, loadOutletTypeOptionsTask);
  yield takeLatest(loadOutletOptions, loadOutletOptionsTask);
  yield takeLatest(SEARCH_BY_LEGAL_SUCCESS, searchVirsResultsTask);
  yield takeLatest(SEARCH_BY_OUTLET_SUCCESS, searchOutletsResultsTask);
}
