import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { BoxTitle } from './TreeChartBoxes/BoxTitle';
import { GroupBox } from './TreeChartBoxes/GroupBox';
import { GroupBoxTitle } from './TreeChartBoxes/GroupBoxTitle';
import { PercentageInfo } from './TreeChartBoxes/PercentageInfo';
import { TreeSingleBox } from './TreeChartBoxes/TreeSingleBox';
import {
  getShareholderIdAndLevelFromLinks,
  mapToStateShareholder,
  removeConcatenatedLevelFromParentIdNumber
} from '../../../pages/VirsTreePage/treeState/treeFunctions';
import {
  useVirsTreeDispatch,
  useVirsTreeState
} from '../../../pages/VirsTreePage/VirsTreePageContext';
import { ChartShareholderPerson } from '../../../store/virsTree/virsTreeDataTypes';
import {
  childrenNumber,
  displayPersonName,
  isLegalPerson,
  personBoxColor,
  setBubblePosition,
  subtitle
} from './treeChartFunctions/uiFunctions';

const useStyles = makeStyles(() => ({
  groupContainer: {
    border: '1px solid grey',
    minHeight: '40px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: '5px',
    overflow: 'hidden',
    padding: 5
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column'
  },
  mainBox: {
    background: 'white',
    width: '200px',
    boxSizing: 'border-box',
    padding: '10px 0 10px',
    cursor: 'pointer',
    margin: '0',
    height: '100%',
    borderRadius: '0',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  boxButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    alignItems: 'initial',
    justifyContent: 'initial',
    height: '100%'
  },
  title: {
    fontSize: '0.73rem'
  },
  groupPersons: {
    marginTop: 20,
    height: 40,
    border: '1px solid grey',

    flexGrow: 1
  },
  boxWrapper: {
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0
    }
  }
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GroupComponent = ({ data: { shareholder, activeDate } }: any) => {
  const classes = useStyles();
  const { treeDispatch } = useVirsTreeDispatch();
  const {
    treeState: { shareType, showingShareholders }
  } = useVirsTreeState();
  const { t } = useTranslation();
  const {
    shareholderGroupId,
    shareholderPersons,
    shareholderLevel,
    shareholderId,
    parentId,
    sharePercentage,
    votePercentage,
    votesOnRules,
    shareholderEndDate,
    sharePercentageLessThanPermitted,
    votePercentageLessThanPermitted
  } = shareholder;

  const personsGroup = shareholderGroupId !== null;

  const authorisedPerson: ChartShareholderPerson | undefined = shareholderPersons.find(
    (x: ChartShareholderPerson) => x.authorized
  );

  const handleOpenShareholderCard = () => {
    if (!authorisedPerson) {
      return;
    }
    treeDispatch({
      type: 'OPEN_SHAREHOLDER_PREVIEW_CARD',
      shareholderName: displayPersonName(personsGroup, authorisedPerson, shareholderPersons),
      shareholderData: shareholder
    });
  };

  const isShowingPersonList =
    showingShareholders.find((sh) => sh.shareholderGroupId === shareholderGroupId)
      ?.showingGroupExtended || false;

  const openGroup = () => {
    treeDispatch({
      type: 'SHOW_GROUP_LIST',
      groupId: shareholderGroupId
    });
  };

  const closeGroup = () => {
    treeDispatch({
      type: 'HIDE_GROUP_LIST',
      group: mapToStateShareholder(shareholder)
    });
  };

  const togglePersonsList = () => {
    return isShowingPersonList ? closeGroup() : openGroup();
  };

  const showPersonShareholders = (person: ChartShareholderPerson) => () => {
    treeDispatch({
      type: 'SHOW_GROUP_PERSONS_CHILDREN',
      shareholders: getShareholderIdAndLevelFromLinks(person.shareholderLinks, shareholderLevel)
    });
  };

  const closeShareholder = () => {
    treeDispatch({
      type: 'HIDE_SHAREHOLDER',
      shareholder: {
        shareholderId,
        parentId: removeConcatenatedLevelFromParentIdNumber(parentId, shareholderLevel),
        shareholderLevel,
        shareholderPersons
      }
    });
  };

  return (
    <div>
      <Handle type="target" position={Position.Left} style={{ top: '55px', left: 3 }} />

      <div className={classes.groupContainer}>
        <div className={classes.boxWrapper}>
          <GroupBox
            boxTitle={<GroupBoxTitle title={authorisedPerson?.personName || ''} />}
            color="#696969"
            height={95}
            additionalInfo={
              <PercentageInfo
                shares={sharePercentage}
                votes={votePercentage}
                shareType={shareType}
                votesOnRule={votesOnRules}
                sharePercentageLessThanPermitted={sharePercentageLessThanPermitted}
                votePercentageLessThanPermitted={votePercentageLessThanPermitted}
              />
            }
            onClick={handleOpenShareholderCard}
            handleLeftSideButtonBoxClick={closeShareholder}
            leftSideButtonDisabled={shareholderLevel === 1}
            handleOpenGroupPersons={togglePersonsList}
            rightChildrenNumber={shareholderPersons.length}
            foregroundGrid={shareholderEndDate === activeDate}
          />
        </div>
        {isShowingPersonList &&
          shareholderPersons.map((person: ChartShareholderPerson, index: number) => (
            <div className={classes.boxWrapper} key={person.personId}>
              <TreeSingleBox
                boxTitle={
                  <BoxTitle
                    title={person.personName}
                    subtitle={subtitle(
                      person.personType,
                      personsGroup,
                      t('Natural person'),
                      t('Legal entity')
                    )}
                    isPersonFromGroup
                    authorized={person.authorized}
                  />
                }
                showLeftSideBox
                showRightSideBox={isLegalPerson(person.personType)}
                rightChildrenNumber={childrenNumber(person.shareholders)}
                minHeight={95}
                maxHeight={95}
                color={personBoxColor(person.personType)}
                handleRightSideBoxClick={showPersonShareholders(person)}
                handleLeftSideBoxClick={closeGroup}
                hasErrors={person.shareholderErrors && person.shareholderErrors.length > 0}
                errorText={person.shareholderErrors}
              />
              {person.shareholderLinks &&
                person.shareholderLinks.length > 0 &&
                isLegalPerson(person.personType) && (
                  <Handle
                    type="source"
                    id={`${person.personId}`}
                    position={Position.Right}
                    style={setBubblePosition(index)}
                  />
                )}
            </div>
          ))}
      </div>
    </div>
  );
};
