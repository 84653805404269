import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { OutletEditionTable } from './EditionTable';
import { outletEditionTableReducer } from './tableState/tableReducer';
import {
  OutletEditionTableState,
  OutletEditionTableAction,
  initialOutletEditionTableState
} from './tableState/tableTypes';

interface Props {
  chosenDate: string;
}

const OutletEditionTableStateContext = createContext<{
  state: OutletEditionTableState;
}>({
  state: initialOutletEditionTableState
});

const OutletEditionTableDispatchContext = createContext<{
  dispatch: Dispatch<OutletEditionTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const EditionSection: React.FC<Props> = ({ chosenDate }) => {
  const [state, dispatch] = useReducer(outletEditionTableReducer, initialOutletEditionTableState);
  return (
    <OutletEditionTableStateContext.Provider value={{ state }}>
      <OutletEditionTableDispatchContext.Provider value={{ dispatch }}>
        <OutletEditionTable chosenDate={chosenDate} />
      </OutletEditionTableDispatchContext.Provider>
    </OutletEditionTableStateContext.Provider>
  );
};

export function useOutletEditionTableState() {
  return useContext(OutletEditionTableStateContext);
}
export function useOutletEditionTableDispatch() {
  return useContext(OutletEditionTableDispatchContext);
}
