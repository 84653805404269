import moment from 'moment';
import { ShareholderVirsProfessionalMisconductData } from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { VirsProfessionalMisconductTableState } from './tableTypes';

export function displayDataFilter(
  data: ShareholderVirsProfessionalMisconductData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderVirsProfessionalMisconductData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(item.validFrom, item.validTo || undefined, undefined, '[]');
      });
    case 'selectedDate':
      return data.filter((item) => {
        return (
          moment(item.decisionDate).isSame(chosenDate) ||
          moment(chosenDate).isBetween(item.validFrom, item.validTo || undefined, undefined, '[]')
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function filterAndSortProfessionalMisconductTableData(
  tableState: VirsProfessionalMisconductTableState,
  data: ShareholderVirsProfessionalMisconductData[],
  chosenDate: string
): ShareholderVirsProfessionalMisconductData[] {
  const columnsFilters = tableState.customFilter;

  const filters: Filter<ShareholderVirsProfessionalMisconductData>[] = [
    getStringMappedColumnFilter(columnsFilters.decisionDate, ({ decisionDate }) => [decisionDate]),
    getStringMappedColumnFilter(columnsFilters.documentNumber, ({ documentNumber }) => [
      documentNumber
    ]),
    getStringMappedColumnFilter(columnsFilters.outletName, ({ outlets }) =>
      outlets.map((value) => value.outletName)
    ),
    getStringMappedColumnFilter(columnsFilters.professionalMisconduct, ({ outlets }) =>
      outlets.flatMap(({ determinedMisconducts: detectedProfessionalMisconducts }) =>
        detectedProfessionalMisconducts.map((value) => value.misconductTypeName)
      )
    ),
    getStringMappedColumnFilter(columnsFilters.sanction, ({ outlets }) =>
      outlets.flatMap(({ determinedMisconducts: detectedProfessionalMisconducts }) =>
        detectedProfessionalMisconducts.flatMap(({ sanctions }) =>
          sanctions.map((value) => value.sanctionTypeName)
        )
      )
    ),
    getStringMappedColumnFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringMappedColumnFilter(columnsFilters.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(
      columnsFilters.misconductReportingInstitutionName,
      ({ reportingInstitutionName: misconductReportingInstitutionName }) => [
        misconductReportingInstitutionName
      ]
    )
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);
  const filtered = filterData(displayFiltered, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}
