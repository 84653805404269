import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

import {
  OutletResponsiblePersonsColumnHeaderField,
  OutletResponsiblePersonsColumnHeader
} from './tableState/tableTypes';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import { ShareholderOutletResponsiblePersonData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import {
  useOutletResponsiblePersonsSectionTableDispatch,
  useOutletResponsiblePersonsSectionTableState
} from './ResponsiblePersonsSectionSection';

interface Props {
  allData: ShareholderOutletResponsiblePersonData[];
  columnNames: OutletResponsiblePersonsColumnHeader[];
}

const ResponsiblePersonsSectionCustomFilter: React.FC<Props> = ({ columnNames, allData }) => {
  const { state: tableState } = useOutletResponsiblePersonsSectionTableState();
  const { dispatch: tableDispatch } = useOutletResponsiblePersonsSectionTableDispatch();

  const handleSetCustomFilter = (
    column: OutletResponsiblePersonsColumnHeaderField,
    value: string | null
  ) => {
    tableDispatch({ type: 'SET_CUSTOM_FILTER', filterBy: column, value });
  };

  function getUniqueResponsiblePersonsSectiontDataOptions(
    data: ShareholderOutletResponsiblePersonData[],
    objectField: OutletResponsiblePersonsColumnHeaderField
  ): string[] {
    const allValues = data
      .filter(
        (record) =>
          record[objectField] && record[objectField] !== '' && record[objectField] !== null
      )
      .map((record) => String(record[objectField]));
    return Array.from(new Set(allValues));
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueResponsiblePersonsSectiontDataOptions(
                  allData || [],
                  column.id
                )}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
export default ResponsiblePersonsSectionCustomFilter;
