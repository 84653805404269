import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { VirsTreeCustomTooltip } from '../../VirsTreeCustomTooltip';
import {replaceDecimalPoint} from "../../../../utils/tableDataFunctions";

const useStyles = makeStyles(() => ({
  percentageInfo: {
    fontSize: '0.73rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  percentages: {
    fontWeight: 600
  }
}));

export interface Props {
  shares?: number;
  votes?: number;
  shareType: string;
  votesOnRule: boolean;
  sharePercentageLessThanPermitted: boolean | null;
  votePercentageLessThanPermitted: boolean | null;
}

export const PercentageInfo: React.FC<Props> = ({ shares, votes, votesOnRule, shareType, sharePercentageLessThanPermitted, votePercentageLessThanPermitted }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const sharesPercentage = shares !== null ? replaceDecimalPoint(`${shares}% `) : '- %';
  const sharesText = shareType === 'Akcijų dalis' ? t('of shares') : t('of contributions');
  const sharesFullText = `${sharesPercentage} ${sharesText}`;
  const separatorText = ' / ';
  const votesPercentage = votes !== null ? replaceDecimalPoint(`${votes}% `) : '- %';
  const votesText = t('of votes');
  const votesOnRulesText = t('Votes by the rule');
  const votesFullText = votesOnRule ? votesOnRulesText : `${votesPercentage} ${votesText}`;
  const fullText = sharesFullText + separatorText + votesFullText;
  const maxLength = 30;
  const fullTextJsx = (
    <div className={classes.percentageInfo}>
      <span>
        <span className={classes.percentages}>
          {sharePercentageLessThanPermitted ? '< ' : ''}
          {sharesPercentage}
        </span>{' '}
        {sharesText}
      </span>

      <span>{separatorText}</span>

      <span>
        {votesOnRule ? (
          votesOnRulesText
        ) : (
          <>
            <span className={classes.percentages}>
              {votePercentageLessThanPermitted ? '< ' : ''}
              {votesPercentage}
            </span>{' '}
            {votesText}
          </>
        )}
      </span>
    </div>
  );

  return fullText.length > maxLength ? (
    <VirsTreeCustomTooltip text={fullText}>{fullTextJsx}</VirsTreeCustomTooltip>
  ) : (
    fullTextJsx
  );
};
