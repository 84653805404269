export const VIRS_DATA_REQUEST = '@virsData/VIRS_DATA_REQUEST';
export const VIRS_DATA_SUCCESS = '@virsData/VIRS_DATA_SUCCESS';
export const VIRS_DATA_ERROR = '@virsData/VIRS_DATA_ERROR';

export const XML_VIRS_DATA_REQUEST = '@virsData/XML_VIRS_DATA_REQUEST';
export const XML_VIRS_DATA_ERROR = '@virsData/XML_VIRS_DATA_ERROR';

export const VIRS_DATA_REQUEST_OF_DATE = '@virsData/VIRS_DATA_REQUEST_OF_DATE';

export const SET_TIMELINE_ACTIVE_DATE = '@@virsis/SET_TIMELINE_ACTIVE_DATE';
export const SET_TIMELINE_LOADING = '@@virsis/SET_TIMELINE_LOADING';
export const SET_TIMELINE_PERIOD_NAME = '@@virsis/SET_TIMELINE_PERIOD_NAME';
export const SET_TIMELINE_ERROR = '@@virsis/SET_TIMELINE_ERROR';
export const SET_TIMELINE_EVENTS = '@@virsis/SET_TIMELINE_EVENTS';
export const LOAD_TIMELINE_EVENTS = '@@virsis/LOAD_TIMELINE_EVENTS';
