import { action } from 'typesafe-actions';
import {
  GET_OUTLET_TYPES_REQUEST,
  GET_OUTLET_TYPES_SUCCESS,
  CLEAR_SEARCH_VALUES,
  RESET_SEARCH_STATE,
  SEARCH_BY_LEGAL_ERROR,
  SEARCH_BY_LEGAL_LOADING,
  SEARCH_BY_LEGAL_REQUEST,
  SEARCH_BY_LEGAL_SUCCESS,
  SEARCH_BY_OUTLET_ERROR,
  SEARCH_BY_OUTLET_LOADING,
  SEARCH_BY_OUTLET_REQUEST,
  SEARCH_BY_OUTLET_SUCCESS,
  SEARCH_BY_PERSON_ERROR,
  SEARCH_BY_PERSON_LOADING,
  SEARCH_BY_PERSON_REQUEST,
  SEARCH_BY_PERSON_SUCCESS,
  SEARCH_REQUEST
} from './virsSearchActionTypes';

import {
  LegalPersonSearchData,
  LegalPersonSearchQuery,
  NaturalPersonSearchData,
  NaturalPersonSearchQuery,
  OutletSearchData,
  OutletSearchQuery,
  OutletType
} from './virsSearchTypes';

export const searchRequest = (value: string) => action(SEARCH_REQUEST, value);
export const clearSearchValues = () => action(CLEAR_SEARCH_VALUES);
export const resetSearchState = () => action(RESET_SEARCH_STATE);

export const searchByPersonRequest = (query: NaturalPersonSearchQuery) =>
  action(SEARCH_BY_PERSON_REQUEST, query);
export const searchByPersonDataSuccess = (data: NaturalPersonSearchData) =>
  action(SEARCH_BY_PERSON_SUCCESS, data);
export const searchByPersonDataError = (error: string) => action(SEARCH_BY_PERSON_ERROR, error);
export const loadingSearchByPerson = (loading: boolean) =>
  action(SEARCH_BY_PERSON_LOADING, loading);

export const searchByLegalRequest = (query: LegalPersonSearchQuery) =>
  action(SEARCH_BY_LEGAL_REQUEST, query);
export const searchByLegalDataSuccess = (data: {
  virsResultsByLegalPerson: LegalPersonSearchData[] | null;
}) => action(SEARCH_BY_LEGAL_SUCCESS, data);
export const searchByLegalDataError = (error: string) => action(SEARCH_BY_LEGAL_ERROR, error);
export const loadingSearchByLegal = (loading: boolean) => action(SEARCH_BY_LEGAL_LOADING, loading);

export const searchByOutletRequest = (query: OutletSearchQuery) =>
  action(SEARCH_BY_OUTLET_REQUEST, query);
export const searchByOutletSuccess = (data: { virsResultsByOutlet: OutletSearchData[] | null }) =>
  action(SEARCH_BY_OUTLET_SUCCESS, data);
export const searchByOutletDataError = (error: string) => action(SEARCH_BY_OUTLET_ERROR, error);
export const loadingVirsSearchByOutletResults = (loading: boolean) =>
  action(SEARCH_BY_OUTLET_LOADING, loading);

export const fetchOutletTypesRequest = () => action(GET_OUTLET_TYPES_REQUEST);
export const fetchOutletTypesSuccess = (response: OutletType) =>
  action(GET_OUTLET_TYPES_SUCCESS, response);
