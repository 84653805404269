import { CSSProperties } from 'react';
import { Position, XYPosition } from 'react-flow-renderer';
import { ChartShareholder } from '../../../../store/virsTree/virsTreeDataTypes';

export interface OutletNode {
  id: string;
  type: string;
  data: object;
  sourcePosition: Position;
  position: XYPosition;
}

export interface OutletEdge {
  id: string;
  source: string;
  target: string;
  type: string;
  animated: boolean;
  style?: CSSProperties;
}

export interface VirsTreeError {
  errorMessage: string;
  errorType: ErrorTypes;
}

export type ErrorTypes = 'CRITICAL' | 'INFO' | 'NONCRITICAL';

export enum ErrorType {
  CRITICAL = 'CRITICAL',
  INFO = 'INFO',
  NONCRITICAL = 'NONCRITICAL'
}

export interface VirsNode {
  id: string;
  type: string;
  data: object;
  position: { x: number; y: number };
}

export interface ShareholderNodeData {
  shareholder: ChartShareholder;
  virsId?: number;
  activeDate?: string;
}

export interface ShareholderEdge {
  id: string;
  source: string;
  target: string;
  type: string;
  animated: boolean;
  label?: string;
  style?: CSSProperties;
}
