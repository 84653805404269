import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import {
  useVirsEthicalNonComplianceTableDispatch,
  useVirsEthicalNonComplianceTableState
} from './EthicalNonComplianceSection';
import {
  VirsEthicalNonComplianceHeaderField,
  VirsEthicalNonComplianceTableState
} from './tableState/tableTypes';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';

const EthicalNonComplianceTableHead: React.FC = () => {
  const { state: tableState } = useVirsEthicalNonComplianceTableState();
  const { dispatch } = useVirsEthicalNonComplianceTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsEthicalNonComplianceHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsEthicalNonComplianceTableState,
    typeof handleSetSortByColumn,
    VirsEthicalNonComplianceHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {(tableState.columnsDisplayStatus.decisionDate ||
          tableState.columnsDisplayStatus.documentNumber) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.decisionDate,
              tableState.columnsDisplayStatus.documentNumber
            ])}
          >
            <Typography variant="h4">{t('VIRS ethical non compliance decision')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.outletList && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS ethical non compliance related outlets')}
            columnName="outletList"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.ethicalNonComplianceStatus && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS ethical non compliance ethicalNonComplianceStatus')}
            columnName="ethicalNonComplianceStatus"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">{t('VIRS ethical non compliance valid')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.reportingInstitutionName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS ethical non compliance reportingInstitutionName')}
            columnName="reportingInstitutionName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.decisionDate && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('VIRS ethical non compliance date')}
            columnName="decisionDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.documentNumber && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('VIRS ethical non compliance number')}
            columnName="documentNumber"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('From capitalized')}
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('To capitalized')}
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default EthicalNonComplianceTableHead;
