import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderOutletAdPrintingData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { filterOptions } from '../../../../../store/virsisApp/virsisDataTypes';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import DisplayFilterTool from '../../../../../components/TableDisplayFilter/DisplayFilterTool';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import {
  useOutletAdPrintingTableDispatch,
  useOutletAdPrintingTableState
} from './AdPrintingSection';
import { columnNames } from './tableState/TableColumns';
import {
  OutletAdPrintingColumnHeader,
  OutletAdPrintingColumnHeaderField
} from './tableState/tableTypes';

interface Props {
  data: ShareholderOutletAdPrintingData[];
}

export const AdPrintingTableCustomizationBar: React.FC<Props> = ({ data }) => {
  const { state } = useOutletAdPrintingTableState();
  const { dispatch } = useOutletAdPrintingTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: OutletAdPrintingColumnHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: OutletAdPrintingColumnHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const handleSetDisplayFilter = (value: filterOptions) => {
    dispatch({ type: 'SET_DISPLAY_FILTER', value });
  };

  function getAdPrintingColumnsWithTranslation(): OutletAdPrintingColumnHeader[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`Ad printing ${col.id}`)
    }));
  }

  const translatedColumns = getAdPrintingColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <DisplayFilterTool
            disabled={data.length === 0}
            displayFilter={state.displayFilter}
            setDisplayFilter={handleSetDisplayFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
