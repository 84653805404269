import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { get } from '../utils/axiosTvs';

interface Props {
  cmsPath: string;
}

const CmsPage: React.FC<Props> = ({ cmsPath }) => {
  const location = useLocation();
  const [page, setPage] = useState<string>();

  const path = location.search ? cmsPath + location.search : cmsPath;

  useEffect(() => {
    get(path).then((value) => setPage(value.data));
  }, [path]);

  const replaceLocationIn = (source: string) => source.replace(/@@@baseUrl@@@?q=/g, '/');

  if (page) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: replaceLocationIn(page)
        }}
      />
    );
  }
  return (
    <div
      style={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default CmsPage;
