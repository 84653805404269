import { Reducer } from 'redux';
import { VariantType } from 'notistack';
import { SEND_MESSAGE } from './errorOrSuccessMessageActionTypes';

export interface ErrorOrSuccessMessageState {
  message?: string;
  variant: VariantType;
  newMessage: boolean;
}

export const initialState: ErrorOrSuccessMessageState = {
  message: undefined,
  variant: 'success',
  newMessage: false
};

export const errorOrSuccessMessageReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_MESSAGE:
      return {
        ...state,
        variant: action.payload.variant,
        message: action.payload.message,
        newMessage: !state.newMessage
      };
    default:
      return state;
  }
};
