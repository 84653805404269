import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core/';

interface Props {
  leftSideItem?: ReactNode;
  rightSideItem: ReactNode;
}
export const TableToolsFrame: React.FC<Props> = ({ leftSideItem, rightSideItem }) => {
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid container item direction="row" justify="flex-start" alignItems="center" xs={6}>
        {leftSideItem}
      </Grid>
      <Grid container item direction="row" justify="flex-end" alignItems="center" xs={6}>
        {rightSideItem}
      </Grid>
    </Grid>
  );
};
