import React from 'react';
import { Paper, Table, TableBody } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { CategoryTableHead } from './CategoryTableHead';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import { filterAndSortTableData } from './tableState/tableStateFunctions';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { useOutletCategoryTableDispatch, useOutletCategoryTableState } from './CategorySection';
import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../utils/tableTypes';
import { columnNames } from './tableState/TableColumns';
import { CategoryTableCustomizationBar } from './CategoryTableCustomizationBar';
import { OutletCategoryRow } from './CategoryRow';
import CustomFilter from './CategoryCustomFilter';

interface Props {
  chosenDate: string;
}

export const OutletCategoryTable: React.FC<Props> = ({ chosenDate }) => {
  const { state: tableState } = useOutletCategoryTableState();
  const { dispatch } = useOutletCategoryTableDispatch();

  const { outletInfo } = useSelector((state: ApplicationState) => state.outletInfoData);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const data = filterAndSortTableData(outletInfo?.categories || [], tableState, chosenDate);
  const { page: dataPage, pagesCount } = pageTableData(tableState, data);
  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <div className="data-table">
        <CategoryTableCustomizationBar data={outletInfo?.categories || []} />
        <Table>
          <CategoryTableHead />
          {tableState.customFilterOn && <CustomFilter columnNames={columnNames} />}
          <TableBody>
            {dataPage.map((record) => (
              <OutletCategoryRow key={record.outletCategoryId} record={record} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          recordsCount={data.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={data.length === 0}
        />
      </div>
    </Paper>
  );
};
