import {
  OutletShortData,
  PaymentDocumentData,
  ShareholderVirsPaymentData
} from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import {
  Filter,
  filterData,
  getComparator,
  getStringMappedColumnFilter, replaceDecimalPoint,
  stableSort,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { SortOrder } from '../../../../../../utils/tableTypes';
import {
  VirsPaymentDataCustomFilter,
  VirsPaymentDataHeaderField,
  VirsPaymentDataTableState
} from './tableTypes';

export function updatePaymentDataCustomFilter(
  filter: VirsPaymentDataCustomFilter,
  filterBy: VirsPaymentDataHeaderField,
  value: string | null
): VirsPaymentDataCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

function getSortedPaymentData(
  data: ShareholderVirsPaymentData[],
  order: SortOrder,
  sortBy: string
): ShareholderVirsPaymentData[] {
  if (sortBy === 'paymentDocuments') {
    return data.map((record) => {
      if (record.paymentDocuments) {
        const sortedDocs = stableSort<PaymentDocumentData>(
          record.paymentDocuments,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'documentNumber')
        );
        return { ...record, paymentDocuments: sortedDocs };
      }
      return record;
    });
  }

  if (sortBy === 'paymentOutlets') {
    return data.map((record) => {
      if (record.paymentOutlets) {
        const sortedOutlets = stableSort<OutletShortData>(
          record.paymentOutlets,
          getComparator(order === 'desc' ? 'asc' : 'desc', 'outletName')
        );
        return { ...record, paymentOutlets: sortedOutlets };
      }
      return record;
    });
  }

  return stableSortWrapped(data, order, sortBy);
}

export function filterAndSortPaymentDataVirs(
  tableState: VirsPaymentDataTableState,
  data: ShareholderVirsPaymentData[]
): ShareholderVirsPaymentData[] {
  const columnsFilters = tableState.customFilter;

  const filters: Filter<ShareholderVirsPaymentData>[] = [
    getStringMappedColumnFilter(columnsFilters.decisionDate, ({ decisionDate }) => [decisionDate]),
    getStringMappedColumnFilter(columnsFilters.paymentDocuments, ({ paymentDocuments }) =>
      paymentDocuments.map(({ documentNumber }) => documentNumber)
    ),
    getStringMappedColumnFilter(columnsFilters.paymentOutlets, ({ paymentOutlets }) =>
      paymentOutlets.map(({ outletName }) => outletName)
    ),
    getStringMappedColumnFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringMappedColumnFilter(columnsFilters.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(
      columnsFilters.reportingInstitutionName,
      ({ reportingInstitutionName }) => [reportingInstitutionName]
    ),
    getStringMappedColumnFilter(columnsFilters.paymentAmount, ({ paymentAmount }) => [
      replaceDecimalPoint(`${paymentAmount}`)
    ])
  ];

  const filtered = filterData(data, filters);
  const sorted = getSortedPaymentData(filtered, tableState.order, tableState.sortBy);

  return sorted;
}
