import { ShareholderVirsEnterpriseData } from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { ColumnHeader, SortOrder } from '../../../../../../utils/tableTypes';

export type EnterpriseDataTableField = 'enterpriseTypeName' | 'validFrom' | 'validTo';

export type EnterpriseDataTablColumnHeader = ColumnHeader<EnterpriseDataTableField>;

export interface VirsEnterpriseTableState {
  customFilter: EnterpriseDataTableCustomFilter;
  customFilterOn: boolean;
  order: SortOrder;
  sortBy: EnterpriseDataTableField;
  displayFilter: filterOptions;
  page: number;
  rowsPerPage: number;
  pageData: ShareholderVirsEnterpriseData[];
  columnsDisplayStatus: EnterpriseDataTableColumnsDisplayStatus;
}

export interface EnterpriseDataTableCustomFilter {
  enterpriseTypeName: string[];
  validFrom: string[];
  validTo: string[];
}

export type EnterpriseDataTableColumnsDisplayStatus = {
  enterpriseTypeName: boolean;
  validFrom: boolean;
  validTo: boolean;
};

export type VirsEnterpriseTableAction =
  | {
      type: 'SET_STATUS_FILTERS';
    }
  | {
      type: 'SET_DISPLAY_FILTER';
      value: filterOptions;
    }
  | { type: 'TOGGLE_CUSTOM_FILTER_DISPLAY' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: EnterpriseDataTableField;
      value: string | null;
    }
  | { type: 'SET_SORTING'; sortBy: EnterpriseDataTableField }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: number }
  | { type: 'TOGGLE_COLUMN_DISPLAY'; column: EnterpriseDataTableField };

export const initialVirsEnterpriseTableState: VirsEnterpriseTableState = {
  customFilter: {
    enterpriseTypeName: [],
    validFrom: [],
    validTo: []
  },
  customFilterOn: false,
  order: 'desc',
  sortBy: 'validFrom',
  displayFilter: 'allItems',
  page: 0,
  rowsPerPage: 10,
  pageData: [],
  columnsDisplayStatus: {
    enterpriseTypeName: true,
    validFrom: true,
    validTo: true
  }
};
