import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { OutletCategoryTable } from './CategoryTable';
import { OutletCategoryTableReducer } from './tableState/tableReducer';
import {
  OutletCategoryTableState,
  OutletCategoryTableAction,
  initialOutletCategoryTableState
} from './tableState/tableTypes';

interface Props {
  chosenDate: string;
}

const OutletCategoryTableStateContext = createContext<{
  state: OutletCategoryTableState;
}>({
  state: initialOutletCategoryTableState
});

const OutletCategoryTableDispatchContext = createContext<{
  dispatch: Dispatch<OutletCategoryTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const CategorySection: React.FC<Props> = ({ chosenDate }) => {
  const [state, dispatch] = useReducer(OutletCategoryTableReducer, initialOutletCategoryTableState);
  return (
    <OutletCategoryTableStateContext.Provider value={{ state }}>
      <OutletCategoryTableDispatchContext.Provider value={{ dispatch }}>
        <OutletCategoryTable chosenDate={chosenDate} />
      </OutletCategoryTableDispatchContext.Provider>
    </OutletCategoryTableStateContext.Provider>
  );
};

export function useOutletCategoryTableState() {
  return useContext(OutletCategoryTableStateContext);
}
export function useOutletCategoryTableDispatch() {
  return useContext(OutletCategoryTableDispatchContext);
}
