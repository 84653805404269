import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { NaturalPersonSearchData } from '../store/virsSearch/virsSearchTypes';

export type SortOrder = 'asc' | 'desc';

export type RowsPerPageOptions = number[];

export interface ColumnHeader<T> {
  id: T;
  numeric: boolean;
  label: string;
  width?: string;
}

// eslint-disable-next-line
export type TableCustomFilter<T extends string> = {
  [key in T]: string[];
};

export type VirsisData = { [key: string]: number | string };

export interface DataWithStatuses {
  confirmationStatus: string;
  relevancyStatus: string | null;
}

export type RelevancyStatus = 'A' | 'I' | null;
export type ConfirmationStatus = 'P' | 'N' | 'U';

export interface DataWithDocumentStatus {
  documentStatus: WordStatus;
}
export interface DataWithWordedStatus {
  status: WordStatus;
}

export type WordStatus = 'RUOSIAMAS' | 'PASIRASYTAS' | 'ANULIUOTAS';

export type Language = 'en' | 'lt';

export interface ValidationDate {
  id: string;
  date: string | null;
  text: string;
}

export interface ValidationDateImproved {
  id: string;
  date?: MaterialUiPickersDate;
  text: string;
}

export interface DatePickerState {
  value: string | null;
  id: string;
  placeholder: string;
  validated: boolean;
  minDates: ValidationDate[];
  maxDates: ValidationDate[];
}

export interface DatePickerStateImproved {
  value: MaterialUiPickersDate | null;
  error: boolean;
  validated: boolean;
  minDates: ValidationDateImproved[];
  maxDates: ValidationDateImproved[];
  helperText?: string;
  placeholder?: string;
  label?: string;
  id?: string;
}

export interface DropdownStateGeneric<T> {
  value: T | null;
  id?: string;
  error: boolean;
  helperText: string;
  placeholder: string;
  validated: boolean;
  options: T[];
}

export interface DropdownMultipleStateGeneric<T> {
  values: T[];
  id?: string;
  error: boolean;
  helperText: string;
  placeholder: string;
  validated: boolean;
  options: T[];
}

export interface InputStateGeneric<T> {
  value: T | null;
  id: string;
  error: boolean;
  helperText: string;
  placeholder: string;
  validated: boolean;
}

export const ROWS_PER_PAGE_OPTIONS: RowsPerPageOptions = [5, 10, 20, 30];

export interface VirsisFile {
  id: number;
  file: File;
  title: string;
  size: number;
}

export type Order = 'asc' | 'desc';

export interface VirsInputState {
  value: NaturalPersonSearchData | null;
  error: boolean;
  validated: boolean;
  helperText: string;
  placeholder: string;
}
