import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { GenericRow } from './State/types';
import { getMaxRowSpan, getRowSpan } from './State/utilities';
import { useGenericTableState } from './GenericDataTableContext';

interface Props {
  row: GenericRow;
  rowIndex: number;
}

export const GenericTableRow: React.FC<Props> = ({ row, rowIndex }) => {
  const { state: tableState } = useGenericTableState();
  /* eslint-disable */
  let index = 0;
  const rows: JSX.Element[] = [];
  for (let i = 0; i < getMaxRowSpan(row); i++) {
    rows.push(
      <TableRow key={i + rowIndex}>
        {Object.entries(row).map((entry) => {
          const [key, value] = entry;
          if (value.length === 0) {
            return <TableCell key={rowIndex + key} rowSpan={getRowSpan(key, row)} />;
          }
          return value.map((cell, cellIndex) => {
            if (!tableState.columnsDisplayStatus[key] || cellIndex !== i) {
              return null;
            }
            return (
              <TableCell key={cellIndex + rowIndex} rowSpan={getRowSpan(key, row)}>
                {cell}
              </TableCell>
            );
          });
        })}
      </TableRow>
    );
    index++;
  }

  return <>{rows}</>;
};
