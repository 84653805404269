import React from 'react';
import { useTranslation } from 'react-i18next';
import { RowsPerPageOptions } from '../../utils/tableTypes';
import { TableToolsFrame } from '../TableToolFrame/TableToolFrame';
import { PaginationIteratePage } from './PaginationIteratePage';
import { PaginationJumpPage } from './PaginationJumpPage';
import {
  determineLithuanianNumberDefinitionCase,
  determineEnglishNumberDefinitionCase
} from '../../utils/tableDataFunctions';
import { PaginationInfo } from './PaginationInfo';
import { PaginationSelectRowsPerPage } from './PaginationSelectRowsPerPage';

interface Props {
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: RowsPerPageOptions;
  pagesCount: number;
  recordsCount: number;
  setPage: (value: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  disabled?: boolean;
}

export const TablePagination: React.FC<Props> = ({
  page,
  rowsPerPage,
  rowsPerPageOptions,
  pagesCount,
  recordsCount,
  setPage,
  setRowsPerPage,
  disabled
}) => {
  const { t, i18n } = useTranslation();
  function previousPage() {
    if (page) {
      setPage(page - 1);
    }
  }

  function nextPage() {
    if (pagesCount > page + 1) {
      setPage(page + 1);
    }
  }

  const recordsInfoMessage =
    i18n.language === 'lt'
      ? determineLithuanianNumberDefinitionCase(recordsCount)
      : determineEnglishNumberDefinitionCase(recordsCount);
  return (
    <div className="TablePagination">
      <TableToolsFrame
        leftSideItem={
          <>
            <PaginationInfo recordsCount={recordsCount} recordsInfo={recordsInfoMessage} />
            <PaginationSelectRowsPerPage
              actionLabel={t('Pagination records per page')}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              setRowsPerPage={setRowsPerPage}
              disabled={disabled}
            />
          </>
        }
        rightSideItem={
          <>
            <PaginationIteratePage
              currentPage={page}
              previousPage={previousPage}
              nextPage={nextPage}
              pagesCount={pagesCount}
            />
            <PaginationJumpPage
              actionLabel={t('Pagination go to page')}
              currentPage={page}
              setPage={setPage}
              pagesCount={pagesCount}
              disabled={disabled}
            />
          </>
        }
      />
    </div>
  );
};
