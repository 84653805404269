import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { useOutletCategoryTableDispatch, useOutletCategoryTableState } from './CategorySection';
import { OutletCategoryHeaderField, OutletCategoryTableState } from './tableState/tableTypes';

export const CategoryTableHead: React.FC = () => {
  const { state: tableState } = useOutletCategoryTableState();
  const { dispatch } = useOutletCategoryTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: OutletCategoryHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    OutletCategoryTableState,
    typeof handleSetSortByColumn,
    OutletCategoryHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.categoryTypeName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('Category')}
            columnName="categoryTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">{t('Category applied')}</Typography>
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('From capitalized')}
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('To capitalized')}
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
