import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { History } from 'history';
import { useTranslation } from 'react-i18next';
import { TimelinePanel } from '../../containers/VirsTree/Timeline/TimelinePanel';
import { ApplicationState } from '../../store';
import { PersistentDrawerLeft } from '../../containers/VirsTree/SideNavigation/PersistentDrawerLeft';
import { fetchGraphicalRepresentationToolDataRequest } from '../../store/graphicalRepresentationTool/graphicalRepresentationToolAction';
import { useVirsTreeDispatch, useVirsTreeState } from './VirsTreePageContext';
import TreeChart from '../../containers/VirsTree/TreeChart/TreeChart';
import { OutletSearchData, ShortVirsWithRole } from '../../store/virsSearch/virsSearchTypes';
import {
  filterShareholdersByDate,
  getSortedAndTransformedVirsData,
  getTheLastDateOrDefault,
  getTheRedirectOutletIdOrDefault,
  getTheRedirectShareholderIdOrDefault,
  getTheRedirectToVirsInfoTabOrdefault
} from './treeState/treeFunctions';
import { ShareholderPersonPreviewContainer } from '../../containers/VirsTreeCards/ShareholderPersonPreviewContainer';
import { VirsPreviewContainer } from '../../containers/VirsTreeCards/VirsPreviewContainer';
import OutletPreviewContainer from '../../containers/VirsTreeCards/OutletPreviewContainer';
import { sharesChartData, votesChartData } from './treeState/pieChartFunctions';
import { ChartVirsData } from '../../store/virsTree/virsTreeDataTypes';
import { OutletInstitutionDataPreviewContainer } from '../../containers/VirsTreeCards/OutletInstitutionDataContainer';
import { ShareholderDiagramCard } from '../../containers/VirsTreeCards/ShareholderDiagramCard/DiagramCard';
import { VirsTreeHeader } from '../../containers/VirsTree/VirsTreeHeader';
import { fetchVirsData, loadTimelineEvents } from '../../store/virsTree/virsTreeActions';
import { VirsTreeListTable } from '../../containers/VirsTree/VirsTreeListTable';
import moment from 'moment';

const useStyles = makeStyles({
  treeAndBar: {
    overflow: 'auto',
    position: 'relative',
    borderRadius: '5px'
  },
  tree: {
    overflow: 'auto',
    position: 'relative',
    paddingTop: 12,
    height: '1000px',
    backgroundColor: '#fff'
  },
  error: {
    marginTop: '200px',
    paddign: '20px'
  }
});

/*
Any change regarding VirsTreePage subcomponents and logic should be reproduced in the authorized application virsis-ui,
and vice-versa
*/

export const VirsTreePage: React.FC = () => {
  const classes = useStyles();

  const history: History = useHistory();
  const { virsParam, versionId } = useParams<{
    virsParam: string;
    versionId: string;
  }>();

  const {
    chartData: {
      virsData,
      loadingVirsData,
      timeline: { activeDate }
    },
    virsis: { virsName, virsEndDate, virsStartDate, activityPeriods }
  } = useSelector((state: ApplicationState) => state);
  const reduxDispatch = useDispatch();

  const { treeState } = useVirsTreeState();
  const { treeDispatch } = useVirsTreeDispatch();

  const virsId = Number.parseInt(virsParam, 10);
  const isValidId = virsId > 0;

  const chartData: ChartVirsData | undefined = getSortedAndTransformedVirsData(virsData);

  const { t } = useTranslation();
  const shareholdersGroupText: string = t('Shareholders group');
  const unprovidedText: string = t('Unprovided');
  const votesOnRulesText: string = t('Votes on rules');
  const authorizedPersonText: string = t('Shareholders authorized person');

  useEffect(() => {
    if (versionId) {
      reduxDispatch(fetchGraphicalRepresentationToolDataRequest(versionId));
    }
    if (!versionId) {
      reduxDispatch(fetchGraphicalRepresentationToolDataRequest(null));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isValidId) {
      reduxDispatch(loadTimelineEvents(virsId));
    }
  }, [reduxDispatch, isValidId, virsId]);

  useEffect(() => {
    if (isValidId && virsStartDate !== undefined && virsEndDate !== undefined) {
      const lastOrDefault = getTheLastDateOrDefault(
        // eslint-disable-next-line
        history.location.state as {
          fromResult: ShortVirsWithRole;
          fromOtletResult: OutletSearchData;
        }
      );

      if (virsEndDate !== null) {
        const virsEnd = moment(virsEndDate);
        const actionDate = virsEnd.isSameOrAfter(lastOrDefault) ? lastOrDefault : virsEnd;
        reduxDispatch(fetchVirsData(actionDate.format('YYYY-MM-DD'), virsId));
      } else {
        reduxDispatch(fetchVirsData(lastOrDefault.format('YYYY-MM-DD'), virsId));
      }

      treeDispatch({
        type: 'INIT_VIRS_TREE_STATE',
        virsId,
        // eslint-disable-next-line
        preshowShareholderById: getTheRedirectShareholderIdOrDefault(
          history.location.state as { fromResult: ShortVirsWithRole }
        ),
        // eslint-disable-next-line
        preshowOutletById: getTheRedirectOutletIdOrDefault(
          history.location.state as { fromResult: ShortVirsWithRole }
        ),
        // eslint-disable-next-line
        preopenVirsCardTab: getTheRedirectToVirsInfoTabOrdefault(
          history.location.state as {
            fromResult: ShortVirsWithRole;
            resultType: 'legalPerson' | 'naturalPerson';
          }
        )
      });
    }
    // eslint-disable-next-line
  }, [reduxDispatch, isValidId, virsId, history, virsStartDate, virsEndDate]);

  useEffect(() => {
    if (virsData?.shareholders && activeDate) {
      const areAnyVotesOnRules = virsData?.shareholders.some(
        (shareholder) => shareholder.votesOnRules
      );
      const validShareholders = filterShareholdersByDate(virsData.shareholders, activeDate);

      treeDispatch({
        type: 'UPDATE_CHART_DATA',
        shareholderSharesChartData: sharesChartData(
          validShareholders,
          shareholdersGroupText,
          unprovidedText,
          authorizedPersonText
        ),
        shareholderVotesChartData: votesChartData(
          validShareholders,
          shareholdersGroupText,
          unprovidedText,
          authorizedPersonText,
          votesOnRulesText
        ),
        shareType: virsData?.shareTypeName || t('Share of shares / contributions'),
        votesOnRules: areAnyVotesOnRules, // Change logic here if possibility for siblings to have  votesOnRules and votesPercantage is implemented
        mayHaveSharePercentage: virsData.mayHaveSharePercentage,
        mayHaveVotePercentage: virsData.mayHaveVotePercentage
      });
    }
    if (chartData) {
      treeDispatch({
        type: 'DATA_RECEIVED',
        data: chartData
      });
    }
    // eslint-disable-next-line
  }, [virsData, activeDate]);

  if (!isValidId) {
    history.replace('/paieska');
    return <></>;
  }

  const handleCloseChartCard = () => {
    treeDispatch({
      type: 'CLOSE_CHART_CARD'
    });
  };

  return (
    <PersistentDrawerLeft>
      {virsName && <VirsTreeHeader virsName={virsName} virsId={virsId} />}
      <div className={classes.treeAndBar}>
        <TimelinePanel />
        {!loadingVirsData && virsData && activityPeriods && (
          <ShareholderDiagramCard
            isOpen={
              treeState.sideChartCardOpen &&
              (treeState.displaySharePieChart || treeState.displayVotesPieChart)
            }
            onClose={handleCloseChartCard}
            virsName={virsName || ''}
            sharesData={treeState.shareholderSharesChartData || []}
            votesData={treeState.shareholderVotesChartData || []}
            shareTypeName={treeState.shareType}
            displaySharePieChart={treeState.displaySharePieChart}
            displayVotesPieChart={treeState.displayVotesPieChart}
          />
        )}
        <>
          {treeState.shareholderCardOpen && <ShareholderPersonPreviewContainer />}
          {treeState.virsCardOpen && (
            <VirsPreviewContainer
              virsId={treeState.virsId}
              virsName={treeState.virsName}
              preopenVirsCardTab={treeState.preopenVirsCardTab}
            />
          )}
          {treeState.outletCardOpen && <OutletPreviewContainer />}
          {treeState.visibleInstitutionDataType !== undefined && (
            <OutletInstitutionDataPreviewContainer />
          )}
        </>
        <div className={classes.tree} style={{ pointerEvents: loadingVirsData ? 'none' : 'auto' }}>
          <VirsTreeListTable
            height="0"
            virsName={chartData?.virsLegalName}
            outlets={chartData?.outlets}
            shareholders={treeState.showingShareholders}
          />
          {loadingVirsData && (
            <div
              style={{
                display: 'flex',
                height: '100%'
              }}
            >
              <CircularProgress
                style={{
                  height: 60,
                  width: 60,
                  margin: 'auto',
                  marginTop: '200px'
                }}
              />
            </div>
          )}
          {!loadingVirsData && virsData !== null && (
            <TreeChart chartData={chartData} activeDate={activeDate} />
          )}
          {!loadingVirsData && virsData === null && (
            <Typography variant="h3" className={classes.error}>
              {t('No data.')}
            </Typography>
          )}
        </div>
      </div>
    </PersistentDrawerLeft>
  );
};
