import { Box, ClickAwayListener, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { FormikProps, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormikFieldStyles } from '../FieldStyles';
import { useDialogFormStyles } from './DialogFormStyles';
import FormikChipInput from '../FormikChipInput';

export interface SelectOption<TOption> {
  value: TOption;
  label: string;
}

export type PopperContent<TValue> = React.FC<{
  onAdded: (option: TValue) => void;
  onRemoved: (option: TValue) => void;
  onClose: () => void;
  selected: TValue[];
}>;

interface Props<TData, TKey, TValue> extends FormikFieldStyles {
  styledClasses?: Record<string, string>;
  formikProps: FormikProps<TData>;
  label?: string;
  dialogTitle?: string;
  field: TKey;
  isRequired?: boolean;
  disabled?: boolean;
  dialogContent: PopperContent<SelectOption<TValue>>;
  onChange?: (value: TValue[]) => void;
  initialOptions?: SelectOption<TValue>[];
}

export const GenericPopperSelect = <
  // eslint-disable-next-line
  TData,
  TKey extends keyof TData,
  TValue extends TData[TKey] extends (infer X)[] ? X : never
>({
  styledClasses,
  formikProps,
  style,
  field: fieldName,
  disabled,
  label,
  dialogTitle,
  dialogContent: DialogContentInner,
  onChange,
  initialOptions
}: Props<TData, TKey, TValue>) => {
  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);
  const [options, setOptions] = useState<SelectOption<TValue>[]>([]);

  const [field] = useField<TValue[]>(`${fieldName}`);
  useEffect(() => {
    if (initialOptions && initialOptions.length > 0) {
      setOptions(initialOptions);
    } else {
      setOptions(options.filter((option) => field.value.some((value) => option.value === value)));
    }
  }, [field.value]);

  const defaultClasses = useDialogFormStyles();
  const classes = styledClasses || defaultClasses;

  const setValue = (value: SelectOption<TValue>[]) => {
    setOptions(value);

    if (onChange) {
      onChange(value.map((option) => option.value));
    }

    formikProps.setFieldValue(
      field.name,
      value.map((option) => option.value)
    );
  };

  const onDelete = (o: SelectOption<TValue>) =>
    setValue(options.filter(({ value }) => value !== o.value));

  const removableOptions = options.map((o: SelectOption<TValue>) => ({
    ...o,
    onDelete: () => onDelete(o)
  }));

  return (
    <ClickAwayListener onClickAway={() => setAnchorRef(null)}>
      <Box className={classes.inputContainer} style={style?.container}>
        {label && (
          <Typography variant="h4" className={classes.inputLabel}>
            {label}
          </Typography>
        )}
        <FormikChipInput
          selected={removableOptions}
          dialogTitle={dialogTitle}
          disabled={disabled}
          setAnchorEl={setAnchorRef}
          anchorEl={anchorRef}
          dialogContent={
            <DialogContentInner
              selected={options}
              onClose={() => setAnchorRef(null)}
              onAdded={(option) => setValue([...options, option])}
              onRemoved={(option) => onDelete(option)}
            />
          }
        />
        {field.value.length > 0 && (
          <IconButton
            className={classes.clearButton}
            disabled={disabled}
            onClick={() => {
              setValue([]);
            }}
          >
            <ClearIcon className={classes.clearIcon} />
          </IconButton>
        )}
      </Box>
    </ClickAwayListener>
  );
};
