import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { virsProfessionalMisconductDataTableReducer } from './tableState/tableReducer';
import {
  VirsProfessionalMisconductTableState,
  VirsProfessionalMisconductTableAction,
  initialVirsProfessionalMisconductTableState
} from './tableState/tableTypes';
import { VirsProfessionalMisconductTable } from './ProfessionalMisconductTable';
import { ShareholderVirsProfessionalMisconductData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';

interface Props {
  chosenDate: string;
  onClickOutlet: (outletId: number, outletName: string) => void;
  dataFilter?: (entry: ShareholderVirsProfessionalMisconductData) => boolean;
}

const VirsProfessionalMisconductTableStateContext = createContext<{
  state: VirsProfessionalMisconductTableState;
}>({
  state: initialVirsProfessionalMisconductTableState
});

const VirsProfessionalMisconductTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsProfessionalMisconductTableAction>;
  dataFilter: (entry: ShareholderVirsProfessionalMisconductData) => boolean;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
  dataFilter: () => true
});

export const ProfessionalMisconductSection: React.FC<Props> = ({
  chosenDate,
  onClickOutlet,
  dataFilter
}) => {
  const [state, dispatch] = useReducer(
    virsProfessionalMisconductDataTableReducer,
    initialVirsProfessionalMisconductTableState
  );

  return (
    <VirsProfessionalMisconductTableStateContext.Provider value={{ state }}>
      <VirsProfessionalMisconductTableDispatchContext.Provider
        value={{ dispatch, dataFilter: dataFilter || (() => true) }}
      >
        <VirsProfessionalMisconductTable chosenDate={chosenDate} onClickOutlet={onClickOutlet} />
      </VirsProfessionalMisconductTableDispatchContext.Provider>
    </VirsProfessionalMisconductTableStateContext.Provider>
  );
};

export function useVirsProfessionalMisconductTableState() {
  return useContext(VirsProfessionalMisconductTableStateContext);
}
export function useVirsProfessionalMisconductTableDispatch() {
  return useContext(VirsProfessionalMisconductTableDispatchContext);
}
