import React from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { getSortLabelPropsFactory } from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  VirsAnnualFinancialReportsColumnHeaderField,
  VirsAnnualFinancialReportsDataTableState
} from './tableState/tableTypes';
import {
  useVirsAnnualFinancialReportsTableState,
  useVirsAnnualFinancialReportsTableDispatch
} from './AnnualFinancialReportsSection';

export const AnnualFinancialReportsHead: React.FC = () => {
  const { state: tableState } = useVirsAnnualFinancialReportsTableState();
  const { dispatch: tableDispatch } = useVirsAnnualFinancialReportsTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsAnnualFinancialReportsColumnHeaderField) => {
    tableDispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsAnnualFinancialReportsDataTableState,
    typeof handleSetSortByColumn,
    VirsAnnualFinancialReportsColumnHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.documentHeading && (
          <TableSortingHeaderCell
            label={t('VIRS financial report documentHeading')}
            columnName="documentHeading"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.financialPeriodEndDate && (
          <TableSortingHeaderCell
            label={t('VIRS financial report financialPeriodEndDate')}
            columnName="financialPeriodEndDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        <TableCell key="actions" align="left" variant="head">
          <Typography variant="h4">{t('Actions with results')}</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
