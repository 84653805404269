import { OutletResponsiblePersonsColumnHeader } from './tableTypes';

export const columnNames: OutletResponsiblePersonsColumnHeader[] = [
  {
    id: 'responsiblePersonFullName',
    numeric: false,
    label: 'Vardas, Pavardė'
  },
  {
    id: 'validFrom',
    numeric: false,
    label: 'Atsakingas nuo'
  },
  {
    id: 'validTo',
    numeric: false,
    label: 'Atsakingas iki'
  },
  {
    id: 'notes',
    numeric: false,
    label: 'Atsakomybės apimtis ir kitos pastabos'
  }
];
