import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderVirsIndividualPersonFinancialSupportData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../store/virsisApp/virsisDataTypes';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import DisplayFilterTool from '../../../../../components/TableDisplayFilter/DisplayFilterTool';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import {
  useVirsIndividualPersonFinancialSupportTableDispatch,
  useVirsIndividualPersonFinancialSupportTableState
} from './IndividualPersonFinancialSupportSection';
import { columnNames } from './tableState/tableColumns';
import { VirsIndividualPersonFinancialSupportColumnHeaderField } from './tableState/tableTypes';
import { ColumnHeader } from '../../../../../utils/tableTypes';

interface Props {
  data: ShareholderVirsIndividualPersonFinancialSupportData[];
}

export const IndividualPersonFinancialSupportCustomizationBar: React.FC<Props> = ({ data }) => {
  const { state } = useVirsIndividualPersonFinancialSupportTableState();
  const { dispatch } = useVirsIndividualPersonFinancialSupportTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' });
  };

  const handleSetSortByColumn = (column: VirsIndividualPersonFinancialSupportColumnHeaderField) => {
    dispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const toggleDisplayColumn = (column: VirsIndividualPersonFinancialSupportColumnHeaderField) => {
    dispatch({ type: 'TOGGLE_DISPLAY_COLUMN', column });
  };

  const handleSetDisplayFilter = (value: filterOptions) => {
    dispatch({ type: 'SET_DISPLAY_FILTER', value });
  };

  function getVirsIndividualSupportColumnsWithTranslation(): ColumnHeader<VirsIndividualPersonFinancialSupportColumnHeaderField>[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`VIRS individual financial support ${col.id}`)
    }));
  }

  const translatedColumns = getVirsIndividualSupportColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <DisplayFilterTool
            disabled={data.length === 0}
            displayFilter={state.displayFilter}
            setDisplayFilter={handleSetDisplayFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
