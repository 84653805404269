import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderVirsEthicalNonComplianceData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsEthicalNonComplianceTableState } from './EthicalNonComplianceSection';
import OutletNameButton from '../../../../../components/TableButtons/OutletNameButton';

interface Props {
  record: ShareholderVirsEthicalNonComplianceData;
  onClickOutlet: (outletId: number, outletName: string) => void;
}

export const EthicalNonComplianceRow: React.FC<Props> = ({ record, onClickOutlet }) => {
  const { state: tableState } = useVirsEthicalNonComplianceTableState();
  return (
    <TableRow key={record.ethicalNonComplianceId}>
      {tableState.columnsDisplayStatus.decisionDate && <TableCell>{record.decisionDate}</TableCell>}
      {tableState.columnsDisplayStatus.documentNumber && (
        <TableCell>{record.documentNumber} </TableCell>
      )}
      {tableState.columnsDisplayStatus.outletList && (
        <TableCell>
          {record.outlets.map((outlet) => (
            <OutletNameButton
              key={outlet.outletId}
              onClicked={() => {
                onClickOutlet(outlet.outletId, outlet.outletName);
              }}
              text={outlet.outletName}
            />
          ))}
        </TableCell>
      )}
      {tableState.columnsDisplayStatus.ethicalNonComplianceStatus && (
        <TableCell>{record.ethicalNonComplianceStatus}</TableCell>
      )}
      {tableState.columnsDisplayStatus.validFrom && <TableCell>{record.validFrom}</TableCell>}
      {tableState.columnsDisplayStatus.validTo && <TableCell>{record.validTo}</TableCell>}
      {tableState.columnsDisplayStatus.reportingInstitutionName && (
        <TableCell>{record.reportingInstitutionName}</TableCell>
      )}
    </TableRow>
  );
};
