import React, { createContext } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Card } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { getStore } from './state/store';
import { TimelineNavigation } from './TimelineNavigation';
import { TimelineContainer } from './TimelineDisplay';
import { ApplicationState } from '../../../store';
import { currentDate } from '../../../utils/dateUtils';
import { fetchVirsDataOfDate } from '../../../store/virsTree/virsTreeActions';
import { getDateAvailablePredicate } from './dateUtil';

export const VirsDatesContext = createContext<{
  virsStart?: Moment | null;
  virsEnd?: Moment | null;
  isDateAvailable: (date: Moment) => boolean;
}>({ virsStart: undefined, virsEnd: undefined, isDateAvailable: () => false });

export const TimelinePanel: React.FC = () => {
  const dispatch = useDispatch();
  const {
    chartData: {
      loadingVirsData: loadingVirs,
      timeline: { activeDate, periodName, timelineEvents, isLoading, error },
      virsData
    },
    virsis: { virsStartDate, virsEndDate, activityPeriods }
  } = useSelector((state: ApplicationState) => state);

  const virsStart =
    virsStartDate === undefined || virsStartDate === null ? virsStartDate : moment(virsStartDate);
  const virsEnd =
    virsEndDate === undefined || virsEndDate === null ? virsEndDate : moment(virsEndDate);

  return (
    <Provider
      store={getStore(periodName, activeDate, timelineEvents, isLoading, error, (value) =>
        dispatch(fetchVirsDataOfDate(value.format('YYYY-MM-DD')))
      )}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: 11,
          pointerEvents: isLoading || loadingVirs ? 'none' : 'auto'
        }}
      >
        <Card
          style={{
            boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.1)',
            position: 'relative',
            borderRadius: '0'
          }}
        >
          <TimelineNavigation
            minDate={virsData?.virsStartDate}
            maxDate={virsData?.virsEndDate || currentDate}
            activityPeriods={activityPeriods || []}
          />
          <VirsDatesContext.Provider
            value={{
              virsStart,
              virsEnd,
              isDateAvailable: getDateAvailablePredicate(activityPeriods || [])
            }}
          >
            <TimelineContainer />
          </VirsDatesContext.Provider>
        </Card>
      </div>
    </Provider>
  );
};
