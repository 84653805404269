import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderOutletEditionData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { filterOptions } from '../../../../../store/virsisApp/virsisDataTypes';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import DisplayFilterTool from '../../../../../components/TableDisplayFilter/DisplayFilterTool';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import { useOutletEditionTableDispatch, useOutletEditionTableState } from './EditionSection';
import { columnNames } from './tableState/TableColumns';

import { OutletEditionColumnHeader, OutletEditionColumnHeaderField } from './tableState/tableTypes';

interface Props {
  data: ShareholderOutletEditionData[];
  editionsPeriodic?: boolean;
}

const EditionCustomizationBar: React.FC<Props> = ({ data, editionsPeriodic }) => {
  const { state } = useOutletEditionTableState();
  const { dispatch } = useOutletEditionTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: OutletEditionColumnHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: OutletEditionColumnHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const handleSetDisplayFilter = (value: filterOptions) => {
    dispatch({ type: 'SET_DISPLAY_FILTER', value });
  };

  function getEditionColumnsWithTranslation(): OutletEditionColumnHeader[] {
    return columnNames().map((col) => ({
      ...col,
      label: t(
        `Edition ${col.id === 'periodName' && !editionsPeriodic ? 'nonPeriodicName' : col.id}`
      )
    }));
  }

  const translatedColumns = getEditionColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <DisplayFilterTool
            disabled={data.length === 0}
            displayFilter={state.displayFilter}
            setDisplayFilter={handleSetDisplayFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default EditionCustomizationBar;
