import { Paper, Table, TableBody, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../store/virsisApp/virsisDataTypes';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import EnterpriseCustomFilter from './EnterpriseCustomFilter';
import { useVirsEnterpriseTableDispatch, useVirsEnterpriseTableState } from './EnterpriseSection';
import EnterpriseCustomizationBar from './EnterpriseTableCustomizationBar';
import { filterAndSortEnterpriseTableData } from './tableState/tableStateFunctions';
import { EnterpriseTableHead } from './EnterpriseTableHead';
import { EnterpriseRow } from './EnterpriseRow';
import { TableLoader } from '../../../../../components/TableLoader/TableLoad';

interface Props {
  chosenDate: string;
}

export const VirsEnterpriseTable: React.FC<Props> = ({ chosenDate }) => {
  const { state: tableState } = useVirsEnterpriseTableState();
  const { dispatch } = useVirsEnterpriseTableDispatch();

  const { virsEnterpriseData, loadingVirsEnterprieData, virsEnterpriseError } = useSelector(
    (state: ApplicationState) => state.virsInfoData
  );

  const handleSetPage = (value: number) => {
    dispatch({ type: 'SET_PAGE', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'SET_ROWS_PER_PAGE',
      rowsPerPage
    });
  };

  const data = filterAndSortEnterpriseTableData(tableState, virsEnterpriseData || [], chosenDate);

  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <>
        {virsEnterpriseError ? (
          <Typography variant="caption">{virsEnterpriseError}</Typography>
        ) : (
          <div className="data-table">
            <EnterpriseCustomizationBar data={virsEnterpriseData} />
            <Table>
              <EnterpriseTableHead />
              {tableState.customFilterOn && <EnterpriseCustomFilter />}
              <TableBody>
                {loadingVirsEnterprieData ? (
                  <TableLoader columns={7} />
                ) : (
                  <>
                    {dataPage.map((record) => (
                      <EnterpriseRow key={record.enterpriseId} record={record} />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              recordsCount={data.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={handleSetPage}
              setRowsPerPage={handleSetRowsPerPage}
              disabled={data.length === 0}
            />
          </div>
        )}
      </>
    </Paper>
  );
};
