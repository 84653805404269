import { Moment } from 'moment';

/*
Any change regarding VirsTree data and logic should be reproduced in the authorized application virsis-ui,
and vice-versa
*/

export interface ApiVirsData {
  virsId: number;
  uniqueId: number;
  virsLegalName: string;
  virsLegalCode: number;
  virsStartDate: string;
  virsEndDate: string | null;
  shareholders: ApiShareholder[];
  outlets: ApiOutlet[];
  mayHaveSharePercentage: boolean;
  mayHaveVotePercentage: boolean;
  mayHaveVotesOnRules: boolean;
  mayShareholderHaveChildren: boolean;
  shareTypeName: string;
  allShareholdersCount: number;
  hasPoliticalAdRevenues: boolean;
  hasFundsReceivedFromTransactions: boolean;
  hasFinancialSupportReceived: boolean;
  hasEthicalNonCompliances: boolean;
  hasProfessionalMisconducts: boolean;
  shareholderErrors: string[];
}

export interface ApiOutlet {
  outletId: number;
  outletName: string;
  outletTypeName: string;
  enterpriseTypeName?: string;
  controlledFrom: string;
  controlledTo: string | null;
}

export interface ApiShareholder {
  shareholderId: number;
  shareholderGroupId: number;
  shareholderStartDate: string;
  shareholderEndDate: string;
  shareholderPersons: ApiShareholderPerson[];
  eventId: number;
  eventDate: string;
  sharePercentage: number;
  votePercentage: number;
  votesOnRules: boolean;
  sharePercentageLessThanPermitted: boolean | null;
  votePercentageLessThanPermitted: boolean | null;
}

export interface ApiShareholderPerson {
  authorized: boolean;
  personCode: number | null;
  personId: number;
  personLegalFormAbbreviation: string | null;
  personName: string;
  personType: string;
  relatedVirsId: number;
  shareholders: ApiShareholder[];
  shareholderErrors: string[];
}

export interface ChartVirsData extends Omit<ApiVirsData, 'shareholders'> {
  shareholders: ChartShareholder[];
}

export interface ChartShareholder extends Omit<ApiShareholder, 'shareholderPersons'> {
  shareholderPersons: ChartShareholderPerson[];
  shareholderLevel: number;
  parentId: number;
  parentType?: string;
  shareholderFromGroup?: boolean;
  personAsParentId?: number | undefined;
}

export interface ShareholderLink {
  shareholderId: number;
  personType: string;
}

export interface ChartShareholderPerson extends Omit<ApiShareholderPerson, 'shareholders'> {
  shareholders: ChartShareholder[];
  personFromGroup?: boolean;
  shareholderLinks?: ShareholderLink[];
}

export enum LegalPerson {
  LJA = 'LJA',
  UJA = 'UJA'
}

export enum NaturalPerson {
  LFA = 'LFA',
  UFA = 'UFA'
}

export enum GroupShareholderType {
  AUTHORIZED = 'Bendraturčių įgaliotas asmuo',
  UNAUTHORIZED = 'Bendraturtis'
}

export type PeriodName = 'year' | 'month' | 'day';

export interface TimelineEvent {
  date: Moment;
  outletsChanged: boolean;
  shareholdersChanged: boolean;
  shareholdersEnded: boolean;
}

export interface TimelineState {
  activeDate: Moment;
  periodName: PeriodName;
  timelineEvents: TimelineEvent[];
  isLoading: boolean;
  error?: string;
}
