import { action } from 'typesafe-actions';
import {
  FETCH_SHAREHOLDER_OUTLET_INFO_DATA_ERROR,
  FETCH_SHAREHOLDER_OUTLET_INFO_DATA_REQUEST,
  FETCH_SHAREHOLDER_OUTLET_INFO_DATA_SUCCESS
} from './outletInfoActionTypes';
import { ShareholderOutletInfoData } from './outletInfoDataTypes';

export const fetchShareholderOutletInfoData = (virsId: number, outletId: number) =>
  action(FETCH_SHAREHOLDER_OUTLET_INFO_DATA_REQUEST, { virsId, outletId });

export const fetchShareholderOutletInfoDataSuccess = (
  shareholderOutletInfoData: ShareholderOutletInfoData
) => action(FETCH_SHAREHOLDER_OUTLET_INFO_DATA_SUCCESS, shareholderOutletInfoData);

export const fetchShareholderOutletInfoDataError = (error: Error) =>
  action(FETCH_SHAREHOLDER_OUTLET_INFO_DATA_ERROR, error);
