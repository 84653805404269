import { LinearProgress, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

interface Props {
  columns: number;
}

export const TableLoader: React.FC<Props> = ({ columns }) => {
  return (
    <TableRow>
      <TableCell colSpan={columns} padding="none">
        <LinearProgress />
      </TableCell>
    </TableRow>
  );
};
