import { Paper, Table, TableBody, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../store/virsisApp/virsisDataTypes';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import { useVirsPaymentTableDispatch, useVirsPaymentTableState } from './PaymentSection';
import { filterAndSortPaymentDataVirs } from './tableState/tableFunctions';
import { PaymentTableHead } from './PaymentTableHead';
import PaymentDataCustomFilter from './PaymentDataCustomFilter';
import { PaymentRow } from './PaymentRow';
import PaymentCustomizationBar from './PaymentCustomizationBar';
import { TableLoader } from '../../../../../components/TableLoader/TableLoad';

interface Props {
  onClickOutlet: (outletId: number, outletName: string) => void;
}
export const VirsPaymentTable: React.FC<Props> = ({ onClickOutlet }) => {
  const { state: tableState } = useVirsPaymentTableState();
  const { dispatch, dataFilter } = useVirsPaymentTableDispatch();

  const { VirsPaymentData, loadingVirsPaymentData, virsPaymentError } = useSelector(
    (state: ApplicationState) => state.virsInfoData
  );

  const allData = (VirsPaymentData || []).filter(dataFilter);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const data = filterAndSortPaymentDataVirs(tableState, allData);

  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <>
        {virsPaymentError ? (
          <Typography variant="caption">{virsPaymentError}</Typography>
        ) : (
          <div className="data-table">
            <PaymentCustomizationBar />
            <Table>
              <PaymentTableHead />
              {tableState.customFilterOn && <PaymentDataCustomFilter />}
              <TableBody>
                {loadingVirsPaymentData ? (
                  <TableLoader columns={7} />
                ) : (
                  <>
                    {dataPage.map((record) => (
                      <PaymentRow
                        key={record.paymentId}
                        record={record}
                        onClickOutlet={onClickOutlet}
                      />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              recordsCount={data.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={handleSetPage}
              setRowsPerPage={handleSetRowsPerPage}
              disabled={data.length === 0}
            />
          </div>
        )}
      </>
    </Paper>
  );
};
