import React from 'react';
import { isArray } from 'lodash';
import Box from '@material-ui/core/Box';
import { Field, useFormikContext } from 'formik';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import TextField from '@material-ui/core/TextField';
import { IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { FormikFieldStyles, useDialogFormStyles } from './FieldStyles';

interface Props extends FormikFieldStyles {
  styledClasses?: Record<string, string>;
  isRequired: boolean;
  // eslint-disable-next-line
  options: any[];
  // eslint-disable-next-line
  touched?: any;
  // eslint-disable-next-line
  errors: any;
  formikKey: string;
  // eslint-disable-next-line
  setFieldValue: (id: string, value: any) => void;
  // eslint-disable-next-line
  value: any;
  // eslint-disable-next-line
  defaultValue?: any;
  label?: string;
  isClearable: boolean;
  // eslint-disable-next-line
  getOptionLabel: any;
  withoutLabel?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line
  onChange?: (value: any) => void;
  fullWidth?: boolean;
}

type AutocompleteInnerProps = JSX.IntrinsicAttributes &
  AutocompleteProps<unknown, boolean | undefined, boolean | undefined, boolean | undefined>;

export const FormikFormSelectField: React.FC<Props> = ({
  styledClasses,
  isRequired,
  disabled,
  options,
  touched,
  errors,
  formikKey,
  setFieldValue,
  value,
  onChange,
  defaultValue,
  label,
  multiple,
  isClearable,
  getOptionLabel,
  withoutLabel,
  style,
  fullWidth
}) => {
  const defaultClasses = useDialogFormStyles();
  const { t } = useTranslation();
  const classes = styledClasses || defaultClasses;
  const valueDefined = isArray(value) ? value.length > 0 : value;

  const { submitForm } = useFormikContext();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitForm();
    }
  };

  return (
    <Box margin={1} className={classes.inputContainer} style={style?.container}>
      {!withoutLabel && (
        <Typography variant="h5" className={classes.inputLabel}>
          {label}
        </Typography>
      )}

      <Field
        name={formikKey}
        component={(props: AutocompleteInnerProps) => (
          <Autocomplete
            {...props}
            onChange={(_event, fieldValue) => {
              if (onChange) {
                onChange(fieldValue);
              }
              setFieldValue(formikKey, fieldValue);
            }}
            classes={{
              root: classes.autocompleteRoot
            }}
          />
        )}
        options={options}
        multiple={multiple}
        closeIcon=""
        getOptionLabel={getOptionLabel}
        noOptionsText={t('No options text')}
        disabled={disabled || options.length === 0}
        clearOnEscape
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            onKeyDown={handleKeyPress}
            size="small"
            error={touched && !!errors}
            helperText={touched && errors}
            FormHelperTextProps={{ style: { ...style?.helper } }}
            required={isRequired}
            variant="outlined"
            style={style?.input}
            InputProps={{
              ...params.InputProps,
              style: style?.input
            }}
            fullWidth={fullWidth}
            className={classes.textField}
          />
        )}
      />

      {valueDefined && isClearable && (
        <IconButton
          className={classes.clearButton}
          disabled={disabled}
          onClick={() => {
            if (onChange) {
              onChange(defaultValue);
            }
            setFieldValue(formikKey, defaultValue);
          }}
        >
          <ClearIcon className={classes.clearIcon} />
        </IconButton>
      )}
    </Box>
  );
};
