import { OutletCategoryColumnHeader } from './tableTypes';

export const columnNames: OutletCategoryColumnHeader[] = [
  {
    id: 'categoryTypeName',
    numeric: false,
    label: 'Kategorija'
  },
  {
    id: 'validFrom',
    numeric: false,
    label: 'Taikoma nuo'
  },
  {
    id: 'validTo',
    numeric: false,
    label: 'Taikoma iki'
  }
];
