import React from 'react';
import { Typography } from '@material-ui/core/';
import { determineLithuanianNumberDefinitionCase } from '../../utils/tableDataFunctions';

interface PaginationInfoProps {
  recordsCount: number;
  recordsInfo?: string;
}

export const PaginationInfo: React.FC<PaginationInfoProps> = ({ recordsCount, recordsInfo }) => {
  return (
    <div className="pagination-item">
      <Typography variant="h6" style={{ color: '#637082', fontWeight: 600 }}>
        {recordsInfo || determineLithuanianNumberDefinitionCase(recordsCount)}
      </Typography>
    </div>
  );
};
