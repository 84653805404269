import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import {
  initialVirsPaymentDataTableState,
  VirsPaymentDataTableAction,
  VirsPaymentDataTableState
} from './tableState/tableTypes';
import { virsPaymentTableReducer } from './tableState/tableReducer';
import { VirsPaymentTable } from './PaymentTable';
import { ShareholderVirsPaymentData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';

interface Props {
  onClickOutlet: (outletId: number, outletName: string) => void;
  dataFilter?: (data: ShareholderVirsPaymentData) => boolean;
}

const VirsPaymentTableStateContext = createContext<{
  state: VirsPaymentDataTableState;
}>({
  state: initialVirsPaymentDataTableState
});

const VirsPaymentTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsPaymentDataTableAction>;
  dataFilter: (data: ShareholderVirsPaymentData) => boolean;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
  dataFilter: () => false
});

export const PaymentSection: React.FC<Props> = ({ onClickOutlet, dataFilter }) => {
  const [state, dispatch] = useReducer(virsPaymentTableReducer, initialVirsPaymentDataTableState);

  return (
    <VirsPaymentTableStateContext.Provider value={{ state }}>
      <VirsPaymentTableDispatchContext.Provider
        value={{ dispatch, dataFilter: dataFilter || (() => true) }}
      >
        <VirsPaymentTable onClickOutlet={onClickOutlet} />
      </VirsPaymentTableDispatchContext.Provider>
    </VirsPaymentTableStateContext.Provider>
  );
};

export function useVirsPaymentTableState() {
  return useContext(VirsPaymentTableStateContext);
}
export function useVirsPaymentTableDispatch() {
  return useContext(VirsPaymentTableDispatchContext);
}
