import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';

import { useTranslation } from 'react-i18next';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  useOutletResponsiblePersonsSectionTableDispatch,
  useOutletResponsiblePersonsSectionTableState
} from './ResponsiblePersonsSectionSection';
import {
  OutletResponsiblePersonsColumnHeaderField,
  OutletResponsiblePersonsTableState
} from './tableState/tableTypes';

export const OutletResponsiblePersonsSectionTableHead: React.FC = () => {
  const { state: tableState } = useOutletResponsiblePersonsSectionTableState();
  const { dispatch: tableDispatch } = useOutletResponsiblePersonsSectionTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: OutletResponsiblePersonsColumnHeaderField) => {
    tableDispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    OutletResponsiblePersonsTableState,
    typeof handleSetSortByColumn,
    OutletResponsiblePersonsColumnHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.responsiblePersonFullName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('Full name')}
            columnName="responsiblePersonFullName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
            key="valid"
          >
            <Typography variant="h4">{t('Responsible person')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.notes && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('Responsible person responsibilities and notes')}
            columnName="notes"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('From capitalized')}
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('To capitalized')}
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
