import moment from 'moment';
import _ from 'lodash';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';

import {
  TableCustomFilter,
  VirsisData,
  DataWithStatuses,
  SortOrder,
  DatePickerState,
  ValidationDate,
  DataWithWordedStatus,
  DataWithDocumentStatus,
  WordStatus,
  ConfirmationStatus,
  Order
} from './tableTypes';
// eslint-disable-next-line
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] === null && a[orderBy] === null) {
    return 0;
  }
  if (b[orderBy] === null) {
    return -1;
  }
  if (a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: SortOrder,
  orderBy: Key
): (
  a: { [key in Key]: number | string | Date },
  b: { [key in Key]: number | string | Date }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const firstValue = a[0];
    const secondValue = b[0];
    const order = comparator(firstValue, secondValue);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function stableSortWrapped<T>(array: T[], order: SortOrder, sortBy: string) {
  const data = array as unknown as VirsisData[];
  const sorted = stableSort(data, getComparator(order, sortBy));
  return (sorted as unknown as T[]) || [];
}

export function isDateValidAndNotNull(date: string | null): boolean {
  if (date !== null) {
    return false;
  }
  return moment(date, 'YYYY-MM-DD', true).isValid();
}

export function isDateValidOrNull(date: string | null): boolean {
  if (date === null) {
    return true;
  }
  return moment(date, 'YYYY-MM-DD', true).isValid();
}

export function todaysDateString(): string {
  return moment().format('YYYY-MM-DD');
}

export function toFormattedDateString(date: string): string {
  return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
}

export function dateToFormattedDateString(date: Date): string {
  return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
}

export function determineLithuanianNumberDefinitionCase(count: number): string {
  const endsWithZeroOrTeens = 'įrašų';
  const endsWithOne = 'įrašas';
  const endsWithOdd = 'įrašai';
  const zeroMessage = 'Įrašų nėra';

  if (!count) {
    return `${zeroMessage}`;
  }
  if (count % 10 === 1 && count % 100 === 11) {
    return `${count} ${endsWithZeroOrTeens}`;
  }
  if (count % 10 === 1) {
    return `${count} ${endsWithOne}`;
  }
  if (count % 10 === 0) {
    return `${count} ${endsWithZeroOrTeens}`;
  }
  if (count % 10 !== 0) {
    if (count % 100 > 10 && count % 100 < 20) {
      return `${count} ${endsWithZeroOrTeens}`;
    }
    return `${count} ${endsWithOdd}`;
  }
  return '';
}

export function determineEnglishNumberDefinitionCase(count: number): string {
  return `${count} ${count === 1 ? 'record' : 'records'}`;
}

export function dataContainsAnyNotSignedRecords<T extends DataWithStatuses>(data: T[]): boolean {
  return data.some((record) => {
    return record.confirmationStatus !== 'P';
  });
}

export function dataContainsAnySignedRecords<T extends DataWithStatuses>(data: T[]): boolean {
  return data.some((record) => record.confirmationStatus === 'P');
}

export function dataContainsAnyRelevantRecords<T extends DataWithStatuses>(data: T[]): boolean {
  return data.some((record) => record.relevancyStatus === 'A');
}

export function dataContainsAnyOutdatedRecords(data: DataWithStatuses[]): boolean {
  return data.some((record) => record.relevancyStatus === 'I');
}

export function dataContainsMoreThanOneRelevantRecord(data: DataWithStatuses[]): boolean {
  const relevantData = data.filter((record) => record.relevancyStatus === 'A');
  return relevantData.length > 1 || false;
}

export function getDataSlicedToPageGeneric<T>(data: T[], page: number, rowsPerPage: number) {
  return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export function getPagesCountGeneric(numOfRecords: number, rowsPerPage: number) {
  return Math.ceil(numOfRecords / rowsPerPage);
}

export function pageTableData<T extends { rowsPerPage: number; page: number }, TData>(
  { rowsPerPage, page: activePage }: T,
  data: TData[]
): { page: TData[]; pagesCount: number } {
  const pagesCount = getPagesCountGeneric(data.length, rowsPerPage);

  const page = getDataSlicedToPageGeneric(data, activePage, rowsPerPage);

  return { page, pagesCount };
}

export function dataContainsAnyNotSignedRecordWordStatus(data: DataWithWordedStatus[]): boolean {
  return data.some((record) => {
    return record.status === 'RUOSIAMAS';
  });
}
// ----> viršuje esančią funkciją reikėtų ištrinti, kai visų dokumentų būsenos key bus documentStatus, o ne status
export const dataContainsRecordDocStatusNotSigned = (data: DataWithDocumentStatus[]): boolean => {
  return data.some((record) => {
    return record.documentStatus === 'RUOSIAMAS';
  });
};

export const dataContainsAnyDocStatusSignedRecord = (data: DataWithDocumentStatus[]): boolean => {
  return data.some((record) => {
    return record.documentStatus === 'PASIRASYTAS';
  });
};

export const dataContainsMoreThanOneRecordDocStatusSigned = (
  data: DataWithDocumentStatus[]
): boolean => {
  const relevantData = data.filter((record) => record.documentStatus === 'PASIRASYTAS');
  return relevantData.length > 1 || false;
};

export const dataContainsDocStatusAnnuledRecord = (data: DataWithDocumentStatus[]): boolean => {
  return data.some((record) => {
    return record.documentStatus === 'ANULIUOTAS';
  });
};

export function getConfirmationAndRelevancyStatusFilter<TData extends DataWithStatuses>(
  showUnconfirmed: boolean,
  showRelevant: boolean,
  showHistoric: boolean
): Filter<TData> {
  return (data) =>
    data.filter((record) => {
      if (showUnconfirmed && !showRelevant && !showHistoric) {
        return record.confirmationStatus !== 'P';
      }
      if (showUnconfirmed && showRelevant && !showHistoric) {
        return (
          record.confirmationStatus === 'N' ||
          record.confirmationStatus === 'U' ||
          record.relevancyStatus === 'A'
        );
      }
      if (showUnconfirmed && showRelevant && showHistoric) {
        return true;
      }
      if (!showUnconfirmed && showRelevant && showHistoric) {
        return record.relevancyStatus === 'A' || record.relevancyStatus === 'I';
      }
      if (!showUnconfirmed && !showRelevant && showHistoric) {
        return record.relevancyStatus === 'I';
      }
      if (showUnconfirmed && !showRelevant && showHistoric) {
        return record.confirmationStatus !== 'P' || record.relevancyStatus === 'I';
      }
      if (!showUnconfirmed && showRelevant && !showHistoric) {
        return record.relevancyStatus === 'A';
      }
      return false;
    });
}

export function getConfirmationStatusFilter<
  TData extends { confirmationStatus: ConfirmationStatus },
  TTableSettings extends {
    showStatusNotSigned: boolean;
    showStatusRelevant: boolean;
    showStatusOutdated: boolean;
  }
>(
  { showStatusNotSigned, showStatusRelevant, showStatusOutdated }: TTableSettings,
  signedRecordComparator: (a: TData, b: TData) => number
): Filter<TData> {
  return (data) => {
    let filtered;
    const unsigned = data.filter((record) => record.confirmationStatus !== 'P');
    const onlySigned = data.filter((record) => record.confirmationStatus === 'P');

    const [first, ...rest] = onlySigned.sort(signedRecordComparator);

    if (showStatusNotSigned && !showStatusRelevant && !showStatusOutdated) {
      filtered = data.filter((record) => record.confirmationStatus !== 'P') || [];
    }
    if (!showStatusNotSigned && showStatusRelevant && !showStatusOutdated) {
      filtered = [first];
    }
    if (!showStatusNotSigned && !showStatusRelevant && showStatusOutdated) {
      filtered = rest;
    }
    if (showStatusNotSigned && showStatusRelevant && showStatusOutdated) {
      filtered = data;
    }
    if (!showStatusNotSigned && showStatusRelevant && showStatusOutdated) {
      filtered = onlySigned;
    }
    if (showStatusNotSigned && !showStatusRelevant && showStatusOutdated) {
      filtered = [...unsigned, ...rest];
    }
    if (showStatusNotSigned && showStatusRelevant && !showStatusOutdated) {
      filtered = [...unsigned, first];
    }
    return filtered || [];
  };
}

export const MINIMAL_VIP_ESTABLISHED_DATE: ValidationDate = {
  id: 'minimalOutletEstablishedDate',
  date: '1883-01-01',
  text: 'minimali įsteigimo data 1883-01-01'
};

export function getToggledSortOrderGeneric<T>(
  actionSortBy: T,
  stateSortBy: T,
  stateOrder: SortOrder
) {
  if (actionSortBy === stateSortBy) {
    return stateOrder === 'desc' ? 'asc' : 'desc';
  }
  return 'desc';
}

export function getSortedDataGeneric<T extends VirsisData>(
  data: T[],
  order: SortOrder,
  sortBy: string
): T[] {
  return stableSort(data, getComparator(order, sortBy)) || [];
}

export function getSortLabelPropsFactory<
  TTableSettings extends { sortBy: TColumnName; order: Order },
  TTableSettingsUpdateFn extends (value: TColumnName) => void,
  TColumnName
>(sortingStateUpdateHandler: TTableSettingsUpdateFn, { sortBy, order }: TTableSettings) {
  return (columnName: TColumnName) => {
    const direction = sortBy === columnName ? order : 'asc';
    const IconComponent = sortBy === columnName ? ActiveSortIcon : SortIcon;
    const onClick = () => sortingStateUpdateHandler(columnName);
    return { active: true, direction, IconComponent, onClick };
  };
}

export function updateCustomFilterGeneric<T extends string>(
  filter: TableCustomFilter<T>,
  filterBy: T,
  value: string | null
): TableCustomFilter<T> {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function updateCustomFilterMultiple<T extends string>(
  filter: TableCustomFilter<T>,
  filterBy: T,
  values: string[]
): TableCustomFilter<T> {
  return {
    ...filter,
    [filterBy]: values || []
  };
}

export type Filter<T> = (entry: T[]) => T[];

export function filterData<T>(data: T[], filters: Filter<T>[]): T[] {
  let filtered = data;
  filters.forEach((filter) => {
    filtered = filter(filtered);
  });
  return filtered;
}

export type ValueMapper<TSource, TMapped> = (value: TSource) => TMapped;

export function getMappedValueFilter<TData, TValue>(
  value: TValue,
  mapper: ValueMapper<TData, TValue>,
  matcher: ValueMatcher<TValue>
): Filter<TData> {
  return (data) => data.filter((element) => matcher(value, mapper(element)));
}

export const stringIncludesIgnoreCaseMatcher: ValueMatcher<string> = (value, data) =>
  !!data && data.toLocaleLowerCase().includes(value.toLocaleLowerCase());

export type ValueMatcher<T> = (value: T, data: T) => boolean;

export function getArrayIntersectMatcher<T>(matcher: ValueMatcher<T>): ValueMatcher<T[]> {
  return (values, data) => {
    if (values.length === 0) {
      return true;
    }
    let result = false;

    values.forEach((value): void => {
      data.forEach((entry): void => {
        if (matcher(value, entry)) {
          result = true;
        }
      });
    });
    return result;
  };
}

export const stringArrrayIntersectMatcher = getArrayIntersectMatcher(
  stringIncludesIgnoreCaseMatcher
);

export function getStringMappedColumnFilter<T>(
  filterValues: string[],
  valueMapper: ValueMapper<T, string[]>
): Filter<T> {
  return getMappedValueFilter<T, string[]>(filterValues, valueMapper, stringArrrayIntersectMatcher);
}

export function getStatusFilter<T>(
  showNotSigned: boolean,
  showRelevant: boolean,
  showOutdated: boolean,
  signatureStateMapper: ValueMapper<T, WordStatus>,
  decisionDateMapper: ValueMapper<T, Date>
): Filter<T> {
  return (data) => {
    const unsigned = data.filter((entry) => signatureStateMapper(entry) === 'RUOSIAMAS');
    const signed = data
      .filter((entry) => signatureStateMapper(entry) === 'PASIRASYTAS')
      .sort((a, b) => decisionDateMapper(b).valueOf() - decisionDateMapper(a).valueOf());

    const [first, ...rest] = signed;

    if (showNotSigned && showRelevant && showOutdated) {
      return data.filter((entry) => signatureStateMapper(entry) !== 'ANULIUOTAS');
    }
    if (showNotSigned && showRelevant && !showOutdated) {
      return [...unsigned, first];
    }
    if (showNotSigned && !showRelevant && showOutdated) {
      return [...unsigned, ...rest];
    }
    if (showNotSigned && !showRelevant && !showOutdated) {
      return unsigned;
    }
    if (!showNotSigned && showRelevant && showOutdated) {
      return signed;
    }
    if (!showNotSigned && showRelevant && !showOutdated) {
      return [first];
    }
    if (!showNotSigned && !showRelevant && showOutdated) {
      return rest;
    }
    return [];
  };
}

export function valueInBetweenMinMaxDatesGeneric(dateState: DatePickerState): boolean {
  if (dateState.value === null) {
    return true;
  }

  const lowerDate = _.maxBy(dateState.minDates, 'date');
  const upperDate = _.minBy(dateState.maxDates, 'date');

  const datesInRange: boolean[] = [];

  if (lowerDate && lowerDate.date !== null) {
    datesInRange.push(dateState.value >= lowerDate.date);
  } else {
    datesInRange.push(true);
  }
  if (upperDate && upperDate.date !== null) {
    datesInRange.push(dateState.value <= upperDate.date);
  } else {
    datesInRange.push(true);
  }

  return datesInRange.every((dateInRange) => dateInRange === true);
}

export function validatedDatepickerStateRequiredGeneric(state: DatePickerState): DatePickerState {
  const dateValid: boolean = isDateValidAndNotNull(state.value);
  if (!dateValid) {
    return { ...state, value: 'Invalid date', validated: false };
  }
  const dateInRange: boolean = valueInBetweenMinMaxDatesGeneric(state);
  return { ...state, validated: dateValid && dateInRange };
}

export function checkIfDateIsWithinPeriod(dateToCheck: string, periodInDays: number) {
  const date = new Date();
  const numberOfDaysAgoObject = moment(date.setDate(date.getDate() - periodInDays));
  return moment(dateToCheck, 'YYYY-MM-DD') >= numberOfDaysAgoObject;
}

export function getSharedHeaderCellColSpan(displayStatuses: boolean[]): number {
  return displayStatuses.filter((value) => value).length;
}

export function getRecordWithMaxMappedValue<T>(
  data: T[],
  mapper: ValueMapper<T, number>
): T | null {
  if (data.length === 0) {
    return null;
  }
  const sorted = data.sort((a, b) => mapper(b) - mapper(a));
  return sorted[0];
}

export function getUniqueOptionsGeneric<TData>(
  data: TData[],
  mapper: (value: TData[]) => string[]
) {
  return Array.from(new Set(mapper(data)));
}

export function replaceDecimalPoint(string: string): string {
  string = string.replace(/\.(?=\d)/g, ',');
  string = string.replace(/(?:^|\D)(,\d+)/g, (match, p1) =>
    match.charAt(0) === ',' ? '0' + match : match.charAt(0) + '0' + match.slice(1)
  );

  return string;
}

export function toFixedIfNecessary(value: string, dp: number): number {
  return +parseFloat(value).toFixed(dp);
}
