import { sortByColumn, updateCustomFilter } from './tableFunctions';
import {
  VirsLegalEntityFinancialSupportDataTableState,
  VirsLegalEntityFinancialSupportTableAction
} from './tableTypes';

export const virsLegalEntityFinancialSupportTableReducer = (
  state: VirsLegalEntityFinancialSupportDataTableState,
  action: VirsLegalEntityFinancialSupportTableAction
): VirsLegalEntityFinancialSupportDataTableState => {
  switch (action.type) {
    case 'SET_SORT_BY_COLUMN':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: sortByColumn(action.sortBy, state.sortBy, state.order)
      };

    case 'SET_PAGE':
      return {
        ...state,
        page: action.page
      };
    case 'SET_ROWS_PER_PAGE':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };

    case 'TOGGLE_DISPLAY_CUSTOM_FILTER':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: {
          supportProviderName: [],
          supportProviderLegalCode: [],
          financialSupportAmount: [],
          supportReceivedYear: []
        }
      };
    case 'SET_CUSTOM_FILTER':
      return {
        ...state,
        customFilter: updateCustomFilter(state.customFilter, action.filterBy, action.value),
        page: 0
      };
    case 'TOGGLE_DISPLAY_COLUMN':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'SET_DISPLAY_FILTER':
      return {
        ...state,
        displayFilter: action.value
      };
    default:
      return state;
  }
};
