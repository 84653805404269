import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { resetSearchState, searchByLegalRequest } from '../../store/virsSearch/virsSearchActions';
import {
  LegalPersonSearchData,
  LegalPersonSearchQuery
} from '../../store/virsSearch/virsSearchTypes';
import { LegalSearchForm } from './LegalSearchForm';

export const LegalSearchWrapper: React.FC<{
  setQuerySize?: (querySize: number) => void;
  onResults?: (results: LegalPersonSearchData[]) => void;
}> = ({ setQuerySize, onResults }) => {
  const legalSearchResults = useSelector(
    (state: ApplicationState) => state.virsSearchData.legalSearchResults
  );

  const isSearching = useSelector(
    (state: ApplicationState) => state.virsSearchData.loadingLegalSearchResults
  );

  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(resetSearchState());
  }, [reduxDispatch]);

  useEffect(() => {
    if (onResults) {
      onResults(legalSearchResults || []);
    }
  }, [legalSearchResults]);

  function searchVirs(legalQuery: LegalPersonSearchQuery) {
    reduxDispatch(resetSearchState());
    reduxDispatch(searchByLegalRequest(legalQuery));
  }

  return <LegalSearchForm {...{ setQuerySize, searchVirs, isSearching }} />;
};
