import { VirsFundsReceivedDataTableHeaderType } from './tableTypes';

export const columnNames: VirsFundsReceivedDataTableHeaderType[] = [
  {
    id: 'fundsReceivedYear',
    numeric: false,
    label: 'Metai'
  },
  {
    id: 'fundsReceivedSum',
    numeric: false,
    label: 'Suma (EUR)'
  },
  {
    id: 'fundsSourceName',
    numeric: false,
    label: 'Lėšų šaltinio pavadinimas'
  },
  {
    id: 'fundsSourceLegalCode',
    numeric: false,
    label: 'Lešų šaltinio kodas'
  },
  {
    id: 'outletName',
    numeric: false,
    label: 'Susijusios VIP'
  },
  {
    id: 'transactionType',
    numeric: false,
    label: 'Sandorio rūšis'
  }
];
