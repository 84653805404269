import { OutletEditionColumnHeader } from './tableTypes';

export const columnNames = (): OutletEditionColumnHeader[] => [
  {
    id: 'periodName',
    numeric: false,
    label: 'Laikotarpis'
  },
  {
    id: 'circulation',
    numeric: false,
    label: 'Leidinio tiražas, egz.'
  }
];
