import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';

import { useTranslation } from 'react-i18next';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { useVirsOutletsTableDispatch, useVirsOutletsTableState } from './OutletsSection';
import { VirsOutletDataTableField, VirsOutletDataTableState } from './tableState/tableTypes';

export const OutletsTableHead: React.FC = () => {
  const { state: tableState } = useVirsOutletsTableState();
  const { dispatch: tableDispatch } = useVirsOutletsTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsOutletDataTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsOutletDataTableState,
    typeof handleSetSortByColumn,
    VirsOutletDataTableField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.outletName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS outlet outletName')}
            columnName="outletName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.outletTypeName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS outlet outletTypeName')}
            columnName="outletTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.outletGroupName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS outlet outletGroupName')}
            columnName="outletGroupName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.establishedDate && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS outlet establishedDate')}
            columnName="establishedDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {(tableState.columnsDisplayStatus.controlledFrom ||
          tableState.columnsDisplayStatus.controlledTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.controlledFrom,
              tableState.columnsDisplayStatus.controlledTo
            ])}
            key="valid"
          >
            <Typography variant="h4">{t('VIRS outlet control date')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.internationalNumber && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS outlet internationalNumber')}
            columnName="internationalNumber"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.controlledFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('From capitalized')}
            columnName="controlledFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.controlledTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('To capitalized')}
            columnName="controlledTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
