import { Reducer } from 'redux';
import moment from 'moment';
import {
  SET_TIMELINE_ACTIVE_DATE,
  SET_TIMELINE_ERROR,
  SET_TIMELINE_EVENTS,
  SET_TIMELINE_LOADING,
  SET_TIMELINE_PERIOD_NAME,
  VIRS_DATA_ERROR,
  VIRS_DATA_REQUEST,
  VIRS_DATA_REQUEST_OF_DATE,
  VIRS_DATA_SUCCESS,
  XML_VIRS_DATA_ERROR,
  XML_VIRS_DATA_REQUEST
} from './virsTreeActionTypes';
import { ApiOutlet, ApiVirsData, TimelineState } from './virsTreeDataTypes';

export interface KeyNumberValueBoolean {
  [key: number]: boolean;
}

export interface ShareholdersChartState {
  loadingVirsData: boolean;
  virsData?: ApiVirsData;
  virsError?: Error;

  loadingChartDataXml: boolean;
  chartDataXmlError?: Error;

  timeline: TimelineState;
}

export const initialState: ShareholdersChartState = {
  loadingVirsData: false,
  virsData: undefined,
  virsError: undefined,

  loadingChartDataXml: false,
  chartDataXmlError: undefined,

  timeline: {
    activeDate: moment().startOf('day'),
    isLoading: false,
    periodName: 'month',
    timelineEvents: []
  }
};

export const virsTreeReducer: Reducer<ShareholdersChartState> = (state = initialState, action) => {
  switch (action.type) {
    case VIRS_DATA_REQUEST:
    case VIRS_DATA_REQUEST_OF_DATE: {
      return {
        ...state,
        loadingVirsData: true
      };
    }
    case VIRS_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsData: false,
        virsData: {
          ...action.payload.virs,
          uniqueId: action.payload.virs.virsId + Math.random()
        },
        virsError: undefined
      };
    }
    case VIRS_DATA_ERROR: {
      return {
        ...state,
        loadingVirsData: false,
        virsData: undefined,
        virsError: action.payload
      };
    }
    // visų dalyvių xml
    case XML_VIRS_DATA_REQUEST: {
      return {
        ...state,
        loadingVirsDataXml: true,
        virsDataXmlError: undefined
      };
    }
    case XML_VIRS_DATA_ERROR: {
      return {
        ...state,
        loadingVirsDataXml: false,
        virsDataXmlError: action.payload.data
      };
    }

    case SET_TIMELINE_ACTIVE_DATE:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          activeDate: action.payload
        }
      };

    case SET_TIMELINE_LOADING:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          isLoading: action.payload
        }
      };

    case SET_TIMELINE_PERIOD_NAME:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          periodName: action.payload
        }
      };

    case SET_TIMELINE_EVENTS:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          timelineEvents: action.payload
        }
      };

    case SET_TIMELINE_ERROR:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          error: action.payload
        }
      };

    default: {
      return state;
    }
  }
};
