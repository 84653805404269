import { Elements, Position } from 'react-flow-renderer';

import {
  ApiOutlet,
  ChartShareholder,
  ChartVirsData
} from '../../../../store/virsTree/virsTreeDataTypes';
import { OutletEdge, ShareholderNodeData } from './NodesTypes';

const position = { x: 0, y: 0 };
const edgeType = 'straight';
const edgeColor = { stroke: '#C4E2FC' };

export const createOutletsNodes = (outlets: ApiOutlet[], uniqueId: number): Elements => {
  return outlets.map((outlet: ApiOutlet) => {
    return {
      id: `${outlet.outletId}`,
      type: 'outletNode',
      data: { outlet, virsId: uniqueId },
      sourcePosition: Position.Right,
      position
    };
  });
};

export const createOutletEdges = (outlets: ApiOutlet[], uniqueId: number): Elements<OutletEdge> => {
  return outlets.map((x: ApiOutlet) => {
    return {
      id: `e${x.outletId}-${uniqueId}`,
      source: `${x.outletId}`,
      target: `${uniqueId}`,
      type: edgeType,
      animated: false,
      style: edgeColor
    };
  });
};

export const createVirsNode = (chartData: ChartVirsData, uniqueId: number) => {
  return {
    id: `${uniqueId}`,
    type: 'virsNode',
    data: { chartData },
    position
  };
};

export const createShareholdersNodes = (
  arrayToMap: ChartShareholder[],
  virsId: number,
  activeDate: string,
  newArray: Elements<ShareholderNodeData> = []
): Elements<ShareholderNodeData> => {
  arrayToMap.forEach((shareholder) => {
    if (shareholder.shareholderGroupId) {
      newArray.push({
        id: `${shareholder.shareholderLevel}${shareholder.shareholderId}`,
        type: 'shareholderGroupNode',
        data: { shareholder, activeDate },
        targetPosition: Position.Left,
        position
      });
    }
    if (!shareholder.shareholderGroupId) {
      newArray.push({
        id: `${shareholder.shareholderLevel}${shareholder.shareholderId}`,
        type: 'shareholderNode',
        data: { shareholder, virsId, activeDate },
        targetPosition: Position.Left,
        position
      });
    }

    if (shareholder.shareholderPersons?.length > 0) {
      shareholder.shareholderPersons.forEach((person) => {
        if (person !== null && person.shareholders?.length > 0) {
          createShareholdersNodes(person.shareholders, virsId, activeDate, newArray);
        }
        return undefined;
      });
    }
  });

  return newArray;
};

export const createShareholdersEdges = (
  arrayToMap: ChartShareholder[],
  uniqueId: number,
  virsId: number,
  newArray: Elements = []
): Elements<ChartShareholder> => {
  arrayToMap.forEach((shareholder) => {
    const sourceId = shareholder.parentId === virsId ? uniqueId : shareholder.parentId;
    if (!shareholder.shareholderFromGroup) {
      newArray.push({
        id: `e${sourceId}-${shareholder.shareholderId}`,
        source: `${sourceId}`,
        target: `${shareholder.shareholderLevel}${shareholder.shareholderId}`,
        type: edgeType,
        animated: false,
        style: edgeColor
      });
    }
    if (shareholder.shareholderFromGroup) {
      newArray.push({
        id: `e${sourceId}-${shareholder.shareholderId}`,
        source: `${sourceId}`,
        target: `${shareholder.shareholderLevel}${shareholder.shareholderId}`,
        sourceHandle: `${shareholder.personAsParentId}`,
        type: edgeType,
        animated: false,
        style: edgeColor
      });
    }

    if (shareholder.shareholderPersons !== null) {
      shareholder.shareholderPersons.forEach((person) => {
        if (person.shareholders.length > 0) {
          createShareholdersEdges(person.shareholders, uniqueId, virsId, newArray);
        }
        return false;
      });
    }
  });
  return newArray;
};
