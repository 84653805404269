import moment from 'moment';
import { ShareholderOutletAdPrintingData } from '../../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { OutletAdPrintingTableState } from './tableTypes';

export function displayDataFilter(
  data: ShareholderOutletAdPrintingData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderOutletAdPrintingData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(item.validFrom, item.validTo || undefined, undefined, '[]');
      });
    case 'selectedDate':
      return data.filter((item) => {
        return moment(chosenDate).isBetween(
          item.validFrom,
          item.validTo || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function filterAndSortTableData(
  data: ShareholderOutletAdPrintingData[],
  tableState: OutletAdPrintingTableState,
  chosenDate: string
): ShareholderOutletAdPrintingData[] {
  const filters: Filter<ShareholderOutletAdPrintingData>[] = [
    getStringMappedColumnFilter(tableState.customFilter.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(tableState.customFilter.validFrom, ({ validFrom }) => [validFrom])
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);
  const filtered = filterData(displayFiltered, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}
