import {
  mapIntoStateShareholders,
  getFilteredByLevel,
  removeShareholderWithChildrenRecursively,
  getFirstLevelShareholdersWithPreextended,
  setGroupAsExtended,
  addChildrenOfShareholdersToShowingList,
  setGroupAsClosedAndRemoveShareholdersRecursively,
  toggleShareholderChildren,
  toggleVirsShareholders
} from './treeFunctions';
import { initialVirsTreeState, VirsTreeAction, VirsTreeState } from './treeTypes';

export const virsTreeReducer = (state: VirsTreeState, action: VirsTreeAction): VirsTreeState => {
  switch (action.type) {
    case 'INIT_VIRS_TREE_STATE':
      return {
        ...initialVirsTreeState,
        virsId: action.virsId,
        outletId: action.preshowOutletById
          ? action.preshowOutletById
          : initialVirsTreeState.outletId,
        outletCardOpen: !!action.preshowOutletById,
        preopenOutletCardTab: action.preshowOutletById ? 'responsiblePersons' : undefined,
        preextendShareholderById: action.preshowShareholderById,
        preopenVirsCardTab: action.preopenVirsCardTab,
        virsCardOpen: !!action.preopenVirsCardTab
      };
    case 'UPDATE_CHART_DATA':
      return {
        ...state,
        shareholderVotesChartData: action.shareholderVotesChartData,
        shareholderSharesChartData: action.shareholderSharesChartData,
        shareType: action.shareType,
        votesOnRules: action.votesOnRules,
        displaySharePieChart: action.shareholderSharesChartData
          ? action.shareholderSharesChartData.length > 0 && action.mayHaveSharePercentage
          : false,
        displayVotesPieChart: action.shareholderVotesChartData
          ? action.shareholderVotesChartData.length > 0 &&
            action.mayHaveVotePercentage &&
            !action.votesOnRules
          : false
      };
    case 'OPEN_VIRS_PREVIEW_CARD':
      return {
        ...state,
        virsId: action.virsId,
        virsName: action.virsName,
        preopenVirsCardTab: action.preopenVirsCardTab,
        virsCardOpen: true
      };
    case 'CLOSE_VIRS_PREVIEW_CARD':
      return {
        ...state,
        preopenVirsCardTab: undefined,
        virsCardOpen: false
      };
    case 'OPEN_OUTLET_INSTITUTION_DATA_CARD':
      return {
        ...state,
        visibleInstitutionDataType: action.dataType
      };
    case 'CLOSE_OUTLET_INSTITUTION_DATA_CARD':
      return {
        ...state,
        visibleInstitutionDataType: undefined
      };
    case 'OPEN_OUTLET_PREVIEW_CARD':
      return {
        ...state,
        virsId: action.virsId,
        outletId: action.outletId,
        outletName: action.outletName,
        outletCardOpen: true
      };
    case 'CLOSE_OUTLET_PREVIEW_CARD':
      return {
        ...state,
        outletCardOpen: false,
        preopenOutletCardTab: undefined
      };
    case 'OPEN_SHAREHOLDER_PREVIEW_CARD':
      return {
        ...state,
        shareholderName: action.shareholderName,
        shareholderData: action.shareholderData,
        shareholderCardOpen: true
      };
    case 'CLOSE_SHAREHOLDER_PREVIEW_CARD':
      return {
        ...state,
        shareholderCardOpen: false
      };
    case 'OPEN_CHART_CARD':
      return {
        ...state,
        sideChartCardOpen: true
      };
    case 'CLOSE_CHART_CARD':
      return {
        ...state,
        sideChartCardOpen: false
      };
    case 'EXPAND_TREE': {
      return {
        ...state,
        showingShareholders: state.allShareholders.map((sh) => {
          if (sh.shareholderGroupId) {
            return { ...sh, showingGroupExtended: true };
          }
          return sh;
        })
      };
    }
    case 'COLLAPSE_TREE':
      return {
        ...state,
        showingShareholders: getFilteredByLevel(state.allShareholders, 1)
      };
    case 'SHOW_UP_TO_LEVEL': {
      return {
        ...state,
        showingShareholders: getFilteredByLevel(state.allShareholders, action.level)
      };
    }

    case 'TOGGLE_VIRS_SHAREHOLDERS': {
      return {
        ...state,
        showingShareholders: toggleVirsShareholders(
          state.allShareholders,
          state.showingShareholders
        )
      };
    }

    case 'TOGGLE_SHAREHOLDER_CHILDREN': {
      return {
        ...state,
        showingShareholders: toggleShareholderChildren(
          state.allShareholders,
          action.shareholder,
          state.showingShareholders
        )
      };
    }

    case 'SHOW_GROUP_PERSONS_CHILDREN': {
      return {
        ...state,
        showingShareholders: addChildrenOfShareholdersToShowingList(
          state.allShareholders,
          action.shareholders,
          state.showingShareholders
        )
      };
    }

    case 'HIDE_SHAREHOLDER': {
      return {
        ...state,
        showingShareholders:
          action.shareholder.shareholderLevel > 1
            ? removeShareholderWithChildrenRecursively(
                state.showingShareholders,
                action.shareholder
              )
            : state.showingShareholders
      };
    }

    case 'SHOW_GROUP_LIST': {
      return {
        ...state,
        showingShareholders: setGroupAsExtended(state.showingShareholders, action.groupId)
      };
    }

    case 'HIDE_GROUP_LIST': {
      return {
        ...state,
        showingShareholders: setGroupAsClosedAndRemoveShareholdersRecursively(
          state.showingShareholders,
          action.group
        )
      };
    }

    case 'DATA_RECEIVED': {
      const shareholders = mapIntoStateShareholders(action.data.shareholders);
      const firstLevelOrPreextended = getFirstLevelShareholdersWithPreextended(
        shareholders,
        state.preextendShareholderById
      );
      return {
        ...state,
        allShareholders: shareholders,
        showingShareholders: firstLevelOrPreextended
      };
    }
    default:
      return state;
  }
};
