import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { OutletAdPrintingTableReducer } from './tableState/tableReducer';
import {
  initialAdPrintingTableState,
  OutletAdPrintingTableAction,
  OutletAdPrintingTableState
} from './tableState/tableTypes';
import { OutletAdPrintingTable } from './AdPrintingTable';

interface Props {
  chosenDate: string;
}

const OutletAdPrintingTableStateContext = createContext<{
  state: OutletAdPrintingTableState;
}>({
  state: initialAdPrintingTableState
});

const OutletAdPrintingTableDispatchContext = createContext<{
  dispatch: Dispatch<OutletAdPrintingTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const AdPrintingSection: React.FC<Props> = ({ chosenDate }) => {
  const [state, dispatch] = useReducer(OutletAdPrintingTableReducer, initialAdPrintingTableState);
  return (
    <OutletAdPrintingTableStateContext.Provider value={{ state }}>
      <OutletAdPrintingTableDispatchContext.Provider value={{ dispatch }}>
        <OutletAdPrintingTable chosenDate={chosenDate} />
      </OutletAdPrintingTableDispatchContext.Provider>
    </OutletAdPrintingTableStateContext.Provider>
  );
};

export function useOutletAdPrintingTableState() {
  return useContext(OutletAdPrintingTableStateContext);
}
export function useOutletAdPrintingTableDispatch() {
  return useContext(OutletAdPrintingTableDispatchContext);
}
