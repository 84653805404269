import React from 'react';
import { useTranslation } from 'react-i18next';
import { VirsTreeCustomTooltip } from '../VirsTreeCustomTooltip';

interface Props {
  color: string;
  withTooltip?: boolean;
}

export const PoliticalAdRevenues: React.FC<Props> = ({ color, withTooltip }) => {
  const svg = (
    <svg width="17.4" height="17.399">
      <path
        d="M14.872 2.583l-.034-.034A8.648 8.648 0 008.728 0h-.023a8.716 8.716 0 106.167 2.583zM8.694 16.411a7.717 7.717 0 117.717-7.717 7.723 7.723 0 01-7.717 7.717z"
        fill={color}
      />
      <path
        data-name="Path 845"
        d="M8.975 6.234a2.435 2.435 0 011.236.337.449.449 0 00.359.045.487.487 0 00.292-.225.449.449 0 00.045-.359.487.487 0 00-.225-.292 3.386 3.386 0 00-1.707-.461 3.437 3.437 0 00-3.179 2.168h-.382a.487.487 0 00-.483.483.479.479 0 00.483.483h.157a2.74 2.74 0 00-.011.292 2.637 2.637 0 00.011.292h-.157a.477.477 0 00-.472.483.487.487 0 00.483.483h.382a3.426 3.426 0 003.179 2.168 3.386 3.386 0 001.707-.461.476.476 0 10-.483-.82 2.487 2.487 0 01-1.236.337 2.446 2.446 0 01-1.741-.719 2.411 2.411 0 01-.382-.494h2.58a.487.487 0 00.483-.483.479.479 0 00-.483-.483h-2.9c-.011-.112-.022-.2-.022-.292a2.765 2.765 0 01.022-.292h2.9a.487.487 0 00.483-.483.479.479 0 00-.483-.483H6.852a2.326 2.326 0 01.382-.494 2.449 2.449 0 011.741-.73z"
        fill={color}
      />
    </svg>
  );
  const { t } = useTranslation();
  const translation: string = t('Funds from political campaigns');

  if (withTooltip) return <VirsTreeCustomTooltip text={translation}>{svg}</VirsTreeCustomTooltip>;
  return svg;
};
