import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  fetchShareholderOutletInfoData,
  fetchShareholderOutletInfoDataError,
  fetchShareholderOutletInfoDataSuccess
} from './outletInfoActions';
import { FETCH_SHAREHOLDER_OUTLET_INFO_DATA_REQUEST } from './outletInfoActionTypes';
import { getOutletInfoData } from './outletInfoApi';
import { Language } from '../../utils/tableTypes';
import { language } from '../virsisApp/virsisSelectors';

function* handleShareholderOutletInfoDataFetch(
  action: ActionType<typeof fetchShareholderOutletInfoData>
) {
  try {
    const lang: Language = yield select(language);
    const { virsId, outletId } = action.payload;
    const selectedOutletData = yield call(getOutletInfoData, virsId, outletId, lang);
    yield put(fetchShareholderOutletInfoDataSuccess(selectedOutletData.data.outletInfo));
  } catch (error) {
    yield put(fetchShareholderOutletInfoDataError(error));
  }
}

function* outletInfoSaga() {
  yield takeEvery(FETCH_SHAREHOLDER_OUTLET_INFO_DATA_REQUEST, handleShareholderOutletInfoDataFetch);
}
export default outletInfoSaga;
