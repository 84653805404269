import {
  EditionCheckConclusions,
  EditionCheckVirsOutlets,
  ShareholderVirsEditionCheckData
} from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import {
  VirsEditionCheckDataTableCustomFilter,
  VirsEditionCheckDataTableField,
  VirsEditionCheckDataTableState
} from './tableTypes';

export function updateEditCheckDataCustomFilter(
  filter: VirsEditionCheckDataTableCustomFilter,
  filterBy: VirsEditionCheckDataTableField,
  value: string | null
): VirsEditionCheckDataTableCustomFilter {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
}

export function sortTableData(
  data: ShareholderVirsEditionCheckData[],
  tableState: VirsEditionCheckDataTableState
) {
  let sortedData = [...data];
  if (
    tableState.sortBy === 'checkDate' ||
    tableState.sortBy === 'periodName' ||
    tableState.sortBy === 'checkedByInstitutionName'
  ) {
    sortedData = stableSortWrapped(data, tableState.order, tableState.sortBy);
  }

  if (tableState.sortBy === 'outletName') {
    sortedData = sortedData.map((entry) => ({
      ...entry,
      editionCheckVips: stableSortWrapped(
        entry.editionCheckOutlets,
        tableState.order,
        tableState.sortBy
      )
    }));
  }

  if (tableState.sortBy === 'docNr') {
    sortedData = sortedData.map((entry) => ({
      ...entry,
      editionCheckDocs: stableSortWrapped(
        entry.editionCheckDocuments,
        tableState.order,
        tableState.sortBy
      )
    }));
  }

  if (tableState.sortBy === 'conclusion' || tableState.sortBy === 'periodName') {
    sortedData = sortedData.map((entry) => ({
      ...entry,
      editionCheckVips: entry.editionCheckOutlets.map((outlet) => ({
        ...outlet,
        editionCheckConclusions: stableSortWrapped(
          outlet.editionCheckConclusions,
          tableState.order,
          tableState.sortBy
        )
      }))
    }));
  }

  return sortedData;
}

export function filterAndSortEditionCheckTableData(
  data: ShareholderVirsEditionCheckData[],
  tableState: VirsEditionCheckDataTableState
): ShareholderVirsEditionCheckData[] {
  const filters: Filter<ShareholderVirsEditionCheckData>[] = [
    getStringMappedColumnFilter(tableState.customFilter.checkDate, ({ checkDate }) => [checkDate]),
    getStringMappedColumnFilter(
      tableState.customFilter.checkedByInstitutionName,
      ({ checkedByInstitutionName }) => [checkedByInstitutionName]
    ),
    getStringMappedColumnFilter(tableState.customFilter.docNr, ({ editionCheckDocuments }) =>
      editionCheckDocuments.map(({ documentNumber }) => documentNumber)
    ),
    getStringMappedColumnFilter(tableState.customFilter.outletName, ({ editionCheckOutlets }) =>
      editionCheckOutlets.map(({ outletName }) => outletName)
    ),
    getStringMappedColumnFilter(tableState.customFilter.periodName, ({ editionCheckOutlets }) =>
      editionCheckOutlets.flatMap(({ editionCheckConclusions }) =>
        editionCheckConclusions.map(({ periodName }) => periodName)
      )
    ),
    getStringMappedColumnFilter(tableState.customFilter.conclusion, ({ editionCheckOutlets }) =>
      editionCheckOutlets.flatMap(({ editionCheckConclusions }) =>
        editionCheckConclusions.map(({ conclusionTypeName }) => conclusionTypeName)
      )
    )
  ];

  const nestedVipFilters: Filter<EditionCheckVirsOutlets>[] = [
    getStringMappedColumnFilter(tableState.customFilter.outletName, ({ outletName }) => [
      outletName
    ])
  ];

  const nestedConclusionFilters: Filter<EditionCheckConclusions>[] = [
    getStringMappedColumnFilter(tableState.customFilter.conclusion, ({ conclusionTypeName }) => [
      conclusionTypeName
    ]),
    getStringMappedColumnFilter(tableState.customFilter.periodName, ({ periodName }) => [
      periodName
    ])
  ];

  const filtered = filterData(data, filters).map((entry) => ({
    ...entry,
    editionCheckVips: filterData(entry.editionCheckOutlets, nestedVipFilters).map((outlet) => ({
      ...outlet,
      editionCheckConclusions: filterData(outlet.editionCheckConclusions, nestedConclusionFilters)
    }))
  }));

  return sortTableData(filtered, tableState);
}
