import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import { columnNames } from './tableState/tableColumns';
import { useVirsEnterpriseTableDispatch, useVirsEnterpriseTableState } from './EnterpriseSection';
import { EnterpriseDataTableField } from './tableState/tableTypes';
import { ShareholderVirsEnterpriseData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';

const EnterpriseCustomFilter: React.FC = () => {
  const { state: tableState } = useVirsEnterpriseTableState();
  const { dispatch } = useVirsEnterpriseTableDispatch();

  const handleSetCustomFilter = (column: EnterpriseDataTableField, value: string | null) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  const allData =
    useSelector((state: ApplicationState) => state.virsInfoData.virsEnterpriseData) || [];

  function getUniqueOptions(name: EnterpriseDataTableField, data: ShareholderVirsEnterpriseData[]) {
    if (data)
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => String(item[name]))
        )
      );
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
export default EnterpriseCustomFilter;
