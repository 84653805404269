import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderVirsProfessionalMisconductData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../store/virsisApp/virsisDataTypes';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import DisplayFilterTool from '../../../../../components/TableDisplayFilter/DisplayFilterTool';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import {
  useVirsProfessionalMisconductTableDispatch,
  useVirsProfessionalMisconductTableState
} from './ProfessionalMisconductSection';
import { columnNames } from './tableState/tableColumns';
import {
  VirsProfessionalMisconductColumnHeader,
  VirsProfessionalMisconductTableField
} from './tableState/tableTypes';

interface Props {
  data: ShareholderVirsProfessionalMisconductData[];
}

const ProfessionalMisconductCustomizationBar: React.FC<Props> = ({ data }) => {
  const { state } = useVirsProfessionalMisconductTableState();
  const { dispatch } = useVirsProfessionalMisconductTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: VirsProfessionalMisconductTableField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: VirsProfessionalMisconductTableField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  const handleSetDisplayFilter = (value: filterOptions) => {
    dispatch({ type: 'SET_DISPLAY_FILTER', value });
  };

  function getVirsProfessionalMisconductColumsWithTranslation(): VirsProfessionalMisconductColumnHeader[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`VIRS professional misconduct ${col.id}`)
    }));
  }

  const translatedColumns = getVirsProfessionalMisconductColumsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <DisplayFilterTool
            disabled={data.length === 0}
            displayFilter={state.displayFilter}
            setDisplayFilter={handleSetDisplayFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default ProfessionalMisconductCustomizationBar;
