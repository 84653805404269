import React from 'react';
import { MenuItem, Select, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface PaginationJumpPageProps {
  actionLabel: string;
  currentPage: number;
  pagesCount: number;
  setPage: (value: number) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      height: 24
    }
  })
);

export const PaginationJumpPage: React.FC<PaginationJumpPageProps> = ({
  actionLabel,
  currentPage,
  pagesCount,
  setPage,
  disabled
}) => {
  const classes = useStyles();

  const totalOfPages = pagesCount > 0 ? Array.from(Array(pagesCount).keys()) : [0];

  return (
    <div className="pagination-item">
      <Typography variant="h6" style={{ color: '#637082', fontWeight: 600 }}>
        {actionLabel}
      </Typography>
      {(pagesCount === 0 || Number.isNaN(pagesCount)) && (
        <Select
          labelId="jump-page-select-label"
          id="jump-page-select"
          disabled
          value={1}
          className={classes.margin}
          variant="outlined"
        >
          <MenuItem key={1} selected value={1}>
            {1}
          </MenuItem>
        </Select>
      )}
      {pagesCount !== 0 && !Number.isNaN(pagesCount) && (
        <Select
          labelId="jump-page-select-label"
          id="jump-page-select"
          value={currentPage}
          onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) =>
            setPage(Number(event.target.value))
          }
          className={classes.margin}
          variant="outlined"
          disabled={disabled}
        >
          {totalOfPages.map((pageIndex: number) => {
            return (
              <MenuItem key={pageIndex} selected={currentPage === pageIndex} value={pageIndex}>
                {pageIndex + 1}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </div>
  );
};
