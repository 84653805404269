import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createBrowserHistory } from 'history';
import App from './App';
import configureStore from './configureStore';
import 'resize-observer-polyfill/dist/ResizeObserver.global';

const history = createBrowserHistory();

const initialState = window.INITIAL_REDUX_STATE;
export const store = configureStore(history, initialState);

ReactDOM.render(<App store={store} />, document.getElementById('root'));
