import React, { useReducer } from 'react';
import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Link as MaterialLink,
  Button,
  Divider
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ROWS_PER_PAGE_OPTIONS } from '../../store/virsisApp/virsisDataTypes';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import {
  getComparator,
  getDataSlicedToPageGeneric,
  getPagesCountGeneric,
  stableSort
} from '../../utils/tableDataFunctions';
import { SortOrder, VirsisData } from '../../utils/tableTypes';
import {
  initialOutletResultsTableState,
  OutletSearchDataField,
  OutletSearchResultsTableColumnHeader,
  OutletSearchResultsTableReducer
} from './state/OutletSearchTableReducer';
import { OutletSearchData } from '../../store/virsSearch/virsSearchTypes';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

const useStyles = makeStyles(() => ({
  button: {
    padding: '6px 10px',
    margin: '6px auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tableCell: {
    boxSizing: 'border-box',
    borderTop: '1px solid inherit',
    borderRight: '1px solid inherit',
    borderBottom: '1px solid inherit',
    padding: '0 10px'
  },
  tableContainer: {
    border: 'none',
    boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px'
  },
  materialLink: {
    color: '#006FB2',
    fontWeight: 600
  }
}));

interface Props {
  outletSearchResults: OutletSearchData[];
}

export const OutletSearchResults: React.FC<Props> = ({ outletSearchResults }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [tableState, tableDispatch] = useReducer(
    OutletSearchResultsTableReducer,
    initialOutletResultsTableState
  );

  const language = useSelector((appState: ApplicationState) => appState.virsis.language);

  function getSortedOutletSearchData(
    data: OutletSearchData[],
    order: SortOrder,
    sortBy: string
  ): OutletSearchData[] {
    // eslint-disable-next-line
    return (
      (stableSort(
        data as unknown as VirsisData[],
        getComparator(order, sortBy)
      ) as unknown as OutletSearchData[]) || []
    );
  }

  const createSortHandler = (column: OutletSearchDataField) => () => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  function setPage(page: number): void {
    tableDispatch({ type: 'PAGE_SET', page });
  }

  function setRowsPerPage(rowsPerPage: number): void {
    tableDispatch({ type: 'ROWS_PER_PAGE_SET', rowsPerPage });
  }

  const pagesCount = getPagesCountGeneric(outletSearchResults.length, tableState.rowsPerPage);
  const sortedData = getSortedOutletSearchData(
    outletSearchResults,
    tableState.order,
    tableState.sortBy
  );
  const pagedData = getDataSlicedToPageGeneric(
    sortedData,
    tableState.page,
    tableState.rowsPerPage
  ) as unknown as OutletSearchData[];

  const openSearchResult = (result: OutletSearchData) => {
    history.push({
      pathname: `virs/${result.virsId}?lang=${language}`,
      state: { fromOtletResult: result }
    });
  };

  const columns: OutletSearchResultsTableColumnHeader[] = [
    {
      id: 'outletName',
      numeric: false,
      label: t('VIP name')
    },
    {
      id: 'outletType',
      numeric: false,
      label: t('VIP type')
    },
    {
      id: 'virsLegalName',
      numeric: false,
      label: t('virsResults')
    },
    {
      id: 'outletControlledToDate',
      numeric: false,
      label: t('VIP closing date')
    }
  ];

  return (
    <>
      <Divider light style={{ width: '100%', marginTop: '25px' }} />
      <Typography
        variant="h2"
        style={{
          paddingTop: '10px',
          paddingBottom: '20px',
          fontSize: '1rem'
        }}
      >
        {t('Search results')}
      </Typography>
      <Container disableGutters maxWidth="lg">
        <TableContainer className={classes.tableContainer}>
          <Paper elevation={6}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{ border: 'none' }}>
                  {columns.map((column, index) => (
                    <TableCell
                      style={{
                        borderTopLeftRadius: index === 0 ? '10px' : 0,
                        minWidth: '142px'
                      }}
                      key={column.id}
                      sortDirection={tableState.sortBy === column.id ? tableState.order : false}
                      align="left"
                      variant="head"
                    >
                      <TableSortLabel
                        active
                        direction={tableState.sortBy === column.id ? tableState.order : 'asc'}
                        IconComponent={tableState.sortBy === column.id ? ActiveSortIcon : SortIcon}
                        onClick={createSortHandler(column.id)}
                      >
                        <Typography variant="h4">{column.label}</Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell
                    style={{
                      width: '100px',
                      borderRight: 'none',
                      borderTopRightRadius: '10px'
                    }}
                  >
                    <Typography variant="h4">{t('Actions with results')}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagedData.map((result) => (
                  <TableRow
                    style={{ border: 'none' }}
                    key={`${result.outletControlledFromDate}-${result.outletId}`}
                  >
                    <TableCell
                      variant="body"
                      style={{ borderLeft: 'none' }}
                      className={classes.tableCell}
                    >
                      <Typography>{result.outletName}</Typography>
                    </TableCell>
                    <TableCell variant="body" className={classes.tableCell}>
                      <Typography>{result.outletTypeName}</Typography>
                    </TableCell>

                    <TableCell variant="body" className={classes.tableCell}>
                      <MaterialLink
                        className={classes.materialLink}
                        href={`virs/${result.virsId}?lang=${language}`}
                      >
                        {result.virsLegalName}
                      </MaterialLink>
                    </TableCell>
                    <TableCell variant="body" className={classes.tableCell}>
                      <Typography color="textSecondary">
                        {result.outletControlledToComment || result.outletControlledToDate}
                      </Typography>
                    </TableCell>
                    <TableCell variant="body" className={classes.tableCell}>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={() => openSearchResult(result)}
                        startIcon={<VisibilityIcon />}
                      >
                        {t('View VIRS')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              recordsCount={outletSearchResults.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              disabled={outletSearchResults.length === 0}
            />
          </Paper>
        </TableContainer>
      </Container>
    </>
  );
};
