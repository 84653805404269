import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DocumentActionButton } from './DocumentActionButton';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick?: () => void;
}

export const ViewDocumentButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return <DocumentActionButton onClick={onClick} IconComponent={VisibilityIcon} text={t('View')} />;
};
