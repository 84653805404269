import { Action, createAction, createReducer } from 'typesafe-actions';
import {
  FormOptions,
  initialReportSettings,
  PrefilledReportInputValues,
  ReportInput,
  ReportState
} from './types';

export const loadReportOptions = createAction(
  'LOAD_REPORT_OPTIONS',
  (report?: string, prefilledValues?: PrefilledReportInputValues) => ({
    report,
    prefilledValues
  })
)();
export const setReportOptions = createAction('SET_REPORT_OPTIONS', (options: FormOptions) => ({
  options
}))();

export const generateReportFile = createAction('GENERATE_REPORT_FILE', (input: ReportInput) => ({
  input
}))();
export const setReportFileUrl = createAction('SET_REPORT_FILE_URL', (reportFileUrl?: string) => ({
  reportFileUrl
}))();

export const setLoading = createAction('SET_LOADING', (loading: boolean) => ({
  loading
}))();

export const loadOutletTypeOptions = createAction(
  'LOAD_OUTLET_TYPES',
  (enterpriseTypeIds: number[]) => ({
    enterpriseTypeIds
  })
)();

export const loadOutletOptions = createAction('LOAD_OUTLETS', (virsIds: number[]) => ({
  virsIds
}))();

export const setError = createAction('SET_ERROR', (error: boolean) => ({
  error
}))();

export const reportReducer = createReducer<ReportState, Action>({
  error: false,
  isLoading: false,
  open: true,
  options: {
    dataBlockOptions: [],
    fileOptions: [],
    enterpriseTypeOptions: [],
    outletTypeOptions: [],
    reportSettings: initialReportSettings,
    reportOptions: [],
    outletOptions: [],
    sortOptions: [],
    virsOptions: [],
    institutionOptions: []
  }
})
  .handleAction(setReportOptions, (state, action) => ({
    ...state,
    options: action.payload.options
  }))
  .handleAction(setReportFileUrl, (state, action) => ({
    ...state,
    reportFileUrl: action.payload.reportFileUrl
  }))
  .handleAction(setLoading, (state, action) => ({
    ...state,
    isLoading: action.payload.loading
  }))
  .handleAction(setError, (state, action) => ({
    ...state,
    error: action.payload.error
  }));
