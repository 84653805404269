import React, { ReactElement } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../style/virsisTheme';

interface Props {
  text: string | boolean;
  errorColor?: boolean;
  children: ReactElement;
  className?: string;
}

export const VirsTreeCustomTooltip: React.FC<Props> = ({
  text,
  errorColor,
  className,
  children
}) => {
  const selectedColor = errorColor ? `${theme.palette.error.main}` : '#172D46';

  const classes = makeStyles({
    tooltip: {
      margin: '0.8em 0',
      padding: 12,
      borderColor: '#172D46',
      backgroundColor: '#172D46',
      color: 'white',
      fontFamily: 'Open Sans',
      fontSize: '0.8rem',
      fontWeight: 600,
      '& span': {
        color: '#172D46'
      }
    },
    arrow: {
      color: '#FFFFFF',
      '&.MuiTooltip-arrow:before': {
        border: '1px solid',
        borderColor: selectedColor
      }
    }
  })();

  return (
    <Tooltip
      className={className}
      title={text}
      placement="top"
      arrow
      disableHoverListener={!text}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
    >
      {children}
    </Tooltip>
  );
};
