import { GenericDataTableState, GenericTableAction, initialGenericTableState } from './types';
import { getCustomFilter, sortByColumn, updateCustomFilter } from './utilities';

export const genericTableReducer = (
  state: GenericDataTableState,
  action: GenericTableAction
): GenericDataTableState => {
  switch (action.type) {
    case 'INITIALIZE':
      return { ...action.state };
    case 'RESET_STATE':
      return { ...initialGenericTableState };
    case 'SET_SORT_BY_COLUMN':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: sortByColumn(action.sortBy, state.sortBy, state.order)
      };

    case 'SET_PAGE':
      return {
        ...state,
        page: action.page
      };
    case 'SET_ROWS_PER_PAGE':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };

    case 'TOGGLE_DISPLAY_CUSTOM_FILTER':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: getCustomFilter(state?.data)
      };
    case 'SET_CUSTOM_FILTER':
      return {
        ...state,
        customFilter: updateCustomFilter(state.customFilter, action.filterBy, action.value),
        page: 0
      };
    case 'TOGGLE_DISPLAY_COLUMN':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'SET_DISPLAY_FILTER':
      return {
        ...state,
        displayFilter: action.value
      };
    default:
      return state;
  }
};
