import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/core';

import { getPeriodRelativeValue, groupTimelineEvents } from './dateUtil';
import { changeContainerWidth } from './state/actions';
import { selector } from './state/selector';

import { TimelineIndicator } from './TimelineIndicator';
import { TimelineIndicatorGroup } from './TimelineIndicatorGroup';
import { TimelinePeriodDisplay } from './TimelinePeriodDisplay';
import { VirsDatesContext } from './TimelinePanel';

const useStyles = makeStyles({
  timelineInner: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: '30px',
    right: 0
  }
});

export const TimelineDisplayInner: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeDate = useSelector(selector.activeDate);
  const periods = useSelector(selector.periods);
  const visibleRange = useSelector(selector.visiblePeriod);
  const groupingDistance = useSelector(selector.groupingDistance);
  const timelineEvents = useSelector(selector.timelineEvents);
  const cursorDate = useSelector(selector.cursorDate);
  const currentDate = moment();

  const { isDateAvailable } = useContext(VirsDatesContext);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleResize() {
      if (ref.current) {
        dispatch(changeContainerWidth(ref.current.clientWidth));
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [ref, dispatch]);

  const eventGroups =
    visibleRange && groupingDistance
      ? groupTimelineEvents(visibleRange, timelineEvents, groupingDistance)
      : [];

  const showActiveDateSeparate = timelineEvents.every(
    ({ date }) => date.valueOf() !== activeDate.valueOf()
  );

  return (
    <div className={classes.timelineInner} ref={ref}>
      {visibleRange &&
        periods &&
        periods.map((value) => (
          <TimelinePeriodDisplay key={value.start.valueOf()} period={value} />
        ))}

      {visibleRange &&
        eventGroups.map((group) => (
          <TimelineIndicatorGroup key={group.date.valueOf()} eventGroup={group} />
        ))}

      {visibleRange && cursorDate && cursorDate.isSameOrBefore(currentDate) && (
        <TimelineIndicator
          relativeOffset={getPeriodRelativeValue(cursorDate, visibleRange)}
          temp
          dateAvailable={isDateAvailable(cursorDate)}
          helperText={`${cursorDate.month() + 1}-${cursorDate.date()}`}
        />
      )}

      {visibleRange && showActiveDateSeparate && (
        <TimelineIndicator
          relativeOffset={getPeriodRelativeValue(activeDate, visibleRange)}
          cursor
        />
      )}
    </div>
  );
};
