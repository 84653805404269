import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { BoxTitle } from './TreeChartBoxes/BoxTitle';
import { TreeSingleBox } from './TreeChartBoxes/TreeSingleBox';
import { useVirsTreeDispatch } from '../../../pages/VirsTreePage/VirsTreePageContext';
import { virsBoxColor } from './treeChartFunctions/constants';
import { ApiOutlet } from '../../../store/virsTree/virsTreeDataTypes';

interface Props {
  data: { outlet: ApiOutlet; virsId: number };
}

export const OutletComponent = ({ data }: Props) => {
  const { outlet, virsId } = data;
  const { treeDispatch } = useVirsTreeDispatch();

  const handleOpenOutletCard = () => {
    treeDispatch({
      type: 'OPEN_OUTLET_PREVIEW_CARD',
      virsId,
      outletId: outlet.outletId,
      outletName: outlet.outletName
    });
  };

  return (
    <div>
      <Handle type="source" position={Position.Right} style={{ background: virsBoxColor }} />
      <TreeSingleBox
        boxTitle={<BoxTitle title={outlet.outletName} subtitle={outlet.outletTypeName} />}
        color="#B8581B"
        minHeight={60}
        maxHeight={95}
        showLeftSideBox={false}
        showRightSideBox
        onClick={handleOpenOutletCard}
      />
    </div>
  );
};
