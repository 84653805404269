import { Reducer } from 'redux';
import {
  VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_ERROR,
  VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_REQUEST,
  VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_SUCCESS,
  VIRS_EDITION_CHECK_DATA_ERROR,
  VIRS_EDITION_CHECK_DATA_REQUEST,
  VIRS_EDITION_CHECK_DATA_SUCCESS,
  VIRS_ENTERPRISE_DATA_ERROR,
  VIRS_ENTERPRISE_DATA_REQUEST,
  VIRS_ENTERPRISE_DATA_SUCCESS,
  VIRS_ETHICAL_NON_COMPLIANCE_DATA_ERROR,
  VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
  VIRS_ETHICAL_NON_COMPLIANCE_DATA_SUCCESS,
  VIRS_FUNDS_RECEIVED_DATA_ERROR,
  VIRS_FUNDS_RECEIVED_DATA_REQUEST,
  VIRS_FUNDS_RECEIVED_DATA_SUCCESS,
  VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_ERROR,
  VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_REQUEST,
  VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_SUCCESS,
  VIRS_INFO_DATA_ERROR,
  VIRS_INFO_DATA_REQUEST,
  VIRS_INFO_DATA_SUCCESS,
  VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_ERROR,
  VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_REQUEST,
  VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_SUCCESS,
  VIRS_LICENSES_DATA_ERROR,
  VIRS_LICENSES_DATA_REQUEST,
  VIRS_LICENSES_DATA_SUCCESS,
  VIRS_PAYMENT_DATA_ERROR,
  VIRS_PAYMENT_DATA_REQUEST,
  VIRS_PAYMENT_DATA_SUCCESS,
  VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_ERROR,
  VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_REQUEST,
  VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_SUCCESS,
  VIRS_PROFESSIONAL_MISCONDUCT_DATA_ERROR,
  VIRS_PROFESSIONAL_MISCONDUCT_DATA_REQUEST,
  VIRS_PROFESSIONAL_MISCONDUCT_DATA_SUCCESS,
  VIRS_OUTLETS_DATA_REQUEST,
  VIRS_OUTLETS_DATA_SUCCESS,
  VIRS_OUTLETS_DATA_ERROR
} from './virsInfoActionTypes';
import {
  ShareholderVirsEditionCheckData,
  ShareholderVirsEnterpriseData,
  ShareholderVirsEthicalNonComplianceData,
  ShareholderVirsFundsReceivedData,
  ShareholderVirsProfessionalMisconductData,
  ShareholderVirsPaymentData,
  ShareholderVirsLicensesData,
  ShareholderVirsPoliticalAdFinancialSupportData,
  ShareholderVirsLegalEntityFinancialSupportData,
  ShareholderVirsIndividualPersonFinancialSupportData,
  ShareholderVirsAnnualFinancialReportsData,
  ShareholderVirsInfoData,
  ShareholderVirsOutletData
} from './virsInfoDataTypes';

export interface VirsInfoDataState {
  loadingVirsInfoData: boolean;
  virsInfoData?: ShareholderVirsInfoData | null;
  VirsInfoError?: Error;

  loadingVirsEnterprieData: boolean;
  virsEnterpriseData?: ShareholderVirsEnterpriseData[];
  virsEnterpriseError?: Error;

  loadingVirsProfessionalMisconductData: boolean;
  VirsProfessionalMisconductData?: ShareholderVirsProfessionalMisconductData[];
  virsProfessionalMisconductError?: Error;

  loadingVirsFundsReceivedData: boolean;
  VirsFundsReceivedData?: ShareholderVirsFundsReceivedData[];
  virsFundsReceivedError?: Error;

  loadingVirsEditionCheckData: boolean;
  VirsEditionCheckData?: ShareholderVirsEditionCheckData[];
  virsEditionCheckError?: Error;

  loadingVirsPaymentData: boolean;
  VirsPaymentData?: ShareholderVirsPaymentData[];
  virsPaymentError?: Error;

  loadingVirsLicensesData: boolean;
  VirsLicensesData?: ShareholderVirsLicensesData[];
  virsLicensesError?: Error;

  loadingVirsPoliticalAdFinancialSupportData: boolean;
  VirsPoliticalAdFinancialSupportData?: ShareholderVirsPoliticalAdFinancialSupportData[];
  virsPoliticalAdFinancialSupportError?: Error;

  loadingVirsEthicalNonComplianceData: boolean;
  VirsEthicalNonComplianceData?: ShareholderVirsEthicalNonComplianceData[];
  virsEthicalNonComplianceError?: Error;

  loadingVirsLegalEntityFinancialSupportData: boolean;
  VirsLegalEntityFinancialSupportData?: ShareholderVirsLegalEntityFinancialSupportData[];
  virsLegalEntityFinancialSupportError?: Error;

  loadingVirsIndividualPersonFinancialSupportData: boolean;
  VirsIndividualPersonFinancialSupportData?: ShareholderVirsIndividualPersonFinancialSupportData[];
  virsIndividualPersonFinancialSupportError?: Error;

  loadingVirsAnnualFinancialReportsData: boolean;
  VirsAnnualFinancialReportsData?: ShareholderVirsAnnualFinancialReportsData[];
  virsAnnualFinancialReportsError?: Error;

  loadingVirsOutletsdata: boolean;
  virsOutletsData?: ShareholderVirsOutletData[];
  virsOutletsError?: Error;
}

export const initialState: VirsInfoDataState = {
  loadingVirsInfoData: false,
  VirsInfoError: undefined,
  virsInfoData: undefined,
  loadingVirsEnterprieData: false,
  virsEnterpriseData: undefined,
  virsEnterpriseError: undefined,
  loadingVirsProfessionalMisconductData: false,
  VirsProfessionalMisconductData: undefined,
  virsProfessionalMisconductError: undefined,
  loadingVirsEthicalNonComplianceData: false,
  VirsEthicalNonComplianceData: undefined,
  virsEthicalNonComplianceError: undefined,
  loadingVirsFundsReceivedData: false,
  VirsFundsReceivedData: undefined,
  virsFundsReceivedError: undefined,
  loadingVirsEditionCheckData: false,
  VirsEditionCheckData: undefined,
  virsEditionCheckError: undefined,
  loadingVirsPaymentData: false,
  VirsPaymentData: undefined,
  virsPaymentError: undefined,
  loadingVirsLicensesData: false,
  VirsLicensesData: undefined,
  virsLicensesError: undefined,
  loadingVirsPoliticalAdFinancialSupportData: false,
  VirsPoliticalAdFinancialSupportData: undefined,
  virsPoliticalAdFinancialSupportError: undefined,
  loadingVirsLegalEntityFinancialSupportData: false,
  VirsLegalEntityFinancialSupportData: undefined,
  virsLegalEntityFinancialSupportError: undefined,
  loadingVirsIndividualPersonFinancialSupportData: false,
  VirsIndividualPersonFinancialSupportData: undefined,
  virsIndividualPersonFinancialSupportError: undefined,
  loadingVirsAnnualFinancialReportsData: false,
  VirsAnnualFinancialReportsData: undefined,
  virsAnnualFinancialReportsError: undefined,
  loadingVirsOutletsdata: false,
  virsOutletsData: undefined,
  virsOutletsError: undefined
};

export const virsInfoReducer: Reducer<VirsInfoDataState> = (state = initialState, action) => {
  switch (action.type) {
    case VIRS_INFO_DATA_REQUEST: {
      return { ...state, loadingVirsInfoData: true };
    }
    case VIRS_INFO_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsInfoData: false,
        virsInfoData: action.payload
      };
    }
    case VIRS_INFO_DATA_ERROR: {
      return {
        ...state,
        loadingVirsInfoData: false,
        VirsInfoError: action.payload.statusText
      };
    }
    case VIRS_ENTERPRISE_DATA_REQUEST: {
      return { ...state, loadingVirsEnterprieData: true };
    }
    case VIRS_ENTERPRISE_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsEnterprieData: false,
        virsEnterpriseData: action.payload
      };
    }
    case VIRS_ENTERPRISE_DATA_ERROR: {
      return {
        ...state,
        loadingVirsEnterprieData: false,
        virsEnterpriseError: action.payload.statusText
      };
    }
    case VIRS_PROFESSIONAL_MISCONDUCT_DATA_REQUEST: {
      return { ...state, loadingVirsProfessionalMisconductData: true };
    }
    case VIRS_PROFESSIONAL_MISCONDUCT_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsProfessionalMisconductData: false,
        VirsProfessionalMisconductData: action.payload
      };
    }
    case VIRS_PROFESSIONAL_MISCONDUCT_DATA_ERROR: {
      return {
        ...state,
        loadingVirsProfessionalMisconductData: false,
        virsProfessionalMisconductError: action.payload.statusText
      };
    }
    case VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST: {
      return { ...state, loadingVirsEthicalNonComplianceData: true };
    }
    case VIRS_ETHICAL_NON_COMPLIANCE_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsEthicalNonComplianceData: false,
        VirsEthicalNonComplianceData: action.payload
      };
    }
    case VIRS_ETHICAL_NON_COMPLIANCE_DATA_ERROR: {
      return {
        ...state,
        loadingVirsEthicalNonComplianceData: false,
        virsEthicalNonComplianceError: action.payload.statusText
      };
    }
    case VIRS_FUNDS_RECEIVED_DATA_REQUEST: {
      return { ...state, loadingVirsFundsReceivedData: true };
    }
    case VIRS_FUNDS_RECEIVED_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsFundsReceivedData: false,
        VirsFundsReceivedData: action.payload
      };
    }
    case VIRS_FUNDS_RECEIVED_DATA_ERROR: {
      return {
        ...state,
        loadingVirsFundsReceivedData: false,
        virsFundsReceivedError: action.payload.statusText
      };
    }
    case VIRS_EDITION_CHECK_DATA_REQUEST: {
      return { ...state, loadingVirsEditionCheckData: true };
    }
    case VIRS_EDITION_CHECK_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsEditionCheckData: false,
        VirsEditionCheckData: action.payload
      };
    }
    case VIRS_EDITION_CHECK_DATA_ERROR: {
      return {
        ...state,
        loadingVirsEditionCheckData: false,
        virsFundsReceivedError: action.payload.statusText
      };
    }
    case VIRS_PAYMENT_DATA_REQUEST: {
      return { ...state, loadingVirsPaymentData: true };
    }
    case VIRS_PAYMENT_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsPaymentData: false,
        VirsPaymentData: action.payload
      };
    }
    case VIRS_PAYMENT_DATA_ERROR: {
      return {
        ...state,
        loadingVirsPaymentData: false,
        virsPaymentError: action.payload.statusText
      };
    }
    case VIRS_LICENSES_DATA_REQUEST: {
      return { ...state, loadingVirsLicensesData: true };
    }
    case VIRS_LICENSES_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsLicensesData: false,
        VirsLicensesData: action.payload
      };
    }
    case VIRS_LICENSES_DATA_ERROR: {
      return {
        ...state,
        loadingVirsLicensesData: false,
        virsLicensesError: action.payload.statusText
      };
    }
    case VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_REQUEST: {
      return { ...state, loadingVirsPoliticalAdFinancialSupportData: true };
    }
    case VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsPoliticalAdFinancialSupportData: false,
        VirsPoliticalAdFinancialSupportData: action.payload
      };
    }
    case VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_ERROR: {
      return {
        ...state,
        loadingVirsPoliticalAdFinancialSupportData: false,
        virsPoliticalAdFinancialSupportError: action.payload.statusText
      };
    }
    case VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_REQUEST: {
      return { ...state, loadingVirsLegalEntityFinancialSupportData: true };
    }
    case VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsLegalEntityFinancialSupportData: false,
        VirsLegalEntityFinancialSupportData: action.payload
      };
    }
    case VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_ERROR: {
      return {
        ...state,
        loadingVirsLegalEntityFinancialSupportData: false,
        virsLegalEntityFinancialSupportError: action.payload.statusText
      };
    }
    case VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_REQUEST: {
      return {
        ...state,
        loadingVirsIndividualPersonFinancialSupportData: true
      };
    }
    case VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsIndividualPersonFinancialSupportData: false,
        VirsIndividualPersonFinancialSupportData: action.payload
      };
    }
    case VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_ERROR: {
      return {
        ...state,
        loadingVirsIndividualPersonFinancialSupportData: false,
        virsIndividualPersonFinancialSupportError: action.payload.statusText
      };
    }
    case VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_REQUEST: {
      return {
        ...state,
        loadingVirsAnnualFinancialReportsData: true
      };
    }
    case VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsAnnualFinancialReportsData: false,
        VirsAnnualFinancialReportsData: action.payload
      };
    }
    case VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_ERROR: {
      return {
        ...state,
        loadingVirsAnnualFinancialReportsData: false,
        virsAnnualFinancialReportsError: action.payload.statusText
      };
    }
    case VIRS_OUTLETS_DATA_REQUEST: {
      return {
        ...state,
        loadingVirsOutletsdata: true
      };
    }
    case VIRS_OUTLETS_DATA_SUCCESS: {
      return {
        ...state,
        loadingVirsOutletsdata: false,
        virsOutletsData: action.payload
      };
    }
    case VIRS_OUTLETS_DATA_ERROR: {
      return {
        ...state,
        loadingVirsOutletsdata: false,
        virsOutletsError: action.payload.statusText
      };
    }

    default: {
      return state;
    }
  }
};
