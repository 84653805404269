import moment from 'moment';
import { ShareholderOutletEditionData } from '../../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { OutletEditionColumnHeader, OutletEditionTableState } from './tableTypes';

export function filterEditionColumnsByPeriodicOrNotPeriodic(
  columnNames: OutletEditionColumnHeader[],
  isPeriodic: boolean
) {
  return columnNames.filter((column) => column.id !== (isPeriodic ? 'editionDate' : 'periodName'));
}

export function displayDataFilter(
  data: ShareholderOutletEditionData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderOutletEditionData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        if (item.editionDate) {
          return moment().isSame(item.editionDate);
        }
        return moment().isBetween(
          item.periodStartDate,
          item.periodEndDate || undefined,
          undefined,
          '[]'
        );
      });
    case 'selectedDate':
      return data.filter((item) => {
        if (item.editionDate) {
          return moment(chosenDate).isSame(item.editionDate);
        }
        return moment(chosenDate).isBetween(
          item.periodStartDate,
          item.periodEndDate || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function filterAndSortTableData(
  data: ShareholderOutletEditionData[],
  tableState: OutletEditionTableState,
  chosenDate: string
): ShareholderOutletEditionData[] {
  const filters: Filter<ShareholderOutletEditionData>[] = [
    getStringMappedColumnFilter(tableState.customFilter.periodName, ({ periodName }) => [
      periodName
    ]),
    getStringMappedColumnFilter(tableState.customFilter.circulation, ({ circulation }) => [
      `${circulation}`
    ])
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);
  const filtered = filterData(displayFiltered, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}
