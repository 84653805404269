import { Button, createStyles, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import virsisTheme from '../../../style/virsisTheme';

interface Props {
  isOpen: boolean;
  title: string;
  isVirs?: number;
  subTitle: string;
  onClose: () => void;
  reduceHeightBy?: string;
  handleClickVirs?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'absolute',
      zIndex: 1300,
      inset: '0px',
      overflow: 'hidden',
      height: '100%',
      width: '100%'
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      boxShadow: 'rgb(0 0 0 / 20%) 0px 0px 10px 10px',
      borderRadius: '10px 10px 0px 0px',
      color: 'rgba(0, 0, 0, 0.87)',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundColor: '#fff'
    },
    cardHeader: {
      display: 'flex',
      flex: '0 0 auto',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '22px 40px 18px'
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      overflow: 'auto',
      '-webkit-overflow-scrolling': 'touch',
      padding: '10px 40px 22px 40px'
    },
    divider: {
      margin: 0,
      backgroundColor: '#AEAEAE'
    },
    title: {
      color: '#5A5A5A'
    },
    titleButton: {
      color: virsisTheme.palette.primary.main,
      fontWeight: 'bold',
      padding: 0,
      margin: '0 0 0 8px',
      height: 'auto'
    },
    subTitle: {
      color: '#5A5A5A'
    },
    closeButton: {
      height: 'auto',
      padding: '10px',
      margin: 0,
      minWidth: 0,
      '&:hover': {
        backgroundColor: 'white'
      }
    },
    icon: {
      color: '#637082',
      width: 20,
      height: 20
    }
  })
);

export const VirsTreePreviewCards: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  isVirs,
  subTitle,
  children,
  reduceHeightBy,
  handleClickVirs
}) => {
  const paperRef = useRef(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const containerStyles = {
    maxHeight: `calc(60% - ${reduceHeightBy || '0px'})`
  };

  const handlePaperClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (paperRef && paperRef.current && event.target === paperRef.current) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Paper ref={paperRef} className={classes.paper} elevation={0} onClick={handlePaperClick}>
        <div className={classes.container} style={containerStyles}>
          <div className={classes.cardHeader}>
            <div>
              <Typography variant="h3" className={classes.title}>
                {title}
                {isVirs && (
                  <Button className={classes.titleButton} onClick={handleClickVirs}>
                    {t('View as VIRS')}
                  </Button>
                )}
              </Typography>
              <Typography variant="body1" className={classes.subTitle}>
                {subTitle}
              </Typography>
            </div>
            <Button className={classes.closeButton} onClick={onClose}>
              <CloseIcon className={classes.icon} />
            </Button>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.cardContent}>{children}</div>
        </div>
      </Paper>
    </div>
  );
};
