import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { ApplicationState } from '../../../../../store';
import { columnNames } from './tableState/tableColumns';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import {
  useVirsAnnualFinancialReportsTableDispatch,
  useVirsAnnualFinancialReportsTableState
} from './AnnualFinancialReportsSection';
import { VirsAnnualFinancialReportsColumnHeaderField } from './tableState/tableTypes';
import { ShareholderVirsAnnualFinancialReportsData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';

export const AnnualFinancialCustomFilter: React.FC = () => {
  const { state: tableState } = useVirsAnnualFinancialReportsTableState();
  const { dispatch: tableDispatch } = useVirsAnnualFinancialReportsTableDispatch();

  const handleSetCustomFilter = (
    column: VirsAnnualFinancialReportsColumnHeaderField,
    value: string | null
  ) => {
    tableDispatch({
      type: 'SET_CUSTOM_FILTER',
      filterBy: column,
      value
    });
  };
  const allData =
    useSelector((state: ApplicationState) => state.virsInfoData.VirsAnnualFinancialReportsData) ||
    [];

  function getUniqueOptions(
    name: VirsAnnualFinancialReportsColumnHeaderField,
    data: ShareholderVirsAnnualFinancialReportsData[]
  ) {
    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => item[name].toString())
        )
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
        <TableCell
          style={{
            borderColor: '#C4E2FC',
            borderTop: 'none',
            borderBottom: 'none'
          }}
        />
      </TableRow>
    </TableBody>
  );
};
