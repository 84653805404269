import { getWithQuery } from '../../utils/axiosApi';
import { Language } from '../../utils/tableTypes';

export const getChartData = (date: string, id: number, lang: Language) =>
  getWithQuery(`/virs/${id}/full-json`, { params: { date, lang } });

export const getXmlChartData = (date: string, id: string, lang: Language) =>
  getWithQuery(`/virs/${id}/full-xml`, { params: { date, lang } });

export const getTimelineEvents = (virsId: number, lang: Language) => {
  return getWithQuery(`/virs/${virsId}/events`, { params: { lang } });
};
