import React from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import { useTranslation } from 'react-i18next';
import { IncomeFromDeals } from '../../SvgIconsAsComponents/IncomeFromDeals';
import { IncomeFromSupport } from '../../SvgIconsAsComponents/IncomeFromSupport';
import { PoliticalAdRevenues } from '../../SvgIconsAsComponents/IncomeFromPoliticalCampings';
import { ShareholdersPieNotFilled } from '../../SvgIconsAsComponents/ShareholdersPieNotFilled';
import { ShareholdersPieFilled } from '../../SvgIconsAsComponents/ShareholdersPieFilled';
import theme from '../../../../style/virsisTheme';
import { VirsTreeCustomTooltip } from '../../VirsTreeCustomTooltip';

interface Props {
  onClickDiagram?: () => void;
  onAdRevenuesClick?: () => void;
  onFinancialSupportReceivedClick?: () => void;
  onFundsReceivedFromTransactionsClick?: () => void;
  onEthicalNonCompliancesClick?: () => void;
  onProfessionalMisconductsClick?: () => void;
  areDiagrams: boolean;
  hasErrors: boolean;
  sideChartCardOpen: boolean;
}

export const VirsComponentInformationIcons: React.FC<Props> = ({
  onClickDiagram,
  onAdRevenuesClick,
  onFinancialSupportReceivedClick,
  onFundsReceivedFromTransactionsClick,
  onEthicalNonCompliancesClick,
  onProfessionalMisconductsClick,
  areDiagrams,
  hasErrors,
  sideChartCardOpen
}) => {
  const useStyles = makeStyles(() => ({
    sitOnTopOfMainBox: {
      position: 'absolute',
      bottom: hasErrors ? 26 : 8,
      zIndex: 4,
      left: 0,
      width: '100%'
    },
    virsIconButtons: {
      padding: '0px',
      marginRight: '8px',
      borderRadius: '5px',
      '& svg > g': {
        transition: 'fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      }
    },
    virsPieWrapper: {
      padding: '5px',
      backgroundColor: 'white',
      marginRight: '8px',
      borderRadius: '5px',
      '& svg > g': {
        transition: 'fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      },
      '&:hover': {
        backgroundColor: '#daecfb',
        '& svg > g': {
          fill: '#006FB2'
        }
      }
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const misconductsTranslation: string = t('Professional misconducts');
  const ethicalNonComplianceTranslation: string = t('Ethical non compliance');

  return (
    <div className={classes.sitOnTopOfMainBox}>
      <Grid container wrap="nowrap" direction="row" justify="space-between" alignItems="center">
        <Grid
          container
          item
          wrap="nowrap"
          direction="row"
          justify="flex-start"
          alignItems="center"
          style={{ marginLeft: '8px' }}
        >
          {onAdRevenuesClick && (
            <IconButton className={classes.virsIconButtons} onClick={onAdRevenuesClick}>
              <PoliticalAdRevenues color="white" withTooltip />
            </IconButton>
          )}
          {onFundsReceivedFromTransactionsClick && (
            <IconButton
              className={classes.virsIconButtons}
              onClick={onFundsReceivedFromTransactionsClick}
            >
              <IncomeFromDeals color="white" withTooltip />
            </IconButton>
          )}
          {onFinancialSupportReceivedClick && (
            <IconButton
              className={classes.virsIconButtons}
              onClick={onFinancialSupportReceivedClick}
            >
              <IncomeFromSupport color="white" withTooltip />
            </IconButton>
          )}
          {onProfessionalMisconductsClick && (
            <VirsTreeCustomTooltip text={misconductsTranslation}>
              <IconButton
                className={classes.virsIconButtons}
                onClick={onProfessionalMisconductsClick}
              >
                <ErrorOutlineIcon style={{ fill: '#fff' }} />
              </IconButton>
            </VirsTreeCustomTooltip>
          )}
          {onEthicalNonCompliancesClick && (
            <VirsTreeCustomTooltip text={ethicalNonComplianceTranslation}>
              <IconButton
                className={classes.virsIconButtons}
                onClick={onEthicalNonCompliancesClick}
              >
                <ErrorOutlinedIcon style={{ fill: '#fff' }} />
              </IconButton>
            </VirsTreeCustomTooltip>
          )}
        </Grid>
        <Grid container item wrap="nowrap" direction="row" justify="flex-end" alignItems="center">
          {areDiagrams && (
            <IconButton className={classes.virsPieWrapper} onClick={onClickDiagram}>
              {sideChartCardOpen ? (
                <ShareholdersPieFilled color={theme.palette.primary.main} />
              ) : (
                <ShareholdersPieNotFilled color="#626f81" />
              )}
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
