import React from 'react';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface Props {
  onClick: () => void;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      height: 'auto',
      padding: 6,
      margin: 0,
      borderRadius: '50%',
      '&:hover': {
        '& span svg': {
          color: theme.palette.text.primary
        }
      }
    },
    icon: {
      color: theme.palette.info.dark,
      width: 20,
      height: 20
    }
  })
);

export const CustomCloseIconButton: React.FC<Props> = ({ onClick, style }) => {
  const classes = useStyles();

  return (
    <Button className={classes.closeButton} onClick={onClick} style={style}>
      <CloseIcon className={classes.icon} />
    </Button>
  );
};
