export const GET_GRAPHICAL_REPRESENTATION_DATA_REQUEST =
  '@graphicalRepresentationTool/GET_GRAPHICAL_REPRESENTATION_DATA_REQUEST';
export const GET_GRAPHICAL_REPRESENTATION_DATA_ERROR =
  '@graphicalRepresentationTool/GET_GRAPHICAL_REPRESENTATION_DATA_ERROR';
export const GET_GRAPHICAL_REPRESENTATION_DATA_SUCCESS =
  '@graphicalRepresentationTool/GET_GRAPHICAL_REPRESENTATION_DATA_SUCCESS';

export type GraphicalRepresentationToolsTypes = 'VIRS' | 'VIP' | 'DJA' | 'DFA';

export interface GraphicalRecord {
  type: GraphicalRepresentationToolsTypes;
  urlLt: string;
  urlEn: string;
  tableNameLt: string;
  tableNameEn: string;
  styleFileName: string;
  isActive: boolean;
  rowNr: number;
  staticId: string | null;
}
