import { Paper, Table, TableBody, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../store/virsisApp/virsisDataTypes';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import { AnnualFinancialReportsCustomizationBar } from './AnnualFinancialReportsCustomizationbar';
import { AnnualFinancialReportsHead } from './AnnualFinancialReportsHead';
import {
  useVirsAnnualFinancialReportsTableDispatch,
  useVirsAnnualFinancialReportsTableState
} from './AnnualFinancialReportsSection';
import { AnnualFinancialReportsRow } from './AnnulFinancialReportsRow';
import { AnnualFinancialCustomFilter } from './AnnualFinancialCustomFilter';
import { filterAndSortAnnualFinancialReportsDataVirs } from './tableState/tableFunctions';
import { TableLoader } from '../../../../../components/TableLoader/TableLoad';

export const VirsAnnualFinancialReportsTable: React.FC = () => {
  const { state: tableState } = useVirsAnnualFinancialReportsTableState();
  const { dispatch } = useVirsAnnualFinancialReportsTableDispatch();

  const {
    VirsAnnualFinancialReportsData,
    loadingVirsAnnualFinancialReportsData,
    virsAnnualFinancialReportsError
  } = useSelector((state: ApplicationState) => state.virsInfoData);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'SET_PAGE', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'SET_ROWS_PER_PAGE',
      rowsPerPage
    });
  };

  const data = filterAndSortAnnualFinancialReportsDataVirs(
    tableState,
    VirsAnnualFinancialReportsData || []
  );

  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <>
        {virsAnnualFinancialReportsError ? (
          <Typography variant="caption">{virsAnnualFinancialReportsError}</Typography>
        ) : (
          <div className="data-table">
            <AnnualFinancialReportsCustomizationBar />
            <Table>
              <AnnualFinancialReportsHead />
              {tableState.customFilterOn && <AnnualFinancialCustomFilter />}
              <TableBody>
                {loadingVirsAnnualFinancialReportsData ? (
                  <TableLoader columns={3} />
                ) : (
                  <>
                    {dataPage.map((record) => (
                      <AnnualFinancialReportsRow key={record.annualReportId} record={record} />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              recordsCount={data.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={handleSetPage}
              setRowsPerPage={handleSetRowsPerPage}
              disabled={data.length === 0}
            />
          </div>
        )}
      </>
    </Paper>
  );
};
