import { Reducer } from 'redux';
import {
  GET_MENU_DATA_ERROR,
  GET_MENU_DATA_REQUEST,
  GET_MENU_DATA_SUCCESS,
  SET_LANGUAGE,
  SET_VIRS_NAME
} from './virsisActionTypes';
import { MenuData } from './virsisDataTypes';
import { getFooterData, getHeaderMenuData } from './virsisReducerFunctions';
import { Language } from '../../utils/tableTypes';

export interface VirsisState {
  getMenuDataLoading: boolean;
  getMenuDataError?: Error;
  menuData?: MenuData[];
  footerData?: MenuData;
  virsName?: string;
  virsId?: number;
  virsStartDate?: string | null;
  virsEndDate?: string | null;
  activityPeriods?: ActivityPeriod[];
  language: Language;
}

export interface ActivityPeriod {
  startDate: string;
  endDate: string | null;
}

export const initialState: VirsisState = {
  getMenuDataLoading: false,
  getMenuDataError: undefined,
  menuData: undefined,
  footerData: undefined,
  virsName: undefined,
  virsId: undefined,
  language: 'lt'
};

const reducer: Reducer<VirsisState> = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU_DATA_REQUEST:
      return {
        ...state,
        getMenuDataLoading: true
      };

    case GET_MENU_DATA_SUCCESS:
      return {
        ...state,
        getMenuDataLoading: false,
        menuData: getHeaderMenuData(action.payload.menu),
        footerData: getFooterData(action.payload.menu, action.payload.lang)
      };

    case GET_MENU_DATA_ERROR:
      return {
        ...state,
        getMenuDataError: action.payload
      };

    case SET_VIRS_NAME:
      return {
        ...state,
        activityPeriods: action.payload.activityPeriods,
        virsName: action.payload.virsName,
        virsId: action.payload.virsId,
        virsStartDate: action.payload.virsStartDate,
        virsEndDate: action.payload.virsEndDate
      };

    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.toLowerCase()
      };

    default: {
      return state;
    }
  }
};

export { reducer as virsisReducer };
