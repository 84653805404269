import { FormikProps } from 'formik';
import React from 'react';
import { FormikFieldStyles } from '../FieldStyles';
import { FormikFormRadioGroup } from '../FormikFormRadioGroup';
import { useDialogFormStyles } from './DialogFormStyles';

interface Props<TData, TOption> extends FormikFieldStyles {
  formikProps: FormikProps<TData>;
  // eslint-disable-next-line
  field: keyof TData;
  options: { value: TOption; label: string }[];
  onChange?: (value: TOption) => void;
  clearable?: boolean;
  disabled?: boolean;
}

export const GenericRadioGroup = <TData, TOption>({
  field,
  formikProps,
  options,
  style,
  onChange,
  clearable,
  disabled
}: Props<TData, TOption>) => {
  const classes = useDialogFormStyles();
  return (
    <FormikFormRadioGroup
      styledClasses={classes}
      formikKey={`${field}`}
      options={options}
      touched={formikProps.touched[field]}
      errors={formikProps.errors[field]}
      setFieldValue={formikProps.setFieldValue}
      onChange={onChange}
      isClearable={clearable}
      defaultValue={null}
      disabled={disabled}
      style={style}
    />
  );
};

GenericRadioGroup.defaultProps = {
  clearable: true,
  disabled: false,
  onChange: undefined
};
