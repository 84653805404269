import { makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import { useTranslation } from 'react-i18next';
import { LegendItem } from './LegendItem';
import { SquareSymbol } from './SquareSymbol';
import { IncomeFromDeals } from '../../SvgIconsAsComponents/IncomeFromDeals';
import { IncomeFromSupport } from '../../SvgIconsAsComponents/IncomeFromSupport';
import { CircleIcon } from '../../../../components/Icons/CircleIcon';
import { ShareholdersPieNotFilled } from '../../SvgIconsAsComponents/ShareholdersPieNotFilled';
import {
  groupBoxColor,
  legalPersonColor,
  naturalPersonColor,
  outletBoxColor,
  virsBoxColor
} from '../../TreeChart/treeChartFunctions/constants';
import { RectIcon } from '../../../../components/Icons/RectIcon';
import { PoliticalAdRevenues } from '../../SvgIconsAsComponents/IncomeFromPoliticalCampings';

const useStyles = makeStyles({
  legend: {
    '& > div': {
      marginTop: 20
    },
    '& > div:first-child': {
      marginTop: 0
    }
  },
  categoryHeader: {
    marginBottom: 5,
    color: '#626f81',
    fontWeight: 'bold'
  }
});

export const BottomPanel = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.legend}>
      <div>
        <Typography className={classes.categoryHeader}>{t('Timeline')}</Typography>
        <LegendItem
          symbol={
            <CircleIcon
              color={theme.palette.primary.main}
              innerSize={0.8}
              fillInner="white"
              size="16px"
            />
          }
          text={t('Shareholders change date')}
        />
        <LegendItem
          symbol={<RectIcon color={theme.palette.primary.main} fillInner="white" size="20px" />}
          text={t('Shareholders change date with closure')}
        />
        <LegendItem
          symbol={<CircleIcon color="#b8581b" innerSize={0.5} size="10px" />}
          text={t('VIP change date')}
        />
      </div>

      <div>
        <Typography className={classes.categoryHeader}>{t('Violations')}</Typography>
        <LegendItem
          symbol={<ErrorOutlineIcon style={{ fill: '#626f81' }} />}
          text={t('Professional misconducts')}
        />
        <LegendItem
          symbol={<ErrorOutlinedIcon style={{ fill: '#626f81' }} />}
          text={t('Ethical non compliance')}
        />
      </div>

      <div>
        <Typography className={classes.categoryHeader}>{t('Funding')}</Typography>
        <LegendItem
          symbol={<PoliticalAdRevenues color="#626f81" />}
          text={t('Funds from political campaigns')}
        />
        <LegendItem
          symbol={<IncomeFromDeals color="#626f81" />}
          text={t('Funds from transactions')}
        />
        <LegendItem
          symbol={<IncomeFromSupport color="#626f81" />}
          text={t('Financial support received')}
        />
      </div>

      <div>
        <Typography className={classes.categoryHeader}>{t('Shareholders tree')}</Typography>
        <LegendItem symbol={<SquareSymbol color={virsBoxColor} />} text={t('VIRS')} />
        <LegendItem symbol={<SquareSymbol color={outletBoxColor} />} text={t('Media (VIP)')} />
        <LegendItem symbol={<SquareSymbol color={legalPersonColor} />} text={t('Legal entity')} />
        <LegendItem
          symbol={<SquareSymbol color={legalPersonColor} foregroundGrid />}
          text={`${t('Legal entity')} (${t('last day of participation')})`}
        />
        <LegendItem
          symbol={<SquareSymbol color={naturalPersonColor} />}
          text={t('Natural person')}
        />
        <LegendItem
          symbol={<SquareSymbol color={naturalPersonColor} foregroundGrid />}
          text={`${t('Natural person')} (${t('last day of participation')})`}
        />
        <LegendItem
          symbol={<SquareSymbol color={groupBoxColor} />}
          text={t('Shareholders group')}
        />
        <LegendItem
          symbol={<SquareSymbol color={groupBoxColor} foregroundGrid />}
          text={`${t('Shareholders group')} (${t('last day of participation')})`}
        />
        <LegendItem
          symbol={<ShareholdersPieNotFilled color="#626f81" />}
          text={t('Shareholders diagram')}
        />
      </div>
    </div>
  );
};
