import { ColumnHeader, SortOrder } from '../../../utils/tableTypes';
import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';

export type FindVirsResultsTableAction =
  | {
      type: 'SORTING_CHANGED';
      sortBy: PersonSearchDataField;
    }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number };

export type PersonSearchDataField =
  | 'personFullName'
  | 'virsResults'
  | 'virsRole'
  | 'virsRoleValidToDate';

export const initialPersonResultsTableState: PersonSearchResultsTableState = {
  order: 'desc',
  sortBy: 'personFullName',
  page: 0,
  rowsPerPage: 10
};

export type PersonSearchResultsTableColumnHeader = ColumnHeader<PersonSearchDataField>;

export interface PersonSearchResultsTableState {
  order: SortOrder;
  sortBy: PersonSearchDataField;
  page: number;
  rowsPerPage: number;
}

export const PersonSearchResultsTableReducer = (
  state: PersonSearchResultsTableState,
  action: FindVirsResultsTableAction
): PersonSearchResultsTableState => {
  switch (action.type) {
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    default:
      return state;
  }
};
