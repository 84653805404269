import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { Order } from '../../../../../../utils/tableTypes';

export type RowsPerPageOptions = number[];

export interface VirsPoliticalAdFinancialSupportDataTableState {
  sortBy: VirsPoliticalAdFinancialSupportColumnHeaderField;
  order: Order;
  page: number;
  recordsPassed: number;
  pagesCount: number;
  rowsPerPage: number;
  columnsDisplayStatus: VirsPoliticalAdFinancialSupportColumnsDisplayStatus;
  customFilterOn: boolean;
  displayFilter: filterOptions;
  customFilter: VirsPoliticalAdFinancialSupportTableCustomFilter;
}

export interface VirsPoliticalAdFinancialSupportColumnHeader {
  id: VirsPoliticalAdFinancialSupportColumnHeaderField;
  numeric: boolean;
  label: string;
}

export type VirsPoliticalAdFinancialSupportColumnHeaderField =
  | 'distributorName'
  | 'distributorCode'
  | 'beneficiaryName'
  | 'campaignName'
  | 'advertisingFrom'
  | 'advertisingTo'
  | 'financialSupportAmount';

export type VirsPoliticalAdFinancialSupportColumnsDisplayStatus = {
  distributorName: boolean;
  distributorCode: boolean;
  beneficiaryName: boolean;
  campaignName: boolean;
  advertisingFrom: boolean;
  advertisingTo: boolean;
  financialSupportAmount: boolean;
};
export type VirsPoliticalAdFinancialSupportTableCustomFilter = {
  distributorName: string[];
  distributorCode: number[];
  beneficiaryName: string[];
  campaignName: string[];
  advertisingFrom: Date[];
  advertisingTo: Date[];
  financialSupportAmount: number[];
};

export type VirsPoliticalAdFinancialSupportTableAction =
  | {
      type: 'SET_SORT_BY_COLUMN';
      sortBy: VirsPoliticalAdFinancialSupportColumnHeaderField;
    }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: number }
  | { type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' }
  | {
      type: 'SET_CUSTOM_FILTER';
      filterBy: VirsPoliticalAdFinancialSupportColumnHeaderField;
      value: string | null;
    }
  | {
      type: 'SET_DISPLAY_FILTER';
      value: filterOptions;
    }
  | {
      type: 'TOGGLE_DISPLAY_COLUMN';
      column: VirsPoliticalAdFinancialSupportColumnHeaderField;
    };

export const initialVirsPoliticalAdFinancialSupportState: VirsPoliticalAdFinancialSupportDataTableState =
  {
    order: 'desc',
    sortBy: 'advertisingFrom',
    displayFilter: 'allItems',
    page: 0,
    recordsPassed: 0,
    pagesCount: 0,
    rowsPerPage: 5,
    columnsDisplayStatus: {
      distributorName: true,
      distributorCode: true,
      beneficiaryName: true,
      campaignName: true,
      advertisingFrom: true,
      advertisingTo: true,
      financialSupportAmount: true
    },
    customFilterOn: false,
    customFilter: {
      distributorName: [],
      distributorCode: [],
      beneficiaryName: [],
      campaignName: [],
      advertisingFrom: [],
      advertisingTo: [],
      financialSupportAmount: []
    }
  };
