import { action } from 'typesafe-actions';
import { Moment } from 'moment';
import {
  LOAD_TIMELINE_EVENTS,
  SET_TIMELINE_ACTIVE_DATE,
  SET_TIMELINE_ERROR,
  SET_TIMELINE_EVENTS,
  SET_TIMELINE_LOADING,
  SET_TIMELINE_PERIOD_NAME,
  VIRS_DATA_ERROR,
  VIRS_DATA_REQUEST,
  VIRS_DATA_REQUEST_OF_DATE,
  VIRS_DATA_SUCCESS,
  XML_VIRS_DATA_ERROR,
  XML_VIRS_DATA_REQUEST
} from './virsTreeActionTypes';
import { ApiVirsData, PeriodName, TimelineEvent } from './virsTreeDataTypes';

export const fetchVirsData = (date: string, virsId: number) =>
  action(VIRS_DATA_REQUEST, { date, virsId });
export const fetchVirsDataSuccess = (data: ApiVirsData) => action(VIRS_DATA_SUCCESS, data);
export const fetchVirsDataError = (error: Error) => action(VIRS_DATA_ERROR, error);

export const fetchXmlVirsData = (date: string, virsId: string) =>
  action(XML_VIRS_DATA_REQUEST, { date, virsId });
export const fetchXmlVirsDataError = (error: Error) => action(XML_VIRS_DATA_ERROR, error);

export const fetchVirsDataOfDate = (date: string) => action(VIRS_DATA_REQUEST_OF_DATE, date);

export const setTimelineActiveDate = (activeDate: Moment) =>
  action(SET_TIMELINE_ACTIVE_DATE, activeDate);

export const setTimelinePeriodName = (periodName: PeriodName) =>
  action(SET_TIMELINE_PERIOD_NAME, periodName);

export const setTimelineLoading = (isLoading: boolean) => action(SET_TIMELINE_LOADING, isLoading);

export const setTimelineError = (message?: string) => action(SET_TIMELINE_ERROR, message);

export const setTimelineEvents = (timelineEvents: TimelineEvent[]) =>
  action(SET_TIMELINE_EVENTS, timelineEvents);

export const loadTimelineEvents = (virsId: number) => action(LOAD_TIMELINE_EVENTS, virsId);
