import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { ColumnHeader, Order } from '../../../../../../utils/tableTypes';

export type VirsFundsReceivedDataTableHeaderType =
  ColumnHeader<VirsFundsReceivedDataVirsTableField>;

export type VirsFundsReceivedDataVirsTableField =
  | 'fundsReceivedYear'
  | 'fundsReceivedSum'
  | 'fundsSourceName'
  | 'fundsSourceLegalCode'
  | 'transactionType'
  | 'outletName';

export interface VirsFundsReceivedDataTableState {
  customFilter: VirsFundsReceivedDataTableCustomFilter;
  displayFilter: filterOptions;
  customFilterOn: boolean;
  order: Order;
  sortBy: VirsFundsReceivedDataVirsTableField;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: VirsFundsReceivedDataTableColumnsDisplayStatus;
}

export interface VirsFundsReceivedDataTableCustomFilter {
  fundsReceivedYear: string[];
  fundsReceivedSum: string[];
  fundsSourceName: string[];
  fundsSourceLegalCode: string[];
  transactionType: string[];
  outletName: string[];
}

export type VirsFundsReceivedDataTableColumnsDisplayStatus = {
  // eslint-disable-next-line
  [key in VirsFundsReceivedDataVirsTableField]: boolean;
};

export type VirsFundsReceivedDataTableAction =
  | {
      type: 'TABLE_INITIALIZED';
    }
  | {
      type: 'SET_DISPLAY_FILTER';
      value: filterOptions;
    }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: VirsFundsReceivedDataVirsTableField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: VirsFundsReceivedDataVirsTableField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | {
      type: 'COLUMN_DISPLAY_TOGGLED';
      column: VirsFundsReceivedDataVirsTableField;
    };

export const initialVirsFundsReceivedDataTableState: VirsFundsReceivedDataTableState = {
  order: 'desc',
  sortBy: 'fundsReceivedYear',
  displayFilter: 'allItems',
  page: 0,
  rowsPerPage: 10,
  columnsDisplayStatus: {
    fundsReceivedYear: true,
    fundsReceivedSum: true,
    fundsSourceName: true,
    fundsSourceLegalCode: true,
    transactionType: true,
    outletName: true
  },
  customFilterOn: false,
  customFilter: {
    fundsReceivedYear: [],
    fundsReceivedSum: [],
    fundsSourceName: [],
    fundsSourceLegalCode: [],
    transactionType: [],
    outletName: []
  }
};
