import React from 'react';
import * as htmlToImage from 'html-to-image';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CustomButtonWithMenu } from '../Buttons/CustomButtonWithMenu';
import { ApplicationState } from '../../../../store';
import { fetchXmlVirsData } from '../../../../store/virsTree/virsTreeActions';
import { sendMessage } from '../../../../store/errorOrSuccessMessage/errorOrSuccessMessageAction';
import { downloadCanvas, ImageMimeTypes } from '../../../../utils/downloadCanvas';
import { VirsTreeListTableClass } from '../../VirsTreeListTable';
import { ShareholderDiagramCardClass } from '../../../VirsTreeCards/ShareholderDiagramCard/DiagramCard';

export const DownloadButtonsContainer: React.FC = () => {
  const {
    chartData: {
      virsData: virs,
      timeline: { activeDate }
    }
  } = useSelector((state: ApplicationState) => state);
  const dispatch = useDispatch();
  const { virsParam } = useParams<{ virsParam: string }>();
  const { t } = useTranslation();

  const handleDownloadJSON = () => {
    const file = new Blob([JSON.stringify(virs)], {
      type: 'text/json'
    });
    saveAs(file, 'data.json');
  };

  const handleDownloadXML = () => {
    dispatch(fetchXmlVirsData(activeDate.format('YYYY-MM-DD'), virsParam));
  };

  const handleDownload = async (format: 'JPEG' | 'PNG', fileName: string) => {
    try {
      // eslint-disable-next-line
      const flowChart = document.querySelector('.react-flow__renderer') as HTMLElement;
      const diagramCard = document.querySelector(`.${ShareholderDiagramCardClass}`) as HTMLElement;
      const virsList = document.querySelector(`.${VirsTreeListTableClass}`) as HTMLElement;
      const virsListTable = virsList.querySelector('table') as HTMLElement;

      if (!flowChart) {
        sendMessage('error', 'Nėra duomenų. Atsisiuntimas negalimas');
        return;
      }

      const promises = [
        htmlToImage.toCanvas(flowChart, {
          width: flowChart.clientWidth,
          height: flowChart.clientHeight,
          backgroundColor: 'white',
          style: {
            transformOrigin: 'top left'
          }
        }),
        htmlToImage.toCanvas(virsList, {
          height: virsListTable.clientHeight,
          backgroundColor: 'white'
        })
      ];

      if (diagramCard.clientWidth && diagramCard.clientHeight) {
        promises.push(
          htmlToImage.toCanvas(diagramCard, {
            width: diagramCard.clientWidth,
            height: diagramCard.clientHeight,
            backgroundColor: 'white',
            style: {
              transformOrigin: 'top left'
            }
          })
        );
      }

      Promise.all(promises).then(([chart, table, diagram]) => {
        const spacing = 30;
        const dateSpacing = 20;
        const canvas = document.createElement('canvas');
        canvas.width = chart.width + spacing + (diagram ? diagram.width + spacing : 0);
        canvas.height = chart.height + table.height + spacing * 2;
        const canvasCtx = canvas.getContext('2d') as CanvasRenderingContext2D;
        canvasCtx.fillStyle = 'white';
        canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
        canvasCtx.fillStyle = 'black';
        canvasCtx.fillText(new Date().toLocaleString('lt-LT'), dateSpacing, dateSpacing);
        canvasCtx.drawImage(chart, spacing, spacing);
        if (diagram) {
          canvasCtx.drawImage(diagram, chart.width + spacing, spacing);
        }
        canvasCtx.drawImage(table, spacing, chart.height + spacing);

        if (format === 'PNG') {
          downloadCanvas(canvas, ImageMimeTypes.Png, fileName);
        }

        if (format === 'JPEG') {
          downloadCanvas(canvas, ImageMimeTypes.Jpeg, fileName);
        }
      });
    } catch (err) {
      sendMessage('error', 'Nėra duomenų. Atsisiuntimas negalimas');
    }
  };

  const handleDownloadPNG = () => {
    handleDownload('PNG', `${t('VIRS_tree')}.png`);
  };

  const handleDownloadJPEG = () => {
    handleDownload('JPEG', `${t('VIRS_tree')}.jpeg`);
  };

  return (
    <CustomButtonWithMenu
      handleDownloadJPEG={handleDownloadJPEG}
      handleDownloadJSON={handleDownloadJSON}
      handleDownloadXML={handleDownloadXML}
      handleDownloadPNG={handleDownloadPNG}
    />
  );
};
