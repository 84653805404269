import {
  getToggledSortOrderGeneric,
  updateCustomFilterGeneric
} from '../../../../../../utils/tableDataFunctions';
import {
  EnterpriseDataTableField,
  VirsEnterpriseTableAction,
  VirsEnterpriseTableState
} from './tableTypes';

export const virsEnterpriseDataTableReducer = (
  state: VirsEnterpriseTableState,
  action: VirsEnterpriseTableAction
): VirsEnterpriseTableState => {
  switch (action.type) {
    case 'SET_STATUS_FILTERS':
      return {
        ...state
      };
    case 'TOGGLE_CUSTOM_FILTER_DISPLAY':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: {
          enterpriseTypeName: [],
          validFrom: [],
          validTo: []
        }
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateCustomFilterGeneric<EnterpriseDataTableField>(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };

    case 'SET_SORTING':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: action.page
      };
    case 'SET_ROWS_PER_PAGE':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'TOGGLE_COLUMN_DISPLAY':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'SET_DISPLAY_FILTER':
      return {
        ...state,
        displayFilter: action.value
      };
    default:
      return state;
  }
};
