import {
  groupBoxColor,
  legalPersonColor,
  naturalPersonColor
} from '../../../containers/VirsTree/TreeChart/treeChartFunctions/constants';
import { isNaturalPerson } from '../../../containers/VirsTree/TreeChart/treeChartFunctions/uiFunctions';
import { ApiShareholder, NaturalPerson } from '../../../store/virsTree/virsTreeDataTypes';
import { PieChartData } from './treeTypes';
import { pieChartLabelSeparator } from './treeFunctions';
import { replaceDecimalPoint, toFixedIfNecessary } from '../../../utils/tableDataFunctions';

export const getPieChartLabel = (
  percentages: number | null,
  name: string,
  notProvidedText: string
) => {
  if (percentages !== null) {
    const numberAsString = `${percentages}`;
    const fixedDecimal = `${toFixedIfNecessary(numberAsString, 2)}`;
    const formattedNumber = replaceDecimalPoint(fixedDecimal);
    return `${formattedNumber}%${pieChartLabelSeparator}${name}`;
  }
  return `${notProvidedText}${pieChartLabelSeparator}${name}`;
};

const pieChartFill = (type: string, percentages: number | null, isGroup?: boolean) => {
  const isNatural = type === NaturalPerson.LFA || type === NaturalPerson.UFA;
  if (!percentages || percentages === null) {
    return '#fff';
  }

  if (isGroup) return groupBoxColor;
  if (isNatural) {
    return naturalPersonColor;
  }
  return legalPersonColor;
};

const pieChartBorder = (type: string, percentages: number, isGroup?: boolean) => {
  if (percentages > 0) {
    return 'transparent';
  }
  if (isGroup) return groupBoxColor;
  if (isNaturalPerson(type)) {
    return naturalPersonColor;
  }
  return legalPersonColor;
};

const sharesPlaceholder = (data: ApiShareholder[]) => {
  const numberOfEmptyObjects = data.filter(
    (x) => x.sharePercentage === 0 || x.sharePercentage === null
  ).length;
  const sumOfShares = data.reduce((acc, curr) => {
    return acc + curr.sharePercentage;
  }, 0);
  const percentagePlaceholder = (100 - sumOfShares) / numberOfEmptyObjects;
  return percentagePlaceholder;
};

const votesPlaceholder = (data: ApiShareholder[]) => {
  const numberOfEmptyObjects = data.filter(
    (x) => x.votePercentage === 0 || x.votePercentage === null
  ).length;
  const sumOfVotes = data.reduce((acc, curr) => {
    return acc + curr.votePercentage;
  }, 0);
  const percentagePlaceholder = (100 - sumOfVotes) / numberOfEmptyObjects;
  return percentagePlaceholder;
};

export const sharesChartData = (
  shareholders: ApiShareholder[],
  shareholdersGroupName: string,
  notProvidedText: string,
  authorizedPersonText: string
): PieChartData[] | undefined => {
  const shareholderWithoutPercentages = shareholders.some(
    (x) => x.sharePercentage === 0 || x.sharePercentage === null
  );

  const roundNumber = (num: number): number => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const totalPercentages = shareholders.reduce(
    (acc, curr) => roundNumber(acc) + roundNumber(curr.sharePercentage),
    0
  );

  // kai yra nežinomų dalyvių dalis
  const fillTheGap = {
    key: parseInt(`${shareholders[0]?.shareholderId}${100 - totalPercentages}`, 10),
    fill: 'transparent',
    stroke: '#000000',
    shareholderName: getPieChartLabel(100 - totalPercentages, notProvidedText, notProvidedText),
    percentage: 100 - totalPercentages
  };

  const sharesData = shareholders
    .map((shareholder) => {
      const isGroup = !!shareholder.shareholderGroupId;
      const authorizedPerson = shareholder.shareholderPersons.find((x) => x.authorized);
      const label = isGroup
        ? `${shareholdersGroupName}${
            authorizedPerson
              ? `, ${authorizedPerson.personName} ${authorizedPersonText.toLocaleLowerCase()}`
              : ''
          }`
        : shareholder.shareholderPersons[0].personName;

      return {
        key: shareholder.shareholderId,
        fill: pieChartFill(
          shareholder.shareholderPersons[0].personType,
          shareholder.sharePercentage,
          isGroup
        ),
        stroke: pieChartBorder(
          shareholder.shareholderPersons[0].personType,
          shareholder.sharePercentage,
          isGroup
        ),
        shareholderName: `${
          shareholder.sharePercentageLessThanPermitted ? '< ' : ''
        }${getPieChartLabel(shareholder.sharePercentage, label, notProvidedText)}`,
        percentage:
          shareholder.sharePercentage === 0 || shareholder.sharePercentage === null
            ? sharesPlaceholder(shareholders)
            : shareholder.sharePercentage
      };
    })
    .filter((entry) => entry.percentage);

  const dataToPieChart = () => {
    if (shareholders.length && totalPercentages < 100 && !shareholderWithoutPercentages) {
      return [...sharesData, fillTheGap];
    }
    return [...sharesData];
  };

  return dataToPieChart();
};

export const votesChartData = (
  shareholders: ApiShareholder[],
  shareholdersGroupName: string,
  notProvidedText: string,
  authorizedPersonText: string,
  votesOnRulesText: string
): PieChartData[] | undefined => {
  const shareholdersWithPercentages = shareholders.filter((x) => Boolean(x.votePercentage));
  const shareholdersWithoutPercentages = shareholders.filter((x) => !x.votePercentage);

  const roundNumber = (num: number): number => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const totalPercentages = shareholders.reduce(
    (acc, curr) => roundNumber(acc) + curr.votePercentage,
    0
  );

  // with percentage
  const votesWithPercentageData = shareholdersWithPercentages.map((shareholder) => {
    const isGroup = !!shareholder.shareholderGroupId;
    const authorizedPerson = shareholder.shareholderPersons.find((x) => x.authorized);
    const label = isGroup
      ? `${shareholdersGroupName}${
          authorizedPerson
            ? `, ${authorizedPerson.personName} ${authorizedPersonText.toLocaleLowerCase()}`
            : ''
        }`
      : shareholder.shareholderPersons[0].personName;

    return {
      key: shareholder.shareholderId,
      fill: pieChartFill(
        shareholder.shareholderPersons[0].personType,
        shareholder.votePercentage,
        isGroup
      ),
      stroke: pieChartBorder(
        shareholder.shareholderPersons[0].personType,
        shareholder.votePercentage,
        isGroup
      ),
      shareholderName: `${
        shareholder.votePercentageLessThanPermitted ? '< ' : ''
      }${getPieChartLabel(shareholder.votePercentage, label, notProvidedText)}`,
      percentage: shareholder.votePercentage
    };
  });

  // without percentage
  const percentagePart = (100 - totalPercentages) / shareholdersWithoutPercentages.length;
  const votesWithoutPercentageData = shareholdersWithoutPercentages.map((shareholder) => {
    const isGroup = !!shareholder.shareholderGroupId;
    const isVotesOnRules = !!shareholder.votesOnRules;
    const authorizedPerson = shareholder.shareholderPersons.find((x) => x.authorized);
    const label = isGroup
      ? `${shareholdersGroupName}${
          authorizedPerson
            ? `, ${authorizedPerson.personName} ${authorizedPersonText.toLocaleLowerCase()}`
            : ''
        }`
      : shareholder.shareholderPersons[0].personName;

    return {
      key: shareholder.shareholderId,
      fill: isVotesOnRules
        ? pieChartFill(shareholder.shareholderPersons[0].personType, percentagePart, isGroup)
        : 'transparent',
      stroke: isVotesOnRules
        ? pieChartBorder(shareholder.shareholderPersons[0].personType, percentagePart, isGroup)
        : groupBoxColor,
      shareholderName: getPieChartLabel(
        null,
        label,
        isVotesOnRules ? votesOnRulesText : notProvidedText
      ),
      percentage: percentagePart
    };
  });

  // kai yra nežinomų dalyvių dalis
  const fillTheGap = [
    {
      key: parseInt(`${shareholders[0]?.shareholderId}${100 - totalPercentages}`, 10),
      fill: 'transparent',
      stroke: '#000000',
      shareholderName: getPieChartLabel(100 - totalPercentages, notProvidedText, notProvidedText),
      percentage: 100 - totalPercentages
    }
  ];

  const dataToPieChart = () => {
    if (shareholders.length > 0 && totalPercentages < 100) {
      return [
        ...votesWithPercentageData,
        ...(votesWithoutPercentageData.length ? votesWithoutPercentageData : fillTheGap)
      ];
    }
    return votesWithPercentageData;
  };

  return dataToPieChart();
};
