import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { PieChartData } from '../../../pages/VirsTreePage/treeState/treeTypes';
import { PersonChart } from '../VirsTreePreviewCards/PersonCard/PersonChart';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  virsName: string;
  sharesData: PieChartData[];
  votesData: PieChartData[];
  shareTypeName: string;
  displaySharePieChart: boolean;
  displayVotesPieChart: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      position: 'absolute',
      height: '100%',
      paddingTop: 140,
      zIndex: 10,
      background: 'white',
      right: 0,
      boxShadow: 'rgba(0, 0, 0, 0.2) -2px -10px 5px 5px',
      flexDirection: 'column',
      maxWidth: '100%',
      overflow: 'auto'
    },
    chartsWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '0 20px 20px',
      '& .VictoryContainer svg g': {
        transform: 'translate(4%, 10px)'
      }
    },
    iconButton: {
      padding: 0,
      width: 40,
      height: 40
    },
    listWrapper: {
      margin: '10px 0 30px 0'
    }
  })
);

export const ShareholderDiagramCardClass = 'diagram-charts';

export const ShareholderDiagramCard: React.FC<Props> = ({
  isOpen,
  onClose,
  sharesData,
  votesData,
  virsName,
  shareTypeName,
  displaySharePieChart,
  displayVotesPieChart
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dialogStyles: React.CSSProperties = {
    display: isOpen ? 'flex' : 'none'
  };

  const chartWrapStyles: React.CSSProperties = {
    display: 'flex',
    flexFlow: 'column'
  };

  return (
    <div className={classes.dialog} style={dialogStyles}>
      <IconButton onClick={onClose} className={classes.iconButton}>
        <CloseIcon />
      </IconButton>

      <div className={`${classes.chartsWrap} ${ShareholderDiagramCardClass}`}>
        {displaySharePieChart && (
          <PersonChart
            wrapStyles={chartWrapStyles}
            title={shareTypeName}
            chartData={sharesData}
            parentName={virsName}
            height={250}
            ariaLabel={t('Contribution share chart')}
          />
        )}

        {displayVotesPieChart && (
          <PersonChart
            wrapStyles={chartWrapStyles}
            title={t('Votes part')}
            chartData={votesData}
            parentName={virsName}
            height={250}
            ariaLabel={t('Votes diagram')}
          />
        )}
      </div>
    </div>
  );
};
