import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { VirsTreePreviewCards } from './VirsTreePreviewCards/VirsTreePreviewCards';
import {
  NavigationBar,
  ShareholderPreviewCardSubNavTab
} from './VirsTreePreviewCards/ShareholderPreviewCardNavigation/NavigationBar';
import { EditionCheckSection } from './VirsTreePreviewCards/VirsCard/EditionCheckSection/EditionCheckSection';
import { EnterpriseSection } from './VirsTreePreviewCards/VirsCard/EnterpriseSection/EnterpriseSection';
import { EthicalNonComplianceSection } from './VirsTreePreviewCards/VirsCard/EthicalNonComplianceSection/EthicalNonComplianceSection';
import { FundsReceivedSection } from './VirsTreePreviewCards/VirsCard/FundsReceivedSection/FundsReceivedSection';
import { VirsInfoSection } from './VirsTreePreviewCards/VirsCard/InfoSection/VirsInfoSection';
import { PaymentSection } from './VirsTreePreviewCards/VirsCard/PaymentSection/PaymentSection';
import { ProfessionalMisconductSection } from './VirsTreePreviewCards/VirsCard/ProfessionalMisconductSection/ProfessionalMisconductSection';
import { LicensesSection } from './VirsTreePreviewCards/VirsCard/LicensesSection/LicensesSection';
import { PoliticalAdFinancialSupportSection } from './VirsTreePreviewCards/VirsCard/PoliticalAdFinancialSupportSection/PoliticalAdFinancialSupportSection';
import { LegalEntityFinancialSupportSection } from './VirsTreePreviewCards/VirsCard/LegalEntityFinancialSupportSection/LegalEntityFinancialSupportSection';
import { IndividualPersonFinancialSupportSection } from './VirsTreePreviewCards/VirsCard/IndividualPersonFinancialSupportSection/IndividualPersonFinancialSupportSection';
import { AnnualFinancialReportsSection } from './VirsTreePreviewCards/VirsCard/AnnualFinancialReportsSection/AnnualFinancialReportsSection';
import { OutletsSection } from './VirsTreePreviewCards/VirsCard/OutletsSection/OutletsSection';
import {
  fetchVirsAnnualFinancialReportsData,
  fetchVirsEditionCheckData,
  fetchVirsEnterprseData,
  fetchVirsEthicalNonComplianceData,
  fetchVirsFundsReceivedData,
  fetchVirsIndividualPersonFinancialSupportData,
  fetchVirsInfoData,
  fetchVirsLegalEntityFinancialSupportData,
  fetchVirsLicensesData,
  fetchVirsOutletsData,
  fetchVirsPaymentData,
  fetchVirsPoliticalAdFinancialSupportData,
  fetchVirsProfessionalMisconductData
} from '../../store/virsTreeVirsInfo/virsInfoActions';
import { ApplicationState } from '../../store';
import { GenericDataTableContainer } from '../../components/GenericDataTable/GenericDataTableContainer';
import { fetchGenericTableDataRequest } from '../../store/genericDataTable/genericDataTableAction';
import { useVirsTreeDispatch } from '../../pages/VirsTreePage/VirsTreePageContext';
import {
  getActiveAndSortedTablesByType,
  mapIntoActiveTabs,
  insertDefaultVirsInfoTab,
  openTabByKeyname
} from '../../pages/VirsTreePage/treeState/treeFunctions';

interface Props {
  virsId: number;
  virsName: string;
  preopenVirsCardTab?: string;
}

export const VirsPreviewContainer: React.FC<Props> = ({ virsId, virsName, preopenVirsCardTab }) => {
  const {
    virsInfoData: { virsInfoData, loadingVirsInfoData },
    graphicalRepresentationToolData: { graphicalRepresentationToolData },
    chartData: {
      timeline: { activeDate }
    },
    virsis: { language }
  } = useSelector((state: ApplicationState) => state);

  const { treeDispatch } = useVirsTreeDispatch();

  const { t } = useTranslation();

  const activeVirsTables = getActiveAndSortedTablesByType(
    graphicalRepresentationToolData || [],
    'VIRS'
  );

  const activeTabs = mapIntoActiveTabs(activeVirsTables, language);

  const activeTabsWithVirsInfoTab = insertDefaultVirsInfoTab(
    activeTabs,
    t('VIRS default tab name')
  );

  const withPreopenedTab = openTabByKeyname(
    activeTabsWithVirsInfoTab,
    preopenVirsCardTab || 'virsInfo'
  );

  const [tabs, setTabs] = useState<ShareholderPreviewCardSubNavTab[]>(withPreopenedTab);
  const reduxDispatch = useDispatch();

  function handleTabClick(tabKey: string) {
    setTabs(
      tabs.map((tab) => (tab.key === tabKey ? { ...tab, open: true } : { ...tab, open: false }))
    );
  }

  const openTab = tabs.find((tab) => tab.open);

  useEffect(() => {
    if (openTab) {
      if (openTab.key === 'virsInfo' && virsInfoData?.virsId !== virsId) {
        reduxDispatch(fetchVirsInfoData(virsId));
      } else if (openTab.key === 'enterpriseTypes') {
        reduxDispatch(fetchVirsEnterprseData(virsId));
      } else if (openTab.key === 'outlets') {
        reduxDispatch(fetchVirsOutletsData(virsId));
      } else if (openTab.key === 'professionalMisconducts') {
        reduxDispatch(fetchVirsProfessionalMisconductData(virsId));
      } else if (openTab.key === 'ethicalNonCompliance') {
        reduxDispatch(fetchVirsEthicalNonComplianceData(virsId));
      } else if (openTab.key === 'receivedFunds') {
        reduxDispatch(fetchVirsFundsReceivedData(virsId));
      } else if (openTab.key === 'editionChecks') {
        reduxDispatch(fetchVirsEditionCheckData(virsId));
      } else if (openTab.key === 'payments') {
        reduxDispatch(fetchVirsPaymentData(virsId));
      } else if (openTab.key === 'licences') {
        reduxDispatch(fetchVirsLicensesData(virsId));
      } else if (openTab.key === 'politicalAdFunds') {
        reduxDispatch(fetchVirsPoliticalAdFinancialSupportData(virsId));
      } else if (openTab.key === 'legalPersonSupport') {
        reduxDispatch(fetchVirsLegalEntityFinancialSupportData(virsId));
      } else if (openTab.key === 'naturalPersonSupport') {
        reduxDispatch(fetchVirsIndividualPersonFinancialSupportData(virsId));
      } else if (openTab.key === 'annualFinancial') {
        reduxDispatch(fetchVirsAnnualFinancialReportsData(virsId));
      } else if (openTab.url !== '/' && openTab.url) {
        reduxDispatch(fetchGenericTableDataRequest(`${openTab.url}/${virsId}`));
      }
    }
  }, [virsInfoData, openTab, virsId]);

  const handleOpenOutletCard = (virsIdForOutlet: number, outletId: number, outletName: string) => {
    treeDispatch({
      type: 'OPEN_OUTLET_PREVIEW_CARD',
      virsId: virsIdForOutlet,
      outletId,
      outletName
    });
  };

  const handleCloseVirsCard = () => {
    treeDispatch({
      type: 'CLOSE_VIRS_PREVIEW_CARD'
    });
  };

  const onClickOutletPrefilled = (outletId: number, outletName: string) => {
    handleOpenOutletCard(virsId, outletId, outletName);
  };

  const chosenDate = activeDate.format('L');

  function selectedSection() {
    if (!openTab || !openTab.url) return null;
    switch (openTab.key) {
      case 'virsInfo':
        return <VirsInfoSection />;
      case 'enterpriseTypes':
        return <EnterpriseSection chosenDate={chosenDate} />;
      case 'outlets':
        return <OutletsSection chosenDate={chosenDate} onClickOutlet={onClickOutletPrefilled} />;
      case 'professionalMisconducts':
        return (
          <ProfessionalMisconductSection
            chosenDate={chosenDate}
            onClickOutlet={onClickOutletPrefilled}
          />
        );
      case 'ethicalNonCompliance':
        return <EthicalNonComplianceSection onClickOutlet={onClickOutletPrefilled} />;
      case 'receivedFunds':
        return (
          <FundsReceivedSection chosenDate={chosenDate} onClickOutlet={onClickOutletPrefilled} />
        );
      case 'editionChecks':
        return <EditionCheckSection onClickOutlet={onClickOutletPrefilled} />;
      case 'payments':
        return <PaymentSection onClickOutlet={onClickOutletPrefilled} />;
      case 'licences':
        return <LicensesSection chosenDate={chosenDate} />;
      case 'politicalAdFunds':
        return <PoliticalAdFinancialSupportSection chosenDate={chosenDate} />;
      case 'legalPersonSupport':
        return <LegalEntityFinancialSupportSection chosenDate={chosenDate} />;
      case 'naturalPersonSupport':
        return <IndividualPersonFinancialSupportSection chosenDate={chosenDate} />;
      case 'annualFinancial':
        return <AnnualFinancialReportsSection />;
      default:
        return <GenericDataTableContainer chosenDate={chosenDate} url={openTab.url} />;
    }
  }

  return (
    <VirsTreePreviewCards
      isOpen
      onClose={handleCloseVirsCard}
      title={virsName}
      subTitle={t('VIRS')}
      reduceHeightBy="60px"
    >
      {loadingVirsInfoData ? (
        <CircularProgress style={{ height: 60, width: 60, margin: 'auto' }} />
      ) : (
        <>
          {virsInfoData === null ? (
            <div style={{ padding: '10px 0 200px 0', marginTop: 10 }}>
              <Typography variant="body2">{`${virsName} neegzistuoja kaip VIRS arba neturi jokių duomenų.`}</Typography>
            </div>
          ) : (
            <>
              <NavigationBar tabs={tabs} onTabClick={handleTabClick} />
              {selectedSection()}
            </>
          )}
        </>
      )}
    </VirsTreePreviewCards>
  );
};
