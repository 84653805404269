import React from 'react';
import { useTranslation } from 'react-i18next';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import { ColumnHeader } from '../../../../../utils/tableTypes';
import {
  useVirsAnnualFinancialReportsTableDispatch,
  useVirsAnnualFinancialReportsTableState
} from './AnnualFinancialReportsSection';
import { columnNames } from './tableState/tableColumns';
import { VirsAnnualFinancialReportsColumnHeaderField } from './tableState/tableTypes';

export const AnnualFinancialReportsCustomizationBar: React.FC = () => {
  const { state } = useVirsAnnualFinancialReportsTableState();
  const { dispatch } = useVirsAnnualFinancialReportsTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' });
  };

  const handleSetSortByColumn = (column: VirsAnnualFinancialReportsColumnHeaderField) => {
    dispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const toggleDisplayColumn = (column: VirsAnnualFinancialReportsColumnHeaderField) => {
    dispatch({ type: 'TOGGLE_DISPLAY_COLUMN', column });
  };

  function getVirsFinancialReportsColumnsWithTranslation(): ColumnHeader<VirsAnnualFinancialReportsColumnHeaderField>[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`VIRS financial report ${col.id}`)
    }));
  }

  const translatedColumns = getVirsFinancialReportsColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
