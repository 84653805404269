import React from 'react';
import FilterButton from '../TableButtons/FilterButton';

interface Props {
  onFilterTabToggle: () => void;
  showFilter: boolean;
  disabled?: boolean;
}

const FilterTool: React.FC<Props> = ({ onFilterTabToggle, showFilter, disabled }) => {
  return (
    <FilterButton
      onFilterTabToggle={onFilterTabToggle}
      showFilter={showFilter}
      disabled={disabled}
    />
  );
};
export default FilterTool;
