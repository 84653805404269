import React, { useEffect, useState } from 'react';
import { makeStyles, Menu, MenuItem, Tab, TabProps } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { MenuData } from '../../store/virsisApp/virsisDataTypes';

type PropsType = RouteComponentProps & TabProps;

interface Props {
  onClick: () => void;
  subMenu: MenuData[];
}

type SomeProps = PropsType & Props;

const useStyles = makeStyles((theme) => ({
  headerMenu: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    borderRadius: 0
  }
}));

const TabMenu: React.FC<SomeProps> = (props) => {
  const classes = useStyles();

  const { subMenu, history, ...tabProps } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onTabClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!subMenu.length) {
      if (tabProps.value.indexOf('http') === 0) {
        window.open(tabProps.value, '_blank');
      } else {
        history.push('/');
        history.push(tabProps.value);
      }
    }
    if (subMenu.length) {
      setAnchorEl(event.currentTarget);
    }
    props.onClick();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: string) => {
    handleMenuClose();
    if (item.indexOf('http') === 0) {
      window.open(item, '_blank');
    } else {
      history.push(`/${item}`);
    }
  };

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);

  return (
    <>
      <Tab {...tabProps} onClick={onTabClick} disableRipple />
      <Menu
        id="navigation-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        elevation={0}
        classes={{ paper: classes.headerMenu }}
      >
        {subMenu?.map((i) => (
          <MenuItem key={i.tid} onClick={() => handleMenuItemClick(i.fieldUrl)}>
            {i.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default withRouter(TabMenu);
