import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { Order } from '../../../../../../utils/tableTypes';

export interface VirsLicensesDataTableState {
  sortBy: VirsLicensesColumnHeaderField;
  order: Order;
  page: number;
  recordsPassed: number;
  pagesCount: number;
  rowsPerPage: number;
  columnsDisplayStatus: VirsLicensesColumnsDisplayStatus;
  customFilterOn: boolean;
  displayFilter: filterOptions;
  customFilter: VirsLicensesTableCustomFilter;
}

export interface VirsLicensesColumnHeader {
  id: VirsLicensesColumnHeaderField;
  numeric: boolean;
  label: string;
}

export type VirsLicensesColumnHeaderField =
  | 'licenseNumber'
  | 'licenseStatus'
  | 'licenseIssueDate'
  | 'licenseTypeName'
  | 'enterpriseTypeName'
  | 'validFrom'
  | 'validTo'
  | 'issuingInstitutionName'
  | 'licenseNotes';

export type VirsLicensesColumnsDisplayStatus = {
  licenseNumber: boolean;
  licenseIssueDate: boolean;
  licenseTypeName: boolean;
  enterpriseTypeName: boolean;
  licenseStatus: boolean;
  validFrom: boolean;
  validTo: boolean;
  issuingInstitutionName: boolean;
  licenseNotes: boolean;
};
export type VirsLicensesTableCustomFilter = {
  licenseNumber: number[];
  licenseIssueDate: Date[];
  licenseTypeName: string[];
  enterpriseTypeName: string[];
  licenseStatus: string[];
  validFrom: Date[];
  validTo: Date[];
  issuingInstitutionName: string[];
  licenseNotes: string[];
};

export type VirsLicensesTableAction =
  | { type: 'SET_SORT_BY_COLUMN'; sortBy: VirsLicensesColumnHeaderField }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: number }
  | { type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' }
  | {
      type: 'SET_DISPLAY_FILTER';
      value: filterOptions;
    }
  | {
      type: 'SET_CUSTOM_FILTER';
      filterBy: VirsLicensesColumnHeaderField;
      value: string | null;
    }
  | {
      type: 'TOGGLE_DISPLAY_COLUMN';
      column: VirsLicensesColumnHeaderField;
    };

export const initialVirsLicensesDataTableState: VirsLicensesDataTableState = {
  order: 'desc',
  sortBy: 'licenseIssueDate',
  displayFilter: 'allItems',
  page: 0,
  recordsPassed: 0,
  pagesCount: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    licenseIssueDate: true,
    licenseNumber: true,
    licenseTypeName: true,
    enterpriseTypeName: true,
    licenseStatus: true,
    validFrom: true,
    validTo: true,
    issuingInstitutionName: true,
    licenseNotes: true
  },
  customFilterOn: false,
  customFilter: {
    licenseIssueDate: [],
    licenseNumber: [],
    licenseTypeName: [],
    enterpriseTypeName: [],
    licenseStatus: [],
    validFrom: [],
    validTo: [],
    issuingInstitutionName: [],
    licenseNotes: []
  }
};
