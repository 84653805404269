import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderVirsLicensesData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../store/virsisApp/virsisDataTypes';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import DisplayFilterTool from '../../../../../components/TableDisplayFilter/DisplayFilterTool';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import { useVirsLicensesTableDispatch, useVirsLicensesTableState } from './LicensesSection';
import { columnNames } from './tableState/tableColumns';
import { VirsLicensesColumnHeader, VirsLicensesColumnHeaderField } from './tableState/tableTypes';

interface Props {
  data: ShareholderVirsLicensesData[];
}

const LicensesCustomizationBar: React.FC<Props> = ({ data }) => {
  const { state } = useVirsLicensesTableState();
  const { dispatch } = useVirsLicensesTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' });
  };

  const handleSetSortByColumn = (column: VirsLicensesColumnHeaderField) => {
    dispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const toggleDisplayColumn = (column: VirsLicensesColumnHeaderField) => {
    dispatch({ type: 'TOGGLE_DISPLAY_COLUMN', column });
  };

  const handleSetDisplayFilter = (value: filterOptions) => {
    dispatch({ type: 'SET_DISPLAY_FILTER', value });
  };

  function getVirsLicencesColumnsWithTranslation(): VirsLicensesColumnHeader[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`VIRS licence ${col.id}`)
    }));
  }

  const translatedColumns = getVirsLicencesColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <DisplayFilterTool
            disabled={data.length === 0}
            displayFilter={state.displayFilter}
            setDisplayFilter={handleSetDisplayFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default LicensesCustomizationBar;
