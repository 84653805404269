import React, { memo, useCallback, useEffect } from 'react';
import { Elements, useZoomPanHelper } from 'react-flow-renderer';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import theme from '../../../style/virsisTheme';
import { ExpandTreeIcon } from '../SvgIconsAsComponents/ExpandTreeIcon';
import { CollapseTreeIcon } from '../SvgIconsAsComponents/CollapseTreeIcon';
import { FitView } from '../SvgIconsAsComponents/FitView';
import {
  StateShareholder,
  KeyNumberValueBoolean
} from '../../../pages/VirsTreePage/treeState/treeTypes';
import {
  maximumShareholderLevel,
  objectOfLevelsFullyShown
} from '../../../pages/VirsTreePage/treeState/treeFunctions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  toolsBtn: {
    background: '#ffffff',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px 0',
    color: theme.palette.primary.main,
    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: '#DBEDFC',
      cursor: 'pointer'
    }
  },
  container: {
    display: 'flex',
    paddingLeft: '40px',
    position: 'relative'
  },
  tools: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 5,
    position: 'absolute',
    left: '0px'
  }
});

export interface ControlProps extends React.HTMLAttributes<HTMLDivElement> {
  showZoom?: boolean;
  showFitView?: boolean;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onFitView?: () => void;
  expandTree: () => void;
  collapseTree: () => void;
  setLevel: (showLevel: number) => void;
  showingElements: Elements;
  showingShareholders: StateShareholder[];
  allShareholders: StateShareholder[];
  activeDate: string;
}

const TreeChartControls = ({
  style,
  showZoom = true,
  showFitView = true,
  onZoomIn,
  onZoomOut,
  onFitView,
  expandTree,
  collapseTree,
  setLevel,
  showingElements,
  showingShareholders,
  allShareholders,
  activeDate
}: ControlProps) => {
  const { t } = useTranslation();
  const { zoomIn, zoomOut, fitView } = useZoomPanHelper();

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    fitView?.();
    // eslint-disable-next-line no-unused-expressions
    onFitView?.();
  }, [fitView, onFitView, activeDate]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    onFitView?.();
    // eslint-disable-next-line
  }, [showingElements]);

  const onZoomInHandler = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    zoomIn?.();
    // eslint-disable-next-line no-unused-expressions
    onZoomIn?.();
  }, [zoomIn, onZoomIn]);

  const onZoomOutHandler = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    zoomOut?.();
    // eslint-disable-next-line no-unused-expressions
    onZoomOut?.();
  }, [zoomOut, onZoomOut]);

  const onFitViewHandler = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    fitView?.();
    // eslint-disable-next-line no-unused-expressions
    onFitView?.();
  }, [fitView, onFitView]);

  const expandTreeTreeHandler = () => {
    expandTree();
    setTimeout(() => onFitViewHandler(), 100);
  };

  const collapseTreeHandler = () => {
    collapseTree();
    setTimeout(() => onFitViewHandler(), 100);
  };

  const setLevelHandler = (lvl: number) => () => {
    setLevel(lvl);
    setTimeout(() => onFitViewHandler(), 100);
  };

  const classes = useStyles();

  const fullyShownLevels: KeyNumberValueBoolean = objectOfLevelsFullyShown(
    allShareholders,
    showingShareholders
  );

  const clickedStyleIfFullyShown = (lvl: number) =>
    fullyShownLevels[lvl] ? { backgroundColor: theme.palette.secondary.main } : undefined;

  const maxLevel: number = maximumShareholderLevel(allShareholders);

  const allShareholderLevels: number[] = Array.from({ length: maxLevel }, (_, i) => i + 1);

  return (
    <div className="react-flow__controls" style={style}>
      <>
        {showFitView && (
          <Tooltip title={t<string>('Fit to screen')} placement="right" arrow>
            <Button className={classes.toolsBtn} onClick={onFitViewHandler}>
              <FitView />
            </Button>
          </Tooltip>
        )}
        {showZoom && (
          <>
            <Tooltip title={t<string>('Increase')} placement="right" arrow>
              <Button className={classes.toolsBtn} onClick={onZoomInHandler}>
                <ZoomInIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t<string>('Reduce')} placement="right" arrow>
              <Button className={classes.toolsBtn} onClick={onZoomOutHandler}>
                <ZoomOutIcon />
              </Button>
            </Tooltip>
          </>
        )}
        <Tooltip title={t<string>('Spread')} placement="right" arrow>
          <Button className={classes.toolsBtn} onClick={expandTreeTreeHandler}>
            <ExpandTreeIcon />
          </Button>
        </Tooltip>
        <Tooltip title={t<string>('Collapse')} placement="right" arrow>
          <Button className={classes.toolsBtn} onClick={collapseTreeHandler}>
            <CollapseTreeIcon />
          </Button>
        </Tooltip>
        {allShareholderLevels.map((level) => (
          <Tooltip
            key={level}
            title={t<string>('Expand to specified level')}
            placement="right"
            arrow
          >
            <Button
              onClick={setLevelHandler(level)}
              className={classes.toolsBtn}
              style={clickedStyleIfFullyShown(level)}
            >
              {level}
            </Button>
          </Tooltip>
        ))}
      </>
    </div>
  );
};

TreeChartControls.displayName = 'Controls';

export default memo(TreeChartControls);
