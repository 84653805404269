import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderVirsOutletData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsOutletsTableState } from './OutletsSection';
import OutletNameButton from '../../../../../components/TableButtons/OutletNameButton';

interface Props {
  record: ShareholderVirsOutletData;
  onClickOutlet: (outletId: number, outletName: string) => void;
}

export const OutletsRow: React.FC<Props> = ({ record, onClickOutlet }) => {
  const { state: tableState } = useVirsOutletsTableState();
  return (
    <TableRow key={record.outletId}>
      {tableState.columnsDisplayStatus.outletName && (
        <TableCell>
          <OutletNameButton
            onClicked={() => {
              onClickOutlet(record.outletId, record.outletName);
            }}
            text={record.outletName}
          />
        </TableCell>
      )}
      {tableState.columnsDisplayStatus.outletTypeName && (
        <TableCell>{record.outletTypeName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.outletGroupName && (
        <TableCell>{record.outletGroupName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.establishedDate && (
        <TableCell>{record.establishedDate}</TableCell>
      )}
      {tableState.columnsDisplayStatus.controlledFrom && (
        <TableCell>{record.controlledFrom}</TableCell>
      )}
      {tableState.columnsDisplayStatus.controlledTo && <TableCell>{record.controlledTo}</TableCell>}
      {tableState.columnsDisplayStatus.internationalNumber && (
        <TableCell>{record.internationalNumber}</TableCell>
      )}
    </TableRow>
  );
};
