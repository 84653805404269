import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import theme from '../../style/virsisTheme';

const placeholderText = 'Nesiejama su VIP';

const useStyles = makeStyles({
  linkButton: {
    display: 'block',
    fontFamily: 'Open Sans',
    fontSize: '0.85rem',
    color: theme.palette.primary.main,
    textTransform: 'none',
    padding: '0px',
    margin: '0px',
    height: 'auto',
    fontWeight: 600,
    textAlign: 'start',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    }
  }
});

interface ButtonProps {
  text: string;
  onClicked?: () => void;
}

const OutletNameButton: React.FC<ButtonProps> = ({ text, onClicked }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      className={classes.linkButton}
      style={{
        color: text !== placeholderText ? theme.palette.primary.main : theme.palette.text.primary
      }}
      disabled={text === placeholderText}
      onClick={text !== placeholderText ? onClicked : undefined}
    >
      {text === placeholderText ? t('VIP not assigned') : text}
    </Button>
  );
};
export default OutletNameButton;
