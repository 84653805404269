import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../store';
import { PersonSearchForm } from './PersonSearchForm';
import { PersonSearchResults } from './PersonSearchResults';
import { NaturalPersonSearchQuery } from '../../store/virsSearch/virsSearchTypes';
import { resetSearchState, searchByPersonRequest } from '../../store/virsSearch/virsSearchActions';

const PersonSearch: React.FC = () => {
  const { loadingPersonSearchResults, personSearchResults, personSearchResultsError } = useSelector(
    (state: ApplicationState) => state.virsSearchData
  );
  const { t } = useTranslation();
  const [querySize, setQuerySize] = useState<number>(0);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(resetSearchState());
  }, [reduxDispatch]);

  function searchForVirsis(personQuery: NaturalPersonSearchQuery) {
    reduxDispatch(resetSearchState());
    reduxDispatch(searchByPersonRequest(personQuery));
  }

  return (
    <Container disableGutters maxWidth="lg">
      <PersonSearchForm
        setQuerySize={setQuerySize}
        searchVirs={searchForVirsis}
        isSearching={loadingPersonSearchResults}
      />
      <Grid container item direction="column" justify="flex-end" alignItems="flex-start">
        {personSearchResultsError?.message && (
          <Typography variant={'h5'} style={{ margin: '1rem 0' }}>{`${t('Responded with error')}. ${
            personSearchResultsError.message
          }`}</Typography>
        )}
        {!loadingPersonSearchResults && personSearchResults === null && querySize > 0 && (
          <div style={{ margin: '1rem 0' }}>
            <Typography variant="h5">{t('No VIRS found by query')}.</Typography>
            <Typography>
              {querySize > 0 && `${t('No VIRS found natural person advice')}.`}
            </Typography>
          </div>
        )}
        {personSearchResults && personSearchResults.length > 0 && (
          <PersonSearchResults personSearchResults={personSearchResults} />
        )}
      </Grid>
    </Container>
  );
};

export default PersonSearch;
