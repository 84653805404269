import React, { ReactNode, useEffect } from 'react';
import moment from 'moment';
import { Box, CircularProgress, ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import virsisTheme from '../style/virsisTheme';
import VirsisHeader from '../components/VirsisHeader/VirsisHeader';
import { ApplicationState } from '../store';
import CmsPage from './CmsPage';
import { ReportsPage } from './ReportsPage';
import NotFoundPage from './NotFoundPage';
import MessageDialog from '../components/MessageDialog/MessageDialog';
import VirsisSearchPage from './VirsSearchPage';
import { getMenuDataRequest } from '../store/virsisApp/virsisActions';
import { VirsPageWithContext } from './VirsTreePage/VirsTreePageContext';

const MainRoute: React.FC = () => {
  const dispatch = useDispatch();
  const { menuData, footerData, getMenuDataLoading, language } = useSelector(
    (state: ApplicationState) => state.virsis
  );

  const filteredMenuData = menuData?.filter(({ subMenu }) => subMenu.length === 0);

  useEffect(() => {
    dispatch(getMenuDataRequest());
    moment.locale(language);
  }, [language, dispatch]);

  const route = (fieldId: string, url: string, node: ReactNode) => (
    <Route key={fieldId} exact path={`/${url}`}>
      {node}
    </Route>
  );

  return (
    <ThemeProvider theme={virsisTheme}>
      <SnackbarProvider autoHideDuration={5000} maxSnack={4}>
        {getMenuDataLoading || menuData === undefined ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
            <CircularProgress style={{ width: 80, height: 80 }} />
          </Box>
        ) : (
          <Router>
            <MessageDialog />

            <VirsisHeader />
            <div style={{ flex: '1 0 auto' }}>
              <Switch>
                {filteredMenuData?.map((data) => {
                  const { fieldId, fieldUrl, fieldCmsLink } = data;

                  if (fieldUrl === 'rinkiniai' || fieldUrl === 'data-sets') {
                    return route(fieldId, fieldUrl, <ReportsPage />);
                  }

                  if (fieldUrl === 'paieska' || fieldUrl === 'virs-search') {
                    return route(fieldId, fieldUrl, <VirsisSearchPage />);
                  }

                  return route(fieldId, fieldUrl, <CmsPage key={fieldId} cmsPath={fieldCmsLink} />);
                })}

                <Route
                  path="/node/:nodeId"
                  render={(props) => <CmsPage cmsPath={props.location.pathname} />}
                />

                <Route
                  path="/naujienos/:nodeId"
                  render={(props) => <CmsPage cmsPath={props.location.pathname} />}
                />

                <Route
                  path="/news/:nodeId"
                  render={(props) => <CmsPage cmsPath={props.location.pathname} />}
                />

                <Route path="/virs/:virsParam/:versionId" render={() => <VirsPageWithContext />} />

                <Route path="/virs/:virsParam" render={() => <VirsPageWithContext />} />

                <Route
                  path="/search/:p"
                  render={(props) => <CmsPage cmsPath={`${props.location.pathname}`} />}
                />

                <Route component={NotFoundPage} />
              </Switch>
            </div>

            {menuData && footerData?.fieldCmsLink && (
              <CmsPage key={footerData?.fieldId} cmsPath={footerData?.fieldCmsLink} />
            )}
          </Router>
        )}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default MainRoute;
