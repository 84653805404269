import { call, put, select, takeEvery, race, delay } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  fetchOutletTypesSuccess,
  loadingSearchByLegal,
  loadingSearchByPerson,
  loadingVirsSearchByOutletResults,
  searchByLegalDataError,
  searchByLegalDataSuccess,
  searchByLegalRequest,
  searchByOutletDataError,
  searchByOutletRequest,
  searchByOutletSuccess,
  searchByPersonDataError,
  searchByPersonDataSuccess,
  searchByPersonRequest
} from './virsSearchActions';
import {
  GET_OUTLET_TYPES_REQUEST,
  SEARCH_BY_LEGAL_REQUEST,
  SEARCH_BY_OUTLET_REQUEST,
  SEARCH_BY_PERSON_REQUEST
} from './virsSearchActionTypes';
import {
  getFindVirsByLegal,
  getFindVirsByOutlet,
  getFindVirsByPerson,
  getOutletTypes
} from './virsSearchApi';
import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import { language } from '../virsisApp/virsisSelectors';
import { Language } from '../../utils/tableTypes';

function* handleSearchByPersonSaga(action: ActionType<typeof searchByPersonRequest>) {
  try {
    const lang: Language = yield select(language);
    yield put(loadingSearchByPerson(true));

    const { response, timeout } = yield race({
      response: call(getFindVirsByPerson, action.payload, lang),
      timeout: delay(60000)
    });

    if (response) {
      yield put(searchByPersonDataSuccess(response.data));
    } else if (timeout) {
      const errorMessage =
        lang === 'lt'
          ? 'Per daug rezultatų, prašau patikslinti paiešką'
          : 'Too many results, please refine your search';
      yield put(sendMessage('error', errorMessage));
      yield put(searchByPersonDataError(errorMessage));
    }
  } catch (err) {
    yield put(searchByPersonDataError(err.response));
  }
}

function* handleSearchByLegalSaga(action: ActionType<typeof searchByLegalRequest>) {
  try {
    const lang: Language = yield select(language);
    yield put(loadingSearchByLegal(true));

    const { response, timeout } = yield race({
      response: call(getFindVirsByLegal, action.payload, lang),
      timeout: delay(60000)
    });

    if (response) {
      yield put(searchByLegalDataSuccess(response.data));
    } else if (timeout) {
      const errorMessage =
        lang === 'lt'
          ? 'Per daug rezultatų, prašau patikslinti paiešką'
          : 'Too many results, please refine your search';
      yield put(sendMessage('error', errorMessage));
      yield put(searchByLegalDataError(errorMessage));
    }
  } catch (err) {
    yield put(searchByLegalDataError(err.response));
  }
}

function* findVirsByOutletSaga(action: ActionType<typeof searchByOutletRequest>) {
  try {
    const lang: Language = yield select(language);
    yield put(loadingVirsSearchByOutletResults(true));

    const { response, timeout } = yield race({
      response: call(getFindVirsByOutlet, action.payload, lang),
      timeout: delay(60000)
    });

    if (response) {
      yield put(searchByOutletSuccess(response.data));
    } else if (timeout) {
      const errorMessage =
        lang === 'lt'
          ? 'Per daug rezultatų, prašau patikslinti paiešką'
          : 'Too many results, please refine your search';
      yield put(sendMessage('error', errorMessage));
      yield put(searchByOutletDataError(errorMessage));
    }
  } catch (e) {
    yield put(searchByOutletDataError(e.payload));
  }
}

function* fetchOutletTypesSaga() {
  try {
    const lang = yield select(language);
    const { data } = yield call(getOutletTypes, lang);
    yield put(fetchOutletTypesSuccess(data));
  } catch (err) {
    yield put(sendMessage('error', err?.response?.message || 'Įvyko klaida gaunant VIP rūšis'));
  }
}

function* virsSearchSaga() {
  yield takeEvery(SEARCH_BY_PERSON_REQUEST, handleSearchByPersonSaga);
  yield takeEvery(SEARCH_BY_LEGAL_REQUEST, handleSearchByLegalSaga);
  yield takeEvery(SEARCH_BY_OUTLET_REQUEST, findVirsByOutletSaga);
  yield takeEvery(GET_OUTLET_TYPES_REQUEST, fetchOutletTypesSaga);
}

export default virsSearchSaga;
