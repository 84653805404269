import React from 'react';
import { Paper, Table, TableBody } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../utils/tableTypes';
import {
  useOutletAdPrintingTableDispatch,
  useOutletAdPrintingTableState
} from './AdPrintingSection';
import { filterAndSortTableData } from './tableState/tableStateFunctions';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import AdPrintingCustomFilter from './AdPrintingCustomFilter';
import { AdPrintingTableHead } from './AdPrintingTableHead';
import { OutletAdPrintingRow } from './AdPrintingRow';
import { AdPrintingTableCustomizationBar } from './AdPrintingTableCustomizationBar';

interface Props {
  chosenDate: string;
}

export const OutletAdPrintingTable: React.FC<Props> = ({ chosenDate }) => {
  const { state: tableState } = useOutletAdPrintingTableState();
  const { dispatch } = useOutletAdPrintingTableDispatch();

  const { outletInfo } = useSelector((state: ApplicationState) => state.outletInfoData);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'PAGE_SET', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'ROWS_PER_PAGE_SET',
      rowsPerPage
    });
  };

  const data = filterAndSortTableData(
    outletInfo?.advertisementPrintings || [],
    tableState,
    chosenDate
  );
  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <div className="data-table">
        <AdPrintingTableCustomizationBar data={outletInfo?.advertisementPrintings || []} />
        <Table>
          <AdPrintingTableHead />
          {tableState.customFilterOn && <AdPrintingCustomFilter />}
          <TableBody>
            {dataPage.map((record) => (
              <OutletAdPrintingRow key={record.advertisementId} record={record} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          recordsCount={data.length}
          pagesCount={pagesCount}
          rowsPerPage={tableState.rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={tableState.page}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          disabled={data.length === 0}
        />
      </div>
    </Paper>
  );
};
