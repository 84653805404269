import {
  initialVirsEditionCheckDataTableState,
  VirsEditionCheckDataTableAction,
  VirsEditionCheckDataTableState
} from './tableTypes';
import { getToggledSortOrderGeneric } from '../../../../../../utils/tableDataFunctions';
import { updateEditCheckDataCustomFilter } from './tableStateFunctions';

export const virsEditionCheckDataTableReducer = (
  state: VirsEditionCheckDataTableState,
  action: VirsEditionCheckDataTableAction
): VirsEditionCheckDataTableState => {
  switch (action.type) {
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialVirsEditionCheckDataTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateEditCheckDataCustomFilter(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };

    default:
      return state;
  }
};
