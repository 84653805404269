import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderOutletEditionData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { useOutletEditionTableState } from './EditionSection';

interface Props {
  record: ShareholderOutletEditionData;
}

export const OutletEditionRow: React.FC<Props> = ({ record }) => {
  const {
    state: { columnsDisplayStatus }
  } = useOutletEditionTableState();

  return (
    <TableRow key={record.editionId}>
      {columnsDisplayStatus.periodName && (
        <TableCell>{record.periodName || record.editionDate}</TableCell>
      )}
      {columnsDisplayStatus.circulation && <TableCell>{record.circulation} </TableCell>}
    </TableRow>
  );
};
