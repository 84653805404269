import { put, takeEvery, call } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  fetchGraphicalRepresentationToolDataError,
  fetchGraphicalRepresentationToolDataRequest,
  fetchGraphicalRepresentationToolDataSuccess
} from './graphicalRepresentationToolAction';
import { GET_GRAPHICAL_REPRESENTATION_DATA_REQUEST } from './graphicalRepresentationToolType';
import {
  getGraphicalRepresentationData,
  getGraphicalRepresentationDataByVersionID
} from './graphicalRepresentationToolApi';

function* handleFetchGraphicalRepresentationToolDataRequest(
  action: ActionType<typeof fetchGraphicalRepresentationToolDataRequest>
) {
  try {
    if (action.payload) {
      const { data } = yield call(getGraphicalRepresentationDataByVersionID, action.payload);
      yield put(fetchGraphicalRepresentationToolDataSuccess(data));
    } else {
      const { data } = yield call(getGraphicalRepresentationData);
      yield put(fetchGraphicalRepresentationToolDataSuccess(data));
    }
  } catch (err) {
    yield put(fetchGraphicalRepresentationToolDataError(err.response));
    yield put(sendMessage('error', err.response.data.message || 'Gauti duomenų nepavyko'));
  }
}

function* graphicalRepresentationDataSaga() {
  yield takeEvery(
    GET_GRAPHICAL_REPRESENTATION_DATA_REQUEST,
    handleFetchGraphicalRepresentationToolDataRequest
  );
}

export default graphicalRepresentationDataSaga;
