import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import lt from './translationLt.json';
import en from './translationEn.json';

const resources = {
  en: {
    translation: en
  },
  lt: {
    translation: lt
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'lt',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
