import { createStyles, makeStyles, MenuItem, Select, Typography, Theme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DisplayFilterField,
  DISPLAY_FILTER_HEADERS,
  filterOptions
} from '../../store/virsisApp/virsisDataTypes';

interface Props {
  disabled: boolean;
  displayFilter: filterOptions;
  setDisplayFilter: (value: filterOptions) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      height: 24
    },
    select: {
      width: 200
    }
  })
);

const DisplayFilterTool: React.FC<Props> = ({ disabled, displayFilter, setDisplayFilter }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  function getDisplayFilterHeadersWithTranslation(): DisplayFilterField[] {
    return DISPLAY_FILTER_HEADERS.map((header) => ({
      ...header,
      label: t(header.value)
    }));
  }

  const displayFilterHeaders = getDisplayFilterHeadersWithTranslation();

  const { value } = displayFilterHeaders.filter((header) => header.value === displayFilter)[0];

  return (
    <div
      className="display-filter-item"
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 15px'
      }}
    >
      <Typography variant="h6" style={{ color: '#637082', fontWeight: 600 }}>
        {`${t('Displaying data')}:`}
      </Typography>
      <Select
        labelId="display-filter-select-label"
        id="display-filter-select"
        value={value}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(event: any) => setDisplayFilter(event.target.value)}
        className={classes.margin}
        variant="outlined"
        disabled={disabled}
        classes={{
          select: classes.select
        }}
      >
        {displayFilterHeaders.map((header) => {
          return (
            <MenuItem key={header.value} value={header.value}>
              {header.label}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default DisplayFilterTool;
