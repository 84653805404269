import { Paper, Table, TableBody, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../store/virsisApp/virsisDataTypes';
import { pageTableData } from '../../../../../utils/tableDataFunctions';
import { TableLoader } from '../../../../../components/TableLoader/TableLoad';
import { TablePagination } from '../../../../../components/TablePagination/TablePagination';
import { IndividualPersonFinancialSupportCustomFilter } from './IndividualPersonFinancialSupportCustomFilter';
import { IndividualPersonFinancialSupportCustomizationBar } from './IndividualPersonFinancialSupportCustomizationBar';
import { IndividualPersonFinancialSupportHead } from './IndividualPersonFinancialSupportHead';
import { IndividualPersonFinancialSupportRow } from './IndividualPersonFinancialSupportRow';
import {
  useVirsIndividualPersonFinancialSupportTableDispatch,
  useVirsIndividualPersonFinancialSupportTableState
} from './IndividualPersonFinancialSupportSection';
import { filterAndSortIndividualPersonFinancialSupportDataVirs } from './tableState/tableFunctions';

interface Props {
  chosenDate: string;
}

export const VirsIndividualPersonFinancialSupportTable: React.FC<Props> = ({ chosenDate }) => {
  const { state: tableState } = useVirsIndividualPersonFinancialSupportTableState();
  const { dispatch } = useVirsIndividualPersonFinancialSupportTableDispatch();

  const {
    VirsIndividualPersonFinancialSupportData,
    loadingVirsIndividualPersonFinancialSupportData,
    virsIndividualPersonFinancialSupportError
  } = useSelector((state: ApplicationState) => state.virsInfoData);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'SET_PAGE', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'SET_ROWS_PER_PAGE',
      rowsPerPage
    });
  };

  const data = filterAndSortIndividualPersonFinancialSupportDataVirs(
    tableState,
    VirsIndividualPersonFinancialSupportData || [],
    chosenDate
  );

  const { page: dataPage, pagesCount } = pageTableData(tableState, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <>
        {virsIndividualPersonFinancialSupportError ? (
          <Typography variant="caption">{virsIndividualPersonFinancialSupportError}</Typography>
        ) : (
          <div className="data-table">
            <IndividualPersonFinancialSupportCustomizationBar
              data={VirsIndividualPersonFinancialSupportData || []}
            />
            <Table>
              <IndividualPersonFinancialSupportHead />
              {tableState.customFilterOn && <IndividualPersonFinancialSupportCustomFilter />}
              <TableBody>
                {loadingVirsIndividualPersonFinancialSupportData ? (
                  <TableLoader columns={5} />
                ) : (
                  <>
                    {dataPage.map((record) => (
                      <IndividualPersonFinancialSupportRow
                        key={record.financialSupportReceivedId}
                        record={record}
                      />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              recordsCount={data.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={handleSetPage}
              setRowsPerPage={handleSetRowsPerPage}
              disabled={data.length === 0}
            />
          </div>
        )}
      </>
    </Paper>
  );
};
