import { ColumnHeader, SortOrder } from '../../../utils/tableTypes';
import { getToggledSortOrderGeneric } from '../../../utils/tableDataFunctions';

export type FindVirsResultsTableAction =
  | {
      type: 'SORTING_CHANGED';
      sortBy: LegalSearchDataField;
    }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number };

export type LegalSearchDataField =
  | 'legalCode'
  | 'legalName'
  | 'virsResults'
  | 'virsRole'
  | 'virsRoleValidToDate';

export const initialLegalResultsTableState: LegalSearchResultsTableState = {
  order: 'desc',
  sortBy: 'legalCode',
  page: 0,
  rowsPerPage: 10
};

export type LegalSearchResultsTableColumnHeader = ColumnHeader<LegalSearchDataField>;

export interface LegalSearchResultsTableState {
  order: SortOrder;
  sortBy: LegalSearchDataField;
  page: number;
  rowsPerPage: number;
}

export const LegalSearchResultsTableReducer = (
  state: LegalSearchResultsTableState,
  action: FindVirsResultsTableAction
): LegalSearchResultsTableState => {
  switch (action.type) {
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    default:
      return state;
  }
};
