import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';

import { useTranslation } from 'react-i18next';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  useVirsEditionCheckTableDispatch,
  useVirsEditionCheckTableState
} from './EditionCheckSection';
import {
  VirsEditionCheckDataTableField,
  VirsEditionCheckDataTableState
} from './tableState/tableTypes';

export const EditionCheckTableHead: React.FC = () => {
  const { state: tableState } = useVirsEditionCheckTableState();
  const { dispatch: tableDispatch } = useVirsEditionCheckTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsEditionCheckDataTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsEditionCheckDataTableState,
    typeof handleSetSortByColumn,
    VirsEditionCheckDataTableField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {(tableState.columnsDisplayStatus.checkDate || tableState.columnsDisplayStatus.docNr) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.checkDate,
              tableState.columnsDisplayStatus.docNr
            ])}
            key="valid"
          >
            <Typography variant="h4">{t('VIRS edition check column')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.outletName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS edition check outletName')}
            columnName="outletName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.periodName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS edition check periodName')}
            columnName="periodName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.conclusion && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS edition check conclusion')}
            columnName="conclusion"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.checkedByInstitutionName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS edition check checkedByInstitutionName')}
            columnName="checkedByInstitutionName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.checkDate && (
          <TableSortingHeaderCell
            label={t('VIRS edition check checkDate')}
            columnName="checkDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.docNr && (
          <TableSortingHeaderCell
            label={t('VIRS edition check docNr')}
            columnName="docNr"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
