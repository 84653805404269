import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { VirsPaymentDataHeaderField } from './tableState/tableTypes';
import { useVirsPaymentTableDispatch, useVirsPaymentTableState } from './PaymentSection';
import { ApplicationState } from '../../../../../store';
import { ShareholderVirsPaymentData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import { columnNames } from './tableState/columnNames';
import { replaceDecimalPoint } from '../../../../../utils/tableDataFunctions';

const PaymentDataCustomFilter: React.FC = () => {
  const { state: tableState } = useVirsPaymentTableState();
  const { dispatch, dataFilter } = useVirsPaymentTableDispatch();

  const handleCustomFilterChange = (column: VirsPaymentDataHeaderField, value: string | null) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  const allData = (
    useSelector((state: ApplicationState) => state.virsInfoData.VirsPaymentData) || []
  ).filter(dataFilter);

  function getUniqueOptions(name: VirsPaymentDataHeaderField, data: ShareholderVirsPaymentData[]) {
    if (name === 'paymentDocuments') {
      const documentNumbers: string[] = data.flatMap((record) =>
        record.paymentDocuments.map((doc) => doc.documentNumber)
      );
      return Array.from(new Set(documentNumbers));
    }
    if (name === 'paymentOutlets') {
      const outletNames: string[] = data.flatMap((record) =>
        record.paymentOutlets.map((outlet) => outlet.outletName)
      );
      return Array.from(new Set(outletNames));
    }
    if (name === 'paymentAmount') {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] !== null)
            .map((item) => replaceDecimalPoint(String(item[name])))
        )
      );
    }
    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => item[name])
        )
      );
    }
    return [];
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleCustomFilterChange}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
export default PaymentDataCustomFilter;
