import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import { useVirsOutletsTableDispatch, useVirsOutletsTableState } from './OutletsSection';
import { VirsOutletDataTableField } from './tableState/tableTypes';
import { ShareholderVirsOutletData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { columnNames } from './tableState/tableColumns';

const OutletCustomFilter: React.FC = () => {
  const { state: tableState } = useVirsOutletsTableState();
  const { dispatch } = useVirsOutletsTableDispatch();

  const handleSetCustomFilter = (column: VirsOutletDataTableField, value: string | null) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  const allData =
    useSelector((state: ApplicationState) => state.virsInfoData.virsOutletsData) || [];

  const outletsWIthInternationalNo = allData.map((o) => ({
    ...o,
    internationalNumber: o.isbn ? o.isbn : o.issn
  }));

  function getUniqueOptions(name: VirsOutletDataTableField, data: ShareholderVirsOutletData[]) {
    if (data)
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => String(item[name]))
        )
      );
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, outletsWIthInternationalNo)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
export default OutletCustomFilter;
