import { makeStyles } from '@material-ui/core';

export const useDialogFormStyles = makeStyles(() => ({
  textField: {
    maxWidth: '586px'
  },
  radioField: {
    maxWidth: '185px'
  },
  inputLabel: {
    margin: '0 4px 0 0',
    lineHeight: '25px',
    textAlign: 'right'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: '1',
    margin: '4px',
    '& button': {
      padding: '4px'
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '100%'
    },
    '& .MuiRadio-root': {
      padding: '4px'
    }
  },
  clearButton: {
    margin: '0 0 0 4px',
    size: '12px',
    padding: '4px'
  },
  clearIcon: {
    height: '1rem'
  },
  button: {
    height: '34px',
    width: '100px'
  },
  dateField: {
    width: '181px'
  },
  shortDateFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px',
    '& button': {
      padding: '4px'
    },
    '& .MuiBox-root': {
      padding: 0,
      margin: 0
    },
    '& + &': {
      marginLeft: '10px'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  autocompleteRoot: {
    width: '100%'
  }
}));
