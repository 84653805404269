import { put, takeEvery, call } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { sendMessage } from '../errorOrSuccessMessage/errorOrSuccessMessageAction';
import {
  fetchGenericTableDataError,
  fetchGenericTableDataRequest,
  fetchGenericTableDataSuccess
} from './genericDataTableAction';
import { GET_GENERIC_DATA_REQUEST } from './genericDataTableType';
import { getGenericTableData } from './genericDataTableApi';

function* handleGetGenericDataTableRequest(
  action: ActionType<typeof fetchGenericTableDataRequest>
) {
  try {
    const { data } = yield call(getGenericTableData, action.payload);
    yield put(fetchGenericTableDataSuccess(data));
  } catch (err) {
    yield put(fetchGenericTableDataError(err.response));
    yield put(sendMessage('error', err.response.data.message || 'Gauti duomenų nepavyko'));
  }
}

function* genericDataTableSaga() {
  yield takeEvery(GET_GENERIC_DATA_REQUEST, handleGetGenericDataTableRequest);
}

export default genericDataTableSaga;
