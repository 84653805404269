import { Typography } from '@material-ui/core';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { virsLegalEntityFinancialSupportTableReducer } from './tableState/tableReducer';
import {
  virsInitialLegalEntityFinancialSupportTableState,
  VirsLegalEntityFinancialSupportDataTableState,
  VirsLegalEntityFinancialSupportTableAction
} from './tableState/tableTypes';
import { VirsLegalEntityFinancialSupportTable } from './LegalEntityFinancialSupportTable';

interface Props {
  chosenDate: string;
}

const VirsLegalEntityFinancialSupportTableStateContext = createContext<{
  state: VirsLegalEntityFinancialSupportDataTableState;
}>({
  state: virsInitialLegalEntityFinancialSupportTableState
});

const VirsLegalEntityFinancialSupportTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsLegalEntityFinancialSupportTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const LegalEntityFinancialSupportSection: React.FC<Props> = ({ chosenDate }) => {
  const [state, dispatch] = useReducer(
    virsLegalEntityFinancialSupportTableReducer,
    virsInitialLegalEntityFinancialSupportTableState
  );

  const { t } = useTranslation();

  return (
    <VirsLegalEntityFinancialSupportTableStateContext.Provider value={{ state }}>
      <VirsLegalEntityFinancialSupportTableDispatchContext.Provider value={{ dispatch }}>
        <Typography variant="subtitle2" style={{ marginBottom: '25px' }}>
          {t('VIRS legal financial support info message')}
        </Typography>
        <VirsLegalEntityFinancialSupportTable chosenDate={chosenDate} />
      </VirsLegalEntityFinancialSupportTableDispatchContext.Provider>
    </VirsLegalEntityFinancialSupportTableStateContext.Provider>
  );
};

export function useVirsLegalEntityFinancialSupportTableState() {
  return useContext(VirsLegalEntityFinancialSupportTableStateContext);
}
export function useVirsLegalEntityFinancialSupportTableDispatch() {
  return useContext(VirsLegalEntityFinancialSupportTableDispatchContext);
}
