import React from 'react';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Form, Formik } from 'formik';
import { FormGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { pickBy, identity } from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormikFormTextField } from '../../components/FormikFields/FormikFormTextField';
import { useDialogFormStyles } from '../../components/FormikFields/FieldStyles';
import { LegalPersonSearchQuery } from '../../store/virsSearch/virsSearchTypes';
import { language as languageSelector } from '../../store/virsisApp/virsisSelectors';

interface Props {
  setQuerySize?: (size: number) => void;
  searchVirs: (virsQuery: LegalPersonSearchQuery) => void;
  isSearching: boolean;
}
export interface Values {
  legalName?: string;
  legalCode?: number;
}

export const LegalSearchForm: React.FC<Props> = ({ setQuerySize, searchVirs, isSearching }) => {
  const language = useSelector(languageSelector);
  const classes = useDialogFormStyles();
  const { t } = useTranslation();

  const initValues: Partial<Values> = {
    legalName: '',
    legalCode: undefined
  };

  const validationSchema = Yup.object().shape(
    {
      legalName: Yup.string().when(['legalCode'], {
        is: (legalCode) => !legalCode,
        then: Yup.string().required(t('Validation at least one required'))
      }),
      legalCode: Yup.string().when(['legalName'], {
        is: (legalName) => !legalName,
        then: Yup.string().required(t('Validation at least one required'))
      })
    },
    [['legalCode', 'legalName']]
  );

  const clearForm = (setFieldValue: (id: string, value: string | null | undefined) => void) => {
    setFieldValue('legalCode', '');
    setFieldValue('legalName', '');
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const searchQuery: LegalPersonSearchQuery = {
          legalName: values.legalName !== '' ? values.legalName : undefined,
          legalCode: values.legalCode !== undefined ? values.legalCode : undefined
        };
        if (setQuerySize) {
          setQuerySize(Object.keys(pickBy(searchQuery, identity)).length);
        }
        searchVirs(searchQuery);
        setSubmitting(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, errors, values, isValidating }) => (
        <MuiPickersUtilsProvider locale={language} utils={MomentUtils}>
          <Form>
            <FormGroup
              classes={{
                root: classes.formGroup
              }}
            >
              <FormikFormTextField
                maxLength={255}
                label={t('Name__1')}
                formikKey="legalName"
                errorText
                values={values.legalName}
                error={errors.legalName}
                setFieldValue={setFieldValue}
                fullWidth={true}
              />
              <FormikFormTextField
                maxLength={255}
                label={t('Code')}
                formikKey="legalCode"
                errorText
                values={values.legalCode}
                error={errors.legalCode}
                setFieldValue={setFieldValue}
                fullWidth={true}
              />
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isValidating || isSearching}
                  onClick={submitForm}
                  className={classes.button}
                  startIcon={<SearchIcon className={classes.smallIcon} />}
                >
                  <Typography color="inherit">{t('Search')}</Typography>
                </Button>
                <Button
                  variant="outlined"
                  disabled={isValidating || isSearching}
                  onClick={() => clearForm(setFieldValue)}
                  className={[classes.button, classes.greyButton].join(' ')}
                  style={{ marginRight: '4px' }}
                  startIcon={<ClearIcon className={classes.smallIcon} />}
                >
                  <Typography color="inherit">{t('Clear')}</Typography>
                </Button>
              </Box>
            </FormGroup>
            {isSearching && <LinearProgress style={{ margin: '1rem 0' }} />}
          </Form>
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  );
};
