import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  useVirsEthicalNonComplianceTableDispatch,
  useVirsEthicalNonComplianceTableState
} from './EthicalNonComplianceSection';
import { VirsEthicalNonComplianceHeaderField } from './tableState/tableTypes';
import { ShareholderVirsEthicalNonComplianceData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { columnNames } from './tableState/tableColumns';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import { ApplicationState } from '../../../../../store';

const EthicalNonComplianceCustomFilter: React.FC = () => {
  const { state: tableState } = useVirsEthicalNonComplianceTableState();
  const { dispatch, dataFilter } = useVirsEthicalNonComplianceTableDispatch();

  const { VirsEthicalNonComplianceData } = useSelector(
    (state: ApplicationState) => state.virsInfoData
  );

  const allData = (VirsEthicalNonComplianceData || []).filter(dataFilter);

  const handleCustomFilterChange = (
    column: VirsEthicalNonComplianceHeaderField,
    value: string | null
  ) => {
    dispatch({ type: 'CUSTOM_FILTER_VALUE_CHANGED', filterBy: column, value });
  };

  function getUniqueOptions(
    name: VirsEthicalNonComplianceHeaderField,
    data: ShareholderVirsEthicalNonComplianceData[]
  ) {
    if (name === 'outletList') {
      const outletNames: string[] = data.flatMap((record) =>
        record.outlets.map((outlet) => outlet.outletName)
      );
      return Array.from(new Set(outletNames));
    }

    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => item[name])
        )
      );
    }
    return [];
  }
  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleCustomFilterChange}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
export default EthicalNonComplianceCustomFilter;
