import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import {
  fetchOutletTypesRequest,
  resetSearchState,
  searchByOutletRequest
} from '../../store/virsSearch/virsSearchActions';
import {
  OutletSearchData,
  OutletSearchQuery,
  OutletType
} from '../../store/virsSearch/virsSearchTypes';
import { OutletSearchForm } from './OutletSearchForm';

export const OutletSearchWrapper: React.FC<{
  setQuerySize?: (querySize: number) => void;
  outletTypes?: OutletType[];
  onResults?: (results: OutletSearchData[]) => void;
}> = ({ setQuerySize, onResults, outletTypes }) => {
  const outletSearchResults = useSelector(
    (state: ApplicationState) => state.virsSearchData.virsSearchByOutletResults
  );

  const isSearching = useSelector(
    (state: ApplicationState) => state.virsSearchData.loadingVirsSearchByOutletResults
  );

  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(resetSearchState());
    reduxDispatch(fetchOutletTypesRequest());
  }, [reduxDispatch]);

  useEffect(() => {
    if (onResults) {
      onResults(outletSearchResults || []);
    }
  }, [outletSearchResults]);

  function searchVirs(outletQuery: OutletSearchQuery) {
    reduxDispatch(resetSearchState());
    reduxDispatch(searchByOutletRequest(outletQuery));
  }

  return <OutletSearchForm {...{ setQuerySize, searchVirs, isSearching, outletTypes }} />;
};
