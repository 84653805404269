export const VIRS_INFO_DATA_REQUEST = '@shareholder/VIRS_INFO_DATA_REQUEST';
export const VIRS_INFO_DATA_SUCCESS = '@shareholder/VIRS_INFO_DATA_SUCCESS';
export const VIRS_INFO_DATA_ERROR = '@shareholder/VIRS_INFO_DATA_ERROR';

export const VIRS_ENTERPRISE_DATA_REQUEST = '@shareholder/VIRS_ENTERPRISE_DATA_REQUEST';
export const VIRS_ENTERPRISE_DATA_SUCCESS = '@shareholder/VIRS_ENTERPRISE_DATA_SUCCESS';
export const VIRS_ENTERPRISE_DATA_ERROR = '@shareholder/VIRS_ENTERPRISE_DATA_ERROR';

export const VIRS_PROFESSIONAL_MISCONDUCT_DATA_REQUEST =
  '@shareholder/VIRS_PROFESSIONAL_MISCONDUCT_DATA_REQUEST';
export const VIRS_PROFESSIONAL_MISCONDUCT_DATA_SUCCESS =
  '@shareholder/VIRS_PROFESSIONAL_MISCONDUCT_DATA_SUCCESS';
export const VIRS_PROFESSIONAL_MISCONDUCT_DATA_ERROR =
  '@shareholder/VIRS_PROFESSIONAL_MISCONDUCT_DATA_ERROR';

export const VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST =
  '@shareholder/VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST';
export const VIRS_ETHICAL_NON_COMPLIANCE_DATA_SUCCESS =
  '@shareholder/VIRS_ETHICAL_NON_COMPLIANCE_DATA_SUCCESS';
export const VIRS_ETHICAL_NON_COMPLIANCE_DATA_ERROR =
  '@shareholder/VIRS_ETHICAL_NON_COMPLIANCE_DATA_ERROR';

export const VIRS_FUNDS_RECEIVED_DATA_REQUEST = '@shareholder/VIRS_FUNDS_RECEIVED_DATA_REQUEST';
export const VIRS_FUNDS_RECEIVED_DATA_SUCCESS = '@shareholder/VIRS_FUNDS_RECEIVED_DATA_SUCCESS';
export const VIRS_FUNDS_RECEIVED_DATA_ERROR = '@shareholder/VIRS_FUNDS_RECEIVED_DATA_ERROR';

export const VIRS_EDITION_CHECK_DATA_REQUEST = '@shareholder/VIRS_EDITION_CHECK_DATA_REQUEST';
export const VIRS_EDITION_CHECK_DATA_SUCCESS = '@shareholder/VIRS_EDITION_CHECK_DATA_SUCCESS';
export const VIRS_EDITION_CHECK_DATA_ERROR = '@shareholder/VIRS_EDITION_CHECK_DATA_ERROR';
export const EDITION_CHECK_DOCUMENT_PDF_REQUEST = '@shareholder/EDITION_CHECK_DOCUMENT_PDF_REQUEST';

export const VIRS_PAYMENT_DATA_REQUEST = '@shareholder/VIRS_PAYMENT_DATA_REQUEST';
export const VIRS_PAYMENT_DATA_SUCCESS = '@shareholder/VIRS_PAYMENT_DATA_SUCCESS';
export const VIRS_PAYMENT_DATA_ERROR = '@shareholder/VIRS_PAYMENT_DATA_ERROR';
export const PAYMENT_DOCUMENT_PDF_REQUEST = '@shareholder/PAYMENT_DOCUMENT_PDF_REQUEST';

export const VIRS_LICENSES_DATA_REQUEST = '@shareholder/VIRS_LICENSES_DATA_REQUEST';
export const VIRS_LICENSES_DATA_SUCCESS = '@shareholder/VIRS_LICENSES_DATA_SUCCESS';
export const VIRS_LICENSES_DATA_ERROR = '@shareholder/VIRS_LICENSES_DATA_ERROR';

export const VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_REQUEST =
  '@shareholder/VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_REQUEST';
export const VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_SUCCESS =
  '@shareholder/VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_SUCCESS';
export const VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_ERROR =
  '@shareholder/VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_ERROR';

export const VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_REQUEST =
  '@shareholder/VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_REQUEST';
export const VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_SUCCESS =
  '@shareholder/VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_SUCCESS';
export const VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_ERROR =
  '@shareholder/VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_ERROR';

export const VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_REQUEST =
  '@shareholder/VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_REQUEST';
export const VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_SUCCESS =
  '@shareholder/VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_SUCCESS';
export const VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_ERROR =
  '@shareholder/VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_ERROR';

export const VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_REQUEST =
  '@shareholder/VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_REQUEST';
export const VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_SUCCESS =
  '@shareholder/VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_SUCCESS';
export const VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_ERROR =
  '@shareholder/VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_ERROR';

export const VIRS_OUTLETS_DATA_REQUEST = '@shareholder/VIRS_OUTLETS_DATA_REQUEST';
export const VIRS_OUTLETS_DATA_SUCCESS = '@shareholder/VIRS_OUTLETS_DATA_SUCCESS';
export const VIRS_OUTLETS_DATA_ERROR = '@shareholder/VIRS_OUTLETS_DATA_ERROR';
