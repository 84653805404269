import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderOutletResponsiblePersonData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { filterOptions } from '../../../../../store/virsisApp/virsisDataTypes';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import DisplayFilterTool from '../../../../../components/TableDisplayFilter/DisplayFilterTool';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import {
  useOutletResponsiblePersonsSectionTableDispatch,
  useOutletResponsiblePersonsSectionTableState
} from './ResponsiblePersonsSectionSection';
import { columnNames } from './tableState/TableColumns';
import {
  OutletResponsiblePersonsColumnHeaderField,
  OutletResponsiblePersonsColumnHeader
} from './tableState/tableTypes';

interface Props {
  data: ShareholderOutletResponsiblePersonData[];
}

export const ResponsiblePersonsSectionTableCustomizationBar: React.FC<Props> = ({ data }) => {
  const { state } = useOutletResponsiblePersonsSectionTableState();
  const { dispatch } = useOutletResponsiblePersonsSectionTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' });
  };

  const handleSetSortByColumn = (column: OutletResponsiblePersonsColumnHeaderField) => {
    dispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const toggleDisplayColumn = (column: OutletResponsiblePersonsColumnHeaderField) => {
    dispatch({ type: 'TOGGLE_DISPLAY_COLUMN', column });
  };

  const handleSetDisplayFilter = (value: filterOptions) => {
    dispatch({ type: 'SET_DISPLAY_FILTER', value });
  };

  function getResponsiblePersonsColumnsWithTranslation(): OutletResponsiblePersonsColumnHeader[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`Responsible person ${col.id}`)
    }));
  }

  const translatedColumns = getResponsiblePersonsColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <DisplayFilterTool
            disabled={data.length === 0}
            displayFilter={state.displayFilter}
            setDisplayFilter={handleSetDisplayFilter}
          />
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
