import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderVirsEnterpriseData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsEnterpriseTableState } from './EnterpriseSection';

interface Props {
  record: ShareholderVirsEnterpriseData;
}

export const EnterpriseRow: React.FC<Props> = ({ record }) => {
  const { state: tableState } = useVirsEnterpriseTableState();
  return (
    <TableRow key={record.enterpriseId}>
      {tableState.columnsDisplayStatus.enterpriseTypeName && (
        <TableCell>{record.enterpriseTypeName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.validFrom && <TableCell>{record.validFrom} </TableCell>}
      {tableState.columnsDisplayStatus.validTo && <TableCell>{record.validTo} </TableCell>}
    </TableRow>
  );
};
