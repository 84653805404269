import React, { useState } from 'react';
import {
  Collapse,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useTranslation } from 'react-i18next';
import { isLegalPerson } from '../../../VirsTree/TreeChart/treeChartFunctions/uiFunctions';
import { ChartShareholder } from '../../../../store/virsTree/virsTreeDataTypes';
import {replaceDecimalPoint} from "../../../../utils/tableDataFunctions";

interface Props {
  isGroup: boolean;
  shareholderData: ChartShareholder;
  shareTypeName: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    personContentWrap: {
      marginTop: 10
    },
    rowWrap: {
      display: 'flex'
    },
    propertyName: {
      flexShrink: 0,
      width: 160,
      margin: '0 30px 0 0',
      textAlign: 'end',
      fontSize: '0.95rem',
      fontWeight: 600
    },
    interactivePropertyName: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'flex-end',
      width: 160
    },
    propertyValue: {
      fontSize: '0.95rem',
      fontWeight: 'normal'
    },
    propertyValueWrap: {
      display: 'flex',
      flexFlow: 'row'
    },
    dateFrom: {
      fontSize: '0.95rem',
      fontWeight: 'normal',
      marginRight: 25
    },
    dateTo: {
      marginLeft: 12,
      fontSize: '0.95rem',
      fontWeight: 'normal'
    },
    boldText: {
      margin: '10px 0 10px'
    },
    midDivider: {
      margin: '14px 0'
    },
    invisibleDivider: {
      margin: '24px 0',
      opacity: 0
    },
    dropdownIcon: {
      margin: 'auto 0',
      height: 18,
      width: 18,
      fill: '#006FB2'
    },
    listItem: {
      width: 'auto',
      padding: 0,
      marginLeft: 30
    },
    insetItem: {
      paddingLeft: 20
    }
  })
);

export const PersonInfo: React.FC<Props> = ({ isGroup, shareholderData, shareTypeName }) => {
  const classes = useStyles();
  const [showList, setShowList] = useState(false);
  const { t } = useTranslation();

  const {
    shareholderPersons,
    shareholderStartDate,
    shareholderEndDate,
    votePercentage,
    sharePercentage,
    votesOnRules,
    sharePercentageLessThanPermitted,
    votePercentageLessThanPermitted
  } = shareholderData;

  return (
    <div className={classes.personContentWrap}>
      {!isGroup && isLegalPerson(shareholderPersons[0].personType) ? (
        <>
          <div className={classes.rowWrap}>
            <Typography variant="h3" className={classes.propertyName}>
              {t('Code')}
            </Typography>
            <Typography variant="h3" className={classes.propertyValue}>
              {shareholderPersons[0].personCode}
            </Typography>
          </div>

          <Divider className={classes.midDivider} />

          <div className={classes.rowWrap}>
            <Typography variant="h3" className={classes.propertyName}>
              {t('Legal form')}
            </Typography>
            <Typography variant="h3" className={classes.propertyValue}>
              {shareholderPersons[0].personLegalFormAbbreviation}
            </Typography>
          </div>
          <Divider className={classes.midDivider} />
        </>
      ) : null}

      <div className={classes.rowWrap}>
        <Typography variant="h3" className={classes.propertyName}>
          {t('Involved from')}
        </Typography>
        <div className={classes.propertyValueWrap}>
          <Typography variant="h3" className={classes.dateFrom}>
            {shareholderStartDate}
          </Typography>
          <Typography variant="h3" style={{ fontSize: '0.95rem' }}>
            {t('Involved to')}
          </Typography>
          <Typography variant="h3" className={classes.dateTo}>
            {shareholderEndDate || '-'}
          </Typography>
        </div>
      </div>

      <Divider className={classes.invisibleDivider} />

      <div className={classes.rowWrap}>
        <Typography variant="h3" className={classes.propertyName}>
          {shareTypeName}
        </Typography>
        <Typography variant="h3" className={classes.propertyValue}>
          {sharePercentage !== null ? replaceDecimalPoint(`${sharePercentageLessThanPermitted ? '< ' : ''}${sharePercentage}%`) : '-'}
        </Typography>
      </div>

      <Divider className={classes.midDivider} />

      <div className={classes.rowWrap}>
        <Typography variant="h3" className={classes.propertyName}>
          {t('Votes part')}
        </Typography>
        <Typography variant="h3" className={classes.propertyValue}>
          {votesOnRules
            ? t('Votes by the rule')
            : (votePercentage !== null && replaceDecimalPoint(`${votePercentageLessThanPermitted ? '< ' : ''}${votePercentage}%`)) || '-'}
        </Typography>
      </div>
      {isGroup ? (
        <>
          <Divider className={classes.invisibleDivider} />

          <div className={classes.rowWrap}>
            <span
              aria-hidden
              role="button"
              className={classes.interactivePropertyName}
              onClick={() => {
                setShowList(!showList);
              }}
            >
              <Typography variant="h3" style={{ fontSize: '0.95rem', marginRight: 10 }}>
                {t('Shareholders group one word')}
              </Typography>

              {!showList ? (
                <ArrowDropDownIcon className={classes.dropdownIcon} />
              ) : (
                <ArrowDropUpIcon className={classes.dropdownIcon} />
              )}
            </span>
          </div>
          {!shareholderPersons.length ? null : (
            <Collapse in={showList} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ paddingTop: 15 }}>
                {shareholderPersons.map((person) => {
                  return (
                    <ListItem
                      key={person.personId}
                      className={classes.listItem}
                      disableGutters
                      divider
                      alignItems="center"
                    >
                      <ListItemText inset classes={{ inset: classes.insetItem }}>
                        {person.personName}
                        <br />
                        {isLegalPerson(shareholderPersons[0].personType) && person.personCode
                          ? ` ${person.personCode}`
                          : null}
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          )}
        </>
      ) : null}
    </div>
  );
};
