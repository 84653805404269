import { Order } from '../../../../../../utils/tableTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';

export interface VirsIndividualPersonFinancialSupportDataTableState {
  sortBy: VirsIndividualPersonFinancialSupportColumnHeaderField;
  order: Order;
  page: number;
  recordsPassed: number;
  pagesCount: number;
  displayFilter: filterOptions;
  rowsPerPage: number;
  columnsDisplayStatus: VirsIndividualPersonFinancialSupportColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: VirsIndividualPersonFinancialSupportTableCustomFilter;
}

export type VirsIndividualPersonFinancialSupportColumnHeaderField =
  | 'supportProviderFullName'
  | 'supportReceivedYear'
  | 'financialSupportAmount';

export type VirsIndividualPersonFinancialSupportColumnsDisplayStatus = {
  supportProviderFullName: boolean;
  supportReceivedYear: boolean;
  financialSupportAmount: boolean;
};
export type VirsIndividualPersonFinancialSupportTableCustomFilter = {
  supportProviderFullName: string[];
  supportReceivedYear: string[];
  financialSupportAmount: number[];
};

export type VirsIndividualPersonFinancialSupportTableAction =
  | {
      type: 'SET_DISPLAY_FILTER';
      value: filterOptions;
    }
  | {
      type: 'SET_SORT_BY_COLUMN';
      sortBy: VirsIndividualPersonFinancialSupportColumnHeaderField;
    }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: number }
  | { type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' }
  | {
      type: 'SET_CUSTOM_FILTER';
      filterBy: VirsIndividualPersonFinancialSupportColumnHeaderField;
      value: string | null;
    }
  | {
      type: 'TOGGLE_DISPLAY_COLUMN';
      column: VirsIndividualPersonFinancialSupportColumnHeaderField;
    };

export const initialVirsIndividualPersonFinancialSupportTableState: VirsIndividualPersonFinancialSupportDataTableState =
  {
    order: 'desc',
    sortBy: 'supportReceivedYear',
    page: 0,
    recordsPassed: 0,
    pagesCount: 0,
    rowsPerPage: 5,
    displayFilter: 'allItems',
    columnsDisplayStatus: {
      supportProviderFullName: true,
      financialSupportAmount: true,
      supportReceivedYear: true
    },
    customFilterOn: false,
    customFilter: {
      supportProviderFullName: [],
      financialSupportAmount: [],
      supportReceivedYear: []
    }
  };
