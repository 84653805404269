import { ShareholderVirsEthicalNonComplianceData } from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { SortOrder } from '../../../../../../utils/tableTypes';
import {
  VirsEthicalNonComplianceCustomFilter,
  VirsEthicalNonComplianceHeaderField,
  VirsEthicalNonComplianceTableState
} from './tableTypes';

export const updateEthicalNonComplianceCustomFilter = (
  filter: VirsEthicalNonComplianceCustomFilter,
  filterBy: VirsEthicalNonComplianceHeaderField,
  value: string | null
): VirsEthicalNonComplianceCustomFilter => {
  return {
    ...filter,
    [filterBy]: value ? [value] : []
  };
};

type OutletListSorterFn = (
  record: ShareholderVirsEthicalNonComplianceData
) => ShareholderVirsEthicalNonComplianceData;

function getOutletListSorter(order: SortOrder): OutletListSorterFn {
  return (record) => ({
    ...record,
    outletList: stableSortWrapped(record.outlets, order, 'outletName')
  });
}

export const getSortedEthicalNonComplianceData = (
  data: ShareholderVirsEthicalNonComplianceData[],
  order: SortOrder,
  sortBy: string
): ShareholderVirsEthicalNonComplianceData[] => {
  if (sortBy === 'outletList') {
    return data.map(getOutletListSorter(order));
  }
  return stableSortWrapped(data, order, sortBy);
};

export function filterAndSortVirsEthicalNonComplianceTableData(
  tableState: VirsEthicalNonComplianceTableState,
  data: ShareholderVirsEthicalNonComplianceData[]
): ShareholderVirsEthicalNonComplianceData[] {
  const columnsFilters = tableState.customFilter;

  const filters: Filter<ShareholderVirsEthicalNonComplianceData>[] = [
    getStringMappedColumnFilter(columnsFilters.decisionDate, ({ decisionDate }) => [decisionDate]),
    getStringMappedColumnFilter(columnsFilters.documentNumber, ({ documentNumber }) => [
      documentNumber
    ]),
    getStringMappedColumnFilter(
      columnsFilters.ethicalNonComplianceStatus,
      ({ ethicalNonComplianceStatus }) => [ethicalNonComplianceStatus]
    ),
    getStringMappedColumnFilter(columnsFilters.validFrom, ({ validFrom }) => [validFrom]),
    getStringMappedColumnFilter(columnsFilters.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(
      columnsFilters.reportingInstitutionName,
      ({ reportingInstitutionName }) => [reportingInstitutionName]
    ),
    getStringMappedColumnFilter(columnsFilters.outletList, ({ outlets }) =>
      outlets.map((value) => value.outletName)
    )
  ];

  const filtered = filterData(data, filters);
  const sorted = getSortedEthicalNonComplianceData(filtered, tableState.order, tableState.sortBy);

  return sorted;
}
