import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { ApplicationState } from '../../../../../store';
import { columnNames } from './tableState/tableColumns';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import { useVirsLicensesTableDispatch, useVirsLicensesTableState } from './LicensesSection';
import { VirsLicensesColumnHeaderField } from './tableState/tableTypes';
import { ShareholderVirsLicensesData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';

export const LicensesCustomFilter: React.FC = () => {
  const { dispatch: tableDispatch } = useVirsLicensesTableDispatch();
  const { state: tableState } = useVirsLicensesTableState();

  const handleSetCustomFilter = (column: VirsLicensesColumnHeaderField, value: string | null) => {
    tableDispatch({
      type: 'SET_CUSTOM_FILTER',
      filterBy: column,
      value
    });
  };
  const allData =
    useSelector((state: ApplicationState) => state.virsInfoData.VirsLicensesData) || [];

  function getUniqueOptions(
    name: VirsLicensesColumnHeaderField,
    data: ShareholderVirsLicensesData[]
  ) {
    if (data) {
      return Array.from(
        new Set(
          data
            .filter((item) => item[name] && item[name] !== '' && item[name] !== null)
            .map((item) => item[name])
        )
      );
    }
    return [];
  }

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {columnNames
          .filter((column) => tableState.columnsDisplayStatus[column.id])
          .map((column) => (
            <TableCell
              key={column.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={column.id}
                uniqueOptions={getUniqueOptions(column.id, allData)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
