import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ApiOutlet } from '../../store/virsTree/virsTreeDataTypes';
import { StateShareholder } from '../../pages/VirsTreePage/treeState/treeTypes';

interface Props {
  height?: string;
  virsName?: string;
  outlets?: ApiOutlet[];
  shareholders?: StateShareholder[];
}

const useStyles = makeStyles(() => ({
  tableContainer: {
    border: 'none',
    boxShadow: 'none',
    borderRadius: '0'
  },
  tableRow: {
    border: 'none'
  },
  tableHeadingCell: {
    borderTop: '1px solid #dddddd'
  },
  tableCell: {
    boxSizing: 'border-box',
    padding: '0 10px'
  }
}));

export const VirsTreeListTableClass = 'virs-list-table';

export const VirsTreeListTable: React.FC<Props> = ({
  height = 'auto',
  virsName,
  outlets = [],
  shareholders = []
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const tableContainerStyles: React.CSSProperties = {
    height,
    overflow: 'hidden'
  };
  const sortedShareholders = shareholders.sort((a, b) => a.shareholderLevel - b.shareholderLevel);

  if (!virsName) return <></>;

  return (
    <TableContainer
      style={tableContainerStyles}
      component={Paper}
      className={`${classes.tableContainer} ${VirsTreeListTableClass}`}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableHeadingCell} />
            <TableCell className={classes.tableHeadingCell}>
              {`${t('Title')} / ${t('Name')} ${t('Surname')}`}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>VIRS</TableCell>
            <TableCell className={classes.tableCell}>{virsName}</TableCell>
          </TableRow>
          {sortedShareholders?.length > 0 &&
            sortedShareholders.map(
              (shareholder) =>
                shareholder.shareholderPersons.length && (
                  <TableRow
                    className={classes.tableRow}
                    key={`${shareholder.shareholderId}-${shareholder.parentId}`}
                  >
                    <TableCell className={classes.tableCell}>
                      {shareholder.shareholderLevel} {t('Level').toLowerCase()}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {shareholder.shareholderPersons.length > 1
                        ? `${t('Group of co-owners')} (${shareholder.shareholderPersons
                            .map((p) => p.personName)
                            .join(', ')})`
                        : shareholder.shareholderPersons[0].personName}
                    </TableCell>
                  </TableRow>
                )
            )}
          {outlets?.length > 0 &&
            outlets.map((outlet) => (
              <TableRow className={classes.tableRow} key={outlet.outletId}>
                <TableCell className={classes.tableCell}>VIP</TableCell>
                <TableCell className={classes.tableCell}>{outlet.outletName}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
