import {
  initialVirsFundsReceivedDataTableState,
  VirsFundsReceivedDataTableAction,
  VirsFundsReceivedDataTableState
} from './tableTypes';
import { getToggledSortOrderGeneric } from '../../../../../../utils/tableDataFunctions';
import { updateFundsReceivedDataCustomFilter } from './tableFunctions';

export const virsFundsReceivedDataTableReducer = (
  state: VirsFundsReceivedDataTableState,
  action: VirsFundsReceivedDataTableAction
): VirsFundsReceivedDataTableState => {
  switch (action.type) {
    case 'TABLE_INITIALIZED':
      return { ...state };
    case 'CUSTOM_FILTER_DISPLAY_TOGGLED':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: initialVirsFundsReceivedDataTableState.customFilter
      };
    case 'CUSTOM_FILTER_VALUE_CHANGED':
      return {
        ...state,
        customFilter: updateFundsReceivedDataCustomFilter(
          state.customFilter,
          action.filterBy,
          action.value
        ),
        page: 0
      };
    case 'SORTING_CHANGED':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: getToggledSortOrderGeneric(action.sortBy, state.sortBy, state.order)
      };
    case 'PAGE_SET':
      return {
        ...state,
        page: action.page
      };
    case 'ROWS_PER_PAGE_SET':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0
      };
    case 'COLUMN_DISPLAY_TOGGLED':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };
    case 'SET_DISPLAY_FILTER':
      return {
        ...state,
        displayFilter: action.value
      };
    default:
      return state;
  }
};
