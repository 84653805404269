import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useVirsTreeDispatch,
  useVirsTreeState
} from '../../pages/VirsTreePage/VirsTreePageContext';
import { VirsTreePreviewCards } from './VirsTreePreviewCards/VirsTreePreviewCards';
import {
  fetchVirsEditionCheckData,
  fetchVirsEthicalNonComplianceData,
  fetchVirsFundsReceivedData,
  fetchVirsPaymentData,
  fetchVirsProfessionalMisconductData
} from '../../store/virsTreeVirsInfo/virsInfoActions';
import { ApplicationState } from '../../store';
import { OutletShortData } from '../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { EthicalNonComplianceSection } from './VirsTreePreviewCards/VirsCard/EthicalNonComplianceSection/EthicalNonComplianceSection';
import { ProfessionalMisconductSection } from './VirsTreePreviewCards/VirsCard/ProfessionalMisconductSection/ProfessionalMisconductSection';
import { PaymentSection } from './VirsTreePreviewCards/VirsCard/PaymentSection/PaymentSection';
import { EditionCheckSection } from './VirsTreePreviewCards/VirsCard/EditionCheckSection/EditionCheckSection';
import { FundsReceivedSection } from './VirsTreePreviewCards/VirsCard/FundsReceivedSection/FundsReceivedSection';

export enum InsitutionDataTypes {
  PROFESSIONAL_MISCONDUCT,
  ETHICAL_NONCOMPLIANCE,
  EDITION_CHECK,
  PAYMENT,
  FUNDS_RECEIVED
}

function getDataActionProvider(dataType?: InsitutionDataTypes) {
  switch (dataType) {
    case InsitutionDataTypes.PROFESSIONAL_MISCONDUCT:
      return fetchVirsProfessionalMisconductData;
    case InsitutionDataTypes.ETHICAL_NONCOMPLIANCE:
      return fetchVirsEthicalNonComplianceData;
    case InsitutionDataTypes.EDITION_CHECK:
      return fetchVirsEditionCheckData;
    case InsitutionDataTypes.PAYMENT:
      return fetchVirsPaymentData;
    case InsitutionDataTypes.FUNDS_RECEIVED:
      return fetchVirsFundsReceivedData;
    default:
      return undefined;
  }
}

function getOutletsPredicate(outletId: number) {
  return (outlets: OutletShortData[]) => outlets.some(({ outletId: id }) => id === outletId);
}

export const OutletInstitutionDataPreviewContainer: React.FC = () => {
  const reduxDispatch = useDispatch();
  const {
    chartData: {
      timeline: { activeDate }
    }
  } = useSelector((state: ApplicationState) => state);
  const { treeDispatch } = useVirsTreeDispatch();
  const { treeState } = useVirsTreeState();
  const { t } = useTranslation();

  const { visibleInstitutionDataType, virsId, outletId } = treeState;

  useEffect(() => {
    const actionProvider = getDataActionProvider(visibleInstitutionDataType);
    if (actionProvider) {
      reduxDispatch(actionProvider(virsId));
    }
  }, [reduxDispatch, visibleInstitutionDataType, virsId]);

  const close = () => {
    treeDispatch({ type: 'CLOSE_OUTLET_INSTITUTION_DATA_CARD' });
  };

  const showOutlet = (id: number, outletName: string) => {
    treeDispatch({
      type: 'OPEN_OUTLET_PREVIEW_CARD',
      virsId,
      outletId: id,
      outletName
    });
    close();
  };

  function getTitle(dataType?: InsitutionDataTypes) {
    switch (dataType) {
      case InsitutionDataTypes.PROFESSIONAL_MISCONDUCT:
        return `${t('Outlet the professional misconducts')}`;
      case InsitutionDataTypes.ETHICAL_NONCOMPLIANCE:
        return `${t('Outlet the ethical non compliances')}`;
      case InsitutionDataTypes.EDITION_CHECK:
        return `${t('Outlet the edition checks')}`;
      case InsitutionDataTypes.PAYMENT:
        return `${t('Outlet the yearly payment')}`;
      case InsitutionDataTypes.FUNDS_RECEIVED:
        return `${t('Outlet the funds received')}`;
      default:
        return undefined;
    }
  }

  const outletsPredicate = getOutletsPredicate(outletId);

  return (
    <VirsTreePreviewCards
      isOpen
      onClose={close}
      title={getTitle(visibleInstitutionDataType) || ''}
      subTitle=""
      reduceHeightBy="180px"
    >
      {visibleInstitutionDataType === InsitutionDataTypes.PROFESSIONAL_MISCONDUCT && (
        <ProfessionalMisconductSection
          chosenDate={activeDate.format('L')}
          onClickOutlet={showOutlet}
          dataFilter={({ outlets }) => outlets.some(({ outletId: id }) => id === outletId)}
        />
      )}
      {visibleInstitutionDataType === InsitutionDataTypes.ETHICAL_NONCOMPLIANCE && (
        <EthicalNonComplianceSection
          onClickOutlet={showOutlet}
          dataFilter={({ outlets }) => outletsPredicate(outlets)}
        />
      )}
      {visibleInstitutionDataType === InsitutionDataTypes.EDITION_CHECK && (
        <EditionCheckSection
          onClickOutlet={showOutlet}
          dataFilter={({ editionCheckOutlets }) => outletsPredicate(editionCheckOutlets)}
        />
      )}
      {visibleInstitutionDataType === InsitutionDataTypes.PAYMENT && (
        <PaymentSection
          onClickOutlet={showOutlet}
          dataFilter={({ paymentOutlets }) => outletsPredicate(paymentOutlets)}
        />
      )}
      {visibleInstitutionDataType === InsitutionDataTypes.FUNDS_RECEIVED && (
        <FundsReceivedSection
          chosenDate={activeDate.format('L')}
          onClickOutlet={showOutlet}
          dataFilter={({ fundsReceivedOutlets }) => outletsPredicate(fundsReceivedOutlets)}
        />
      )}
    </VirsTreePreviewCards>
  );
};
