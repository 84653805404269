import React from 'react';
import { makeStyles, TableCell, TableRow } from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import { ShareholderVirsEditionCheckData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsEditionCheckTableState } from './EditionCheckSection';
import OutletNameButton from '../../../../../components/TableButtons/OutletNameButton';
import BtnOpenNewDoc from '../../../../../components/TableButtons/BtnOpenNewDoc';
import { getEditionCheckDocumentInNewTabRequest } from '../../../../../store/virsTreeVirsInfo/virsInfoActions';

interface Props {
  record: ShareholderVirsEditionCheckData;
  onClickOutlet: (outletId: number, outletName: string) => void;
}

const useStyles = makeStyles({
  removePadding: {
    padding: '5px',
    position: 'relative',
    left: '-5px'
  },
  dataColumnWidth: {
    width: '110px'
  },
  vipColumnWidth: {
    width: '120px'
  },
  actionColumnWidth: {
    width: '160px',
    boxSizing: 'border-box'
  }
});

export const EditionCheckRow: React.FC<Props> = ({ record, onClickOutlet }) => {
  const { state: tableState } = useVirsEditionCheckTableState();
  const reduxDispatch = useDispatch();
  const classes = useStyles();

  const totalRowSpan = () => {
    let rowSpan = 0;
    record.editionCheckOutlets.forEach((outlet) => {
      rowSpan += outlet.editionCheckConclusions.length;
    });
    return rowSpan;
  };

  function openDocumentInNewTab(editionCheckDocumentId: number, documentNumber: string) {
    reduxDispatch(getEditionCheckDocumentInNewTabRequest(editionCheckDocumentId, documentNumber));
  }

  let rowIndex = 0;
  const rows: JSX.Element[] = [];

  record.editionCheckOutlets.forEach((outlet) => {
    outlet.editionCheckConclusions.forEach((conclusion, conclusionIndex) => {
      rows.push(
        <TableRow key={rowIndex}>
          {rowIndex === 0 && (
            <>
              {tableState.columnsDisplayStatus.checkDate && (
                <TableCell
                  align="left"
                  rowSpan={totalRowSpan()}
                  className={classes.dataColumnWidth}
                >
                  {record.checkDate}
                </TableCell>
              )}

              {tableState.columnsDisplayStatus.docNr && (
                <TableCell
                  align="left"
                  className={classes.dataColumnWidth}
                  rowSpan={totalRowSpan()}
                >
                  {record.editionCheckDocuments.map((document) => (
                    <div key={document.documentId}>
                      <BtnOpenNewDoc
                        onClicked={() =>
                          openDocumentInNewTab(document.documentId, document.documentNumber)
                        }
                        text={document.documentNumber}
                      />
                    </div>
                  ))}
                </TableCell>
              )}
            </>
          )}

          {conclusionIndex === 0 && tableState.columnsDisplayStatus.outletName && (
            <TableCell align="left" rowSpan={outlet.editionCheckConclusions.length}>
              <OutletNameButton
                key={outlet.outletId}
                onClicked={() => {
                  onClickOutlet(outlet.outletId, outlet.outletName);
                }}
                text={outlet.outletName}
              />
            </TableCell>
          )}

          {tableState.columnsDisplayStatus.periodName && (
            <TableCell align="left">{conclusion.periodName}</TableCell>
          )}

          {tableState.columnsDisplayStatus.conclusion && (
            <TableCell align="left">{conclusion.conclusionTypeName}</TableCell>
          )}

          {rowIndex === 0 && (
            <>
              {tableState.columnsDisplayStatus.checkedByInstitutionName && (
                <TableCell align="left" rowSpan={totalRowSpan()}>
                  {record.checkedByInstitutionName}
                </TableCell>
              )}
            </>
          )}
        </TableRow>
      );
      // eslint-disable-next-line no-plusplus
      rowIndex++;
    });
  });

  return <>{rows}</>;
};
