import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';

import { useTranslation } from 'react-i18next';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  useVirsPoliticalAdFinancialSupportTableDispatch,
  useVirsPoliticalAdFinancialSupportTableState
} from './PoliticalAdFinancialSupportSection';
import {
  VirsPoliticalAdFinancialSupportColumnHeaderField,
  VirsPoliticalAdFinancialSupportDataTableState
} from './tableState/tableTypes';

export const PoliticalAdFinancialSupportTableHead: React.FC = () => {
  const { state: tableState } = useVirsPoliticalAdFinancialSupportTableState();
  const { dispatch: tableDispatch } = useVirsPoliticalAdFinancialSupportTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsPoliticalAdFinancialSupportColumnHeaderField) => {
    tableDispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsPoliticalAdFinancialSupportDataTableState,
    typeof handleSetSortByColumn,
    VirsPoliticalAdFinancialSupportColumnHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.distributorName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS political ad distributorName')}
            columnName="distributorName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.distributorCode && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS political ad distributorCode')}
            columnName="distributorCode"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.beneficiaryName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS political ad beneficiaryName')}
            columnName="beneficiaryName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.campaignName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS political ad campaignName')}
            columnName="campaignName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {(tableState.columnsDisplayStatus.advertisingFrom ||
          tableState.columnsDisplayStatus.advertisingTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.advertisingFrom,
              tableState.columnsDisplayStatus.advertisingTo
            ])}
          >
            <Typography variant="h4">{t('VIRS political ad valid')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.financialSupportAmount && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS political ad financialSupportAmount')}
            columnName="financialSupportAmount"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.advertisingFrom && (
          <TableSortingHeaderCell
            label={t('From capitalized')}
            columnName="advertisingFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.advertisingTo && (
          <TableSortingHeaderCell
            label={t('To capitalized')}
            columnName="advertisingTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
