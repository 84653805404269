import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderOutletAdPrintingData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { useOutletAdPrintingTableState } from './AdPrintingSection';

interface Props {
  record: ShareholderOutletAdPrintingData;
}

export const OutletAdPrintingRow: React.FC<Props> = ({ record }) => {
  const {
    state: { columnsDisplayStatus }
  } = useOutletAdPrintingTableState();

  return (
    <TableRow key={record.advertisementId}>
      {columnsDisplayStatus.validFrom && <TableCell>{record.validFrom}</TableCell>}
      {columnsDisplayStatus.validTo && <TableCell>{record.validTo}</TableCell>}
    </TableRow>
  );
};
