import React from 'react';

interface Props {
  color: string;
}

export const ShareholdersPieFilled: React.FC<Props> = ({ color }) => {
  return (
    <svg width="17.13" height="16.969">
      <g fill={color}>
        <path
          data-name="Path 848"
          d="M7.318 7.882H.56a.56.56 0 01-.56-.56A7.332 7.332 0 017.323 0a.56.56 0 01.56.56v6.763a.566.566 0 01-.565.559zm0-6.227l-.56 5.108V1.141c-2.971.269.829-2.463.56.514z"
        />
        <path
          data-name="Path 849"
          d="M9.807 16.968a7.332 7.332 0 01-7.323-7.323.56.56 0 01.56-.56h6.2v-6.2a.56.56 0 01.559-.56 7.321 7.321 0 110 14.641zm6.2-7.323c.282 3.16-8.876-6.172-5.644-6.172 3.42 0-.259 4.492-.259 1.072 0-3.232 3.42-.791.259-1.072V9.65c0 .309.049-4.311-.259-4.311z"
        />
      </g>
    </svg>
  );
};
