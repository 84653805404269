import { Button, createStyles, Divider, makeStyles, Typography } from '@material-ui/core';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useVirsTreeDispatch } from '../../../../../pages/VirsTreePage/VirsTreePageContext';
import { ApplicationState } from '../../../../../store';
import virsisTheme from '../../../../../style/virsisTheme';
import { InsitutionDataTypes } from '../../../OutletInstitutionDataContainer';

const useStyles = makeStyles(() =>
  createStyles({
    infoSection: {
      display: 'grid',
      gridTemplateColumns: '1.2fr 0.9fr',
      gridGap: '30px'
    },
    infoColumn: {
      width: '100%'
    },
    rowWrap: {
      display: 'flex',
      flexFlow: 'row'
    },
    propertyName: {
      margin: '0 30px 0 0',
      width: 200,
      textAlign: 'end',
      fontSize: '0.95rem',
      fontWeight: 600
    },
    propertyValue: {
      fontSize: '0.95rem',
      fontWeight: 'normal',
      flexShrink: 10000
    },
    propertyValueWrap: {
      display: 'flex',
      flexFlow: 'row'
    },
    dateFrom: {
      fontSize: '0.95rem',
      fontWeight: 'normal',
      marginRight: 25
    },
    dateTo: {
      marginLeft: 12,
      fontSize: '0.95rem',
      fontWeight: 'normal'
    },
    midDivider: {
      margin: '14px 0',
      height: '0.9px'
    },
    invisibleDivider: {
      margin: '24px 0',
      opacity: 0
    },
    button: {
      margin: '0 0 0 15px',
      color: virsisTheme.palette.primary.main,
      padding: 0,
      height: 'auto',
      lineHeight: '1.167',
      fontSize: '0.95rem',
      fontWeight: 600
    }
  })
);

export const OutletInfoSection = () => {
  const { treeDispatch } = useVirsTreeDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const { outletInfo } = useSelector((state: ApplicationState) => state.outletInfoData);

  const {
    outletGroupName,
    establishedDate,
    controlPeriods,
    outletTypeName,
    issn,
    isbn,
    inProfessionalMisconducts,
    inEthicalNonCompliances,
    inEditionChecks,
    inFundsReceived,
    inAnnualPayments
  } = { ...outletInfo };

  const linkToExtendedData = (isData: boolean, dataType: InsitutionDataTypes) => {
    if (isData) {
      return (
        <>
          <Typography variant="h3" className={classes.propertyValue}>
            {t('Extended data present')}
          </Typography>
          <Button
            onClick={() =>
              treeDispatch({
                type: 'OPEN_OUTLET_INSTITUTION_DATA_CARD',
                dataType
              })
            }
            className={classes.button}
          >
            {t('Extended data view')}
          </Button>
        </>
      );
    }
    return (
      <Typography variant="h3" className={classes.propertyValue}>
        {t('Extended data not present')}
      </Typography>
    );
  };

  return (
    <div className={classes.infoSection}>
      <div className={classes.infoColumn}>
        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet established')}
          </Typography>
          <Typography variant="h3" className={classes.propertyValue}>
            {establishedDate}
          </Typography>
        </div>

        <Divider className={classes.midDivider} />

        {controlPeriods?.map((period, index) => (
          <div key={`${index} ${period}`} className={classes.rowWrap}>
            {index === 0 ? (
              <Typography variant="h3" className={classes.propertyName}>
                {t('Outlet controlled from')}
              </Typography>
            ) : (
              <Typography variant="h3" className={classes.propertyName}>
                {t('From capitalized')}
              </Typography>
            )}
            <div className={classes.propertyValueWrap}>
              <Typography variant="h3" className={classes.dateFrom}>
                {period.controlledFrom}
              </Typography>
              <Typography variant="h3" style={{ fontSize: '0.95rem' }}>
                {t('To capitalized')}
              </Typography>
              <Typography variant="h3" className={classes.dateTo}>
                {period.controlledTo ? period.controlledTo : '-'}
              </Typography>
            </div>
          </div>
        ))}

        <Divider className={classes.midDivider} />

        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet the type')}
          </Typography>
          <Typography variant="h3" className={classes.propertyValue}>
            {outletTypeName}
          </Typography>
        </div>

        <Divider className={classes.midDivider} />

        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet the group')}
          </Typography>
          <Typography variant="h3" className={classes.propertyValue}>
            {outletGroupName}
          </Typography>
        </div>
        <Divider className={classes.midDivider} />
        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet the enterprise type')}
          </Typography>
          <Typography variant="h3" className={classes.propertyValue}>
            {controlPeriods ? (
              <>
                {controlPeriods.length > 0
                  ? controlPeriods[controlPeriods?.length - 1].enterpriseTypeName
                  : '-'}
              </>
            ) : (
              '-'
            )}
          </Typography>
        </div>

        <Divider className={classes.midDivider} />

        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet the international number')}
          </Typography>
          <Typography variant="h3" className={classes.propertyValue}>
            {issn || isbn || '-'}
          </Typography>
        </div>
      </div>

      <div className={classes.infoColumn}>
        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet the professional misconducts')}
          </Typography>
          {linkToExtendedData(
            inProfessionalMisconducts || false,
            InsitutionDataTypes.PROFESSIONAL_MISCONDUCT
          )}
        </div>
        <Divider className={classes.midDivider} />
        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet the ethical non compliances')}
          </Typography>
          {linkToExtendedData(
            inEthicalNonCompliances || false,
            InsitutionDataTypes.ETHICAL_NONCOMPLIANCE
          )}
        </div>
        <Divider className={classes.midDivider} />
        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet the edition checks')}
          </Typography>
          {linkToExtendedData(inEditionChecks || false, InsitutionDataTypes.EDITION_CHECK)}
        </div>
        <Divider className={classes.midDivider} />
        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet the yearly payment')}
          </Typography>
          {linkToExtendedData(inAnnualPayments || false, InsitutionDataTypes.PAYMENT)}
        </div>
        <Divider className={classes.midDivider} />
        <div className={classes.rowWrap}>
          <Typography variant="h3" className={classes.propertyName}>
            {t('Outlet the funds received')}
          </Typography>
          {linkToExtendedData(inFundsReceived || false, InsitutionDataTypes.FUNDS_RECEIVED)}
        </div>
      </div>
    </div>
  );
};
