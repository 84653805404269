import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { ColumnHeader, Order } from '../../../../../../utils/tableTypes';

export type OutletEditionColumnHeaderField = 'periodName' | 'circulation';

export type OutletEditionColumnsDisplayStatus = {
  periodName: boolean;
  circulation: boolean;
};

export type OutletEditionTableCustomFilter = {
  periodName: string[];
  circulation: string[];
};

export type OutletEditionColumnHeader = ColumnHeader<OutletEditionColumnHeaderField>;

export interface OutletEditionTableState {
  periodic: boolean;
  sortBy: OutletEditionColumnHeaderField;
  order: Order;
  displayFilter: filterOptions;
  page: number;
  pagesCount: number;
  rowsPerPage: number;
  columnsDisplayStatus: OutletEditionColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: OutletEditionTableCustomFilter;
}

export const initialOutletEditionTableState: OutletEditionTableState = {
  periodic: false,
  order: 'asc',
  sortBy: 'periodName',
  displayFilter: 'allItems',
  page: 0,
  pagesCount: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    periodName: true,
    circulation: true
  },
  customFilterOn: false,
  customFilter: {
    periodName: [],
    circulation: []
  }
};

export type OutletEditionTableAction =
  | {
      type: 'TABLE_INITIALIZED';
      periodic: boolean;
    }
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: OutletEditionColumnHeaderField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: OutletEditionColumnHeaderField }
  | {
      type: 'COLUMN_DISPLAY_TOGGLED';
      column: OutletEditionColumnHeaderField;
    }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number }
  | { type: 'SET_DISPLAY_FILTER'; value: filterOptions };
