import moment from 'moment';
import { ShareholderVirsOutletData } from '../../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { VirsOutletDataTableState } from './tableTypes';

export function displayDataFilter(
  data: ShareholderVirsOutletData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderVirsOutletData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(
          item.controlledFrom,
          item.controlledTo || undefined,
          undefined,
          '[]'
        );
      });
    case 'selectedDate':
      return data.filter((item) => {
        return moment(chosenDate).isBetween(
          item.controlledFrom,
          item.controlledTo || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function filterAndSortTableData(
  tableState: VirsOutletDataTableState,
  data: ShareholderVirsOutletData[],
  chosenDate: string
): ShareholderVirsOutletData[] {
  const columnsFilters = tableState.customFilter;

  const filters: Filter<ShareholderVirsOutletData>[] = [
    getStringMappedColumnFilter(columnsFilters.outletName, ({ outletName }) => [outletName]),
    getStringMappedColumnFilter(columnsFilters.outletTypeName, ({ outletTypeName }) => [
      outletTypeName
    ]),
    getStringMappedColumnFilter(columnsFilters.outletGroupName, ({ outletGroupName }) => [
      outletGroupName
    ]),
    getStringMappedColumnFilter(columnsFilters.establishedDate, ({ establishedDate }) => [
      establishedDate
    ]),
    getStringMappedColumnFilter(columnsFilters.controlledFrom, ({ controlledFrom }) => [
      controlledFrom
    ]),
    getStringMappedColumnFilter(columnsFilters.controlledTo, ({ controlledTo }) => [controlledTo]),
    getStringMappedColumnFilter(columnsFilters.internationalNumber, ({ internationalNumber }) => [
      internationalNumber
    ])
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);

  const filtered = filterData(displayFiltered, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}
