import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';

import { useTranslation } from 'react-i18next';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { useVirsEnterpriseTableDispatch, useVirsEnterpriseTableState } from './EnterpriseSection';
import { EnterpriseDataTableField, VirsEnterpriseTableState } from './tableState/tableTypes';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';

export const EnterpriseTableHead: React.FC = () => {
  const { state: tableState } = useVirsEnterpriseTableState();
  const { dispatch: tableDispatch } = useVirsEnterpriseTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: EnterpriseDataTableField) => {
    tableDispatch({ type: 'SET_SORTING', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsEnterpriseTableState,
    typeof handleSetSortByColumn,
    EnterpriseDataTableField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.enterpriseTypeName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS enterprise enterpriseTypeName')}
            columnName="enterpriseTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
            key="valid"
          >
            <Typography variant="h4">{t('VIRS enterprise active')}</Typography>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('VIRS enterprise validFrom')}
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('VIRS enterprise validTo')}
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
