import { Typography } from '@material-ui/core';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { VirsPoliticalAdFinancialSupportTable } from './PoliticalAdFinancialSupportTable';
import { VirsPoliticalAdFinancialSupportDatatableReducer } from './tableState/tableReducer';
import {
  initialVirsPoliticalAdFinancialSupportState,
  VirsPoliticalAdFinancialSupportDataTableState,
  VirsPoliticalAdFinancialSupportTableAction
} from './tableState/tableTypes';

interface Props {
  chosenDate: string;
}

const VirsPoliticalAdFinancialSupportTableStateContext = createContext<{
  state: VirsPoliticalAdFinancialSupportDataTableState;
}>({
  state: initialVirsPoliticalAdFinancialSupportState
});

const VirsPoliticalAdFinancialSupportTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsPoliticalAdFinancialSupportTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const PoliticalAdFinancialSupportSection: React.FC<Props> = ({ chosenDate }) => {
  const [state, dispatch] = useReducer(
    VirsPoliticalAdFinancialSupportDatatableReducer,
    initialVirsPoliticalAdFinancialSupportState
  );

  const { t } = useTranslation();

  return (
    <VirsPoliticalAdFinancialSupportTableStateContext.Provider value={{ state }}>
      <VirsPoliticalAdFinancialSupportTableDispatchContext.Provider value={{ dispatch }}>
        <Typography variant="subtitle2" style={{ marginBottom: '25px' }}>
          {t('VIRS political ad info message')}
        </Typography>
        <VirsPoliticalAdFinancialSupportTable chosenDate={chosenDate} />
      </VirsPoliticalAdFinancialSupportTableDispatchContext.Provider>
    </VirsPoliticalAdFinancialSupportTableStateContext.Provider>
  );
};

export function useVirsPoliticalAdFinancialSupportTableState() {
  return useContext(VirsPoliticalAdFinancialSupportTableStateContext);
}
export function useVirsPoliticalAdFinancialSupportTableDispatch() {
  return useContext(VirsPoliticalAdFinancialSupportTableDispatchContext);
}
