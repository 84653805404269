import React from 'react';
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

interface Props {
  onChange:
    | ((
        event: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>,
        child: React.ReactNode
      ) => void)
    | undefined;
}

const useStyles = makeStyles({
  navbarSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '11px 6px 11px 6px',
    color: 'white'
  },
  icon: {
    fill: '#FFFFFF'
  }
});

const LanguageSelector: React.FC<Props> = ({ onChange }) => {
  const classes = useStyles();
  const { language } = useSelector((state: ApplicationState) => state.virsis);

  return (
    <FormControl className={classes.navbarSection}>
      <Select
        disableUnderline
        value={language}
        onChange={onChange}
        style={{ fontWeight: 600 }}
        SelectDisplayProps={{
          style: {
            color: 'white',
            paddingLeft: '8px',
            minWidth: '20px'
          }
        }}
        inputProps={{
          classes: {
            icon: classes.icon
          }
        }}
      >
        <MenuItem value="lt">LT</MenuItem>
        <MenuItem value="en">ENG</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
