import React from 'react';
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

export interface BreadCrumbLink {
  label: string;
  path: string;
  enabled: boolean;
}

interface Props {
  links: BreadCrumbLink[];
}

const useStyles = makeStyles({
  linkOverride: {
    '& a': {
      textDecoration: 'none'
    }
  },
  link: {
    fontWeight: 'bold'
  }
});

export const VirsisBreadCrumbs: React.FC<Props> = ({ links }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const language = useSelector((state: ApplicationState) => state.virsis.language);

  function createBreadCrumbs({
    enabled,
    path,
    label
  }: {
    enabled: boolean;
    path: string;
    label: string;
  }) {
    if (enabled) {
      return (
        <Link key={label} to={path}>
          <Typography color="primary" className={classes.link}>
            {label}
          </Typography>
        </Link>
      );
    }
    return (
      <Typography key={label} color="textSecondary" className={classes.link}>
        {label}
      </Typography>
    );
  }

  return (
    <Breadcrumbs className={classes.linkOverride} aria-label="breadcrumb">
      <Link to={language === 'lt' ? '/' : '/home'}>
        <Typography color="primary" className={classes.link}>
          {t('Home')}
        </Typography>
      </Link>

      {links.map((link) => createBreadCrumbs(link))}
    </Breadcrumbs>
  );
};
