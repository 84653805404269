import { VirsPaymentDataColumnHeader } from './tableTypes';

export const columnNames: VirsPaymentDataColumnHeader[] = [
  {
    id: 'decisionDate',
    numeric: false,
    label: 'Sprendimo data'
  },
  {
    id: 'paymentDocuments',
    numeric: false,
    label: 'Sprendimo nr.'
  },
  {
    id: 'paymentOutlets',
    numeric: false,
    label: 'Susijusios VIP'
  },
  {
    id: 'paymentAmount',
    numeric: false,
    label: 'Įmokos dydis'
  },
  {
    id: 'validFrom',
    numeric: false,
    label: 'Galioja nuo'
  },
  {
    id: 'validTo',
    numeric: false,
    label: 'Galioja iki'
  },
  {
    id: 'reportingInstitutionName',
    numeric: false,
    label: 'Įmoką nustatė'
  }
];
