import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../store';
import { LegalSearchWrapper } from './LegalSearchWrapper';
import { LegalSearchResults } from './LegalSearchResults';

const LegalSearch: React.FC = () => {
  const { loadingLegalSearchResults, legalSearchResults, legalSearchResultsError } = useSelector(
    (state: ApplicationState) => state.virsSearchData
  );
  const { t } = useTranslation();
  const [querySize, setQuerySize] = useState<number>(0);

  return (
    <Container disableGutters maxWidth="lg">
      <LegalSearchWrapper setQuerySize={setQuerySize} />
      <Grid container item direction="column" justify="flex-end" alignItems="flex-start">
        {legalSearchResultsError?.message && (
          <Typography variant={'h5'} style={{ margin: '1rem 0' }}>{`${t('Responded with error')}. ${
            legalSearchResultsError.message
          }`}</Typography>
        )}
        {!loadingLegalSearchResults && legalSearchResults === null && querySize > 0 && (
          <div style={{ margin: '1rem 0' }}>
            <Typography variant="h5">{t('No VIRS found by query')}.</Typography>
            <Typography>
              {querySize === 2 && `${t('No VIRS found legal person advice')}.`}
            </Typography>
          </div>
        )}
        {legalSearchResults && legalSearchResults.length > 0 && (
          <LegalSearchResults legalSearchResults={legalSearchResults} />
        )}
      </Grid>
    </Container>
  );
};
export default LegalSearch;
