import * as React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import './utils/i18n/i18nconfig';
import { ApplicationState } from './store';
import './App.css';
import MainRoute from './pages/MainRoute';

interface Props {
  store: Store<ApplicationState>;
}

const App: React.FC<Props> = ({ store }) => (
  <Provider store={store}>
    <div className="App">
      <MainRoute />
    </div>
  </Provider>
);

export default App;
