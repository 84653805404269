import { Button, createStyles, Divider, makeStyles } from '@material-ui/core';
import React from 'react';

import virsisTheme from '../../../../style/virsisTheme';

export interface ShareholderPreviewCardSubNavTab {
  open: boolean;
  tabName: string;
  key: string;
  url: string | null;
}

interface Props {
  tabs: ShareholderPreviewCardSubNavTab[];
  onTabClick: (key: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    navItem: {
      margin: '0 0 0 25px',
      color: virsisTheme.palette.primary.main,
      padding: 0,
      height: 'auto',
      lineHeight: '1.167',
      fontSize: '0.9rem',
      fontWeight: 'bold'
    },
    botDivider: {
      margin: '20px 0 28px'
    },
    open: {
      color: '#172D46',
      pointerEvents: 'none'
    }
  })
);

export const NavigationBar: React.FC<Props> = ({ tabs, onTabClick }) => {
  const classes = useStyles();
  return (
    <div>
      <div>
        {tabs.map((tab) => (
          <Button
            className={`${classes.navItem} ${tab.open && classes.open}`}
            key={tab.tabName}
            onClick={() => onTabClick(tab.key)}
          >
            {tab.tabName}
          </Button>
        ))}
      </div>
      <Divider className={classes.botDivider} />
    </div>
  );
};
