import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DocumentActionButton } from './DocumentActionButton';
import { useTranslation } from 'react-i18next';

interface Props {
  documentId: number;
  onClick: (documentId: number) => void;
}
export const DownloadDocumentButton: React.FC<Props> = ({ documentId, onClick }) => {
  const { t } = useTranslation();
  return (
    <DocumentActionButton
      onClick={() => onClick(documentId)}
      IconComponent={GetAppIcon}
      text={t('Download')}
    />
  );
};
