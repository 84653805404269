import React from 'react';
import { useField, useFormikContext } from 'formik';
import { IconButton, TextField, TextFieldProps, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import { FormikFieldStyles, useDialogFormStyles } from './FieldStyles';

type FormikTextFieldProps = {
  formikKey: string;
  error: boolean;
  length: number;
  setFieldValue?: () => void;
  clearIcon?: boolean;
  disabled?: boolean;
} & TextFieldProps &
  FormikFieldStyles;

interface Props extends FormikFieldStyles {
  styledClasses?: Record<string, string>;
  error: string | undefined;
  formikKey: string;
  label?: string;
  values: any;
  setFieldValue: (id: string, value: string) => void;
  errorText: boolean;
  required?: boolean;
  maxLength?: number;
  withoutLabel?: boolean;
  isMultiline?: boolean;
  rowsCount?: number;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const FormikTextField = ({
  error,
  formikKey,
  length,
  setFieldValue,
  clearIcon,
  disabled,
  style,
  ...props
}: FormikTextFieldProps) => {
  const [field] = useField(formikKey);

  return (
    <TextField
      error={error}
      id={field.name}
      name={field.name}
      value={field.value ? field.value : ''}
      onChange={field.onChange}
      onBlur={field.onBlur}
      FormHelperTextProps={{ style: { ...style?.helper } }}
      inputProps={
        field.value && clearIcon
          ? {
              maxLength: length,
              endAdornment: (
                <IconButton onClick={() => setFieldValue}>
                  <ClearIcon />
                </IconButton>
              )
            }
          : { maxLength: length }
      }
      disabled={disabled}
      {...props}
    />
  );
};

export const FormikFormTextField: React.FC<Props> = ({
  maxLength,
  styledClasses,
  error,
  formikKey,
  label,
  values,
  setFieldValue,
  errorText,
  withoutLabel,
  rowsCount,
  isMultiline,
  placeholder,
  disabled,
  style,
  fullWidth
}) => {
  const defaultClasses = useDialogFormStyles();
  const classes = styledClasses || defaultClasses;
  const { submitForm } = useFormikContext();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitForm();
    }
  };

  return (
    <Box margin={1} className={classes.inputContainer} style={style?.container}>
      {!withoutLabel && (
        <Typography variant="h5" className={classes.inputLabel}>
          {label}
        </Typography>
      )}
      <FormikTextField
        onKeyDown={handleKeyPress}
        length={maxLength || 60}
        helperText={errorText ? error : undefined}
        error={!!error}
        className={classes.textField}
        formikKey={formikKey}
        style={style}
        variant="outlined"
        rows={rowsCount}
        multiline={isMultiline}
        placeholder={placeholder}
        disabled={disabled}
        fullWidth={fullWidth}
      />
      {values && (
        <IconButton
          className={classes.clearButton}
          disabled={disabled}
          onClick={() => {
            setFieldValue(formikKey, '');
          }}
        >
          <ClearIcon className={classes.clearIcon} />
        </IconButton>
      )}
    </Box>
  );
};
