import React from 'react';
import { Order } from '../../utils/tableTypes';
import FilterTool from './FilterTool';
import SelectionTool from './SelectionTool';
import SortByTool from './SortByTool';

export interface TableColumn {
  id: string;
  numeric: boolean;
  label: string;
}

export interface ColumnsDisplayStatus {
  [x: string]: boolean;
}

interface Props {
  tableColumns: TableColumn[];
  showFilter: boolean;
  onFilterTabToggle: () => void;
  sortOrder: Order;
  sortBy: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSortByColumnClick: (columnId: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleDisplayColumn: (columnId: any) => void;
  columnsDisplayStatus: ColumnsDisplayStatus;
  filterDisabled?: boolean;
  sortingDisabled?: boolean;
  selectingDisabled?: boolean;
  allToolsDisabled?: boolean;
}

const TableCustomTools: React.FC<Props> = ({
  tableColumns,
  showFilter,
  onFilterTabToggle,
  sortOrder,
  sortBy,
  onSortByColumnClick,
  columnsDisplayStatus,
  toggleDisplayColumn,
  filterDisabled,
  sortingDisabled,
  selectingDisabled,
  allToolsDisabled
}) => {
  return (
    <>
      <FilterTool
        onFilterTabToggle={onFilterTabToggle}
        showFilter={showFilter}
        disabled={allToolsDisabled || filterDisabled}
      />

      <SortByTool
        order={sortOrder}
        sortBy={sortBy}
        onSortByColumnClick={onSortByColumnClick}
        columnNames={tableColumns}
        disabled={allToolsDisabled || sortingDisabled}
      />

      <SelectionTool
        columnNames={tableColumns}
        columnsDisplayStatus={columnsDisplayStatus}
        onDisplayColumnChecked={toggleDisplayColumn}
        disabled={allToolsDisabled || selectingDisabled}
      />
    </>
  );
};

export default TableCustomTools;
