import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { ShareholderVirsFundsReceivedData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { VirsFundsReceivedTable } from './FundsReceivedTable';
import { virsFundsReceivedDataTableReducer } from './tableState/tableReducer';
import {
  initialVirsFundsReceivedDataTableState,
  VirsFundsReceivedDataTableAction,
  VirsFundsReceivedDataTableState
} from './tableState/tableTypes';

interface Props {
  chosenDate: string;
  onClickOutlet: (outletId: number, outletName: string) => void;
  dataFilter?: (data: ShareholderVirsFundsReceivedData) => boolean;
}

const VirsFundsReceivedTableStateContext = createContext<{
  state: VirsFundsReceivedDataTableState;
}>({
  state: initialVirsFundsReceivedDataTableState
});

const VirsFundsReceivedTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsFundsReceivedDataTableAction>;
  dataFilter: (data: ShareholderVirsFundsReceivedData) => boolean;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
  dataFilter: () => false
});

export const FundsReceivedSection: React.FC<Props> = ({
  chosenDate,
  onClickOutlet,
  dataFilter
}) => {
  const [state, dispatch] = useReducer(
    virsFundsReceivedDataTableReducer,
    initialVirsFundsReceivedDataTableState
  );

  return (
    <VirsFundsReceivedTableStateContext.Provider value={{ state }}>
      <VirsFundsReceivedTableDispatchContext.Provider
        value={{ dispatch, dataFilter: dataFilter || (() => true) }}
      >
        <VirsFundsReceivedTable chosenDate={chosenDate} onClickOutlet={onClickOutlet} />
      </VirsFundsReceivedTableDispatchContext.Provider>
    </VirsFundsReceivedTableStateContext.Provider>
  );
};

export function useVirsFundsReceivedTableState() {
  return useContext(VirsFundsReceivedTableStateContext);
}
export function useVirsFundsReceivedTableDispatch() {
  return useContext(VirsFundsReceivedTableDispatchContext);
}
