import { ColumnHeader, Order } from '../../../../../../utils/tableTypes';

export type VirsPaymentDataHeaderField =
  | 'decisionDate'
  | 'paymentDocuments'
  | 'paymentOutlets'
  | 'paymentAmount'
  | 'validFrom'
  | 'validTo'
  | 'reportingInstitutionName';

export type VirsPaymentDataColumnsDisplayStatus = {
  decisionDate: boolean;
  paymentDocuments: boolean;
  paymentOutlets: boolean;
  paymentAmount: boolean;
  validFrom: boolean;
  validTo: boolean;
  reportingInstitutionName: boolean;
};

export type VirsPaymentDataCustomFilter = {
  decisionDate: string[];
  paymentDocuments: string[];
  paymentOutlets: string[];
  paymentAmount: string[];
  validFrom: string[];
  validTo: string[];
  reportingInstitutionName: string[];
};

export type VirsPaymentDataColumnHeader = ColumnHeader<VirsPaymentDataHeaderField>;

export interface VirsPaymentDataTableState {
  sortBy: VirsPaymentDataHeaderField;
  order: Order;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: VirsPaymentDataColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: VirsPaymentDataCustomFilter;
}

export type VirsPaymentDataTableAction =
  | { type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' }
  | {
      type: 'CUSTOM_FILTER_VALUE_CHANGED';
      filterBy: VirsPaymentDataHeaderField;
      value: string | null;
    }
  | { type: 'SORTING_CHANGED'; sortBy: VirsPaymentDataHeaderField }
  | { type: 'COLUMN_DISPLAY_TOGGLED'; column: VirsPaymentDataHeaderField }
  | { type: 'PAGE_SET'; page: number }
  | { type: 'ROWS_PER_PAGE_SET'; rowsPerPage: number };

export const initialVirsPaymentDataTableState: VirsPaymentDataTableState = {
  order: 'desc',
  sortBy: 'decisionDate',
  page: 0,
  rowsPerPage: 5,
  columnsDisplayStatus: {
    decisionDate: true,
    paymentDocuments: true,
    paymentOutlets: true,
    paymentAmount: true,
    validFrom: true,
    validTo: true,
    reportingInstitutionName: true
  },
  customFilterOn: false,
  customFilter: {
    decisionDate: [],
    paymentDocuments: [],
    paymentOutlets: [],
    paymentAmount: [],
    validFrom: [],
    validTo: [],
    reportingInstitutionName: []
  }
};
