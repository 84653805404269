import { OptionedFormState } from '../../components/FormikFields/GenericFormikWrappers/types';

export interface ReportInput {
  report: string | null;
  virsIds: number[];
  fromDate: Date | null;
  toDate: Date | null;
  virsEnterpriseTypeIds: number[];
  outletTypeIds: number[];
  outletIds: number[];
  sortByColumn: string | null;
  dataBlock: string | null;
  fileFormat: string | null;
  todayReport: boolean;
  virsSelectEnabled: boolean;
  institutionSelectEnabled: boolean;
  institutionIds: number[];
  classifierDataBlockIds: string[];
}

export interface PrefilledReportInputValues {
  report: string;
  institutionIds: number[];
}

export interface FormOption<TValue> {
  value: TValue;
  label: string;
}

export interface ReportSettings {
  timeFilter: boolean;
  virsFilter: boolean;
  institutionFilter: boolean;
  virsEnterpriseFilter: boolean;
  outletTypeFilter: boolean;
  outletNameFilter: boolean;
  sorting: boolean;
  columnFilter: boolean;
  classifierBlocks: boolean;
}

export const initialValues: ReportInput = {
  report: null,
  virsIds: [],
  dataBlock: null,
  sortByColumn: null,
  fileFormat: null,
  fromDate: null,
  toDate: null,
  outletIds: [],
  outletTypeIds: [],
  virsEnterpriseTypeIds: [],
  todayReport: true,
  virsSelectEnabled: false,
  institutionSelectEnabled: false,
  institutionIds: [],
  classifierDataBlockIds: []
};

export const initialReportSettings: ReportSettings = {
  timeFilter: false,
  virsFilter: false,
  institutionFilter: false,
  virsEnterpriseFilter: false,
  columnFilter: false,
  outletTypeFilter: false,
  outletNameFilter: false,
  sorting: false,
  classifierBlocks: false
};

export interface FormOptions {
  reportSettings: ReportSettings;
  reportOptions: FormOption<string>[];
  dataBlockOptions: FormOption<string>[];
  sortOptions: FormOption<string>[];
  fileOptions: FormOption<string>[];
  virsOptions: FormOption<number>[];
  outletTypeOptions: FormOption<number>[];
  outletOptions: FormOption<number>[];
  enterpriseTypeOptions: FormOption<number>[];
  institutionOptions: FormOption<number>[];
  prefilledValues?: PrefilledReportInputValues;
}

export type ReportState = OptionedFormState<ReportInput, FormOptions>;
