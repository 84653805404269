import React from 'react';
import { GenericDataTableContext } from './GenericDataTableContext';

interface Props {
  chosenDate: string;
  url: string;
}

export const GenericDataTableContainer: React.FC<Props> = ({ chosenDate, url }) => {
  return <GenericDataTableContext chosenDate={chosenDate} url={url} />;
};
