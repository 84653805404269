import axios, { AxiosResponse } from 'axios';

export const api = axios.create({ baseURL: '/api' });

export const get = (url: string): Promise<AxiosResponse<any>> => api.get(url);
export const getBlob = (url: string) => api.get(url, { responseType: 'blob' });
export const post = (url: string, data?: any) => api.post(url, data);
export const axiosDelete = (url: string, data?: any, headers?: any) =>
  api.delete(`${url}`, { data, headers });
export const put = (url: string, data?: any, headers?: any) => api.put(`${url}`, data);

export const getWithQuery = (url: string, queryParams: Record<string, any>) =>
  api.get(url, queryParams);
