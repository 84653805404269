import React from 'react';
import ReactFlow, { Elements, ReactFlowProvider } from 'react-flow-renderer';
import { Moment } from 'moment';
import { VirsComponent } from './VirsComponent';
import { OutletComponent } from './OutletComponent';
import { ShareholderComponent } from './ShareholderComponent';
import { GroupComponent } from './GroupComponent';
import { ChartVirsData } from '../../../store/virsTree/virsTreeDataTypes';
import HorizontalFlowChartControls from './TreeChartControls';
import {
  createInitialFlowElements,
  filterElementsByState,
  getLayoutedElements
} from './treeChartFunctions/treeFlowFunctions';
import {
  useVirsTreeDispatch,
  useVirsTreeState
} from '../../../pages/VirsTreePage/VirsTreePageContext';

const nodeTypes = {
  virsNode: VirsComponent,
  outletNode: OutletComponent,
  shareholderNode: ShareholderComponent,
  shareholderGroupNode: GroupComponent
};

interface Props {
  chartData: ChartVirsData | undefined;
  activeDate: Moment;
}
const TreeChart: React.FC<Props> = ({ chartData, activeDate }) => {
  const { treeState } = useVirsTreeState();
  const { treeDispatch } = useVirsTreeDispatch();

  const expandTree = () => {
    treeDispatch({
      type: 'EXPAND_TREE'
    });
  };

  const collapseTree = () => {
    treeDispatch({
      type: 'COLLAPSE_TREE'
    });
  };

  const setLevel = (level: number) => {
    treeDispatch({
      type: 'SHOW_UP_TO_LEVEL',
      level
    });
  };

  const initialFlowElements: Elements = chartData
    ? createInitialFlowElements(chartData, activeDate.format('L'))
    : [];

  const elementsByState: Elements = chartData
    ? filterElementsByState(chartData, initialFlowElements, treeState.showingShareholders)
    : [];

  const layoutedElements = getLayoutedElements(elementsByState, treeState);

  return (
    <ReactFlowProvider>
      <div className="layoutflow">
        <ReactFlow
          elements={layoutedElements}
          nodeTypes={nodeTypes}
          nodesDraggable={false}
          zoomOnScroll={false}
          nodesConnectable={false}
          minZoom={0.1}
        />
        <HorizontalFlowChartControls
          expandTree={expandTree}
          collapseTree={collapseTree}
          setLevel={setLevel}
          showingElements={elementsByState}
          showingShareholders={treeState.showingShareholders}
          allShareholders={treeState.allShareholders}
          activeDate={activeDate.format('L')}
        />
      </div>
    </ReactFlowProvider>
  );
};
export default TreeChart;
