import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { VirsTreeCustomTooltip } from '../../VirsTreeCustomTooltip';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontStyle: 'italic',
    fontSize: '0.7rem',
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    overflow: 'hidden'
  },
  title: {
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    overflow: 'hidden',
    maxHeight: '40px',
    fontSize: '0.73rem'
  }
}));

export interface Props {
  title: string;
  authorized?: boolean;
}

export const GroupBoxTitle: React.FC<Props> = ({ title }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      {title && title.length > 30 ? (
        <VirsTreeCustomTooltip text={title}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
        </VirsTreeCustomTooltip>
      ) : (
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      )}

      <Typography variant="h6" className={classes.subtitle}>
        {t('Shareholders authorized person')}
      </Typography>
    </>
  );
};
