import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import MomentUtils from '@date-io/moment';

import pako from 'pako';
import { encode, decode } from 'base64-arraybuffer';
import { escape, unescape } from '../../utils/base64url';

import { Box, Button, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FieldGroup } from '../../components/FormikFields/GenericFormikWrappers/FieldGroup';

import { FormOption, initialValues, ReportInput } from '../../store/reports/types';
import { generateReportFile, loadReportOptions, setReportOptions } from '../../store/reports/store';
import { FormContent } from './FormContent';
import {
  noUndefinedString,
  noUndefinedDate
} from '../../components/FormikFields/GenericFormikWrappers/validationSchemas';
import { ApplicationState } from '../../store';
import { language as languageSelector } from '../../store/virsisApp/virsisSelectors';
import { useLocation } from 'react-router';
import { searchByLegalDataSuccess } from '../../store/virsSearch/virsSearchActions';
import { LegalPersonSearchData } from '../../store/virsSearch/virsSearchTypes';
import { sendMessage } from '../../store/errorOrSuccessMessage/errorOrSuccessMessageAction';

export const ReportForm: React.FC = () => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const { error, isLoading } = useSelector((state: ApplicationState) => state.report);
  const { legalSearchResults } = useSelector((state: ApplicationState) => state.virsSearchData);
  const { t } = useTranslation();
  const { options } = useSelector((state: ApplicationState) => state.report);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const canSeeButton = searchParams.get('ref') === 'true';

  const getVirsOptions = (legalSearchResults: LegalPersonSearchData[] | null) =>
    legalSearchResults
      ?.map(({ virsResults }) =>
        virsResults
          .filter(({ virsRole }) => virsRole === 'VIRS')
          .map(({ virsId, virsLegalName }) => ({
            value: virsId,
            label: virsLegalName
          }))
      )
      .reduce((prev, value) => (value ? [...prev, ...value] : prev), [])
      .filter((option, i, arr) => !arr.slice(0, i).some(({ value }) => value === option.value)) ||
    [];

  const getInstitutionOptions = (legalSearchResults: LegalPersonSearchData[] | null) =>
    legalSearchResults
      ?.map((result) => ({
        value: result.personId,
        label: result.legalName
      }))
      .filter((option, i, arr) => !arr.slice(0, i).some(({ value }) => value === option.value)) ||
    [];

  useEffect(() => {
    const parameter = searchParams.get('p');
    if (parameter) {
      const params = JSON.parse(pako.inflate(decode(unescape(parameter)), { to: 'string' }));
      if (params.report === 'A6') {
        dispatch(
          searchByLegalDataSuccess({
            virsResultsByLegalPerson:
              params.legalSearchResults === undefined ? null : params.legalSearchResults
          })
        );
        dispatch(
          loadReportOptions('A6', {
            report: 'A6',
            institutionIds: params.institutionIds
          })
        );

        const virsOptions: FormOption<number>[] = getVirsOptions(
          params.legalSearchResults as LegalPersonSearchData[] | null
        );
        const institutionOptions: FormOption<number>[] = getInstitutionOptions(
          params.legalSearchResults as LegalPersonSearchData[] | null
        );

        dispatch(setReportOptions({ ...options, virsOptions, institutionOptions }));
      }
    } else {
      dispatch(loadReportOptions());
    }
  }, [dispatch]);

  const validationSchema: Yup.ObjectSchema<Yup.Shape<object | undefined, ReportInput>> =
    Yup.object().shape({
      report: noUndefinedString(),
      virsIds: Yup.array<number>().defined(),
      dataBlock: noUndefinedString(),
      fileFormat: noUndefinedString(),
      sortByColumn: noUndefinedString(),
      fromDate: noUndefinedDate().when('todayReport', {
        is: false,
        then: Yup.date()
          .required(t('Validation reports required'))
          .max(moment().endOf('day').toDate(), t('Validation reports not future'))
          .typeError(t('Validation reports incorrect date'))
      }),
      toDate: noUndefinedDate()
        .min(Yup.ref('fromDate'), t('Validation reports not before from date'))
        .max(moment().endOf('day').toDate(), t('Validation reports not future')),
      todayReport: Yup.boolean().required(),
      virsSelectEnabled: Yup.boolean().required(),
      outletIds: Yup.array<number>().defined(),
      virsEnterpriseTypeIds: Yup.array<number>().defined(),
      outletTypeIds: Yup.array<number>().defined(),
      institutionSelectEnabled: Yup.boolean().required(),
      institutionIds: Yup.array<number>().defined(),
      classifierDataBlockIds: Yup.array<string>().defined()
    });

  const onGetReferenceClick = (innerProps: FormikProps<ReportInput>) => {
    const reference =
      window.location.href.split('?')[0] +
      '?p=' +
      escape(
        encode(
          pako.deflate(
            JSON.stringify({
              report: 'A6',
              institutionIds: innerProps.values.institutionIds,
              legalSearchResults
            })
          )
        )
      );
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(reference);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = reference;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    }
    dispatch(sendMessage('success', t('Reference copied')));
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(generateReportFile(values));
        setSubmitting(false);
      }}
    >
      {(innerProps: FormikProps<ReportInput>) => (
        <MuiPickersUtilsProvider locale={language} utils={MomentUtils}>
          <Form>
            <Box style={{ padding: '1em 0 0', overflow: 'auto' }}>
              {!error && <FormContent {...innerProps} />}

              {error && (
                <Typography style={{ padding: '13px' }}>{t('Responded with error')}</Typography>
              )}

              {!error && (
                <FieldGroup
                  style={{
                    container: { marginBottom: '2em' }
                  }}
                >
                  {canSeeButton && (
                    <Button
                      onClick={() => onGetReferenceClick(innerProps)}
                      color="primary"
                      variant="outlined"
                      style={{ flex: '0 0 200px', marginRight: '4px' }}
                      disabled={innerProps.values.report !== 'A6'}
                    >
                      {t('Get reference')}
                    </Button>
                  )}
                  <Button
                    onClick={innerProps.submitForm}
                    color="primary"
                    variant="contained"
                    style={{ flex: '0 0 200px' }}
                    disabled={isLoading}
                  >
                    {t('Get data set')}
                  </Button>
                </FieldGroup>
              )}
            </Box>
          </Form>
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  );
};
