import React from 'react';
import { filterOptions } from '../../store/virsisApp/virsisDataTypes';
import { TableToolsFrame } from '../TableToolFrame/TableToolFrame';
import DisplayFilterTool from '../TableDisplayFilter/DisplayFilterTool';
import TableCustomTools from '../TableCustomTools/TableCustomTools';
import { useGenericTableDispatch, useGenericTableState } from './GenericDataTableContext';
import { getColumns } from './State/utilities';

const GenericDataTableCustomization: React.FC = () => {
  const { state } = useGenericTableState();
  const { dispatch } = useGenericTableDispatch();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' });
  };

  const handleSetSortByColumn = (column: string) => {
    dispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const toggleDisplayColumn = (column: string) => {
    dispatch({ type: 'TOGGLE_DISPLAY_COLUMN', column });
  };

  const handleSetDisplayFilter = (value: filterOptions) => {
    dispatch({ type: 'SET_DISPLAY_FILTER', value });
  };

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        leftSideItem={
          <>
            {state.data && state.data.filteredDates.length !== 0 && (
              <DisplayFilterTool
                disabled={state.data?.rows.length === 0}
                displayFilter={state.displayFilter}
                setDisplayFilter={handleSetDisplayFilter}
              />
            )}
          </>
        }
        rightSideItem={
          <TableCustomTools
            tableColumns={getColumns(state.data)}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default GenericDataTableCustomization;
