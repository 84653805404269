import { Box, CircularProgress, Container, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReportForm } from '../containers/reports/ReportForm';
import { ApplicationState } from '../store';
import { VirsisBreadCrumbs } from '../components/BreadCrumbs/VirsisBreadCrumbs';

export const ReportsPage: React.FC = () => {
  const loading = useSelector((state: ApplicationState) => {
    return state.virsis.getMenuDataLoading;
  });
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <ReportsHeader />

      <Typography variant="h2" style={{ fontSize: '1rem' }}>
        {t('Data set settings')}
      </Typography>

      <Divider />

      {loading && <CircularProgress />}

      {!loading && <ReportForm />}
    </Container>
  );
};

const ReportsHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box style={{ padding: '2em 0' }}>
      <VirsisBreadCrumbs links={[{ enabled: false, label: t('Data sets'), path: '/rinkiniai' }]} />

      <Typography variant="h2">{t('Data sets')}</Typography>
    </Box>
  );
};
