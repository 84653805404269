import { Value, TargetId, MenuItem, MenuData } from './virsisDataTypes';

const changeUrl = (url: string) => {
  if (url === '/java/rinkiniai.jsp') {
    return 'rinkiniai';
  }
  if (url === '/java/paieska.jsp') {
    return 'paieska';
  }
  return url;
};

const valueOf = (fields: Value[]) => {
  if (fields.length) {
    const [field] = fields;
    return changeUrl(field.value);
  }
  return '';
};

const targetIdOf = (fields: TargetId[]) => {
  if (fields.length) {
    const [field] = fields;
    return field.target_id;
  }
  return null;
};

const normaliseMenuData = (menuData: MenuItem[]): MenuData[] => {
  return menuData.map((x) => ({
    fieldId: valueOf(x.field_id),
    fieldUrl: valueOf(x.field_url),
    fieldCmsLink: valueOf(x.field_cms_link),
    fieldLanguage: valueOf(x.field_language),
    tid: valueOf(x.tid),
    name: valueOf(x.name),
    fieldHide: valueOf(x.field_hide),
    parentId: targetIdOf(x.parent),
    subMenu: []
  }));
};

// grąžina HeaderMenu data object'ą
export const getHeaderMenuData = (data: MenuItem[]) => {
  const normalisedMenuData = normaliseMenuData(data);
  const filteredHeaderMenuData = normalisedMenuData.filter((item) => item.name !== 'footer_lt');

  const subMenuData = filteredHeaderMenuData.filter((item) => item.parentId);

  for (let i = 0; i < filteredHeaderMenuData.length; i += 1) {
    for (let j = 0; j < subMenuData.length; j += 1) {
      if (parseFloat(filteredHeaderMenuData[i].tid) === subMenuData[j].parentId) {
        filteredHeaderMenuData[i].subMenu.push(subMenuData[j]);
      }
    }
  }

  return filteredHeaderMenuData;
};

const filterFooterData = (menuData: MenuData[]) => {
  const [footer] = menuData.filter((f) => f.name === 'footer_lt');
  return footer;
};

const filterByLanguage = (menuData: MenuData[], language: string) => {
  return menuData.filter((f) => f.fieldLanguage === language);
};

// grąžina FooterMenu data objektą
export const getFooterData = (data: MenuItem[], language: string) => {
  const normalisedMenuData = normaliseMenuData(data);
  const filteredByLanguage = filterByLanguage(normalisedMenuData, language);
  const filteredFooterData = filterFooterData(filteredByLanguage);
  return filteredFooterData;
};
