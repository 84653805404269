import React, { useState } from 'react';
import { Button, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { VirsTreeCustomTooltip } from '../../VirsTreeCustomTooltip';

const useStyles = makeStyles(() => ({
  text: {
    color: '#637082',
    padding: '1px 15px'
  },
  button: {
    padding: 0,
    borderColor: 'transparent',
    backgroundColor: 'white',
    divShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
    color: '#637082'
  },
  themeButton: {
    '&:hover, &:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  margin: {
    margin: '15px 0px'
  }
}));

interface Props {
  handleDownloadJPEG: () => void;
  handleDownloadPNG: () => void;
  handleDownloadJSON: () => void;
  handleDownloadXML: () => void;
}

export const CustomButtonWithMenu: React.FC<Props> = ({
  handleDownloadJPEG,
  handleDownloadPNG
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const getTooltipText = () => {
    const isIE = navigator.userAgent.indexOf('MSIE') > -1;

    if (isIE) {
      return t('Download image unsupported message');
    }
    return '';
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={`${classes.button} ${classes.themeButton} ${classes.margin}`}
        style={
          anchorEl
            ? {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            : {}
        }
      >
        <Typography variant="h5" className={classes.text}>
          {t('Download data')}
        </Typography>
        <ArrowDropDownIcon style={{ marginRight: 5 }} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <VirsTreeCustomTooltip text={getTooltipText()}>
          <div>
            <MenuItem onClick={handleDownloadJPEG}>JPEG</MenuItem>
            <MenuItem onClick={handleDownloadPNG}>PNG</MenuItem>
          </div>
        </VirsTreeCustomTooltip>
      </Menu>
    </>
  );
};
