import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import { ShareholderVirsAnnualFinancialReportsData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsAnnualFinancialReportsTableState } from './AnnualFinancialReportsSection';
import { ViewDownloadDocButtons } from '../../../../../components/TableButtons/ViewDownloadDocButtons';
import { fetchPdfData } from '../../../../../store/virsisApp/virsisActions';
import { openDocumentInNewTab } from '../../../../../utils/documentFunctions';

interface Props {
  record: ShareholderVirsAnnualFinancialReportsData;
}

export const AnnualFinancialReportsRow: React.FC<Props> = ({ record }) => {
  const reduxDispatch = useDispatch();
  const { state: tableState } = useVirsAnnualFinancialReportsTableState();

  const handleDownload = (reportId: number) => {
    reduxDispatch(
      fetchPdfData(reportId, 'financial-reports', (file) => saveAs(file, `FA_${reportId}.pdf`))
    );
  };

  const handleShowPreview = (reportId: number) => {
    reduxDispatch(
      fetchPdfData(reportId, 'financial-reports', (file) =>
        openDocumentInNewTab(file, `FA_${reportId}.pdf`)
      )
    );
  };

  return (
    <TableRow key={record.annualReportId}>
      {tableState.columnsDisplayStatus.documentHeading && (
        <TableCell>{record.documentHeading}</TableCell>
      )}
      {tableState.columnsDisplayStatus.financialPeriodEndDate && (
        <TableCell>{record.financialPeriodEndDate}</TableCell>
      )}
      <TableCell>
        <ViewDownloadDocButtons
          documentId={record.annualReportId}
          openDocClicked={handleShowPreview}
          downloadDocClicked={handleDownload}
        />
      </TableCell>
    </TableRow>
  );
};
