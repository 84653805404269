import { Typography } from '@material-ui/core';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { VirsIndividualPersonFinancialSupportTable } from './IndividualPersonFinancialSupportTable';
import { virsIndividualPersonFinancialSupportTableReducer } from './tableState/tableReducer';
import {
  initialVirsIndividualPersonFinancialSupportTableState,
  VirsIndividualPersonFinancialSupportDataTableState,
  VirsIndividualPersonFinancialSupportTableAction
} from './tableState/tableTypes';

interface Props {
  chosenDate: string;
}

const VirsIndividualPersonFinancialSupportTableStateContext = createContext<{
  state: VirsIndividualPersonFinancialSupportDataTableState;
}>({
  state: initialVirsIndividualPersonFinancialSupportTableState
});

const VirsIndividualPersonFinancialSupportTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsIndividualPersonFinancialSupportTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const IndividualPersonFinancialSupportSection: React.FC<Props> = ({ chosenDate }) => {
  const [state, dispatch] = useReducer(
    virsIndividualPersonFinancialSupportTableReducer,
    initialVirsIndividualPersonFinancialSupportTableState
  );

  const { t } = useTranslation();

  return (
    <VirsIndividualPersonFinancialSupportTableStateContext.Provider value={{ state }}>
      <VirsIndividualPersonFinancialSupportTableDispatchContext.Provider value={{ dispatch }}>
        <Typography variant="subtitle2" style={{ marginBottom: '25px' }}>
          {t('VIRS individual financial support info message')}
        </Typography>
        <VirsIndividualPersonFinancialSupportTable chosenDate={chosenDate} />
      </VirsIndividualPersonFinancialSupportTableDispatchContext.Provider>
    </VirsIndividualPersonFinancialSupportTableStateContext.Provider>
  );
};

export function useVirsIndividualPersonFinancialSupportTableState() {
  return useContext(VirsIndividualPersonFinancialSupportTableStateContext);
}
export function useVirsIndividualPersonFinancialSupportTableDispatch() {
  return useContext(VirsIndividualPersonFinancialSupportTableDispatchContext);
}
