import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Box, makeStyles } from '@material-ui/core';

import { getPeriodDateByRelativeValue } from './dateUtil';
import { changeActiveDate, setCursorDate } from './state/actions';
import { State } from './state/reducer';
import { VirsDatesContext } from './TimelinePanel';

const useStyles = makeStyles({
  box: { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }
});

export const TimelineCursorOverlay: React.FC = () => {
  const dispatch = useDispatch();
  const visiblePeriod = useSelector((state: State) => state.visiblePeriod);
  const containerWidth = useSelector((state: State) => state.containerWidth);
  const classes = useStyles();

  const { isDateAvailable } = useContext(VirsDatesContext);

  const getDateByPosition = (position?: number) =>
    (containerWidth &&
      visiblePeriod &&
      position &&
      getPeriodDateByRelativeValue(position / containerWidth, visiblePeriod)) ||
    undefined;

  return (
    <Box
      className={classes.box}
      onMouseEnter={(event) =>
        dispatch(setCursorDate(getDateByPosition(event.nativeEvent.offsetX)))
      }
      onMouseLeave={() => dispatch(setCursorDate())}
      onMouseMove={(event) => dispatch(setCursorDate(getDateByPosition(event.nativeEvent.offsetX)))}
      onClick={(event) => {
        const date = getDateByPosition(event.nativeEvent.offsetX);
        const currentDate = moment();

        if (date && date.isSameOrBefore(currentDate) && isDateAvailable(date)) {
          dispatch(changeActiveDate(date));
        }
      }}
    />
  );
};
