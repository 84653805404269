import React from 'react';
import { useTranslation } from 'react-i18next';
import { VirsTreeCustomTooltip } from '../VirsTreeCustomTooltip';

interface Props {
  color: string;
  withTooltip?: boolean;
}

export const IncomeFromSupport: React.FC<Props> = ({ color, withTooltip }) => {
  const svg = (
    <svg width="24.771" height="20.678">
      <path
        d="M6.427 5.541a.644.644 0 00.639.639h.505a4.531 4.531 0 004.2 2.867 4.478 4.478 0 002.258-.609.63.63 0 00-.639-1.084 3.289 3.289 0 01-1.634.446 3.235 3.235 0 01-2.3-.951 3.19 3.19 0 01-.505-.654h3.417a.644.644 0 00.639-.639.634.634 0 00-.639-.639H8.536c-.015-.149-.03-.267-.03-.386a3.66 3.66 0 01.03-.386h3.847a.644.644 0 00.639-.639.634.634 0 00-.639-.639H8.967a3.077 3.077 0 01.505-.654 3.235 3.235 0 012.3-.951 3.128 3.128 0 011.619.446.594.594 0 00.475.059.644.644 0 00.386-.3.631.631 0 00-.223-.862A4.478 4.478 0 0011.775 0a4.551 4.551 0 00-4.219 2.867h-.505a.644.644 0 00-.639.639.634.634 0 00.639.639h.208a3.623 3.623 0 00-.015.386 3.487 3.487 0 00.015.386h-.208a.628.628 0 00-.624.624z"
        fill={color}
      />
      <path
        d="M24.446 10.532a2.079 2.079 0 00-2.7-.728 3.5 3.5 0 00-3.565-.282 2.481 2.481 0 00-2.986-.282l-3.4 2.08-3.58-1.1a7.866 7.866 0 00-4.754.045l-3.045 1a.608.608 0 00-.386.743.594.594 0 00.743.371l3.045-1a6.7 6.7 0 014.041-.045l6.417 1.961a1.185 1.185 0 01.8 1.381 1.173 1.173 0 01-1.278.906l-5.422-.55a.591.591 0 00-.134 1.174l5.437.609a1.614 1.614 0 00.267.015 2.377 2.377 0 002.3-1.842 2.07 2.07 0 00.059-.52l5.972-3.61a.89.89 0 01.906 1.53l-10.086 6.586a3.236 3.236 0 01-2.332.46l-9.864-1.9a.6.6 0 10-.223 1.174l9.864 1.9a4.028 4.028 0 00.832.074 4.285 4.285 0 002.377-.713l10.072-6.566a2.083 2.083 0 00.623-2.871zm-8.631-.267a1.281 1.281 0 011.263-.059l-2.941 1.857-.832-.253zm-.386 2.377l3.194-2.005a2.32 2.32 0 011.931-.119l-4.591 2.777a2.071 2.071 0 00-.534-.653z"
        fill={color}
      />
    </svg>
  );
  const { t } = useTranslation();
  const translation: string = t('Financial support received');

  if (withTooltip) return <VirsTreeCustomTooltip text={translation}>{svg}</VirsTreeCustomTooltip>;
  return svg;
};
