import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { GenericDataTable } from './GenericDataTable';
import { GenericDataTableState, GenericTableAction, initialGenericTableState } from './State/types';
import { genericTableReducer } from './State/Reducer';

interface Props {
  chosenDate: string;
  url: string;
}

export const GenericDataTableStateContext = createContext<{
  state: GenericDataTableState;
}>({
  state: initialGenericTableState
});

export const GenericDataTableDispatchContext = createContext<{
  dispatch: Dispatch<GenericTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const GenericDataTableContext: React.FC<Props> = ({ chosenDate, url }) => {
  const [state, dispatch] = useReducer(genericTableReducer, initialGenericTableState);

  return (
    <GenericDataTableStateContext.Provider value={{ state }}>
      <GenericDataTableDispatchContext.Provider value={{ dispatch }}>
        <GenericDataTable chosenDate={chosenDate} url={url} />
      </GenericDataTableDispatchContext.Provider>
    </GenericDataTableStateContext.Provider>
  );
};

export function useGenericTableState() {
  return useContext(GenericDataTableStateContext);
}
export function useGenericTableDispatch() {
  return useContext(GenericDataTableDispatchContext);
}
