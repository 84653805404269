import { makeStyles } from '@material-ui/core';
import React from 'react';

export interface SquareSymbolProps {
  color: string;
  foregroundGrid?: boolean;
}

const useStyles = makeStyles({
  container: {
    height: '24px',
    width: '24px',
    borderRadius: 7
  },
  foregroundGrid: {
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '500%',
      height: '500%',
      top: '-190%',
      left: '-200%',
      transform: 'rotate(45deg)',
      backgroundImage:
        'linear-gradient(rgba(255,255,255,0.5) 1px, transparent 1px), linear-gradient(90deg, rgba(255,255,255,0.5) 1px, transparent 1px)',
      backgroundSize: '10px 10px'
    }
  }
});

export const SquareSymbol: React.FC<SquareSymbolProps> = ({ color, foregroundGrid = false }) => {
  const customBackground = { background: color };
  const classes = useStyles();
  return (
    <div
      className={`${classes.container} ${foregroundGrid ? classes.foregroundGrid : ''}`}
      style={customBackground}
    />
  );
};
