import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Table, TableBody, Typography } from '@material-ui/core';
import { GenericDataTableState } from './State/types';
import {
  filterAndSortGenericData,
  getCustomFilter,
  getDisplayStatus,
  getFirstKey
} from './State/utilities';
import { useGenericTableDispatch, useGenericTableState } from './GenericDataTableContext';
import { ApplicationState } from '../../store';
import { pageTableData } from '../../utils/tableDataFunctions';
import { GenericTableHead } from './GenericTableHead';
import { GenericTableRow } from './GenericTableRow';
import GenericDataTableCustomization from './GenericDataTableCustomization';
import { GenericDataTableCustomFilter } from './GenericDataTableCustomFilter';
import { ROWS_PER_PAGE_OPTIONS } from '../../store/virsisApp/virsisDataTypes';
import { TablePagination } from '../TablePagination/TablePagination';
import { TableLoader } from '../TableLoader/TableLoad';

interface Props {
  chosenDate: string;
  url: string;
}

export const GenericDataTable: React.FC<Props> = ({ chosenDate, url }) => {
  const { state } = useGenericTableState();
  const { dispatch } = useGenericTableDispatch();
  const { genericData, loading, error } = useSelector(
    (appState: ApplicationState) => appState.genericData
  );

  const genericTableState: GenericDataTableState = {
    order: 'asc',
    sortBy: getFirstKey(genericData),
    displayFilter: 'allItems',
    page: 0,
    recordsPassed: 0,
    pagesCount: 0,
    rowsPerPage: 5,
    columnsDisplayStatus: getDisplayStatus(genericData),
    customFilterOn: false,
    customFilter: getCustomFilter(genericData),
    data: genericData
  };

  useEffect(() => {
    if (state.sortBy.length === 0) {
      dispatch({ type: 'INITIALIZE', state: genericTableState });
    }
  }, [dispatch, url, genericData, chosenDate]);

  useEffect(() => {
    dispatch({ type: 'RESET_STATE' });
  }, [url, chosenDate, dispatch]);

  const handleSetPage = (value: number) => {
    dispatch({ type: 'SET_PAGE', page: value });
  };

  const handleSetRowsPerPage = (rowsPerPage: number) => {
    dispatch({
      type: 'SET_ROWS_PER_PAGE',
      rowsPerPage
    });
  };

  const data = filterAndSortGenericData(state, genericData, chosenDate);

  const { page: dataPage, pagesCount } = pageTableData(state, data);

  return (
    <Paper elevation={0} style={{ display: 'flex' }}>
      <>
        {!genericData || error ? (
          <Typography variant="caption">{error?.message || 'Duomenų gavimo klaida'}</Typography>
        ) : (
          <div className="data-table">
            <GenericDataTableCustomization />
            <Table>
              {genericTableState.data && (
                <GenericTableHead
                  tableState={genericTableState}
                  genericData={genericTableState.data}
                />
              )}
              {state.customFilterOn && <GenericDataTableCustomFilter />}
              <TableBody>
                {loading ? (
                  <TableLoader columns={6} />
                ) : (
                  <>
                    {dataPage.map((row, rowIndex) => (
                      <GenericTableRow key={`${rowIndex} ${row}`} row={row} rowIndex={rowIndex} />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              recordsCount={data.length}
              pagesCount={pagesCount}
              rowsPerPage={state.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={state.page}
              setPage={handleSetPage}
              setRowsPerPage={handleSetRowsPerPage}
              disabled={data.length === 0}
            />
          </div>
        )}
      </>
    </Paper>
  );
};
