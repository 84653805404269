import React from 'react';
import { TableHead, TableRow } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { getSortLabelPropsFactory } from '../../../../../utils/tableDataFunctions';
import {
  useVirsFundsReceivedTableDispatch,
  useVirsFundsReceivedTableState
} from './FundsReceivedSection';
import {
  VirsFundsReceivedDataTableState,
  VirsFundsReceivedDataVirsTableField
} from './tableState/tableTypes';

export const FundsReceivedTableHead: React.FC = () => {
  const { state: tableState } = useVirsFundsReceivedTableState();
  const { dispatch: tableDispatch } = useVirsFundsReceivedTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsFundsReceivedDataVirsTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsFundsReceivedDataTableState,
    typeof handleSetSortByColumn,
    VirsFundsReceivedDataVirsTableField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {tableState.columnsDisplayStatus.fundsReceivedYear && (
          <TableSortingHeaderCell
            label={t('VIRS funds received fundsReceivedYear')}
            columnName="fundsReceivedYear"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.fundsReceivedSum && (
          <TableSortingHeaderCell
            label={t('VIRS funds received fundsReceivedSum')}
            columnName="fundsReceivedSum"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.fundsSourceName && (
          <TableSortingHeaderCell
            label={t('VIRS funds received fundsSourceName')}
            columnName="fundsSourceName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.fundsSourceLegalCode && (
          <TableSortingHeaderCell
            label={t('VIRS funds received fundsSourceLegalCode')}
            columnName="fundsSourceLegalCode"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.outletName && (
          <TableSortingHeaderCell
            label={t('VIRS funds received outletName')}
            columnName="outletName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.transactionType && (
          <TableSortingHeaderCell
            label={t('VIRS funds received transactionType')}
            columnName="transactionType"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
