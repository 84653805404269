import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';

import { useTranslation } from 'react-i18next';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import { useVirsLicensesTableDispatch, useVirsLicensesTableState } from './LicensesSection';
import { VirsLicensesColumnHeaderField, VirsLicensesDataTableState } from './tableState/tableTypes';

export const LicensesTableHead: React.FC = () => {
  const { state: tableState } = useVirsLicensesTableState();
  const { dispatch: tableDispatch } = useVirsLicensesTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsLicensesColumnHeaderField) => {
    tableDispatch({ type: 'SET_SORT_BY_COLUMN', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsLicensesDataTableState,
    typeof handleSetSortByColumn,
    VirsLicensesColumnHeaderField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {(tableState.columnsDisplayStatus.licenseIssueDate ||
          tableState.columnsDisplayStatus.licenseNumber) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.licenseIssueDate,
              tableState.columnsDisplayStatus.licenseNumber
            ])}
          >
            <Typography variant="h4">{t('VIRS licence licenses')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.licenseTypeName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS licence licenseTypeName')}
            columnName="licenseTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.enterpriseTypeName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS licence enterpriseTypeName')}
            columnName="enterpriseTypeName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.licenseStatus && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS licence licenseStatus')}
            columnName="licenseStatus"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">{t('VIRS licence valid')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.issuingInstitutionName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS licence issuingInstitutionName')}
            columnName="issuingInstitutionName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.licenseNotes && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS licence licenseNotes')}
            columnName="licenseNotes"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>

      <TableRow>
        {tableState.columnsDisplayStatus.licenseIssueDate && (
          <TableSortingHeaderCell
            label={t('VIRS licence licenseIssueDate')}
            columnName="licenseIssueDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.licenseNumber && (
          <TableSortingHeaderCell
            label={t('VIRS licence licenseNumber')}
            columnName="licenseNumber"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            label={t('From capitalized')}
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            label={t('To capitalized')}
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
