import { Reducer } from 'redux';
import {
  GET_GENERIC_DATA_ERROR,
  GET_GENERIC_DATA_REQUEST,
  GET_GENERIC_DATA_SUCCESS,
  INITIALIZE
} from './genericDataTableType';
import { GenericData } from '../../components/GenericDataTable/State/types';

export interface GenericDataState {
  genericData?: GenericData;
  error?: Error;
  loading: boolean;
}

const initialGenericData: GenericDataState = {
  genericData: undefined,
  error: undefined,
  loading: false
};

export const genericDataTableReducer: Reducer = (state = initialGenericData, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...initialGenericData
      };
    case GET_GENERIC_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_GENERIC_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_GENERIC_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        genericData: action.payload
      };
    default:
      return state;
  }
};
