import { get } from '../../utils/axiosApi';
import { Language } from '../../utils/tableTypes';

export const getVirsInfoData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/info?lang=${lang}`);

export const getVirsEnterprisesData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/enterprises?lang=${lang}`);

export const getVirsOutletsData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/outlets?lang=${lang}`);

export const getVirsProfessionalMisconductData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/professional-misconducts?lang=${lang}`);

export const getVirsEthicalNonComplianceData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/ethical-non-compliances?lang=${lang}`);

export const getVirsFundsReceivedData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/funds-received?lang=${lang}`);

export const getVirsEditionCheckData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/edition-checks?lang=${lang}`);

export const getVirsPaymentData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/annual-payments?lang=${lang}`);

export const getVirsLicencesData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/licenses?lang=${lang}`);

export const getVirsPoliticalAdRevenueData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/political-ad-revenues?lang=${lang}`);

export const getVirsLegalEntityFinancialSupportData = (
  virsId: number | undefined,
  lang: Language
) => get(`/virs/${virsId}/legal-person-support-received?lang=${lang}`);

export const getVirsIndividualPersonfinancialSupportData = (
  virsId: number | undefined,
  lang: Language
) => get(`/virs/${virsId}/natural-person-support-received?lang=${lang}`);

export const getVirsAnnualFinancialReportsData = (virsId: number | undefined, lang: Language) =>
  get(`/virs/${virsId}/
annual-financial-reports?lang=${lang}`);
