import { action } from 'typesafe-actions';
import {
  EDITION_CHECK_DOCUMENT_PDF_REQUEST,
  PAYMENT_DOCUMENT_PDF_REQUEST,
  VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_ERROR,
  VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_REQUEST,
  VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_SUCCESS,
  VIRS_EDITION_CHECK_DATA_ERROR,
  VIRS_EDITION_CHECK_DATA_REQUEST,
  VIRS_EDITION_CHECK_DATA_SUCCESS,
  VIRS_ENTERPRISE_DATA_ERROR,
  VIRS_ENTERPRISE_DATA_REQUEST,
  VIRS_ENTERPRISE_DATA_SUCCESS,
  VIRS_ETHICAL_NON_COMPLIANCE_DATA_ERROR,
  VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST,
  VIRS_ETHICAL_NON_COMPLIANCE_DATA_SUCCESS,
  VIRS_FUNDS_RECEIVED_DATA_ERROR,
  VIRS_FUNDS_RECEIVED_DATA_REQUEST,
  VIRS_FUNDS_RECEIVED_DATA_SUCCESS,
  VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_ERROR,
  VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_REQUEST,
  VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_SUCCESS,
  VIRS_INFO_DATA_ERROR,
  VIRS_INFO_DATA_REQUEST,
  VIRS_INFO_DATA_SUCCESS,
  VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_ERROR,
  VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_REQUEST,
  VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_SUCCESS,
  VIRS_LICENSES_DATA_ERROR,
  VIRS_LICENSES_DATA_REQUEST,
  VIRS_LICENSES_DATA_SUCCESS,
  VIRS_OUTLETS_DATA_ERROR,
  VIRS_OUTLETS_DATA_REQUEST,
  VIRS_OUTLETS_DATA_SUCCESS,
  VIRS_PAYMENT_DATA_ERROR,
  VIRS_PAYMENT_DATA_REQUEST,
  VIRS_PAYMENT_DATA_SUCCESS,
  VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_ERROR,
  VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_REQUEST,
  VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_SUCCESS,
  VIRS_PROFESSIONAL_MISCONDUCT_DATA_ERROR,
  VIRS_PROFESSIONAL_MISCONDUCT_DATA_REQUEST,
  VIRS_PROFESSIONAL_MISCONDUCT_DATA_SUCCESS
} from './virsInfoActionTypes';
import {
  ShareholderVirsAnnualFinancialReportsData,
  ShareholderVirsEditionCheckData,
  ShareholderVirsEnterpriseData,
  ShareholderVirsEthicalNonComplianceData,
  ShareholderVirsFundsReceivedData,
  ShareholderVirsIndividualPersonFinancialSupportData,
  ShareholderVirsInfoData,
  ShareholderVirsLegalEntityFinancialSupportData,
  ShareholderVirsLicensesData,
  ShareholderVirsOutletData,
  ShareholderVirsPaymentData,
  ShareholderVirsPoliticalAdFinancialSupportData,
  ShareholderVirsProfessionalMisconductData
} from './virsInfoDataTypes';

export const fetchVirsInfoData = (virsId: number) => action(VIRS_INFO_DATA_REQUEST, { virsId });
export const fetchVirsInfoDataSuccess = (data: ShareholderVirsInfoData) =>
  action(VIRS_INFO_DATA_SUCCESS, data);
export const fetchVirsInfoDataError = (error: Error) => action(VIRS_INFO_DATA_ERROR, error);

export const fetchVirsEnterprseData = (virsId: number) =>
  action(VIRS_ENTERPRISE_DATA_REQUEST, { virsId });
export const fetchVirsEnterpriseDataSuccess = (data: ShareholderVirsEnterpriseData) =>
  action(VIRS_ENTERPRISE_DATA_SUCCESS, data);
export const fetchVirsEnterpriseDataError = (error: Error) =>
  action(VIRS_ENTERPRISE_DATA_ERROR, error);

export const fetchVirsProfessionalMisconductData = (virsId: number) =>
  action(VIRS_PROFESSIONAL_MISCONDUCT_DATA_REQUEST, { virsId });
export const fetchVirsProfessionalMisconductDataSuccess = (
  data: ShareholderVirsProfessionalMisconductData
) => action(VIRS_PROFESSIONAL_MISCONDUCT_DATA_SUCCESS, data);
export const fetchVirsProfessionalMisconductDataError = (error: Error) =>
  action(VIRS_PROFESSIONAL_MISCONDUCT_DATA_ERROR, error);

export const fetchVirsEthicalNonComplianceData = (virsId: number) =>
  action(VIRS_ETHICAL_NON_COMPLIANCE_DATA_REQUEST, { virsId });
export const fetchVirsEthicalNonComplianceDataSuccess = (
  data: ShareholderVirsEthicalNonComplianceData
) => action(VIRS_ETHICAL_NON_COMPLIANCE_DATA_SUCCESS, data);
export const fetchVirsEthicalNonComplianceDataError = (error: Error) =>
  action(VIRS_ETHICAL_NON_COMPLIANCE_DATA_ERROR, error);

export const fetchVirsFundsReceivedData = (virsId: number) =>
  action(VIRS_FUNDS_RECEIVED_DATA_REQUEST, { virsId });
export const fetchVirsFundsReceivedDataSuccess = (data: ShareholderVirsFundsReceivedData) =>
  action(VIRS_FUNDS_RECEIVED_DATA_SUCCESS, data);
export const fetchVirsFundsReceivedDataError = (error: Error) =>
  action(VIRS_FUNDS_RECEIVED_DATA_ERROR, error);

export const fetchVirsEditionCheckData = (virsId: number) =>
  action(VIRS_EDITION_CHECK_DATA_REQUEST, { virsId });
export const fetchVirsEditionCheckDataSuccess = (data: ShareholderVirsEditionCheckData) =>
  action(VIRS_EDITION_CHECK_DATA_SUCCESS, data);
export const fetchVirsEditionCheckDataError = (error: Error) =>
  action(VIRS_EDITION_CHECK_DATA_ERROR, error);

export const fetchVirsPaymentData = (virsId: number) =>
  action(VIRS_PAYMENT_DATA_REQUEST, { virsId });
export const fetchVirsPaymentDataSuccess = (data: ShareholderVirsPaymentData) =>
  action(VIRS_PAYMENT_DATA_SUCCESS, data);
export const fetchVirsPaymentDataError = (error: Error) => action(VIRS_PAYMENT_DATA_ERROR, error);
export const getPaymentDocumentInNewTabRequest = (
  paymentDocumentId: number,
  documentNumber: string
) => action(PAYMENT_DOCUMENT_PDF_REQUEST, { paymentDocumentId, documentNumber });

export const fetchVirsLicensesData = (virsId: number) =>
  action(VIRS_LICENSES_DATA_REQUEST, { virsId });
export const fetchVirsLicensesDataSuccess = (data: ShareholderVirsLicensesData) =>
  action(VIRS_LICENSES_DATA_SUCCESS, data);
export const fetchVirsLicensesDataError = (error: Error) => action(VIRS_LICENSES_DATA_ERROR, error);

export const fetchVirsPoliticalAdFinancialSupportData = (virsId: number) =>
  action(VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_REQUEST, { virsId });
export const fetchVirsPoliticalAdFinancialSupportDataSuccess = (
  data: ShareholderVirsPoliticalAdFinancialSupportData[]
) => action(VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_SUCCESS, data);
export const fetchVirsPoliticalAdFinancialSupportDataError = (error: Error) =>
  action(VIRS_POLITICAL_AD_FINANCIAL_SUPPORT_DATA_ERROR, error);

export const fetchVirsLegalEntityFinancialSupportData = (virsId: number) =>
  action(VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_REQUEST, { virsId });
export const fetchVirsLegalEntityFinancialSupportDataSuccess = (
  data: ShareholderVirsLegalEntityFinancialSupportData
) => action(VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_SUCCESS, data);
export const fetchVirsLegalEntityFinancialSupportDataError = (error: Error) =>
  action(VIRS_LEGAL_ENTITY_FINANCIAL_SUPPORT_DATA_ERROR, error);

export const fetchVirsIndividualPersonFinancialSupportData = (virsId: number) =>
  action(VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_REQUEST, { virsId });
export const fetchVirsIndividualPersonFinancialSupportDataSuccess = (
  data: ShareholderVirsIndividualPersonFinancialSupportData
) => action(VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_SUCCESS, data);
export const fetchVirsIndividualPersonFinancialSupportDataError = (error: Error) =>
  action(VIRS_INDIVIDUAL_PERSON_FINANCIAL_SUPPORT_DATA_ERROR, error);

export const fetchVirsAnnualFinancialReportsData = (virsId: number) =>
  action(VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_REQUEST, { virsId });
export const fetchVirsAnnualFinancialReportsDataSuccess = (
  data: ShareholderVirsAnnualFinancialReportsData
) => action(VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_SUCCESS, data);
export const fetchVirsAnnualFinancialReportsDataError = (error: Error) =>
  action(VIRS_ANNUAL_FINANCIAL_REPORTS_DATA_ERROR, error);

export const fetchVirsOutletsData = (virsId: number) =>
  action(VIRS_OUTLETS_DATA_REQUEST, { virsId });
export const fetchVirsOutletsDataSuccess = (data: ShareholderVirsOutletData) =>
  action(VIRS_OUTLETS_DATA_SUCCESS, data);
export const fetchVirsOutletsDataError = (error: Error) => action(VIRS_OUTLETS_DATA_ERROR, error);

export const getEditionCheckDocumentInNewTabRequest = (
  editionCheckDocumentId: number,
  documentNumber: string
) =>
  action(EDITION_CHECK_DOCUMENT_PDF_REQUEST, {
    editionCheckDocumentId,
    documentNumber
  });
