import moment from 'moment';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { setInitialVirsData } from '../virsisApp/virsisActions';
import { language, virsId } from '../virsisApp/virsisSelectors';
import {
  fetchVirsData,
  fetchVirsDataError,
  fetchVirsDataOfDate,
  fetchVirsDataSuccess,
  fetchXmlVirsData,
  fetchXmlVirsDataError,
  loadTimelineEvents,
  setTimelineActiveDate,
  setTimelineError,
  setTimelineEvents,
  setTimelineLoading
} from './virsTreeActions';
import {
  LOAD_TIMELINE_EVENTS,
  VIRS_DATA_REQUEST,
  VIRS_DATA_REQUEST_OF_DATE,
  XML_VIRS_DATA_REQUEST
} from './virsTreeActionTypes';
import { getChartData, getTimelineEvents, getXmlChartData } from './virsTreeApi';
import { TimelineEvent } from './virsTreeDataTypes';

function* handleVirsDataRequest(action: ActionType<typeof fetchVirsData>) {
  try {
    // eslint-disable-next-line
    const { date, virsId } = action.payload;
    const lang = yield select(language);
    const { data } = yield call(getChartData, date, virsId, lang);
    yield put(fetchVirsDataSuccess(data));
    yield put(setTimelineActiveDate(moment(data.date)));
  } catch (err) {
    yield put(fetchVirsDataError(err.response));
  }
}

function* handleVirsDataOfDateRequest(action: ActionType<typeof fetchVirsDataOfDate>) {
  try {
    const date = action.payload;
    const lang = yield select(language);
    const currentVirsId: number = yield select(virsId);
    const { data } = yield call(getChartData, date, currentVirsId, lang);
    yield put(fetchVirsDataSuccess(data));
    yield put(setTimelineActiveDate(moment(data.date)));
  } catch (err) {
    yield put(fetchVirsDataError(err.response));
  }
}

function* handleXmlVirsDataRequest(action: ActionType<typeof fetchXmlVirsData>) {
  try {
    // eslint-disable-next-line no-shadow
    const { date, virsId } = action.payload;
    const lang = yield select(language);
    const { data } = yield call(getXmlChartData, date, virsId, lang);
    const file = new Blob([data], { type: 'application/xml' });
    saveAs(file, 'data.xml');
  } catch (err) {
    yield put(fetchXmlVirsDataError(err.response));
  }
}

export function* loadTimelineEventsTask(action: ActionType<typeof loadTimelineEvents>) {
  yield put(setTimelineLoading(true));
  yield put(setTimelineError());

  try {
    const lang = yield select(language);
    const { data } = yield call(getTimelineEvents, action.payload, lang);
    const virsEvents: TimelineEvent[] = data.virsEvents.map((value: TimelineEvent) => ({
      ...value,
      date: moment(value.date)
    }));

    yield put(setTimelineEvents(virsEvents));
    yield put(
      setInitialVirsData(
        data.activityPeriods,
        data.virsLegalName,
        action.payload,
        data.virsStartDate,
        data.virsEndDate
      )
    );
  } catch (error) {
    yield put(setTimelineError('Nepavyko gauti laiko juostos duomenų'));
  }

  yield put(setTimelineLoading(false));
}

function* virsTreeSaga() {
  yield takeEvery(VIRS_DATA_REQUEST, handleVirsDataRequest);
  yield takeEvery(XML_VIRS_DATA_REQUEST, handleXmlVirsDataRequest);
  yield takeEvery(VIRS_DATA_REQUEST_OF_DATE, handleVirsDataOfDateRequest);
  yield takeEvery(LOAD_TIMELINE_EVENTS, loadTimelineEventsTask);
}

export default virsTreeSaga;
