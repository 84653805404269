import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, MenuItem, Select } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../../../store';
import { PeriodName } from '../../../../store/virsTree/virsTreeDataTypes';
import { setTimelinePeriodName } from '../../../../store/virsTree/virsTreeActions';

const timeFrames: { label: string; value: PeriodName }[] = [
  { label: 'Display by years', value: 'year' },
  { label: 'Display by months', value: 'month' },
  { label: 'Display by days', value: 'day' }
];

const useStyles = makeStyles(() => ({
  select: {
    height: '100%',
    transition: 'background-color 0.1s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&::before, &:after': {
      display: 'none'
    },
    '& svg': {
      marginRight: 5
    }
  },
  selectMenu: {
    margin: '0 10px'
  },
  selectItem: {
    width: 70,
    fontWeight: 600,
    fontSize: '0.875rem',
    color: '#626f81'
  },
  listItem: {
    fontWeight: 600,
    fontSize: '0.875rem',
    color: '#626f81',
    fontFamily: 'Open Sans'
  }
}));

export const CustomSelect: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const periodName = useSelector<ApplicationState, PeriodName>(
    (state) => state.chartData.timeline.periodName
  );
  const { t } = useTranslation();

  function handleChange(value: PeriodName) {
    dispatch(setTimelinePeriodName(value));
  }

  return (
    <Select
      className={classes.select}
      value={periodName}
      classes={{ select: classes.selectItem, selectMenu: classes.selectMenu }}
      // eslint-disable-next-line
      onChange={(event) => handleChange(event.target.value as PeriodName)}
    >
      {timeFrames.map(({ label, value }) => (
        <MenuItem className={classes.listItem} key={value} value={value}>
          {t(label)}
        </MenuItem>
      ))}
    </Select>
  );
};
