import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { VirsAnnualFinancialReportsTable } from './AnnualFinancialReportsTable';
import { virsAnnualFinancialReportsTableReducer } from './tableState/tableReducer';
import {
  VirsAnnualFinancialReportsDataTableState,
  VirsAnnualFinancialReportsTableAction,
  virsInitialAnnualFinancialReportsTableState
} from './tableState/tableTypes';

const VirsAnnualFinancialReportsTableStateContext = createContext<{
  state: VirsAnnualFinancialReportsDataTableState;
}>({
  state: virsInitialAnnualFinancialReportsTableState
});

const VirsAnnualFinancialReportsTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsAnnualFinancialReportsTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const AnnualFinancialReportsSection: React.FC = () => {
  const [state, dispatch] = useReducer(
    virsAnnualFinancialReportsTableReducer,
    virsInitialAnnualFinancialReportsTableState
  );

  return (
    <VirsAnnualFinancialReportsTableStateContext.Provider value={{ state }}>
      <VirsAnnualFinancialReportsTableDispatchContext.Provider value={{ dispatch }}>
        <VirsAnnualFinancialReportsTable />
      </VirsAnnualFinancialReportsTableDispatchContext.Provider>
    </VirsAnnualFinancialReportsTableStateContext.Provider>
  );
};

export function useVirsAnnualFinancialReportsTableState() {
  return useContext(VirsAnnualFinancialReportsTableStateContext);
}
export function useVirsAnnualFinancialReportsTableDispatch() {
  return useContext(VirsAnnualFinancialReportsTableDispatchContext);
}
