import React from 'react';
import { Typography, TableHead, TableRow, TableCell } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import {
  useVirsProfessionalMisconductTableDispatch,
  useVirsProfessionalMisconductTableState
} from './ProfessionalMisconductSection';
import {
  VirsProfessionalMisconductTableField,
  VirsProfessionalMisconductTableState
} from './tableState/tableTypes';

export const ProfessionalMisconductTableHead: React.FC = () => {
  const { state: tableState } = useVirsProfessionalMisconductTableState();
  const { dispatch: tableDispatch } = useVirsProfessionalMisconductTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsProfessionalMisconductTableField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsProfessionalMisconductTableState,
    typeof handleSetSortByColumn,
    VirsProfessionalMisconductTableField
  >(handleSetSortByColumn, tableState);

  return (
    <TableHead>
      <TableRow>
        {(tableState.columnsDisplayStatus.decisionDate ||
          tableState.columnsDisplayStatus.documentNumber) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.decisionDate,
              tableState.columnsDisplayStatus.documentNumber
            ])}
            key="decision"
          >
            <Typography variant="h4">{t('VIRS professional misconduct decision')}</Typography>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.outletName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS professional misconduct outletName')}
            columnName="outletName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.professionalMisconduct && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS professional misconduct professionalMisconduct')}
            columnName="professionalMisconduct"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.sanction && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS professional misconduct sanction')}
            columnName="sanction"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
            key="valid"
          >
            <Typography variant="h4">{t('VIRS professional misconduct decision valid')}</Typography>
          </TableCell>
        )}
        {tableState.columnsDisplayStatus.misconductReportingInstitutionName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS professional misconduct misconductReportingInstitutionName')}
            columnName="misconductReportingInstitutionName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.decisionDate && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('VIRS professional misconduct decisionDate')}
            columnName="decisionDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.documentNumber && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('VIRS professional misconduct documentNumber')}
            columnName="documentNumber"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('From capitalized')}
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('To capitalized')}
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
