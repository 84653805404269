import { Reducer } from 'redux';
import {
  GET_GRAPHICAL_REPRESENTATION_DATA_ERROR,
  GET_GRAPHICAL_REPRESENTATION_DATA_REQUEST,
  GET_GRAPHICAL_REPRESENTATION_DATA_SUCCESS,
  GraphicalRecord
} from './graphicalRepresentationToolType';

export interface GraphicalRepresentationDataState {
  graphicalRepresentationToolData?: GraphicalRecord[];
  error?: Error;
  loading: boolean;
}

const initialGraphicalRepresentationData: GraphicalRepresentationDataState = {
  graphicalRepresentationToolData: undefined,
  error: undefined,
  loading: false
};

export const graphicalRepresentationDataReducer: Reducer = (
  state = initialGraphicalRepresentationData,
  action
) => {
  switch (action.type) {
    case GET_GRAPHICAL_REPRESENTATION_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_GRAPHICAL_REPRESENTATION_DATA_ERROR:
      return {
        ...state,
        loading: false
      };
    case GET_GRAPHICAL_REPRESENTATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        graphicalRepresentationToolData: action.payload.graphicalRecord
      };
    default:
      return state;
  }
};
