import { makeStyles } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export interface FormikFieldStyles {
  style?: {
    helper?: React.CSSProperties;
    container?: React.CSSProperties;
    input?: React.CSSProperties;
    radioGroup?: React.CSSProperties;
  };
}

export const useDialogFormStyles = makeStyles(() => ({
  formGroup: {
    maxWidth: '506px',
    paddingRight: '24px'
  },
  textField: {
    maxWidth: '360px'
  },
  inputLabel: {
    minWidth: '130px',
    width: '130px',
    paddingRight: '8px',
    fontSize: '0.9rem',
    color: '#637082',
    lineHeight: '25px',
    textAlign: 'right',
    fontWeight: 700,
    [breakpoints.down('xs')]: {
      width: 'auto',
      textAlign: 'left',
      paddingRight: '0',
      paddingBottom: '8px'
    }
  },
  inputContainer: {
    position: 'relative',
    margin: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: '360px'
    },
    '& .MuiIconButton-root': {
      padding: '4px'
    }
  },
  clearButton: {
    position: 'absolute',
    left: '100%',
    margin: 'auto',
    marginLeft: '4px',
    height: '1em',
    width: '1em',
    padding: '8px',
    [breakpoints.down('xs')]: {
      bottom: '0.5rem'
    }
  },
  clearIcon: {
    height: '1rem',
    width: '1rem'
  },
  button: {
    alignContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    height: '38px',
    width: '130px',
    float: 'right',
    marginRight: '4px',
    marginTop: '8px',
    [breakpoints.down('xs')]: {
      marginTop: '20px',
      float: 'none'
    }
  },
  dateField: {
    maxWidth: '360px'
  },
  greyButton: {
    borderWidth: '1.6px',
    borderColor: '#949494',
    color: '#949494'
  },
  smallIcon: {
    height: '1rem'
  },
  autocompleteRoot: {
    width: '100%'
  }
}));
