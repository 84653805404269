import React, { useState } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../store';
import { OutletSearchResults } from './OutletSearchResults';
import { OutletSearchWrapper } from './OutletSearchWrapper';

const OutletSearch: React.FC = () => {
  const {
    loadingVirsSearchByOutletResults,
    virsSearchByOutletResults,
    virsSearchByOutletError,
    outletTypes
  } = useSelector((state: ApplicationState) => state.virsSearchData);
  const { t } = useTranslation();

  const [querySize, setQuerySize] = useState<number>(0);

  return (
    <Container disableGutters maxWidth="lg">
      <OutletSearchWrapper outletTypes={outletTypes} setQuerySize={setQuerySize} />

      <Grid
        style={{ padding: 0 }}
        container
        item
        direction="column"
        justify="flex-end"
        alignItems="flex-start"
      >
        {virsSearchByOutletError?.message && (
          <Typography variant={'h5'} style={{ margin: '1rem 0' }}>{`${t('Responded with error')}. ${
            virsSearchByOutletError.message
          }`}</Typography>
        )}
        {virsSearchByOutletResults === null &&
          !loadingVirsSearchByOutletResults &&
          querySize > 0 && (
            <div style={{ margin: '1rem 0' }}>
              <Typography variant="h5">{t('No VIRS found by query')}.</Typography>
              <Typography>{querySize === 2 && `${t('No VIRS found outlet advice')}.`}</Typography>
            </div>
          )}
        {virsSearchByOutletResults && virsSearchByOutletResults.length > 0 && (
          <OutletSearchResults outletSearchResults={virsSearchByOutletResults} />
        )}
      </Grid>
    </Container>
  );
};

export default OutletSearch;
