import React from 'react';
import { useTranslation } from 'react-i18next';
import TableCustomTools from '../../../../../components/TableCustomTools/TableCustomTools';
import { TableToolsFrame } from '../../../../../components/TableToolFrame/TableToolFrame';
import { columnNames } from './tableState/columnNames';
import { useVirsPaymentTableDispatch, useVirsPaymentTableState } from './PaymentSection';
import { VirsPaymentDataColumnHeader, VirsPaymentDataHeaderField } from './tableState/tableTypes';

const PaymentCustomizationBar: React.FC = () => {
  const { state } = useVirsPaymentTableState();
  const { dispatch } = useVirsPaymentTableDispatch();
  const { t } = useTranslation();

  const toggleDisplayCustomFilter = () => {
    dispatch({ type: 'CUSTOM_FILTER_DISPLAY_TOGGLED' });
  };

  const handleSetSortByColumn = (column: VirsPaymentDataHeaderField) => {
    dispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const toggleDisplayColumn = (column: VirsPaymentDataHeaderField) => {
    dispatch({ type: 'COLUMN_DISPLAY_TOGGLED', column });
  };

  function getVirsPaymentColumnsWithTranslation(): VirsPaymentDataColumnHeader[] {
    return columnNames.map((col) => ({
      ...col,
      label: t(`VIRS payment ${col.id}`)
    }));
  }

  const translatedColumns = getVirsPaymentColumnsWithTranslation();

  return (
    <div className="CustomizationBar">
      <TableToolsFrame
        rightSideItem={
          <TableCustomTools
            tableColumns={translatedColumns}
            showFilter={state.customFilterOn}
            onFilterTabToggle={toggleDisplayCustomFilter}
            sortOrder={state.order}
            sortBy={state.sortBy}
            onSortByColumnClick={handleSetSortByColumn}
            columnsDisplayStatus={state.columnsDisplayStatus}
            toggleDisplayColumn={toggleDisplayColumn}
          />
        }
      />
    </div>
  );
};
export default PaymentCustomizationBar;
