import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import {
  AppBar,
  Toolbar,
  Tabs,
  Container,
  InputBase,
  IconButton,
  Divider,
  makeStyles,
  Box
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import TabMenu from '../Menu/TabMenu';
import { ApplicationState } from '../../store';
import { setLanguage } from '../../store/virsisApp/virsisActions';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { MenuData } from '../../store/virsisApp/virsisDataTypes';
import { clearSearchValues, searchRequest } from '../../store/virsSearch/virsSearchActions';

import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import virsisLogo from '../../assets/virsis_logo.png';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles({
  navbarSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      maxWidth: '100%'
    }
  },
  navbarSectionForm: {
    [breakpoints.down('md')]: {
      flexDirection: 'row'
    }
  },
  logo: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    height: '1.4rem',
    margin: 'auto'
  },
  logoText: {
    color: '#fff',
    margin: 'auto'
  },
  vDivider: {
    width: 2,
    backgroundColor: '#f6f7fb',
    opacity: 0.3,
    margin: 0,
    [breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  searchForm: {
    display: 'flex',
    flexFlow: 'row',
    margin: 'auto 19px',
    padding: '3px 15px',
    backgroundColor: '#daecfb',
    borderRadius: '3px',
    border: '2px solid #c3e2fb'
  },
  icon: { width: 20, height: 20, fill: '#006FB2' }
});

const VirsisHeader: React.FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [openTab, setOpenTab] = useState<string>('/');

  const { menuData, language } = useSelector((state: ApplicationState) => state.virsis);

  const [disabled, setDisable] = useState(false);

  const activeTab = menuData?.find(
    (item) =>
      item.fieldUrl ===
      menuData
        .map((x) => x.fieldUrl)
        .find((p) => {
          const loc = location.pathname.slice(
            0,
            location.pathname.indexOf('/', 1) !== -1
              ? location.pathname.indexOf('/', 1)
              : location.pathname.length
          );
          if (loc === p) return true;
          return loc === `/${p}`;
        })
  );

  const filteredMenu = menuData?.filter((item) => item.fieldLanguage === activeTab?.fieldLanguage);

  const [, value] = location.search.split('lang=');

  useEffect(() => {
    if (value) {
      dispatch(setLanguage(value));
      i18n.changeLanguage(value);
    }
    if (activeTab && activeTab.fieldLanguage !== language && !value) {
      dispatch(setLanguage(activeTab.fieldLanguage));
      i18n.changeLanguage(activeTab.fieldLanguage);
    }
  }, [dispatch, activeTab, language, i18n, value]);

  const openTabValue = () => {
    if (location.pathname.includes('virs/')) {
      if (language === 'en') return 'virs-search';
      return 'paieska';
    }
    if (location.pathname.includes('naujienos')) return 'naujienos';
    if (location.pathname.includes('duk')) return 'Pagalba';
    if (location.pathname.includes('naudojimosi_gidai')) return 'Pagalba';
    if (location.pathname.includes('kontaktai')) return 'Pagalba';
    if (!disabled && activeTab) return activeTab.fieldUrl;
    if (language === 'en') return 'home';
    return '/';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    const { value } = e.target.elements.search;

    if (language === 'en') {
      history.push(`/search/en/${value}`);
    } else {
      history.push(`/search/${value}`);
    }
    dispatch(searchRequest(value));
    e.target.reset();
    setDisable(true);
  };

  const handleClear = () => {
    setDisable(false);
    dispatch(clearSearchValues());
  };

  const handleLanguageChange = ({
    target: { value }
  }: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => {
    // eslint-disable-next-line
    dispatch(setLanguage(value as string));
    i18n.changeLanguage(value as string);
    handleClear();
    if (value === 'en') {
      history.push('/home');
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    setOpenTab(openTabValue());
  });

  useEffect(() => {
    setOpenTab(openTabValue());
  }, [openTabValue]);

  const createTabNode = (field: MenuData) => {
    return (
      <TabMenu
        label={field.name}
        value={field.fieldUrl}
        subMenu={field.subMenu}
        key={field.fieldId}
        onClick={handleClear}
        style={{
          display: field.fieldHide === true ? 'none' : 'inline-flex'
        }}
      />
    );
  };

  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar className={classes.navbarSection}>
          <div className={classes.navbarSection}>
            <div className={classes.logo}>
              <Box className={classes.logoText}>
                <img className={classes.logo} src={virsisLogo} />
              </Box>
            </div>

            <Tabs value={openTab} variant="scrollable" scrollButtons="on">
              {filteredMenu && filteredMenu.length
                ? filteredMenu.map((field) => {
                    if (field.parentId) {
                      return null;
                    }
                    return createTabNode(field);
                  })
                : menuData &&
                  menuData
                    .filter((item) => item.fieldLanguage === language && !item.parentId)
                    .map((field) => {
                      return createTabNode(field);
                    })}
            </Tabs>
          </div>

          <div
            className={`${classes.navbarSection} ${classes.navbarSectionForm}`}
            style={{ minHeight: 'inherit' }}
          >
            <form
              className={classes.searchForm}
              onSubmit={(e) => handleOnSubmit(e)}
              style={{ maxWidth: 170 }}
            >
              <InputBase
                required
                placeholder={t('Search')}
                type="text"
                name="search"
                id="search"
                inputProps={{
                  style: { color: '#626f81' },
                  onInvalid: (e) => {
                    e.currentTarget.setCustomValidity('');
                    if (!e.currentTarget.validity.valid) {
                      e.currentTarget.setCustomValidity(t('Please enter a search term'));
                    }
                  },
                  onInput: (e) => {
                    e.currentTarget.setCustomValidity('');
                  }
                }}
              />
              <IconButton type="submit" name="Search" style={{ padding: 4 }}>
                <SearchIcon className={classes.icon} />
              </IconButton>
            </form>

            <Divider className={classes.vDivider} orientation="vertical" flexItem />
            <LanguageSelector onChange={handleLanguageChange} />
            <Divider className={classes.vDivider} orientation="vertical" flexItem />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default withRouter(VirsisHeader);
