import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VirsTreePreviewCards } from './VirsTreePreviewCards/VirsTreePreviewCards';
import { PersonInfoSection } from './VirsTreePreviewCards/PersonCard/PersonInfoSection';
import { ChartShareholderPerson } from '../../store/virsTree/virsTreeDataTypes';
import { GraphicalRepresentationToolsTypes } from '../../store/graphicalRepresentationTool/graphicalRepresentationToolType';
import {
  NavigationBar,
  ShareholderPreviewCardSubNavTab
} from './VirsTreePreviewCards/ShareholderPreviewCardNavigation/NavigationBar';
import { GenericDataTableContainer } from '../../components/GenericDataTable/GenericDataTableContainer';
import { fetchGenericTableDataRequest } from '../../store/genericDataTable/genericDataTableAction';
import { isNaturalPerson } from '../VirsTree/TreeChart/treeChartFunctions/uiFunctions';
import {
  useVirsTreeDispatch,
  useVirsTreeState
} from '../../pages/VirsTreePage/VirsTreePageContext';
import { ApplicationState } from '../../store';
import { virsIdOfshareholderOrAuthorizedShareholder } from '../../pages/VirsTreePage/treeState/treeFunctions';

export const ShareholderPersonPreviewContainer: React.FC = () => {
  const { treeState } = useVirsTreeState();
  const { treeDispatch } = useVirsTreeDispatch();
  const { graphicalRepresentationToolData } = useSelector(
    (state: ApplicationState) => state.graphicalRepresentationToolData
  );
  const { t } = useTranslation();

  const {
    chartData: {
      timeline: { activeDate }
    },
    virsis: { language }
  } = useSelector((state: ApplicationState) => state);

  const activeAndSortedTables =
    graphicalRepresentationToolData
      ?.filter((record) => record.isActive)
      .sort((x, y) => x.rowNr - y.rowNr) || [];

  const handleCloseShareholderCard = () => {
    treeDispatch({
      type: 'CLOSE_SHAREHOLDER_PREVIEW_CARD'
    });
  };

  const handleOpenVirsCard = (virsForCardId: number, virsForCarName: string) => {
    treeDispatch({
      type: 'OPEN_VIRS_PREVIEW_CARD',
      virsId: virsForCardId,
      virsName: virsForCarName
    });
  };
  const shareholderData = treeState?.shareholderData;
  const shareholderPersons = shareholderData?.shareholderPersons || [];

  const reduxDispatch = useDispatch();
  const authorisedPerson: ChartShareholderPerson | undefined = shareholderPersons.find(
    (x: ChartShareholderPerson) => x.authorized
  );

  const isGroup = !!treeState?.shareholderData?.shareholderGroupId;

  const subtitle = () => {
    if (isGroup) return `${t('Shareholders authorized person')}: ${authorisedPerson?.personName}`;
    if (isNaturalPerson(shareholderPersons[0].personType)) return t('Natural person');
    if (shareholderPersons[0].relatedVirsId) return t('Legal entity or VIRS');
    return t('Legal entity');
  };

  const getPersonType = (): GraphicalRepresentationToolsTypes | null => {
    if (!isGroup) {
      if (isNaturalPerson(shareholderPersons[0].personType)) return 'DFA';
      if (shareholderPersons[0].relatedVirsId) return 'DJA';
    }
    return null;
  };

  const activeTables = activeAndSortedTables.filter((record) => record.type === getPersonType());

  const getActiveTabs = (): ShareholderPreviewCardSubNavTab[] => {
    return activeTables.map((activeTable) => ({
      open: activeTable.rowNr === 1,
      key: activeTable.staticId || activeTable.tableNameLt.toLowerCase(),
      tabName: language === 'lt' ? activeTable.tableNameLt : activeTable.tableNameEn,
      url: language === 'lt' ? activeTable.urlLt : activeTable.urlEn
    }));
  };

  const activeTabs = getActiveTabs();
  activeTabs.splice(0, 0, {
    open: true,
    key: 'personInfo',
    tabName: isGroup ? t('Persons plural data') : t('Person singular data'),
    url: '/'
  });

  const [tabs, setTabs] = useState<ShareholderPreviewCardSubNavTab[]>(activeTabs);

  function handleTabClick(tabKey: string) {
    if (tabs) {
      setTabs(
        tabs.map((tab) => (tab.key === tabKey ? { ...tab, open: true } : { ...tab, open: false }))
      );
    }
  }

  const openTab = tabs?.find((tab) => tab.open);

  const relatedVirsId: number | undefined =
    virsIdOfshareholderOrAuthorizedShareholder(shareholderPersons);

  const handleClickShowAsVirs = () => {
    if (relatedVirsId) {
      handleOpenVirsCard(relatedVirsId, treeState.shareholderName);
    }
  };

  useEffect(() => {
    if (openTab && openTab.url && openTab.url !== '/' && shareholderData?.shareholderId) {
      reduxDispatch(
        fetchGenericTableDataRequest(`${openTab.url}/${shareholderData?.shareholderId}`)
      );
    }
    // eslint-disable-next-line
  }, [openTab]);

  const chosenDate = activeDate.format('L');

  function displayTab() {
    if (!openTab || !openTab.url || !shareholderData) return null;
    switch (openTab.key) {
      case 'personInfo':
        return (
          <PersonInfoSection
            shareType={treeState.shareType}
            isGroup={isGroup}
            shareholderData={shareholderData}
          />
        );
      default:
        return <GenericDataTableContainer chosenDate={chosenDate} url={openTab.url} />;
    }
  }

  return (
    <VirsTreePreviewCards
      isOpen
      onClose={handleCloseShareholderCard}
      title={isGroup ? t('Shareholders group') : treeState.shareholderName}
      subTitle={subtitle()}
      isVirs={relatedVirsId}
      handleClickVirs={handleClickShowAsVirs}
    >
      <NavigationBar tabs={tabs} onTabClick={handleTabClick} />
      {displayTab()}
    </VirsTreePreviewCards>
  );
};
