import { Typography } from '@material-ui/core';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { VirsLicensesTable } from './LicensesTable';
import { virsLicensesDataTableReducer } from './tableState/tableReducer';
import {
  initialVirsLicensesDataTableState,
  VirsLicensesDataTableState,
  VirsLicensesTableAction
} from './tableState/tableTypes';

interface Props {
  chosenDate: string;
}

const VirsLicensesTableStateContext = createContext<{
  state: VirsLicensesDataTableState;
}>({
  state: initialVirsLicensesDataTableState
});

const VirsLicensesTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsLicensesTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const LicensesSection: React.FC<Props> = ({ chosenDate }) => {
  const [state, dispatch] = useReducer(
    virsLicensesDataTableReducer,
    initialVirsLicensesDataTableState
  );

  const { t } = useTranslation();

  return (
    <VirsLicensesTableStateContext.Provider value={{ state }}>
      <VirsLicensesTableDispatchContext.Provider value={{ dispatch }}>
        <Typography variant="subtitle2" style={{ marginBottom: '25px' }}>
          {t('VIRS licence info message')}
        </Typography>
        <VirsLicensesTable chosenDate={chosenDate} />
      </VirsLicensesTableDispatchContext.Provider>
    </VirsLicensesTableStateContext.Provider>
  );
};

export function useVirsLicensesTableState() {
  return useContext(VirsLicensesTableStateContext);
}
export function useVirsLicensesTableDispatch() {
  return useContext(VirsLicensesTableDispatchContext);
}
