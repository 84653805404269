import React from 'react';
import {
  createStyles,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { TableSortingHeaderCell } from '../../../../../components/TableSortingHeaderCell/TableSortingHeaderCell';
import {
  getSharedHeaderCellColSpan,
  getSortLabelPropsFactory
} from '../../../../../utils/tableDataFunctions';
import { useVirsPaymentTableDispatch, useVirsPaymentTableState } from './PaymentSection';
import { VirsPaymentDataHeaderField, VirsPaymentDataTableState } from './tableState/tableTypes';

export const PaymentTableHead: React.FC = () => {
  const classes = makeStyles(() =>
    createStyles({
      tooltip: {
        maxWidth: '160px',
        width: 'auto',
        backgroundColor: '#FFFFFF',
        color: '#172D46',
        border: '1px solid',
        borderColor: '#172D46',
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
        marginBottom: '5px',
        fontSize: '12px'
      },
      arrow: {
        color: '#FFFFFF',
        '&.MuiTooltip-arrow:before': {
          border: '1px solid',
          borderColor: '#172D46'
        }
      }
    })
  )();

  const { state: tableState } = useVirsPaymentTableState();
  const { dispatch: tableDispatch } = useVirsPaymentTableDispatch();
  const { t } = useTranslation();

  const handleSetSortByColumn = (column: VirsPaymentDataHeaderField) => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  const sortLabelPropsFactory = getSortLabelPropsFactory<
    VirsPaymentDataTableState,
    typeof handleSetSortByColumn,
    VirsPaymentDataHeaderField
  >(handleSetSortByColumn, tableState);

  const tooltipText = t('VIRS payment basic social payments tooltip') || '';

  return (
    <TableHead>
      <TableRow>
        {(tableState.columnsDisplayStatus.decisionDate ||
          tableState.columnsDisplayStatus.paymentDocuments) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.decisionDate,
              tableState.columnsDisplayStatus.paymentDocuments
            ])}
          >
            <Typography variant="h4">{t('VIRS payment decision')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.paymentOutlets && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS payment paymentOutlets')}
            columnName="paymentOutlets"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.paymentAmount && (
          <TableCell
            rowSpan={2}
            sortDirection={tableState.sortBy === 'paymentAmount' ? tableState.order : false}
          >
            <TableSortLabel {...sortLabelPropsFactory('paymentAmount')}>
              <Typography variant="h4">
                {t('VIRS payment paymentAmount')}
                <Tooltip
                  title={tooltipText}
                  placement="top"
                  arrow
                  classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                >
                  <span>{` (${t('VIRS payment basic social payments abbrev')})`}</span>
                </Tooltip>
              </Typography>
            </TableSortLabel>
          </TableCell>
        )}

        {(tableState.columnsDisplayStatus.validFrom || tableState.columnsDisplayStatus.validTo) && (
          <TableCell
            rowSpan={1}
            colSpan={getSharedHeaderCellColSpan([
              tableState.columnsDisplayStatus.validFrom,
              tableState.columnsDisplayStatus.validTo
            ])}
          >
            <Typography variant="h4">{t('VIRS payment valid')}</Typography>
          </TableCell>
        )}

        {tableState.columnsDisplayStatus.reportingInstitutionName && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('VIRS payment reportingInstitutionName')}
            columnName="reportingInstitutionName"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
      <TableRow>
        {tableState.columnsDisplayStatus.decisionDate && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('VIRS payment date')}
            columnName="decisionDate"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.paymentDocuments && (
          <TableSortingHeaderCell
            rowSpan={1}
            label={t('VIRS payment number')}
            columnName="paymentDocuments"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validFrom && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('From capitalized')}
            columnName="validFrom"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}

        {tableState.columnsDisplayStatus.validTo && (
          <TableSortingHeaderCell
            rowSpan={2}
            label={t('To capitalized')}
            columnName="validTo"
            sortLabelPropsFactory={sortLabelPropsFactory}
            tableSettings={tableState}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
