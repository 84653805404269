import React from 'react';
import { MenuItem, Select, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { RowsPerPageOptions } from '../../utils/tableTypes';

interface PaginationSelectRowsPerPageProps {
  actionLabel?: string;
  rowsPerPage: number;
  rowsPerPageOptions: RowsPerPageOptions;
  setRowsPerPage: (rowsPerPage: number) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      height: 24
    }
  })
);

export const PaginationSelectRowsPerPage: React.FC<PaginationSelectRowsPerPageProps> = ({
  actionLabel,
  rowsPerPage,
  rowsPerPageOptions,
  setRowsPerPage,
  disabled
}) => {
  const classes = useStyles();

  return (
    <div className="pagination-item">
      <Typography variant="h6" style={{ color: '#637082', fontWeight: 600 }}>
        {actionLabel || 'Rodyti po:'}
      </Typography>
      <Select
        labelId="rows-per-page-select-label"
        id="rows-per-page-select"
        value={rowsPerPage}
        onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) =>
          setRowsPerPage(Number(event.target.value))
        }
        className={classes.margin}
        variant="outlined"
        disabled={disabled}
      >
        {rowsPerPageOptions.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};
