import React, { useReducer } from 'react';
import { useHistory } from 'react-router';

import SortIcon from '@material-ui/icons/UnfoldMore';
import ActiveSortIcon from '@material-ui/icons/ExpandLess';
import {
  Link as MaterialLink,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Divider
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ROWS_PER_PAGE_OPTIONS } from '../../store/virsisApp/virsisDataTypes';
import { TablePagination } from '../../components/TablePagination/TablePagination';
import {
  initialPersonResultsTableState,
  PersonSearchDataField,
  PersonSearchResultsTableColumnHeader,
  PersonSearchResultsTableReducer
} from './state/personSearchTableReducer';
import {
  getComparator,
  getDataSlicedToPageGeneric,
  getPagesCountGeneric,
  stableSort
} from '../../utils/tableDataFunctions';
import { SortOrder } from '../../utils/tableTypes';
import { NaturalPersonSearchData, ShortVirsWithRole } from '../../store/virsSearch/virsSearchTypes';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

const useStyles = makeStyles(() => ({
  button: {
    padding: '6px 10px',
    overflow: 'hidden',
    margin: '6px auto',
    whiteSpace: 'nowrap'
  },
  tableCell: {
    fontWeight: 600,
    boxSizing: 'border-box',
    borderTop: '1px solid inherit',
    borderRight: '1px solid inherit',
    borderBottom: '1px solid inherit',
    padding: '0 10px'
  },
  tableContainer: {
    fontWeight: 600,
    border: 'none',
    boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px'
  },
  materialLink: {
    color: '#006FB2',
    fontWeight: 600
  },
  rowText: {
    fontWeight: 500
  }
}));

interface Props {
  personSearchResults: NaturalPersonSearchData[];
}

export const PersonSearchResults: React.FC<Props> = ({ personSearchResults }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [tableState, tableDispatch] = useReducer(
    PersonSearchResultsTableReducer,
    initialPersonResultsTableState
  );

  function getSortedPersonSearchData(
    data: NaturalPersonSearchData[],
    order: SortOrder,
    sortBy: string
  ): NaturalPersonSearchData[] {
    if (sortBy === 'personFullName') {
      return stableSort<NaturalPersonSearchData>(
        data,
        getComparator(order === 'desc' ? 'asc' : 'desc', 'personLastName')
      );
    }

    if (sortBy === 'virsResults') {
      return data.map((record) => {
        if (record.virsResults) {
          const sortedOutlets = stableSort<ShortVirsWithRole>(
            record.virsResults,
            getComparator(order === 'desc' ? 'asc' : 'desc', 'virsLegalName')
          );
          return { ...record, virsResults: sortedOutlets };
        }
        return record;
      });
    }

    if (sortBy === 'virsRole') {
      return data.map((record) => {
        if (record.virsResults) {
          const sortedOutlets = stableSort<ShortVirsWithRole>(
            record.virsResults,
            getComparator(order === 'desc' ? 'asc' : 'desc', 'virsRole')
          );
          return { ...record, virsResults: sortedOutlets };
        }
        return record;
      });
    }
    if (sortBy === 'virsRoleValidToDate') {
      return data.map((record) => {
        if (record.virsResults) {
          const sortedOutlets = stableSort<ShortVirsWithRole>(
            record.virsResults,
            getComparator(order === 'desc' ? 'asc' : 'desc', 'virsRoleValidToDate')
          );
          return { ...record, virsResults: sortedOutlets };
        }
        return record;
      });
    }
    return data;
  }

  const createSortHandler = (column: PersonSearchDataField) => () => {
    tableDispatch({ type: 'SORTING_CHANGED', sortBy: column });
  };

  function setPage(page: number): void {
    tableDispatch({ type: 'PAGE_SET', page });
  }

  function setRowsPerPage(rowsPerPage: number): void {
    tableDispatch({ type: 'ROWS_PER_PAGE_SET', rowsPerPage });
  }

  const language = useSelector((appState: ApplicationState) => appState.virsis.language);

  const openSearchResult = (virsResult: ShortVirsWithRole) => {
    history.push({
      pathname: `virs/${virsResult.virsId}?lang=${language}`,
      state: { fromResult: virsResult, resultType: 'naturalPerson' }
    });
  };

  const pagesCount = getPagesCountGeneric(personSearchResults.length, tableState.rowsPerPage);
  const sortedData = getSortedPersonSearchData(
    personSearchResults,
    tableState.order,
    tableState.sortBy
  );
  // eslint-disable-next-line
  const pagedData = getDataSlicedToPageGeneric(
    sortedData,
    tableState.page,
    tableState.rowsPerPage
  ) as unknown as NaturalPersonSearchData[];
  let rowIndex = 0;
  const rows: JSX.Element[] = [];
  pagedData.forEach((person) => {
    person.virsResults.forEach((virs, virsIndex) => {
      rows.push(
        <TableRow style={{ border: 'none' }} key={rowIndex}>
          {virsIndex === 0 && (
            <TableCell
              variant="body"
              style={{ borderLeft: 'none' }}
              className={classes.tableCell}
              rowSpan={person.virsResults.length}
            >
              <Typography className={classes.rowText}>
                {person.personFirstName} {person.personLastName}
              </Typography>
            </TableCell>
          )}
          <TableCell variant="body" className={classes.tableCell}>
            <MaterialLink
              className={classes.materialLink}
              href={`virs/${virs.virsId}?lang=${language}`}
            >
              {virs.virsLegalName || ''}
            </MaterialLink>
          </TableCell>
          <TableCell variant="body" className={classes.tableCell}>
            <Typography className={classes.rowText}>{virs.virsRole}</Typography>
          </TableCell>
          <TableCell variant="body" className={classes.tableCell}>
            <Typography color="textSecondary">
              {virs.virsRoleValidToDateComment || virs.virsRoleValidToDate}
            </Typography>
          </TableCell>
          <TableCell variant="body" className={classes.tableCell}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => openSearchResult(virs)}
              startIcon={<VisibilityIcon />}
            >
              {t('View VIRS')}
            </Button>
          </TableCell>
        </TableRow>
      );
      // eslint-disable-next-line no-plusplus
      rowIndex++;
    });
  });

  const columns: PersonSearchResultsTableColumnHeader[] = [
    {
      id: 'personFullName',
      numeric: false,
      label: t('Full name')
    },
    {
      id: 'virsResults',
      numeric: false,
      label: t('virsResults')
    },
    {
      id: 'virsRole',
      numeric: false,
      label: t('virsRole')
    },
    {
      id: 'virsRoleValidToDate',
      numeric: false,
      label: t('virsRoleValidToDate')
    }
  ];

  return (
    <>
      <Divider light style={{ width: '100%', marginTop: '25px' }} />
      <Typography
        variant="h2"
        style={{
          paddingTop: '10px',
          paddingBottom: '20px',
          fontSize: '1rem'
        }}
      >
        {t('Search results')}
      </Typography>
      <Container disableGutters maxWidth="lg">
        <TableContainer className={classes.tableContainer}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow style={{ border: 'none' }}>
                  {columns.map((column, index) => (
                    <TableCell
                      style={{ borderTopLeftRadius: index === 0 ? '10px' : 0 }}
                      key={column.id}
                      sortDirection={tableState.sortBy === column.id ? tableState.order : false}
                      align="left"
                      variant="head"
                    >
                      <TableSortLabel
                        active
                        direction={tableState.sortBy === column.id ? tableState.order : 'asc'}
                        IconComponent={tableState.sortBy === column.id ? ActiveSortIcon : SortIcon}
                        onClick={createSortHandler(column.id as PersonSearchDataField)}
                      >
                        <Typography variant="h4">{column.label}</Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell
                    style={{
                      width: '100px',
                      borderRight: 'none',
                      borderTopRightRadius: '10px'
                    }}
                  >
                    <Typography variant="h4">{t('Actions with results')}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
            <TablePagination
              recordsCount={personSearchResults.length}
              pagesCount={pagesCount}
              rowsPerPage={tableState.rowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={tableState.page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              disabled={personSearchResults.length === 0}
            />
          </Paper>
        </TableContainer>
      </Container>
    </>
  );
};
