import { sortByColumn, updateCustomFilter } from './tableFunctions';
import {
  VirsAnnualFinancialReportsDataTableState,
  VirsAnnualFinancialReportsTableAction
} from './tableTypes';

export const virsAnnualFinancialReportsTableReducer = (
  state: VirsAnnualFinancialReportsDataTableState,
  action: VirsAnnualFinancialReportsTableAction
): VirsAnnualFinancialReportsDataTableState => {
  switch (action.type) {
    case 'SET_SORT_BY_COLUMN':
      return {
        ...state,
        sortBy: action.sortBy === state.sortBy ? state.sortBy : action.sortBy,
        order: sortByColumn(action.sortBy, state.sortBy, state.order)
      };

    case 'SET_PAGE':
      return {
        ...state,
        page: action.page
      };
    case 'SET_ROWS_PER_PAGE':
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };

    case 'TOGGLE_DISPLAY_CUSTOM_FILTER':
      return {
        ...state,
        customFilterOn: !state.customFilterOn,
        customFilter: {
          documentHeading: [],
          financialPeriodEndDate: []
        }
      };
    case 'SET_CUSTOM_FILTER':
      return {
        ...state,
        customFilter: updateCustomFilter(state.customFilter, action.filterBy, action.value),
        page: 0
      };
    case 'TOGGLE_DISPLAY_COLUMN':
      return {
        ...state,
        columnsDisplayStatus: {
          ...state.columnsDisplayStatus,
          [action.column]: !state.columnsDisplayStatus[action.column]
        }
      };

    default:
      return state;
  }
};
