import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderVirsPoliticalAdFinancialSupportData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsPoliticalAdFinancialSupportTableState } from './PoliticalAdFinancialSupportSection';
import { replaceDecimalPoint } from '../../../../../utils/tableDataFunctions';

interface Props {
  record: ShareholderVirsPoliticalAdFinancialSupportData;
}

export const PoliticalAdFinancialSupportRow: React.FC<Props> = ({ record }) => {
  const { state: tableState } = useVirsPoliticalAdFinancialSupportTableState();

  return (
    <TableRow key={record.vprId}>
      {tableState.columnsDisplayStatus.distributorName && (
        <TableCell>{record.distributorName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.distributorCode && (
        <TableCell>{record.distributorCode} </TableCell>
      )}
      {tableState.columnsDisplayStatus.beneficiaryName && (
        <TableCell>{record.beneficiaryName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.campaignName && <TableCell>{record.campaignName}</TableCell>}
      {tableState.columnsDisplayStatus.advertisingFrom && (
        <TableCell>{record.advertisingFrom}</TableCell>
      )}
      {tableState.columnsDisplayStatus.advertisingTo && (
        <TableCell>{record.advertisingTo}</TableCell>
      )}
      {tableState.columnsDisplayStatus.financialSupportAmount && (
        <TableCell>{replaceDecimalPoint(record.financialSupportAmount.toString())}</TableCell>
      )}
    </TableRow>
  );
};
