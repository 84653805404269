import {
  Box,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  PopperProps
} from '@material-ui/core';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CustomCloseIconButton } from '../../components/IconButtons/CustomCloseIconButton';
import { ApplicationState } from '../../store';
import theme from '../../style/virsisTheme';
import { LegalSearchWrapper } from '../SearchByLegalPerson/LegalSearchWrapper';
import { FormOption } from '../../store/reports/types';

export const LegalSearchPopper: React.FC<{
  onAdded: (option: FormOption<number>) => void;
  onRemoved: (option: FormOption<number>) => void;
  onClose: () => void;
  selected: FormOption<number>[];
}> = ({ selected, onAdded, onRemoved, onClose }) => {
  const paperRef = useRef<HTMLElement>();
  const virsOptions = useSelector((state: ApplicationState) => state.report.options.virsOptions);

  function isOptionSelected(value: number) {
    return selected.some((item) => item.value === value);
  }

  return (
    <Paper ref={paperRef} style={{ marginTop: '8px', padding: '8px 8px 8px 0', display: 'flex' }}>
      <LegalSearchWrapper />

      <Box>
        <CustomCloseIconButton onClick={onClose} />
      </Box>

      <PopperList
        open={!!paperRef.current}
        anchorEl={paperRef.current}
        placement="right-start"
        items={virsOptions}
        isSelected={isOptionSelected}
        itemComponent={({ item, ...itemProps }) => (
          <MenuItem
            {...itemProps}
            button
            onClick={() => (itemProps.selected ? onRemoved(item) : onAdded(item))}
          />
        )}
      />
    </Paper>
  );
};

type PopperListProps<TValue> = Omit<PopperProps, 'children'> & {
  items: FormOption<TValue>[];
  isSelected: (value: TValue) => boolean;
  itemComponent: React.FC<MenuItemProps & { item: FormOption<TValue> }>;
};

const PopperList = <TValue,>({
  items,
  isSelected,
  itemComponent: ItemComponent,
  ...props
}: PopperListProps<TValue>) => {
  const { t } = useTranslation();

  return (
    <Popper
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
          padding: theme.spacing(5)
        }
      }}
      style={{ zIndex: theme.zIndex.drawer }}
      {...props}
    >
      <Paper
        style={{
          margin: '8px',
          overflowY: 'auto',
          maxHeight: `calc(100vh - ${theme.spacing(5)}px * 2)`
        }}
      >
        <MenuList>
          {items.map(({ value, label }) => {
            return (
              <ItemComponent
                key={`${value}`}
                selected={isSelected && isSelected(value)}
                item={{ value, label }}
              >
                {label}
              </ItemComponent>
            );
          })}

          {items.length === 0 && <MenuItem>{t('No results text')}</MenuItem>}
        </MenuList>
      </Paper>
    </Popper>
  );
};
