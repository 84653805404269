import React from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import {
  useVirsProfessionalMisconductTableDispatch,
  useVirsProfessionalMisconductTableState
} from './ProfessionalMisconductSection';
import { ApplicationState } from '../../../../../store';
import { VirsProfessionalMisconductTableField } from './tableState/tableTypes';
import {
  ProfessionalMisconductOutlet,
  ShareholderVirsProfessionalMisconductData
} from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { columnNames } from './tableState/tableColumns';
import { TableCustomFilterAutocomplete } from '../../../../../components/TableCustomFilterCell/TableCustomFilterAutocomplete';
import { getUniqueOptionsGeneric } from '../../../../../utils/tableDataFunctions';

const ProfessionalMisconductCustomFilter: React.FC = () => {
  const { state: tableState } = useVirsProfessionalMisconductTableState();
  const { dispatch: tableDispatch, dataFilter } = useVirsProfessionalMisconductTableDispatch();

  const handleSetCustomFilter = (
    column: VirsProfessionalMisconductTableField,
    value: string | null
  ) => {
    tableDispatch({
      type: 'CUSTOM_FILTER_VALUE_CHANGED',
      filterBy: column,
      value
    });
  };
  const allData = (
    useSelector((state: ApplicationState) => {
      return state.virsInfoData.VirsProfessionalMisconductData;
    }) || []
  ).filter(dataFilter);

  const getOutlet = (item: ShareholderVirsProfessionalMisconductData) => item.outlets;
  const getMisconduct = (item: ProfessionalMisconductOutlet) => item.determinedMisconducts;

  const [
    decisionDate,
    documentNumber,
    outletName,
    professionalMisconduct,
    sanction,
    validFrom,
    validTo,
    misconductReportingInstitutionName
  ] = columnNames;

  const mapableHeaders = [
    {
      header: decisionDate,
      mapper: (data: ShareholderVirsProfessionalMisconductData[]) =>
        data.map((item) => String(item.decisionDate))
    },
    {
      header: documentNumber,
      mapper: (data: ShareholderVirsProfessionalMisconductData[]) =>
        data.map((item) => item.documentNumber)
    },
    {
      header: outletName,
      mapper: (data: ShareholderVirsProfessionalMisconductData[]) =>
        data.flatMap(getOutlet).map((item) => item.outletName)
    },
    {
      header: professionalMisconduct,
      mapper: (data: ShareholderVirsProfessionalMisconductData[]) =>
        data
          .flatMap(getOutlet)
          .flatMap(getMisconduct)
          .map((item) => item.misconductTypeName)
    },
    {
      header: sanction,
      mapper: (data: ShareholderVirsProfessionalMisconductData[]) =>
        data
          .flatMap(getOutlet)
          .flatMap(getMisconduct)
          .flatMap((item) => item.sanctions)
          .map((item) => item.sanctionTypeName)
    },
    {
      header: validFrom,
      mapper: (data: ShareholderVirsProfessionalMisconductData[]) =>
        data.map((item) => item.validFrom)
    },
    {
      header: validTo,
      mapper: (data: ShareholderVirsProfessionalMisconductData[]) =>
        data.map((item) => item.validTo)
    },
    {
      header: misconductReportingInstitutionName,
      mapper: (data: ShareholderVirsProfessionalMisconductData[]) =>
        data.map((item) => item.reportingInstitutionName)
    }
  ];

  return (
    <TableBody>
      <TableRow key="filter" className="CustomFilter">
        {mapableHeaders
          .filter(({ header }) => tableState.columnsDisplayStatus[header.id])
          .map(({ header, mapper }) => (
            <TableCell
              key={header.id}
              align="center"
              component="th"
              scope="row"
              style={{
                borderColor: '#C4E2FC',
                borderTop: 'none',
                borderBottom: 'none'
              }}
            >
              <TableCustomFilterAutocomplete
                name={header.id}
                uniqueOptions={getUniqueOptionsGeneric(allData, mapper)}
                setFilter={handleSetCustomFilter}
              />
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};

export default ProfessionalMisconductCustomFilter;
