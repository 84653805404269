import React from 'react';

export const CollapseTreeIcon = () => {
  return (
    <svg width="20.476" height="20.728">
      <path
        fill="#006fb2"
        d="M12.878 4.935h-1.52V1.273A.373.373 0 0010.983.9H9.491a.374.374 0 00-.374.373v3.661h-1.52a.374.374 0 00-.263.637l2.64 2.64a.373.373 0 00.527 0l2.64-2.64a.374.374 0 00-.263-.636zM15.541 12.879v-1.52h3.662a.373.373 0 00.373-.374V9.492a.374.374 0 00-.373-.374h-3.661v-1.52a.374.374 0 00-.637-.263l-2.64 2.64a.373.373 0 000 .527l2.64 2.64a.374.374 0 00.636-.263zM4.935 7.598v1.52H1.273a.373.373 0 00-.373.374v1.493a.374.374 0 00.373.374h3.661v1.52a.374.374 0 00.637.263l2.64-2.64a.373.373 0 000-.527l-2.64-2.64a.374.374 0 00-.636.263zM11.358 19.454l.003-3.657 1.517-.004a.374.374 0 00.264-.637l-2.64-2.64a.373.373 0 00-.527 0l-2.64 2.64a.373.373 0 00.264.637l1.516.004.003 3.657a.373.373 0 00.374.373h1.493a.373.373 0 00.373-.373z"
      />
    </svg>
  );
};
