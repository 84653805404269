export const SEARCH_REQUEST = '@@virsis/SEARCH_REQUEST';
export const CLEAR_SEARCH_VALUES = '@@virsis/CLEAR_SEARCH_VALUES';

export const RESET_SEARCH_STATE = '@@virsis/RESET_SEARCH_STATE';

export const SEARCH_BY_PERSON_REQUEST = '@@virsis/SEARCH_BY_PERSON_REQUEST';
export const SEARCH_BY_PERSON_SUCCESS = '@@virsis/SEARCH_BY_PERSON_SUCCESS';
export const SEARCH_BY_PERSON_ERROR = '@@virsis/SEARCH_BY_PERSON_ERROR';
export const SEARCH_BY_PERSON_LOADING = '@@virsis/SEARCH_BY_PERSON_LOADING';

export const SEARCH_BY_LEGAL_REQUEST = '@@virsis/SEARCH_BY_LEGAL_REQUEST';
export const SEARCH_BY_LEGAL_SUCCESS = '@@virsis/SEARCH_BY_LEGAL_SUCCESS';
export const SEARCH_BY_LEGAL_ERROR = '@@virsis/SEARCH_BY_LEGAL_ERROR';
export const SEARCH_BY_LEGAL_LOADING = '@@virsis/SEARCH_BY_LEGAL_LOADING';

export const SEARCH_BY_OUTLET_REQUEST = '@@virsis/SEARCH_BY_OUTLET_REQUEST';
export const SEARCH_BY_OUTLET_SUCCESS = '@@virsis/SEARCH_BY_OUTLET_SUCCESS';
export const SEARCH_BY_OUTLET_ERROR = '@@virsis/SEARCH_BY_OUTLET_ERROR';
export const SEARCH_BY_OUTLET_LOADING = '@@virsis/SEARCH_BY_OUTLET_LOADING';

export const GET_OUTLET_TYPES_REQUEST = '@@virsis/GET_OUTLET_TYPES_REQUEST';
export const GET_OUTLET_TYPES_SUCCESS = '@@virsis/GET_OUTLET_TYPES_SUCCESS';
