import React from 'react';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Form, Formik } from 'formik';
import { FormGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { pickBy, identity } from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormikFormTextField } from '../../components/FormikFields/FormikFormTextField';
import { useDialogFormStyles } from '../../components/FormikFields/FieldStyles';
import { FormikFormSelectField } from '../../components/FormikFields/FormikFormSelectField';
import { OutletSearchQuery, OutletType } from '../../store/virsSearch/virsSearchTypes';
import { language as languageSelector } from '../../store/virsisApp/virsisSelectors';

interface Props {
  setQuerySize?: (size: number) => void;
  searchVirs: (virsQuery: OutletSearchQuery) => void;
  isSearching: boolean;
  outletTypes?: OutletType[];
}
export interface Values {
  outletName: string;
  outletType: OutletType | null;
}

export const OutletSearchForm: React.FC<Props> = ({
  outletTypes,
  setQuerySize,
  searchVirs,
  isSearching
}) => {
  const language = useSelector(languageSelector);
  const classes = useDialogFormStyles();
  const { t } = useTranslation();

  const initValues: Partial<Values> = {
    outletName: '',
    outletType: null
  };
  const validationSchema = Yup.object().shape(
    {
      outletName: Yup.string().when(['outletType'], {
        is: (outletType) => !outletType,
        then: Yup.string().required(t('Validation at least one required'))
      }),
      outletType: Yup.object()
        .when(['outletName'], {
          is: (outletName) => !outletName,
          then: Yup.object().required(t('Validation at least one required'))
        })
        .nullable()
    },
    [['outletType', 'outletName']]
  );

  const clearForm = (setFieldValue: (id: string, value: string | null | undefined) => void) => {
    setFieldValue('outletType', null);
    setFieldValue('outletName', '');
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const searchQuery: OutletSearchQuery = {
          outletName: values.outletName ? values.outletName : undefined,
          outletTypeId: values.outletType?.outletTypeId || undefined
        };
        if (setQuerySize) {
          setQuerySize(Object.keys(pickBy(searchQuery, identity)).length);
        }
        searchVirs(searchQuery);
        setSubmitting(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm, setFieldValue, touched, errors, values, isValidating }) => {
        return (
          <MuiPickersUtilsProvider locale={language} utils={MomentUtils}>
            <Form>
              <FormGroup
                classes={{
                  root: classes.formGroup
                }}
              >
                <FormikFormTextField
                  errorText
                  label={t('VIP name')}
                  formikKey="outletName"
                  values={values.outletName}
                  error={errors.outletName}
                  setFieldValue={setFieldValue}
                  fullWidth={true}
                />
                <FormikFormSelectField
                  isRequired={false}
                  options={outletTypes || []}
                  touched={touched.outletType}
                  errors={errors.outletType}
                  formikKey="outletType"
                  setFieldValue={setFieldValue}
                  value={values.outletType}
                  defaultValue={null}
                  label={t('VIP type')}
                  isClearable
                  getOptionLabel={(option: OutletType) => option?.outletTypeName || ''}
                  fullWidth={true}
                />
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isValidating || isSearching}
                    onClick={submitForm}
                    className={classes.button}
                    startIcon={<SearchIcon className={classes.smallIcon} />}
                  >
                    <Typography color="inherit">{t('Search')}</Typography>
                  </Button>

                  <Button
                    variant="outlined"
                    disabled={isValidating || isSearching}
                    onClick={() => clearForm(setFieldValue)}
                    className={[classes.button, classes.greyButton].join(' ')}
                    style={{ marginRight: '4px' }}
                    startIcon={<ClearIcon className={classes.smallIcon} />}
                  >
                    <Typography color="inherit">{t('Clear')}</Typography>
                  </Button>
                </Box>
              </FormGroup>
              {isSearching && <LinearProgress style={{ margin: '1rem 0' }} />}
            </Form>
          </MuiPickersUtilsProvider>
        );
      }}
    </Formik>
  );
};
