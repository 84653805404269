import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { Order } from '../../../../../../utils/tableTypes';

export interface VirsLegalEntityFinancialSupportDataTableState {
  sortBy: VirsLegalEntityFinancialSupportColumnHeaderField;
  order: Order;
  page: number;
  recordsPassed: number;
  pagesCount: number;
  rowsPerPage: number;
  columnsDisplayStatus: VirsLegalEntityFinancialSupportColumnsDisplayStatus;
  customFilterOn: boolean;
  displayFilter: filterOptions;
  customFilter: VirsLegalEntityFinancialSupportTableCustomFilter;
}

export interface VirsLegalEntityFinancialSupportColumnHeader {
  id: VirsLegalEntityFinancialSupportColumnHeaderField;
  numeric: boolean;
  label: string;
}

export type VirsLegalEntityFinancialSupportColumnHeaderField =
  | 'supportProviderName'
  | 'supportProviderLegalCode'
  | 'financialSupportAmount'
  | 'supportReceivedYear';

export type VirsLegalEntityFinancialSupportColumnsDisplayStatus = {
  supportProviderName: boolean;
  supportProviderLegalCode: boolean;
  supportReceivedYear: boolean;
  financialSupportAmount: boolean;
};
export type VirsLegalEntityFinancialSupportTableCustomFilter = {
  supportProviderName: string[];
  supportProviderLegalCode: string[];
  supportReceivedYear: string[];
  financialSupportAmount: number[];
};

export type VirsLegalEntityFinancialSupportTableAction =
  | {
      type: 'SET_SORT_BY_COLUMN';
      sortBy: VirsLegalEntityFinancialSupportColumnHeaderField;
    }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: number }
  | { type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' }
  | {
      type: 'SET_CUSTOM_FILTER';
      filterBy: VirsLegalEntityFinancialSupportColumnHeaderField;
      value: string | null;
    }
  | {
      type: 'SET_DISPLAY_FILTER';
      value: filterOptions;
    }
  | {
      type: 'TOGGLE_DISPLAY_COLUMN';
      column: VirsLegalEntityFinancialSupportColumnHeaderField;
    };

export const virsInitialLegalEntityFinancialSupportTableState: VirsLegalEntityFinancialSupportDataTableState =
  {
    order: 'desc',
    sortBy: 'supportReceivedYear',
    displayFilter: 'allItems',
    page: 0,
    recordsPassed: 0,
    pagesCount: 0,
    rowsPerPage: 5,
    columnsDisplayStatus: {
      supportProviderName: true,
      supportProviderLegalCode: true,
      supportReceivedYear: true,
      financialSupportAmount: true
    },
    customFilterOn: false,
    customFilter: {
      supportProviderName: [],
      supportProviderLegalCode: [],
      supportReceivedYear: [],
      financialSupportAmount: []
    }
  };
