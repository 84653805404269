import * as Yup from 'yup';
import moment from 'moment';

export function noUndefinedString() {
  return Yup.string().defined().nullable();
}

export function noUndefinedNumber() {
  return Yup.number().defined().nullable();
}

export function noUndefinedDate() {
  return Yup.date().typeError('Neteisinga data').defined().nullable();
}

export const minDate = moment('1883-01-01');
export const maxDate = moment().add(100, 'year');
