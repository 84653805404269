import React from 'react';
import { Typography, Button, SvgIconTypeMap } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import theme from '../../style/virsisTheme';

interface Props {
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    universalButton: {
      maxWidth: 140,
      margin: 5,
      padding: '10px',
      borderColor: '#E5E5E5',
      backgroundColor: 'white',
      color: '#637082',
      '&:hover': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main
      },
      '&:active': {
        borderColor: theme.palette.primary.main,
        backgroundColor: '#DBEDFC',
        color: theme.palette.primary.main
      }
    },
    icon: {
      fontSize: '1.25rem',
      marginRight: 10,
      color: 'inherit'
    },
    text: {
      color: 'inherit',
      '&:hover': {
        color: 'inherit'
      }
    }
  })
);

export const DocumentActionButton: React.FC<Props> = ({
  onClick,
  text,
  IconComponent,
  disabled
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      className={classes.universalButton}
      disabled={disabled}
    >
      <IconComponent fontSize="small" className={classes.icon} />
      <Typography variant="h5" className={classes.text}>
        {text}
      </Typography>
    </Button>
  );
};
