import { VirsProfessionalMisconductColumnHeader } from './tableTypes';

export const columnNames: VirsProfessionalMisconductColumnHeader[] = [
  {
    id: 'decisionDate',
    numeric: false,
    label: 'Sprendimo data'
  },
  {
    id: 'documentNumber',
    numeric: false,
    label: 'Sprendimo nr.'
  },
  {
    id: 'outletName',
    numeric: false,
    label: 'Susijusios VIP'
  },
  {
    id: 'professionalMisconduct',
    numeric: false,
    label: 'Nustatytas pažeidimas'
  },
  {
    id: 'sanction',
    numeric: false,
    label: 'Poveikio priemonė(-s)'
  },
  {
    id: 'validFrom',
    numeric: false,
    label: 'Galioja nuo'
  },
  {
    id: 'validTo',
    numeric: false,
    label: 'Galioja iki'
  },
  {
    id: 'misconductReportingInstitutionName',
    numeric: false,
    label: 'Pažeidimą nustatė'
  }
];
