import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderOutletResponsiblePersonData } from '../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { useOutletResponsiblePersonsSectionTableState } from './ResponsiblePersonsSectionSection';

interface Props {
  record: ShareholderOutletResponsiblePersonData;
}

export const OutletResponsiblePersonsSectionRow: React.FC<Props> = ({ record }) => {
  const {
    state: { columnsDisplayStatus }
  } = useOutletResponsiblePersonsSectionTableState();
  return (
    <TableRow key={record.responsiblePersonId}>
      {columnsDisplayStatus.responsiblePersonFullName && (
        <TableCell>{record.responsiblePersonFullName}</TableCell>
      )}
      {columnsDisplayStatus.validFrom && <TableCell>{record.validFrom} </TableCell>}
      {columnsDisplayStatus.validTo && <TableCell>{record.validTo} </TableCell>}
      {columnsDisplayStatus.notes && (
        <TableCell dangerouslySetInnerHTML={{ __html: record.notes }} />
      )}
    </TableRow>
  );
};
