import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { OutletResponsiblePersonsSectionTable } from './ResponsiblePersonsSectionTable';
import { outletResponsiblePersonsSectionTableReducer } from './tableState/tableReducer';
import {
  OutletResponsiblePersonsTableState,
  OutletResponsiblePersonsSectionTableAction,
  initialOutletResponsiblePersonsSectionTableState
} from './tableState/tableTypes';

interface Props {
  chosenDate: string;
}

const OutletResponsiblePersonsSectionTableStateContext = createContext<{
  state: OutletResponsiblePersonsTableState;
}>({
  state: initialOutletResponsiblePersonsSectionTableState
});

const OutletResponsiblePersonsSectionTableDispatchContext = createContext<{
  dispatch: Dispatch<OutletResponsiblePersonsSectionTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const ResponsiblePersonsSection: React.FC<Props> = ({ chosenDate }) => {
  const [state, dispatch] = useReducer(
    outletResponsiblePersonsSectionTableReducer,
    initialOutletResponsiblePersonsSectionTableState
  );
  return (
    <OutletResponsiblePersonsSectionTableStateContext.Provider value={{ state }}>
      <OutletResponsiblePersonsSectionTableDispatchContext.Provider value={{ dispatch }}>
        <OutletResponsiblePersonsSectionTable chosenDate={chosenDate} />
      </OutletResponsiblePersonsSectionTableDispatchContext.Provider>
    </OutletResponsiblePersonsSectionTableStateContext.Provider>
  );
};

export function useOutletResponsiblePersonsSectionTableState() {
  return useContext(OutletResponsiblePersonsSectionTableStateContext);
}
export function useOutletResponsiblePersonsSectionTableDispatch() {
  return useContext(OutletResponsiblePersonsSectionTableDispatchContext);
}
