import { useEffect, useRef, useState } from 'react';

export const useTooltip = (title: string, maxLength?: number) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (context) {
        context.font = getComputedStyle(textRef.current).font;
        const width = context.measureText(title).width;
        setShowTooltip(width > (maxLength || textRef.current.offsetWidth));
      }
    }
  }, [title, maxLength]);

  return { textRef, showTooltip };
};
