import moment from 'moment';
import { ShareholderOutletResponsiblePersonData } from '../../../../../../store/virsTreeOutletInfo/outletInfoDataTypes';
import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import {
  Filter,
  filterData,
  getStringMappedColumnFilter,
  stableSortWrapped
} from '../../../../../../utils/tableDataFunctions';
import { OutletResponsiblePersonsTableState } from './tableTypes';

export function displayDataFilter(
  data: ShareholderOutletResponsiblePersonData[],
  displayFilter: filterOptions,
  chosenDate: string
): ShareholderOutletResponsiblePersonData[] {
  switch (displayFilter) {
    case 'todayItems':
      return data.filter((item) => {
        return moment().isBetween(item.validFrom, item.validTo || undefined, undefined, '[]');
      });
    case 'selectedDate':
      return data.filter((item) => {
        return moment(chosenDate).isBetween(
          item.validFrom,
          item.validTo || undefined,
          undefined,
          '[]'
        );
      });
    case 'allItems':
    default:
      return data;
  }
}

export function filterAndSortTableData(
  data: ShareholderOutletResponsiblePersonData[],
  tableState: OutletResponsiblePersonsTableState,
  chosenDate: string
): ShareholderOutletResponsiblePersonData[] {
  const filters: Filter<ShareholderOutletResponsiblePersonData>[] = [
    getStringMappedColumnFilter(
      tableState?.customFilter?.responsiblePersonFullName,
      ({ responsiblePersonFullName }) => [responsiblePersonFullName]
    ),
    getStringMappedColumnFilter(tableState.customFilter.validFrom, ({ validFrom }) => [validFrom]),
    getStringMappedColumnFilter(tableState.customFilter.validTo, ({ validTo }) => [validTo]),
    getStringMappedColumnFilter(tableState.customFilter.notes, ({ notes }) => [notes])
  ];

  const displayFiltered = displayDataFilter(data, tableState.displayFilter, chosenDate);
  const filtered = filterData(displayFiltered, filters);
  const sorted = stableSortWrapped(filtered, tableState.order, tableState.sortBy);
  return sorted;
}
