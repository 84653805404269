import { FormikProps } from 'formik';
import React from 'react';
import { FormikFieldStyles } from '../FieldStyles';
import { FormikFormSelectField } from '../FormikFormSelectField';
import { useDialogFormStyles } from './DialogFormStyles';

interface Props<TData, TOption> extends FormikFieldStyles {
  formikProps: FormikProps<TData>;
  label?: string;
  // eslint-disable-next-line
  field: keyof TData;
  isRequired?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  onChange?: (value: TOption | null) => void;
  options: { value: TOption; label: string }[];
  fullWidth?: boolean;
  withoutLabel?: boolean;
}

export const GenericSelectField = <TData, TOption>({
  isRequired,
  clearable,
  disabled,
  field,
  label,
  formikProps,
  options,
  onChange,
  style,
  fullWidth,
  withoutLabel
}: Props<TData, TOption>) => {
  const classes = useDialogFormStyles();
  return (
    <FormikFormSelectField
      styledClasses={classes}
      getOptionLabel={(option: TOption) =>
        options.find(({ value }) => value === option)?.label || ''
      }
      isClearable={!!clearable}
      isRequired={!!isRequired}
      label={label}
      disabled={disabled}
      formikKey={`${field}`}
      value={formikProps.values[field]}
      options={options.map((option) => option.value)}
      errors={formikProps.errors[field]}
      setFieldValue={formikProps.setFieldValue}
      defaultValue={null}
      onChange={onChange}
      style={style}
      fullWidth={fullWidth}
      withoutLabel={withoutLabel}
    />
  );
};

GenericSelectField.defaultProps = {
  isRequired: false,
  disabled: false,
  clearable: true,
  onChange: undefined,
  fullWidth: true
};
