import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderVirsFundsReceivedData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsFundsReceivedTableState } from './FundsReceivedSection';
import OutletNameButton from '../../../../../components/TableButtons/OutletNameButton';
import {replaceDecimalPoint} from "../../../../../utils/tableDataFunctions";

interface Props {
  record: ShareholderVirsFundsReceivedData;
  onClickOutlet: (outletId: number, outletName: string) => void;
}

export const FundsreceivedRow: React.FC<Props> = ({ record, onClickOutlet }) => {
  const { state: tableState } = useVirsFundsReceivedTableState();
  return (
    <TableRow key={record.fundsReceivedId}>
      {tableState.columnsDisplayStatus.fundsReceivedYear && (
        <TableCell>{record.fundsReceivedYear}</TableCell>
      )}
      {tableState.columnsDisplayStatus.fundsReceivedSum && (
        <TableCell>{replaceDecimalPoint(`${record.fundsReceivedSum}`)} </TableCell>
      )}
      {tableState.columnsDisplayStatus.fundsSourceName && (
        <TableCell>{record.fundsSourceName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.fundsSourceLegalCode && (
        <TableCell>{record.fundsSourceLegalCode}</TableCell>
      )}
      {tableState.columnsDisplayStatus.outletName && (
        <TableCell>
          {record.fundsReceivedOutlets.map((outlet) => (
            <OutletNameButton
              key={outlet.outletId}
              onClicked={() => {
                onClickOutlet(outlet.outletId, outlet.outletName);
              }}
              text={outlet.outletName}
            />
          ))}
        </TableCell>
      )}
      {tableState.columnsDisplayStatus.transactionType && (
        <TableCell>{record.transactionType}</TableCell>
      )}
    </TableRow>
  );
};
