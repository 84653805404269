import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { virsOutletsDataTableReducer } from './tableState/tableReducer';
import { VirsOutletsTable } from './OutletsTable';
import {
  initialOutletDataTableState,
  VirsOutletDataTableAction,
  VirsOutletDataTableState
} from './tableState/tableTypes';

interface Props {
  chosenDate: string;
  onClickOutlet: (outletId: number, outletName: string) => void;
}

const VirsOutletsTableStateContext = createContext<{
  state: VirsOutletDataTableState;
}>({
  state: initialOutletDataTableState
});

const VirsOutletsTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsOutletDataTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const OutletsSection: React.FC<Props> = ({ chosenDate, onClickOutlet }) => {
  const [state, dispatch] = useReducer(virsOutletsDataTableReducer, initialOutletDataTableState);

  return (
    <VirsOutletsTableStateContext.Provider value={{ state }}>
      <VirsOutletsTableDispatchContext.Provider value={{ dispatch }}>
        <VirsOutletsTable chosenDate={chosenDate} onClickOutlet={onClickOutlet} />
      </VirsOutletsTableDispatchContext.Provider>
    </VirsOutletsTableStateContext.Provider>
  );
};

export function useVirsOutletsTableState() {
  return useContext(VirsOutletsTableStateContext);
}
export function useVirsOutletsTableDispatch() {
  return useContext(VirsOutletsTableDispatchContext);
}
