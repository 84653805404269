import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { SideNavigation } from './SideNavigation';
import { Backdrop, Hidden } from '@material-ui/core';

const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: '#f5f7ff',
    borderRadius: 3,
    boxShadow: '2px 3px 5px 1px rgba(0,0,0,0.15)',
    marginTop: 40,
    height: 47,
    width: 36,
    zIndex: 12,
    position: 'fixed',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: `calc(${drawerWidth}px - 5px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  icon: {
    fill: '#006fb1'
  },
  drawer: {
    position: 'relative',
    width: drawerWidth,
    flexShrink: 0,
    marginBottom: 40
  },
  mobileDrawer: {
    width: 'auto'
  },
  mobileBackdrop: {
    zIndex: 12
  },
  drawerPaper: {
    position: 'absolute',
    width: drawerWidth,
    border: 0
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));

export const PersistentDrawerLeft: React.FC = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Hidden mdDown>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpen(!open)}
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          {open ? (
            <ChevronLeftIcon className={classes.icon} />
          ) : (
            <ChevronRightIcon className={classes.icon} />
          )}
        </IconButton>
        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
          style={{ zIndex: open ? 13 : 0 }}
          classes={{
            root: classes.drawer,
            paper: classes.drawerPaper
          }}
        >
          <SideNavigation />
        </Drawer>

        <main className={clsx(classes.content, open && classes.contentShift)}>{children}</main>
      </Hidden>
      <Hidden lgUp>
        <Backdrop
          className={classes.mobileBackdrop}
          open={mobileOpen}
          onClick={() => setMobileOpen(false)}
        />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setMobileOpen(!mobileOpen)}
          className={clsx(classes.appBar, mobileOpen && classes.appBarShift)}
        >
          {mobileOpen ? (
            <ChevronLeftIcon className={classes.icon} />
          ) : (
            <ChevronRightIcon className={classes.icon} />
          )}
        </IconButton>
        <Drawer
          variant="persistent"
          anchor="left"
          open={mobileOpen}
          style={{ zIndex: mobileOpen ? 13 : 0 }}
          classes={{
            root: clsx(classes.drawer, classes.mobileDrawer),
            paper: classes.drawerPaper
          }}
        >
          <SideNavigation />
        </Drawer>

        <main className={clsx(classes.content, classes.contentShift)}>{children}</main>
      </Hidden>
    </div>
  );
};
