import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { VirsEnterpriseTable } from './EnterpriseTable';
import { virsEnterpriseDataTableReducer } from './tableState/tableReducer';
import {
  initialVirsEnterpriseTableState,
  VirsEnterpriseTableAction,
  VirsEnterpriseTableState
} from './tableState/tableTypes';

interface Props {
  chosenDate: string;
}

const VirsEnterpriseTableStateContext = createContext<{
  state: VirsEnterpriseTableState;
}>({
  state: initialVirsEnterpriseTableState
});

const VirsEnterpriseTableDispatchContext = createContext<{
  dispatch: Dispatch<VirsEnterpriseTableAction>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

export const EnterpriseSection: React.FC<Props> = ({ chosenDate }) => {
  const [state, dispatch] = useReducer(
    virsEnterpriseDataTableReducer,
    initialVirsEnterpriseTableState
  );

  return (
    <VirsEnterpriseTableStateContext.Provider value={{ state }}>
      <VirsEnterpriseTableDispatchContext.Provider value={{ dispatch }}>
        <VirsEnterpriseTable chosenDate={chosenDate} />
      </VirsEnterpriseTableDispatchContext.Provider>
    </VirsEnterpriseTableStateContext.Provider>
  );
};

export function useVirsEnterpriseTableState() {
  return useContext(VirsEnterpriseTableStateContext);
}
export function useVirsEnterpriseTableDispatch() {
  return useContext(VirsEnterpriseTableDispatchContext);
}
