import { call, put, takeEvery, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { getMenuData } from './virsisApiTvs';
import { getMenuDataSuccess, fetchPdfData } from './virsisActions';
import { getDocument } from './virsisApi';
import { language } from './virsisSelectors';
import { FETCH_PDF_DOWNLOAD, GET_MENU_DATA_REQUEST } from './virsisActionTypes';
import { MenuItem } from './virsisDataTypes';

function* getHeaderDataSaga() {
  try {
    const lang = yield select(language);
    const { data }: { data: MenuItem[] } = yield call(getMenuData);
    yield put(getMenuDataSuccess(data, lang));
  } catch (err) {
    console.log(err);
  }
}

function* handlePdfDataRequest(action: ActionType<typeof fetchPdfData>) {
  const { documentId, pathPrefix, onData } = action.payload;

  try {
    const { data } = yield call(getDocument, documentId, pathPrefix);
    const file = new Blob([data], { type: 'application/pdf' });
    if (onData) {
      onData(file);
    }
  } catch (err) {
    console.log(err);
  }
}

function* virsisSaga() {
  yield takeEvery(GET_MENU_DATA_REQUEST, getHeaderDataSaga);
  yield takeEvery(FETCH_PDF_DOWNLOAD, handlePdfDataRequest);
}

export default virsisSaga;
