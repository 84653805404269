import React from 'react';
import { TableCell, TableRow } from '@material-ui/core/';
import { ShareholderVirsLegalEntityFinancialSupportData } from '../../../../../store/virsTreeVirsInfo/virsInfoDataTypes';
import { useVirsLegalEntityFinancialSupportTableState } from './LegalEntityFinancialSupportSection';
import { replaceDecimalPoint } from '../../../../../utils/tableDataFunctions';

interface Props {
  record: ShareholderVirsLegalEntityFinancialSupportData;
}

export const LegalEntityFinancialSupportRow: React.FC<Props> = ({ record }) => {
  const { state: tableState } = useVirsLegalEntityFinancialSupportTableState();

  return (
    <TableRow key={record.financialSupportReceivedId}>
      {tableState.columnsDisplayStatus.supportProviderName && (
        <TableCell>{record.supportProviderName}</TableCell>
      )}
      {tableState.columnsDisplayStatus.supportProviderLegalCode && (
        <TableCell>{record.supportProviderLegalCode} </TableCell>
      )}
      {tableState.columnsDisplayStatus.financialSupportAmount && (
        <TableCell>{`${replaceDecimalPoint(`${record.financialSupportAmount}`)} ${
          record.currencyCode ? record.currencyCode : 'EUR'
        }`}</TableCell>
      )}
      {tableState.columnsDisplayStatus.supportReceivedYear && (
        <TableCell>{record.supportReceivedYear}</TableCell>
      )}
    </TableRow>
  );
};
