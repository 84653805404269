import { filterOptions } from '../../../../../../store/virsisApp/virsisDataTypes';
import { ColumnHeader, SortOrder } from '../../../../../../utils/tableTypes';

export interface OutletResponsiblePersonsTableState {
  sortBy: OutletResponsiblePersonsColumnHeaderField;
  order: SortOrder;
  displayFilter: filterOptions;
  page: number;
  rowsPerPage: number;
  columnsDisplayStatus: OutletResponsiblePersonsSectionColumnsDisplayStatus;
  customFilterOn: boolean;
  customFilter: OutletResponsiblePersonsSectionTableCustomFilter;
}

export const initialOutletResponsiblePersonsSectionTableState: OutletResponsiblePersonsTableState =
  {
    order: 'desc',
    sortBy: 'validFrom',
    displayFilter: 'allItems',
    page: 0,
    rowsPerPage: 5,
    columnsDisplayStatus: {
      responsiblePersonFullName: true,
      validFrom: true,
      validTo: true,
      notes: true
    },
    customFilterOn: false,
    customFilter: {
      responsiblePersonFullName: [],
      validFrom: [],
      validTo: [],
      notes: []
    }
  };

export type OutletResponsiblePersonsColumnHeader =
  ColumnHeader<OutletResponsiblePersonsColumnHeaderField>;

export type OutletResponsiblePersonsColumnHeaderField =
  | 'responsiblePersonFullName'
  | 'validFrom'
  | 'validTo'
  | 'notes';

export type OutletResponsiblePersonsSectionColumnsDisplayStatus = {
  responsiblePersonFullName: boolean;
  validFrom: boolean;
  validTo: boolean;
  notes: boolean;
};

export type OutletResponsiblePersonsSectionTableCustomFilter = {
  responsiblePersonFullName: string[];
  validFrom: string[];
  validTo: string[];
  notes: string[];
};

export type OutletResponsiblePersonsSectionTableAction =
  | {
      type: 'TABLE_INITIALIZED';
    }
  | {
      type: 'SET_SORT_BY_COLUMN';
      sortBy: OutletResponsiblePersonsColumnHeaderField;
    }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_ROWS_PER_PAGE'; rowsPerPage: number }
  | { type: 'TOGGLE_DISPLAY_CUSTOM_FILTER' }
  | {
      type: 'SET_CUSTOM_FILTER';
      filterBy: OutletResponsiblePersonsColumnHeaderField;
      value: string | null;
    }
  | {
      type: 'TOGGLE_DISPLAY_COLUMN';
      column: OutletResponsiblePersonsColumnHeaderField;
    }
  | { type: 'TOGGLE_STATUS_NOT_SIGNED' }
  | { type: 'TOGGLE_STATUS_RELEVANT' }
  | { type: 'TOGGLE_STATUS_OUTDATED' }
  | { type: 'SET_DISPLAY_FILTER'; value: filterOptions };
